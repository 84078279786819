import $ from 'jquery';
import extend from 'extend';
import { log, isSet, isObject, emptyObject, toInt } from '@/data/helpers';

// list of properties that should be copied to the 'shadow div' that mocks the input container
const copyStyles = [
  'boxSizing',
  'width', // on Chrome and IE, exclude the scrollbar, so the shadow div wraps exactly as the textarea does
  'height',
  'overflowX',
  'overflowY', // copy the scrollbar for IE

  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',

  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',

  // https://developer.mozilla.org/en-US/docs/Web/CSS/font
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'lineHeight',
  'fontFamily',

  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration', // might not make a difference, but better be safe

  'letterSpacing',
  'wordSpacing',
];

// known, static styling for the shadow div
const staticStyles = {
  position: 'absolute',
  top: '-10000px',
  left: '-10000px',
  visibility: 'hidden',
  pointerEvents: 'none',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
};

// there are exceptions for firefox

function buildStyles(fromEle, baseList) {
  const base = extend({}, !isObject(baseList) || emptyObject(baseList) ? {} : baseList);
  const computed = window.getComputedStyle(fromEle);
  copyStyles.forEach((k) => {
    if (isSet(computed[k])) {
      base[k] = computed[k];
    } else {
      base[k] = '';
    }
  });
  const isFirefox = !(window.mozInnerScreenX == null);

  // handle special cases for firefox
  if (isFirefox) {
    // fix erroneous width specifications
    const correctWidth = computed.width - 2;
    base.width = `${correctWidth}px`;

    // they lie about overflow too
    if (fromEle.scrollHeight > computed.height) {
      base.overflow = 'scroll';
    }
  // if not firefox, make sure we dont overflow
  } else {
    base.overflow = 'hidden'; // NOTE: ie keeps the separate property overflowY=scroll, which we want
  }

  return base;
}

function assignStyles(ele, styles) {
  const el = ele;
  Object.keys(styles).forEach((k) => {
    el.style[k] = styles[k];
  });
}

let shadowDiv;
function getShadowDiv() {
  if (isSet(shadowDiv)) {
    return shadowDiv;
  }

  shadowDiv = document.createElement('div');
  assignStyles(shadowDiv, staticStyles);
  document.body.appendChild(shadowDiv);

  return shadowDiv;
}

function emptyContainer(ele) {
  Array.from(ele.children).forEach((child) => {
    ele.removeChild(child);
  });
}

/**
 * caretOffset
 *
 * measure the caret offset inside the given container
 *
 * @param Element ele  the element in which the caret should exist
 * @return object  description of the caret pixel position
 */
function caretOffset(ele) {
  const offset = { top: 0, left: 0 };
  emptyContainer(ele);
  // handle containers that dont have a selection option
  if (!isSet(ele.selectionEnd)) {
    return offset;
  }
  const position = ele.selectionEnd;

  // copy the styles from the container to the shadow
  const shadow = getShadowDiv();
  const newStyles = buildStyles(ele, staticStyles);
  assignStyles(shadow, newStyles);

  // copy the content BEFORE the caret to the shadow
  shadow.textContent = ele.value.substring(0, position);

  // create a container for the remainder of the content
  const span = document.createElement('span');
  span.textContent = ele.value.substring(position) || '.'; // use a '.' here if empty, because otherwise it is not actually rendered
  shadow.appendChild(span);

  // compile the position information
  offset.top = span.offsetTop + toInt(newStyles.borderTopWidth);
  offset.left = span.offsetLeft + toInt(newStyles.borderLeftWidth);

  return offset;
}

/**
 * cursorPosition
 *
 * get the current cursor position inside the specified element, as well as information about the current word the cursor is inside
 *
 * @param Element ele  the element that we want the cursor position from
 * @return object  the object describing the current position of the cursor
 */
function cursorPosition(element, delimiter = /[\s.,?!@();:'"]/) {
  const ele = $(element).get(0);
  const data = {
    element: ele,
    text: '',
    length: 0,
    word: {
      text: '',
      length: 0,
      position: 0,
    },
    selection: {
      text: '',
      length: 0,
      start: 0,
      end: 0,
    },
    caret: {
      top: 0,
      left: 0,
    },
    hashtag: {
      is: false,
      text: '',
    },
  };

  // if the element exists and has a start position for the selection, update all the cursor info
  if (ele && isSet(ele.selectionStart)) {
    const v = ele.value;
    data.text = v;
    data.length = v.length;
    data.position = ele.selectionStart;
    data.char = '';
    data.selection.start = data.position;
    data.selection.end = ele.selectionEnd;
    data.selection.length = data.selection.end - data.selection.start;
    data.selection.text = v.substr(data.selection.start, data.selection.length);

    // figure out the word that is currently focused
    const before = v.substr(0, data.position).split(delimiter);
    const after = v.substr(data.position).split(delimiter);
    let beginning = before.pop() || '';
    // handle scenario when last char is a space
    if (before.length > 1) {
      if (beginning === '') {
        beginning = before.pop();
        beginning = `${beginning} `;
        data.char = ' ';
      } else {
        data.char = beginning.substr(-1);
      }
    }
    const ending = after.shift();

    // fix franken hashtags
    let last = '';
    let tempWord = data.char === ' ' ? '' : beginning + ending;
    if (tempWord.match(/#/)) {
      const tempWordPieces = tempWord.split(/#/);
      last = tempWordPieces.pop();
      last = `#${last}`;
      tempWord = tempWordPieces.join('#');
    } else {
      last = tempWord;
    }

    data.word.text = last;
    data.word.length = data.word.text.length;
    data.word.position = data.position - beginning.length;

    // is this word a hashtag?
    // if (data.word.text.substr(0, 1) === '#' && data.word.text.replace(/\s+$/, '').length > 1) {
    if (data.word.text.substr(0, 1) === '#') {
      data.hashtag.is = true;
      data.hashtag.text = data.word.text.substr(1).replace(/\s+$/, '');
      data.hashtag.normalized = data.hashtag.text.toLowerCase();
    }

    // measure caret position
    data.caret = caretOffset(ele);
  } else {
    log('CURSOR:', 'CANNOT update the data', ele);
  }

  return data;
}

/**
 * stubHashtag
 *
 * build a fake result, based solely on the provided string
 *
 * @param string name  the name of the tag to make a fake result for
 * @return object  a fake object describing a fake tag
 */
function stubHashtag(name, slug = null, _id = null) {
  const obj = {
    match: 'exact',
    hash_tag: name,
    // eslint-disable-next-line
    _normalized_hash_tag: slug || name,
    slug: slug || name,
    title: name,
    action_counts: { posts: 0, follows: 0, contributors: 0, partners: 0 },
    flags: { mighty_topic: false },
    wasStubbed: true,
  };
  if (_id) {
    obj._id = _id;
  }
  return obj;
}

export {
  cursorPosition,
  caretOffset,
  stubHashtag,
};

export default {};
