/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';
import { log } from '@/data/helpers';
import userApi from '@/data/api/user';
import promptsApi from '@/data/api/prompts';
import dashboardApi from '@/data/api/dashboard';
import { v4 as uuidv4 } from 'uuid';

const state = () => ({
    userSettings: {},
    // accountDetails is from Node (compared to WP), and includes some additional fields we need
    accountDetails: {},
    conditionsList: {},
    promptsList: [],
    usersPrompts: [],
    storySubmissions: [],
});

const getters = {
    userSettings: currentState => currentState.userSettings,
    getAccountDetails: currentState => currentState.accountDetails,
    getConditionsList: currentState => currentState.conditionsList,
    getPromptsList: currentState => currentState.promptsList,
    getUsersPrompts: currentState => currentState.usersPrompts,
    getUsersStorySubmissions: currentState => currentState.storySubmissions,
};

const actions = {
    // the route for getting a user_follows for some reason returns all of that users groups as well, no matter the filter, if the offset is 0
    loadUserSettings(context) {
        return userApi.getUserSettings(this.$wpAxios)
            .then((res) => {
                if (res.U) {
                    context.commit('setUserSettings', res.U);
                }
            })
            .catch((err) => {
                log('Error loading user settings', err);
            });
    },
    loadAccountDetails(context, username) {
        return this.$nodeAxios.get(`/api/v1/user/${username}`)
            .then(response => { 
                const user = _.get(response, 'data.user', {});
                context.commit('setUserPrompts', user.prompts || []);
                context.commit('setAccountDetails', user);
             })
            .catch((err) => {
                log('Error loading user prompts', err);
            });
    },
    loadConditionsList(context) {
        return dashboardApi.getUserConditions(this.$wpAxios)
            .then((conditions) => {
                context.commit('setConditions', conditions);
            })
            .catch((err) => {
                log('Error loading conditions', err);
            });
    },
    loadPromptsList(context) {
        return promptsApi.getPromptsList(this.$nodeAxios)
            .then((prompts) => {
                context.commit('setPromptsList', prompts);
            })
            .catch((err) => {
                log('Error loading prompts list', err);
            });
    },
    savePromptResponse(context, { userId, prompt }) {
        return promptsApi.savePromptResponse(userId, prompt, this.$nodeAxios)
            .then(prompts => { 
                // want to add the new response to user prompts
                const _id = uuidv4();
                // text is response text, inner nested prompt is prompt question id (ie. not user related)
                const newPrompt = { _id, text: prompt.text, prompt: { _id: prompt._id, } };
                context.commit('addUserPrompt', newPrompt);
             })
            .catch((err) => {
                log('Error saving user prompt', err);
            });
    },
    deletePromptResponse(context, { userId, promptId }) {
        return promptsApi.deletePromptResponse(userId, promptId, this.$nodeAxios)
            .then(() => { context.commit('deleteUserPrompt', promptId); })
            .catch((err) => {
                log('Error deleting user prompt', err);
            });
    },
    updateAvatarImage(context, { formData, nonce, wpAxios }) {
        return userApi.WpAjaxCall({ formData, sa: 'upload-image', nonce, wpAxios })
        .then((res) => {
            const url = _.get(res, 'I.url');
            if (url) {
                context.commit('setAvatarImage', url);
            }
            return url;
        })
        .catch((err) => {
            log('Error updating the avatar image', err);
        });
    },
    saveAccountDetails(context, { formData, nonce, wpAxios }) {
        return userApi.WpAjaxCall({ formData, sa: 'save-account-details', nonce, wpAxios })
            .then((res) => {
                const data = { status: '', };

                if (res.E && res.E.length > 0) {
                  data.status = 'error';
                  data.errors = [...res.E];
                }

                if (res.M && res.M.length > 0) {
                    if (_.some(res.M, message => message = 'Your account details have been saved.')) {
                        context.commit('setUserSettings', res.U);
                        data.status = 'success';
                    }
                }

                return data;
            })
            .catch((err) => {
                log('Error saving account details', err);
            });
    },
    saveUserPreferences(context, { formData, nonce, wpAxios }) {
        return userApi.WpAjaxCall({ formData, sa: 'save-preferences', nonce, wpAxios })
            .then((res) => {
                const data = { status: '', };

                if (res.E && res.E.length > 0) {
                  data.status = 'error';
                  data.errors = [...res.E];
                }

                if (res.M && res.M.length > 0) {
                    if (_.some(res.M, message => message = 'Your account details have been saved.')) {
                        context.commit('setUserSettings', res.U);
                        data.status = 'success';
                    }
                }

                return data;
            })
            .catch((err) => {
                log('Error saving email preferences', err);
            });
    },
};

const mutations = {
    setUserSettings(currentState, userSettings) {
        Vue.set(currentState, 'userSettings', userSettings);
    },
    setAccountDetails(currentState, data) {
        Vue.set(currentState, 'accountDetails', data);
    },
    setConditions(currentState, conditions) {
        Vue.set(currentState, 'conditionsList', conditions);
    },
    setPromptsList(currentState, prompts) {
        Vue.set(currentState, 'promptsList', prompts);
    },
    setUserPrompts(currentState, prompts) {
        // user responses aren't being removed for prompts that have been deleted
        // therefore ensuring there is a respective prompt object
        Vue.set(currentState, 'usersPrompts', prompts.filter(p => p.prompt && p.prompt._id));
    },
    addUserPrompt(currentState, prompt) {
        const prompts = _.filter(currentState.usersPrompts, up => up.prompt._id !== prompt.prompt._id);
        prompts.push(prompt);
        Vue.set(currentState, 'usersPrompts', prompts);
    },
    deleteUserPrompt(currentState, promptId) {
        Vue.set(currentState, 'usersPrompts', currentState.usersPrompts.filter(p => p.prompt._id !== promptId));
    },
    setAvatarImage(currentState, url) {
        if (currentState.userSettings && currentState.userSettings.img) {
            const userSettings = _.assign({}, currentState.userSettings);
            userSettings.img.url = url;
            Vue.set(currentState, 'userSettings', userSettings);
        }
    },
}

// export this module
export default {
    state,
    getters,
    actions,
    mutations,
  };
