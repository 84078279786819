/**
 * This file contains utility methods for creating analytics events data parameters
 */

import { Post } from '@/types/models/post';
import { Group } from '@/types/models/group';
import { Search } from '@/types/models/search';
import { Topic, TopicIdsBag } from '@/types/models/topic';
import segmentAnalytics from '@/libs/segment-analytics';

export { triggerViewPost, triggerViewCollection } from './view-events';

export default segmentAnalytics;


export const getSegmentGroupIds = (post: { groups: Group[]; }) => (post.groups.map(group => group.id || group._id));
export const getSegmentGroupNames = (post: { groups: Group[]; }) => (post.groups.map(group => group.name));

export const getSegmentTopicIds = (post: { topics: Topic[]; }) => (post.topics.map(topic => topic.id || topic._id));
// eslint-disable-next-line camelcase
export const getSegmentTopicNames = (post: { topics: Topic[]; }) => (post.topics.map(topic => topic.name || topic.slug || topic.hash_tag));

export const getSegmentTopicIdsFromTopic = (post: { topic: { ids: string []; }; }) => (post.topic ? post.topic.ids : []);
export const getSegmentTopicNamesFromTopic = (post: { topic: { names: string[]; slugs: string[]; }; }) => (post.topic ? post.topic.names || post.topic.slugs : []);

export const getSegmentUser = ({ post } : { post: Post}) => {
  const user = post.user || {};
  return {
    type: user.type,
    id: user.id || user._id,
    name: user.username,
  };
};

export const getSegmentGroupCard = ({ cardBody, cardIndex, cardType, post }: { cardBody: any, cardIndex: number, cardType: string, post: Post }) => ({
  content_id: post.id || post._id, // [id of content]
  title: post.title,
  type: cardType,
  body: cardBody,
  ...Number.isInteger(cardIndex) ? { index: cardIndex } : {}, // [index of card in set]
});

export const getSegmentGroupFeedView = ({ post, group, viewName }: {post: Post | TopicIdsBag, group: Group, viewName: string}) => {
  const groupFeedPost = post as Post;
  const topicIdsBag = post as TopicIdsBag;
  if (groupFeedPost as Post) {
    return {
      name: viewName || 'post_feed',
      group: {
        ids: !Array.isArray(groupFeedPost.groups) ? [] : getSegmentGroupIds(groupFeedPost),
        names: !Array.isArray(groupFeedPost.groups) ? [] : getSegmentGroupNames(groupFeedPost),
      },
      topic: {
        ids: !Array.isArray(groupFeedPost.topics) ? getSegmentTopicIdsFromTopic(topicIdsBag) : getSegmentTopicIds(groupFeedPost),
        names: !Array.isArray(groupFeedPost.topics) ? getSegmentTopicNamesFromTopic(topicIdsBag) : getSegmentTopicNames(groupFeedPost),
      },
    };
  }

  return {
    name: viewName || 'group_feed',
    group: {
      ids: [group._id],
      names: [group.name],
    },
  };
};

export const getSegmentGroupTarget = ({ post, group }: {post: Post, group: Group}) => ({
  id: post ? post.id || post._id : group._id,
  name: post ? post.title : group.name,
  type: post ? post.type : group.type,
});


export const getEventContextView = (data: { gaCategory: string } | undefined) => {
  let view = 'home_feed';
  if (location.href.match(/\/u\//)) {
    view = 'user_profile';
  } else if (location.href.match(/\/groups\//)) {
    view = 'group_feed';
  } else if (location.href.match(/\/topic\//)) {
    view = 'topic_feed';
  } else if (data && data.gaCategory && data.gaCategory.includes('story_card_full')) {
    view = data.gaCategory;
    if (data.gaCategory.includes('story_card_full')) {
      view = 'story_page';
    }
  }
  return view;
};

export const trackPost = (type: 'thought' | 'question' | 'poll', post: Post) => {
  const group : Group | false = Array.isArray(post.groups) && post.groups[0];

  if (type !== 'poll') {
    segmentAnalytics.post({
      post: {
        id: post.id,
        title: post._normalized_title,
        topic: {
          names: post.topics.map(topic => topic.title),
          ids: post.topics.map(topic => topic.id),
        },
        user: {
          username: post.author_id.username,
          id: post.author_id.id,
          displayname: post.author_id.display_name,
          type: post.author_id.role_slug,
        },
        type,
      },
      context: {
        ...(group as Group)?._id ? {
          group: {
            id: (group as Group)._id,
            name: (group as Group).name,
          },
        } : {},
        section: 'submit_post',
        view: getEventContextView(this),
      },
      target: {
        type: 'post',
      },
    });
  } else {
    segmentAnalytics.createPoll({
      post: {
        id: post.id,
        title: post.title,
        topic: {
          names: post.topics?.map(topic => topic.hash_tag),
          ids: post.topics?.map(topic => topic.id),
        },
        user: {
          username: post.author_id.username,
          id: post.author_id.id,
          displayname: post.author_id.display_name,
          type: post.author_id.role_slug,
        },
        type: post.poll_type === 'SINGLE' ? 'poll_single' : 'poll_multi',
      },
      context: {
        ...(group as Group)?._id ? {
          group: {
            id: (group as Group)._id,
            name: (group as Group).name,
          },
        } : {},
      },
      target: {
        type: 'poll',
      },
    })
  }
};

export const searchEvent = (payload: Search) => ({
  ...payload,
});
