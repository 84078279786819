import { log } from '../helpers';

/**
 * User authorization
 * @param  data   form data to be submitted
 * @return {[type]}        [description]
 */
export function authUser(data, $nodeAxios) {
  return $nodeAxios.post('/api/v1/chats/auth', data)
        .then(true)
        .catch((error) => {
          log('authUser', error.data);
          return false;
        });
}

/**
 * Starts a new chat with someone
 * @param  partnerId  your partner's id
 * @return {[type]}        [description]
 */
export function startChat(partnerId, $nodeAxios) {
  return $nodeAxios.post('/api/v1/chats', { partner_id: partnerId })
        .then(res => res.data)
        .catch((error) => {
          log('startChat', error);
          return false;
        });
}

/**
 * Mute the channel
 * @param  channelId  your channel's id
 * @return {[type]}        [description]
 */
export function muteChat(channelId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/chats/${channelId}/mute`)
        .then(res => res)
        .catch((error) => {
          log('muteChat', error);
          return false;
        });
}

/**
 * Unmute the channel
 * @param  channelId  your channel's id
 * @return {[type]}        [description]
 */
export function unmuteChat(channelId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/chats/${channelId}/unmute`)
        .then(res => res.data)
        .catch((error) => {
          log('unmuteChat', error);
          return false;
        });
}

/**
 * Report the channel
 * @param  channelId  your channel's id
 * @return {[type]}        [description]
 */
export function reportChat(channelId, feedback, messages, $nodeAxios) {
  const reportedMessageId = messages.length > 0 ? messages[0] : null;
  return $nodeAxios.post(`/api/v1/chats/${channelId}/report`, { reported_message_id: reportedMessageId, feedback, message_ids: messages })
        .then(res => res.data)
        .catch((error) => {
          log('reportChat', error);
          return Promise.reject();
        });
}

/**
 * Report a user by their ID.
 *
 * @param {String} userId : mongoId
 */
export function reportUser(userId, feedback, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/user/${userId}/report`, { type: 'profile', feedback })
  .then(response => response.data)
  .catch(error => log('reportUser', userId, error));
}

/**
 *
 * @param {String} messageId  : id of message to save
 */
export function addSavedMessage(messageId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/messages/${messageId}/bookmark`, {})
    .then(res => res.data)
    .catch(error => log('addSavedMessage', error));
}

/**
 *
 * @param {String} messageId  : id of message to remove
 */
export function removeSavedMessage(messageId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/messages/${messageId}/bookmark`, {})
    .then(res => res.data)
    .catch(error => log('removeSavedMessage', error));
}

/**
 *
 * @param {Array} messages  : list of messages to retrieve the status for
 */
export function chatSavedMessages(messages, $nodeAxios) {
  return $nodeAxios.post('/api/v1/chats/search', { ids: messages, saved: true })
    .then(res => res.data)
    .catch(error => log('chatSavedMessages', error));
}

/**
 *
 * @param {string} channelId  : the channelId to upload the image to
 * @param {object} file  : keys of contentType and extension of the file
 */
export function chatUploadImage(channelId, { contentType, extension }, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/chats/${channelId}/upload?content_type=${contentType}&extension=${extension}`, {})
    .then(res => res.data)
    .catch((error) => {
      log('chatUploadImage', error);
      return false;
    });
}

/**
 *
 * Verifies if user has existing Stream account
 */
export function verifyUser($nodeAxios) {
  return $nodeAxios.get('/api/v1/chats/user', {})
    .then(res => res)
    .catch((e) => {
      log('verifyUser', e);
      return e.response;
    });
}
