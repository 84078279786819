/* eslint-disable no-param-reassign */
import { Quill } from 'vue2-editor';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

/**
 * Strip out html from clipboard before pasting text
 */
class QuillPlainClipboard extends Clipboard {
  onPaste(event) {
    const options = this.options;
    event.preventDefault();
    const range = this.quill.getSelection();
    const text = event.clipboardData.getData('text/plain');
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);

    if (options.newLines === false) {
      delta.ops.map((op) => {
        if (!(typeof op.insert === 'undefined')) {
          op.insert = op.insert.replace(/(\r\n|\n|\r)/gm, ' ');
        }
        return op;
      });
    }

    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta);
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

export default QuillPlainClipboard;
