import { Quill } from 'vue2-editor';

const Embed = Quill.import('blots/embed');

class TopicNew extends Embed {
  static create(data) {
    const node = super.create();
    node.innerHTML += `${data.denotationChar}${data.value}`;
    return TopicNew.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    domNode.setAttribute('name', data.value);
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

TopicNew.blotName = 'topic-new';
TopicNew.className = 'topic';
TopicNew.tagName = 'topic-new';

export default TopicNew;
