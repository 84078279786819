import Vue from 'vue';
import Vuelidate from 'vuelidate';
import TmHumanNumber from '@/filters/tmHumanNumber';
import ElementIds from '@/plugins/ElementIds';
import VueEvents from 'vue-events';
import VuePluralize from 'vue-pluralize';

Vue.use(Vuelidate);
Vue.use(TmHumanNumber);
Vue.use(ElementIds);
Vue.use(VueEvents);
Vue.use(VuePluralize);

