
import { mapGetters } from 'vuex';
import { staticPath } from '@/data/helpers';

export default {
  name: 'MenuDropdownMobile',
  props: ['closeMenu'],
  data() {
    return {
      showExploreMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getMyNotifications: 'getMyNotifications',
    }),
    isLogged() {
      return this.user && this.user.id;
    },
  },
  mounted() {
    this.closeMenu(false);
  },
  methods: {
    staticPath,
    toggleExploreMenu() {
      this.showExploreMenu = !this.showExploreMenu;
    },
    // only for sprint because sprint is using a SPA feature
    clearWindowSessionSkip($event, navigate) {
      window.localStorage.removeItem('skippedAcceptPrivacyPolicy');
      navigate($event);
      this.closeMenu(true);
    },
    exploreHandler(navigate) {
      navigate();
      this.closeMenu(true);
    }
  },
};
