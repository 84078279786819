const script = document.createElement('script')
script.src = 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
// script.setAttribute('data-domain-script', '5d528c1d-2f98-40c9-ae55-30fa62060e70')
script.setAttribute('data-domain-script', '29c160ec-d632-4a07-9044-3b2de2713586')
script.type = 'text/javascript'
script.charset = 'UTF-8'
script.setAttribute('data-language', 'en')
document.head.appendChild(script)
script.addEventListener('load', () => {
  function OptanonWrapper() { }
});