/* eslint-disable no-unused-vars */
import { isNumber, clamp, err, log, DataError } from '../helpers';

function sanitizePost() {
}

/**
 * sanitizePostsResponse
 *
 * consumes the raw response from ajax, and makes sure that all the required fields are present on each returned post
 *
 * @param object response  the raw data from the ajax
 * @return object  the translated version of the data
 */
function sanitizePostsResponse(response) {
  response.items.forEach(sanitizePost);
  return response;
}

/**
 * getFeedFromUrl
 *
 * gets the feed items from a url
 *
 * @param string url  the url of the feed
 * @param object query  the query parameters for the url for the feed
 * @return Promise  the promise containing the results
 */
function getFeedFromUrl(url, query, $nodeAxios) {
  // perform the request and generate a promise for the response
  const promise = $nodeAxios.get(url, { params: query })
    // get the response
    .then((response) => {
      const feeds = sanitizePostsResponse(response.data);

      // return the results
      return feeds;
    })
    .catch(err);

  return promise;
}
/**
 * getFeedHandler
 *
 * get the functionThat will be used to generate the feed items list for the
 * given feed
 *
 * @param string feedName the name of the feed that we need the url for
 * @return function the function that will be used to fetch the results
 */
function getFeedHandler(feedName, query, $nodeAxios) {
  const func = () => {
    let url;
    if (feedName.substr(-6) === 'sStats') {
      url = `/api/v1/moderation/by-stats/${feedName.toLowerCase().substr(0, feedName.length - 6)}`;
    } else if (feedName === '2ndReview') {
      url = `/api/v1/moderation/2nd-review`;
    } else {
      url = `/api/v1/moderation/${feedName.toLowerCase()}`;
    }
    return getFeedFromUrl(url, query, $nodeAxios);
  };
  return func;
}

/**
 * getModerationItems
 *
 * get the list of the items in moderation list
 *
 * @param integer of set the offset of the list of items to get the items after. (default 0)
 * @param integer limit the number of items to return. (default 10)
 */

function getFeedItems(feedName, offset, limit, status, $nodeAxios) {
  try {
    const query = {
      pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
      off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    };

    if (status) {
      query.status = status;
    }
    // get the function that will fetch the requested feed items
    const func = getFeedHandler(feedName, query, $nodeAxios);
    if (!func) {
      throw new DataError(`No api endpoint handler is defined for the feed '${feedName}'.`, { feedName, query });
    }

    return func();
  } catch (e) {
    return {};
  }
}

function getSingleItem(itemId, $nodeAxios) {
  try {
    const func = () => {
      const url = `/api/v1/moderation/item/${itemId}`;
      return getFeedFromUrl(url, {}, $nodeAxios);
    };

    return func();
  } catch (e) {
    return () => ({});
  }
}

function getFlaggedGroupItems(groupId, offset, limit, tabItem, $nodeAxios) {
  try {
    // &statuses[]=APPROVE&statuses[]=DENY&statuses[]=BAN USER
    const func = () => {
      const url = `/api/v1/group/${groupId}/flagged`;
      return getFeedFromUrl(url, {
        id: groupId,
        off: offset,
        pp: limit,
        ...tabItem === 'PENDING' ? {} : {
          statuses: ['APPROVE', 'DENY', 'BAN USER'],
        },
      }, $nodeAxios);
    };

    return func();
  } catch (e) {
    return () => ({});
  }
}

async function acceptGroupModerationItem(groupId, moderationId, $nodeAxios) {
  try {
    return await $nodeAxios.put(`/api/v1/group/${groupId}/flagged/${moderationId}/approve`);
  } catch (error) {
    err('api:acceptGroupModerationItem', error);
    return null;
  }
}

async function denyGroupModerationItem(groupId, moderationId, $nodeAxios) {
  try {
    return await $nodeAxios.put(`/api/v1/group/${groupId}/flagged/${moderationId}/deny`);
  } catch (error) {
    err('api:denyGroupModerationItem', error);
    return null;
  }
}

async function flagGroupModerationUser(groupId, userId, feedback = "it's not good", $nodeAxios) {
  try {
    return await $nodeAxios.put(`/api/v1/user/${userId}/report`, {
      feedback,
      context: {
        group: groupId,
      },
      type: 'user',
    });
  } catch (error) {
    log('api:flagGroupModerationUser', error);
    return null;
  }
}

async function banGroupModerationMember(groupId, userId, $nodeAxios) {
  try {
    return await $nodeAxios.post(`/api/v1/group/${groupId}/ban/${userId}`);
  } catch (error) {
    err('api:banGroupModerationUser', error);
    return null;
  }
}

function getContentItems(itemId, offset, limit, $nodeAxios) {
  try {
    const query = {
      pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
      off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    };

    const func = () => {
      const url = `/api/v1/moderation/content/${itemId}`;
      return getFeedFromUrl(url, query, $nodeAxios);
    };

    return func();
  } catch (e) {
    return () => ({});
  }
}
function getUserItems(userName, offset, limit, $nodeAxios) {
  try {
    const query = {
      pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
      off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    };

    const func = () => {
      const url = `/api/v1/moderation/user_content/${userName}`;
      return getFeedFromUrl(url, query, $nodeAxios);
    };

    return func();
  } catch (e) {
    return () => ({});
  }
}

function approveItem(itemID, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/moderation/${itemID}/approve`)
    .then((res) => {
      log('approveItem res', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

function denyItem(itemID, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/moderation/${itemID}/deny`)
    .then((res) => {
      log('denyItem res', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

function banUser(userId, itemId, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/moderation/ban_user/${userId}?item_id=${itemId}`);
}

function unbanUser(userId, itemId, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/moderation/unban_user/${userId}?item_id=${itemId}`);
}

function flagItem(itemID, contentOrComment, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/moderation/${itemID}/flag`, { contentOrComment, status: 'FLAGGED', feedback: '' })
    .then((res) => {
      log('flagItem res', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

function makeItemPrivate(itemID, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/moderation/${itemID}/make_private`)
    .then((res) => res.data)
    .catch(err);
}

function set2ndReviewStatus(itemID, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/moderation/${itemID}/2nd-review`)
    .then((res) => res.data)
    .catch(err);
}

function updateImageStatus(imageId, itemID, status, safe, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/moderation/image/${imageId}/update_status`, { itemID, status, safe })
    .then((res) => {
      log('update image status', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

/* *****************************
 * stats feeds
 ***************************** */
// silence an item
function silenceStatItem(feedName, itemID, $nodeAxios) {
  const type = feedName.toLowerCase().substr(0, feedName.length - 6);
  const promise = $nodeAxios.put(`/api/v1/moderation/by-stats/${type}/${itemID}/silence`)
    .then((res) => {
      log('silenceStatItem res', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

// ban an item
function banStatItem(feedName, itemID, $nodeAxios) {
  const type = feedName.toLowerCase().substr(0, feedName.length - 6);
  const promise = $nodeAxios.delete(`/api/v1/moderation/by-stats/${type}/${itemID}/ban`)
    .then((res) => {
      log('banStatItem res', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

// reset an item
function resetStatItem(feedName, itemID, $nodeAxios) {
  const type = feedName.toLowerCase().substr(0, feedName.length - 6);
  const promise = $nodeAxios.put(`/api/v1/moderation/by-stats/${type}/${itemID}/reset`)
    .then((res) => {
      log('resetStatItem res', res);
      return res.data;
    })
    .catch(err);
  return promise;
}

function banGroup(groupId, moderationId, $nodeAxios) {
  const promise = $nodeAxios.post(`/api/v1/group/${groupId}/ban?moderation_id=${moderationId}`, { })
    .then()
    .catch(err);
  return promise;
}

function unbanGroup(groupId, moderationId, $nodeAxios) {
  const promise = $nodeAxios.post(`/api/v1/group/${groupId}/unban?moderation_id=${moderationId}`, { })
    .then()
    .catch(err);
  return promise;
}

/**
 * commentsForPost
 *
 * get the list of comments for the specified post_id
 *
 * @param integer postId  the id of the story to fetch comments for
 * @param integer pp limit per page
 * @param integer off offset
 * @return array  list of objects that describe the comments for a post
 */
function commentsForPost(postId, pp, off, $nodeAxios) {
  const limit = clamp(pp, 1, 500);
  const offset = clamp(off, 0, 100000000);

  return $nodeAxios.get(`/api/v1/moderation/content/${postId}/comments`, { params: { pp: limit, off: offset } });
}


export default {
  acceptGroupModerationItem,
  denyGroupModerationItem,
  flagGroupModerationUser,
  banGroupModerationMember,
  getFeedItems,
  approveItem,
  denyItem,
  banUser,
  unbanUser,
  flagItem,
  makeItemPrivate,
  getSingleItem,
  getFlaggedGroupItems,
  getContentItems,
  updateImageStatus,
  getUserItems,
  silenceStatItem,
  banStatItem,
  resetStatItem,
  banGroup,
  unbanGroup,
  commentsForPost,
  set2ndReviewStatus
};
