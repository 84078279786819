import { VALID_FOLLOW_TYPES } from '@/data/enum/follow';
import { log, isFunction } from '@/data/helpers';

/**
 * sanitizeFollowType
 *
 * sanitize a follow type into a known, properly capitalized follow type
 *
 * @param string insane  the type name that needs to be sanitized
 * @return string  the sanitized type name
 */
function sanitizeFollowType(insane) {
  let sane = isFunction(insane.toString) ? insane.toString() : insane;
  sane = sane.toLowerCase();

  if (VALID_FOLLOW_TYPES.indexOf(sane) <= -1) {
    sane = 'unknown';
  }

  return sane;
}

/**
 * sanitizeFollowId
 *
 * sanitize a item id that is used in a follow transation.
 * @NOTE - right now, all follow ids are 24-character hash-ids. if that changes, we need to update this function
 *
 * @param string insane  the type name that needs to be sanitized
 * @return string  the sanitized type name
 */
function sanitizeFollowId(insane) {
  let sane = isFunction(insane.toString) ? insane.toString() : insane;
  sane = sane.toLowerCase().substr(0, 24);
  return sane;
}

export {
  sanitizeFollowType,
  sanitizeFollowId,
};

export default {};
