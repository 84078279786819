
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { staticPath } from '@/data/helpers';

export default {
  name: 'PostButton',
  data() {
    return {
      hovered: false,
    }
  },
  computed: {
    // load data from the global state handler
    ...mapGetters({
      user: 'user',
      openModal: 'getOpenModal',
    }),
    notLoggedIn() {
      return _.isNil(this.user._id);
    },
  },
  methods: {
    staticPath,
    // new thought
    startNewThought() {
      if (this.notLoggedIn) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }
      this.$store.dispatch('openModal', { id: 'new-post-modal' });
      this.$store.dispatch('voiceEvent', {
        category: 'global_nav.post_menu',
        action: 'choose',
        label: 'Post',
      });
    },
  },
};
