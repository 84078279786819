/* eslint no-param-reassign: 0 */
/* eslint no-console: 0 */
/* eslint no-underscore-dangle: 0 */
import extend from 'extend';
import { log, err } from '../helpers';

/**
 * createPost
 *
 * use ajax to create the post
 *
 * @param object postData  the object describing the post
 * @return array  list of topic objects
 */
function createPost(postData, $nodeAxios) {
  // default the post data
  const postArgs = extend({
    type: 'THOUGHT',
    title: '',
    body: '',
  }, postData);

  // send the request
  const promise = $nodeAxios.post('/api/v1/content/new/', postArgs)
    // get the response
    .then((response) => {
      return response;
    })
    .catch(err);

  return promise;
}

function createPoll(pollData, $nodeAxios) {
  // send the request
  const promise = $nodeAxios.post('/api/v1/content/new/', pollData)
    // get the response
    .then((response) => {
      return response;
    })
    .catch(err);

  return promise;
}

function deletePost(postId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/content/${postId}`)
    .then((response) => {
      return response;
    })
    .catch(err);
}

function reportPost(postId, reportData, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/content/${postId}/report`, reportData)
    .then((response) => {
      return response;
    })
    .catch(err);
}

function reportHighAlertPost(postId, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/content/${postId}/report-alert`)
    .then((response) => {
      return response;
    })
    .catch(err);
}

function editPost(postId, data, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/content/${postId}/edit`, data)
    .then((response) => response.data)
    .catch(err);
}

export default {
  createPost,
  deletePost,
  reportHighAlertPost,
  reportPost,
  createPoll,
  editPost,
};
