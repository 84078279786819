import _ from 'lodash';
import { log, isSet, isObject, isArray, isString } from './helpers';

/**
 * replaceLinks
 *
 * accept a block of text written by the user, and replace all links with <embed type="link"> tags
 *
 * @param string str  block of text to parse
 * @return string  the converted text
 */
function replaceLinks(str) {
  // these variables will hopefully reduce the possiblity that someone can hack the replacement algo, by wrapping the replacement placeholders in
  // a random string, that is hard to guess
  const rndmCode = Math.random() * 1000000;
  const nowish = (new Date()).getTime();
  const collisionPrevention = `,${rndmCode}-${nowish},`;

  let out = str;
  const map = {};
  // first remove all html tags so that we do not confuse html attributes with plain text, and make a tag inside a tag
  out = out.replace(/(<[^>]+?>)/gi, (full) => {
    const idx = Object.keys(map).length;
    const key = `~~[${collisionPrevention}=${idx}]~~`;
    map[key] = full;
    return key;
  });

  const urlRegex = /(\s|^)((https?:\/\/([0-9a-z%]+:[0-9a-z%])?)?([0-9a-z][-0-9a-z]*?\.)+?([-0-9a-z]+?)(\/[^\s?#]*(\?[^\s?#]*)?(#[^\s?#]*)?)?)(?=\s|$)/gi;
  // now find all urls, and replace them with <link> tags
  out = out.replace(urlRegex, (original, leadingSpace, full, scheme) => {
    const url = isSet(scheme) && isString(scheme) && scheme.length > 0 ? full : `https://${full}`;
    try {
      // make sure the browser thinks it is a url... but only if the browser knows how to do that
      if (isSet(window.URL)) {
        const urlObj = new URL(url);
        log('URL valid: ', url, '=', urlObj);
      }
      return `${leadingSpace}<link url="${url}" originalText="${full}"></link>`;
    } catch (e) {
      log('Not a URL. Skipping');
    }

    // if we failed url checks, then just use the original text
    return original;
  });

  // finally, restore all previously removed html tags
  const replaceRegex = new RegExp(`~~\\[${collisionPrevention}=[0-9]+\\]~~`, 'gi');
  out = out.replace(replaceRegex, full => (isSet(map[full]) ? map[full] : full));

  return out;
}

/**
 * renderEmbeds
 *
 * prior to rendering the content (or comment), we need to find all the embed tags and replace them with their appropriate display versions.
 * this means links like this `<link id="abc123"></link>` will get replaced with <a href="https://www.google.com/">https://www.google.com/</a> for display
 *
 * @param string fullText  the full string to replace embeds in
 * @param array embeds  list of embeds to replace
 * @param function transform  function used to transform the embeds inside the text to something visible
 * @return string  the transformed text
 */
function renderEmbeds(fullText, embeds, transform) {
  let out = fullText;
  embeds.forEach((trans) => {
    out = transform(out, trans);
  });
  return out;
}

/**
 * newAnchorTagString
 *
 * convert a url and headline into a strigng representation of an anchor tag
 *
 * @param string url  the url of the anchor tag
 * @param string headline  the display name of the anchor tag
 * @param object extra  list of extra attributes to add to the anchor tag
 * @return string  the final string representation of the anchor tag we created
 */
function newAnchorTagString(url, headline, extra) {
  const attributes = Object.entries(extra).map(([attribute, value]) => `${attribute}="${value}"`);
  return `<a href="${url}" ${attributes.join(' ')}>${headline}</a>`
}

/**
 * renderLinks
 *
 * replace the link style embeds with a visual representation, based on the associated embeds objects
 *
 * @param string fullText  the full body text to replace links inside
 * @param object allEmbeds  complete list of all embeds, in object form
 * @param bool textOnly  use text representation only of the embed, where possible
 * @return string  the transformed text
 */
function renderLinks(fullText, allEmbeds, textOnly = false) {
  const out = renderEmbeds(fullText, isSet(allEmbeds) && isSet(allEmbeds.links) && isArray(allEmbeds.links) ? allEmbeds.links : [], (text, embed) => {
    // the cleanHTML() function, which is called before this one, makes `<link></link>` in some browsers, and just `<link>` in other browsers. account for both
    const find = `<link id="${embed.id}"></link>`;
    const find2 = `<link id="${embed.id}">`;
    const url = isSet(embed.url) && isString(embed.url) ? embed.url : '';

    let headline;
    const title = _.get(embed, 'openGraph.title');
    const isMightyArticle = _.get(embed, 'openGraph.type') === 'article';
    if (title && isMightyArticle) {
      headline = title
    } else if (isSet(embed.headline) && isString(embed.headline)) {
      headline = embed.headline;
    } else {
      headline = url;
    }
    // remove scheme and trailing slash, for visual clarity and screen-readers
    if (headline.match(/^https?:\/\//)) {
      headline = headline
        .replace(/^https?:\/\//, '')
        .replace(/\/+$/, '');
    }
    const fullHeadline = headline;

    // if it is longer than 70, then truncate it to 60 and add an ellipsis. 69 & 70 are fine, but 71 is not, so shorten it to make space for the '...'
    if (headline.length > 70) {
      headline = headline.substr(0, 60);
      headline += '...';
    }
    const mightyLink = isSet(embed.context) && isSet(embed.context.type) ? 'mighty-link' : '';
    let repl = '';

    let embedExtraClass = '';
    if (embed.auto) {
      embedExtraClass = 'tm-autolink';
      if (isObject(embed.auto) && isSet(embed.auto.cause)) {
        switch (embed.auto.cause) {
        case 'HEALTH_MAP':
          embedExtraClass += ' health-map';
          break;

        default:
          break;
        }
      }
    }

    // if we need a text only representation, the do that now
    if (textOnly) {
      repl = headline;
    } else if (isSet(embed.url) && embed.url.match(/https:\/\/events\.themighty/g)) {
      // if the embed is for an events.themighty.com link, remove it for SEO purposes
      repl = '';
    } else {
      repl = url ? newAnchorTagString(url, headline, {
        class: `tm-embed-link ${mightyLink} ${embedExtraClass}`,
        'data-id': embed.auto ? _.get(embed, 'context.object.id', '') : _.get(embed, 'originalText', ''),
        'data-name': mightyLink ? fullHeadline : _.get(embed, 'originalText', ''),
        title: fullHeadline,
        target: '_blank',
      }) : '';
    }
    return text.replace(find, repl).replace(find2, repl);
  });

  return out;
}

/**
 * renderAllEmbeds
 *
 * eventually we will have a ton of different types of embeds. each one should be added to this function. then this function will be called by
 * the various places that need to replace them, and those calls will not need to be modified, hopefully.
 *
 * @param string fullText  the complete unreformatted text
 * @param object embeds  all the embeds, indexed by type. each group is an array of embeds
 * @param bool textOnly  use text representation only of the embed, where possible
 * @return string  the final transformed, reformatted string, with all known embeds replaced
 */
function renderAllEmbeds(fullText, embeds, textOnly = false) {
  let out = fullText;
  out = renderLinks(out, embeds, textOnly);

  
  // For SEO purposes, replace any http internal links
  return out.replace(/http:\/\/themighty/g, 'https://themighty');
}

export {
  replaceLinks,
  renderEmbeds,
  newAnchorTagString,
  renderLinks,
  renderAllEmbeds,
};
export default {
  replaceLinks,
  renderEmbeds,
  newAnchorTagString,
  renderLinks,
  renderAllEmbeds,
};
