import { get } from 'lodash';
import { log, err } from "../helpers";

function userAwards(userId, $nodeAxios) {
    return $nodeAxios
      .get(`/api/v1/user/${userId}/awards`, {})
        .then((response) => {
          let awards = response.data || [];
          /*
          / doing a little pre-work to avoid repeating functionality later
          / mainly settings a field earned_at for knowing when they will
          / earn their next badge, also updating the slug field in the 
          / tiers to match with the image slugs
          */
          return awards.filter(a => a.tiers && a.tiers.length > 0)
            .map((award) => {
              let level = get(award, 'tier.level', 0);
              // // to get the earned_at number
              let n = level === 3 ? 2 : level;
              let earned_at = award.tiers[n].earned_at;
              let tiers = award.tiers.map((t) => ({ ...t, slug: `${award.award.slug}-level-${t.level}`}))
              // the respective tier's index is the current level - 1
              let tier = level === 0 ? { ...award.award, level } : tiers[level - 1];
              return ({
                ...award,
                earned_at,
                tier,
                tiers,
              })
            })
        })
        .catch((error) => {
          log("userAwards", error);
          return [];
        });
  }

  export default {
    userAwards,
  }