/* eslint-disable no-unused-vars */

function getSourcesList({ limit, after, query, keyword, location }, $nodeAxios) {
  const params = {
    limit,
    ...(after) && { after },
    ...(query) && { query },
    ...(keyword) && { keyword },
    ...(location) && { location },
  };
  return $nodeAxios.get('/api/v1/sources', { params }).then(res => res.data);
}

function getSourcesById(id, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/sources/${id}`).then(res => res.data);
}

function deleteSource(id, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/sources/${id}`);
}

function addLocation({ name }, $nodeAxios) {
  return $nodeAxios.post('/api/v1/sources/locations', { name }).then(res => res.data);
}

function updateLocation({ _id, name }, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/sources/locations/${_id}`, { name }).then(res => res.data);
}

function deleteLocation(id, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/sources/locations/${id}`);
}

function getLocationsList({ limit, after, query }, $nodeAxios) {
  const params = {
    limit,
    ...(after) && { after },
    ...(query) && { query },
  };

  return $nodeAxios.get('/api/v1/sources/locations', { params }).then(res => res.data);
}

function addKeyword({ name }, $nodeAxios) {
  return $nodeAxios.post('/api/v1/sources/keywords', { name }).then(res => res.data);
}

function updateKeyword({ _id, name }, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/sources/keywords/${_id}`, { name }).then(res => res.data);
}

function deleteKeyword(id, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/sources/keywords/${id}`);
}

function getKeywordsList({ limit, after, query }, $nodeAxios) {
  const params = {
    limit,
    ...(after) && { after },
    ...(query) && { query },
  };

  return $nodeAxios.get('/api/v1/sources/keywords', { params }).then(res => res.data);
}

function addSource({ url, location, keyword }, $nodeAxios) {
  const params = {
    location,
    keyword,
    ...(url) && { url },
  };
  return $nodeAxios.post('/api/v1/sources', params).then(res => res.data);
}

function addKeywordToSource(sourceId, keywordId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/sources/${sourceId}/keyword`, {
    id: keywordId,
  });
}

function removeKeywordFromSource(sourceId, keywordId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/sources/${sourceId}/keyword/${keywordId}`);
}

function addLocationToSource(sourceId, locationId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/sources/${sourceId}/location`, {
    id: locationId,
  });
}

function removeLocationFromSource(sourceId, locationId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/sources/${sourceId}/location/${locationId}`);
}

function updateSource({ _id, title, description, url }, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/sources/${_id}`, { title, description, url }).then(res => res.data);
}

function addSourceItem({ sourceId, type, value }, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/sources/${sourceId}/item/`, { type, value });
}

function removeSourceItem({ sourceId, itemId }, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/sources/${sourceId}/item/${itemId}`);
}

export default {
  getSourcesList,
  getLocationsList,
  addLocation,
  updateLocation,
  deleteLocation,
  addKeyword,
  updateKeyword,
  deleteKeyword,
  getKeywordsList,
  addSource,
  getSourcesById,
  deleteSource,
  addKeywordToSource,
  removeKeywordFromSource,
  addLocationToSource,
  removeLocationFromSource,
  updateSource,
  addSourceItem,
  removeSourceItem,
};
