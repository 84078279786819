
import { mapGetters } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { ClipLoader } from '@saeris/vue-spinners';
import Navbar from '@/components/Navbar/index.vue';
import VoicesModals from '@/voices/VoicesModals/index.vue';
import { sendSegmentEvent } from '@/data/helpers';

export default {
  name: 'TopicLayout',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  components: {
    Navbar,
    VoicesModals,
    LazyHydrate,
    Footer: () => import("@/components/Footer/index.vue")
  },
  middleware: ['authorize'],
  watch: {
    "$route.path": {
      immediate: true,
      handler() {
        const name = this.$route.name;
        const properties = {
          name,
          path: this.$route.path,
          title: process.client ? document.title : '',
          url: process.client ? window.location.href : this.$route.fullPath,
          userId: this.user._id,
          sessionId: this.user.sessionId,
        }

        sendSegmentEvent('Page Load', properties, null, { type: "page", name });
      }
    },
  }
}
