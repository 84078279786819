import Vue from 'vue';
import homeNLMApi from '@/data/api/home-nlm-feed';
import { log, isObject } from '@/data/helpers';


// initial state
const state = () => ({
  allNLMPosts: {},

  // contianer for the multiple feeds that will exist on the homepage
  homeNLMFeeds: {
    primary: {
      items: [],
      offset: 0,
      postCounts: false,
    },
  },
});

// getters for states
const getters = {
  /**
   * getNLMFeedItems
   *
   * get the items inside the feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return array  list of items in the feed
   */
  getNLMFeedItems: currentState => function privGetNLMFeedItems(feedName) {
    if (typeof currentState.homeNLMFeeds === 'undefined') {
      return [];
    }
    return isObject(currentState.homeNLMFeeds[feedName])
      ? currentState.homeNLMFeeds[feedName].items
      : [];
  },
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  /**
   * loadNLMFeedItems
   *
   * get all the items in a given feed, and update the store with the result
   *
   * @param object context  the context of the current store
   * @param string feedName  the name of the feed to load the items of
   * @return Promise  handles the updating of the internal store data
   */
  loadNLMFeedItems(context, { feedName, offset, limit, types, reset }) {
    let before = '';

    let homeNLMFeeds = state.homeNLMFeeds;
    if (typeof context.state.homeNLMFeeds !== 'undefined') {
      homeNLMFeeds = context.state.homeNLMFeeds;
    }

    if (homeNLMFeeds[feedName].items.length && !reset) {
      before = homeNLMFeeds[feedName].items[homeNLMFeeds[feedName].items.length - 1]._id;
    }

    // obtain the feed item list from the api
    const extraParams = {
      before,
      types,
    };

    return homeNLMApi.loadFeedItems(feedName, offset, limit, extraParams, this.$nodeAxios)
      // with successful results...
      .then((rawData) => {
        // append the post list
        if (rawData) {
          if (rawData.hits) {
            context.commit('appendNLMFeedItems', { feedName, items: rawData.hits, perPage: limit });
          }

          if (rawData.counts) {
            context.commit('setNLMFeedPostCounts', { feedName, postCounts: rawData.counts });
          }

          // update the list of follows that are related to this feed
          if (rawData.follows) {
            context.commit('setNLMFeedFollows', { feedName, follows: rawData.follows });
          }
        }
      });
  },

  reloadNLMFeedItems(context, params) {
    // remove current stories from store
    context.commit('resetNLMFeedItems', params.feedName);

    // load stories again (with the new filter selected in store)
    return context.dispatch('loadNLMFeedItems', params)
      .then(() => {
        const newParams = params;
        newParams.offset = params.limit;
        newParams.limit = 40;
        context.dispatch('loadNLMFeedItems', newParams);
      });
  },

  reloadNLMFeedItemsWithNoReturn(context, params) {
    // remove current stories from store
    context.commit('resetNLMFeedItems', params.feedName);

    // load stories again (with the new filter selected in store)
    return context.dispatch('loadNLMFeedItems', params);
  },
};

// mutate the data in the states
const mutations = {
  /**
   * appendNLMFeedItems
   *
   * adds items to the end of the list of the specified feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to modify
   * @param array items  the list of items to add to the end of the list
   * @param object authors  id indexed list of authors
   * @param object topics  id indexed list of topics
   */
  appendNLMFeedItems(currentState, { feedName, items, perPage, prepend }) {
    let list = currentState.homeNLMFeeds[feedName].items || [];
    const index = currentState.allNLMPosts;

    // cycle through the new items
    items.forEach((it, i) => {
      const itm = items[i];
      if (isObject(itm.author_id)) {
        itm.author = itm.author_id;
        itm.author_id = itm.author._id;
      }
      // add the item to the list
      if (!list.includes(itm)) {
        if (prepend) {
          list = [itm, ...list];
        } else {
          list.push(itm);
        }
      }

      if (typeof itm.id === 'string') {
        index[itm.id] = itm;
      }
    });

    // set the final list in vue
    Vue.set(currentState, 'allNLMPosts', Object.assign({}, currentState.allNLMPosts, index));
    Vue.set(currentState.homeNLMFeeds[feedName], 'items', list);

    if (perPage) {
      Vue.set(currentState.homeNLMFeeds[feedName], 'offset', currentState.homeNLMFeeds[feedName].offset + parseInt(perPage, 10));
    } else {
      Vue.set(currentState.homeNLMFeeds[feedName], 'offset', list.length);
    }
  },

  resetNLMFeedItems(currentState, feedName) {
    currentState.homeNLMFeeds[feedName].items.splice(0, currentState.homeNLMFeeds[feedName].items.length);
  },

  setNLMFeedPostCounts(currentState, { feedName, postCounts }) {
    Vue.set(currentState.homeNLMFeeds[feedName], 'postCounts', postCounts);
  },

  setNLMFeedFollows(currentState, { feedName, follows }) {
    // fetch existing lists
    const currentFollows = currentState.homeNLMFeeds[feedName].follows || [];
    const currentAutoFollows = currentState.homeNLMFeeds[feedName].autoFollows || [];

    // update the lists locally
    follows.forEach((itm) => {
      currentFollows.push(itm);
      if (itm.auto) {
        currentAutoFollows.push(itm);
      }
    });

    // save the updated version of the lists
    Vue.set(currentState.homeNLMFeeds[feedName], 'follows', currentFollows);
    Vue.set(currentState.homeNLMFeeds[feedName], 'autoFollows', currentAutoFollows);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
