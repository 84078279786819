// import Vue from 'vue';
import { log, err, formDataToArray } from '@/data/helpers';
import uploader from '@/data/api/uploader';
import voices from '@/data/api/voices';

// initial state
const state = () => ({
});

// getters for states
const getters = {
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  uploadImage(context, { data }) {
    return uploader.uploadImage({ data }, this.$nodeAxios)
      .catch(e => err(e));
  },

  deleteImage(context, { id }) {
    return uploader.deleteImage({ id }, this.$nodeAxios)
      .catch(e => err(e));
  },

  savePostWithImage(context, { data }) {
    log('SERIOUSLY....?', data);
    return voices.createPost(formDataToArray(data), this.$nodeAxios)
      .catch(e => err(e));
  },
};

// mutate the data in the states
const mutations = {
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
