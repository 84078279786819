/* eslint no-param-reassign: 0 */

import Vue from 'vue';
import _ from 'lodash';
import postApi from '@/data/api/post';
import groupsApi from '@/data/api/groups';

import { log } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';

// initial state
const state = () => ({
  // list of posts hearted by current session user
  userHearts: [],
  userProfileBookmarks: [],
  userCommunityBookmarks: [],
  groupRelatedToPost: {},
  votedPolls: [],
  // this is really the reactions maintained across a session, to help with consistency across FullPost and PostCards
  userReactions: [],
});

// getters
const getters = {
  userHearts: ctxState => ctxState.userHearts,
  userHeartedPost: (ctxState, ctxGetters) => postId => ctxGetters.userHearts.indexOf(postId) > -1,
  userProfileBookmarks: ctxState => ctxState.userProfileBookmarks,
  userProfileBookmarkedPost: (ctxState, ctxGetters) => postId => ctxGetters.userProfileBookmarks.indexOf(postId) > -1,
  userCommunityBookmarks: ctxState => ctxState.userCommunityBookmarks,
  userCommunityPostBookmarks: (ctxState, ctxGetters) => postId =>
    ctxGetters.userCommunityBookmarks.find(bookmark => bookmark.postId === postId),
  votedPolls: ctxState => ctxState.votedPolls,
  groupRelatedToPost: ctxState => ctxState.groupRelatedToPost,
  userReactions: ctxState => ctxState.userReactions,
};

// actions
const actions = {
  async addUserReaction(context, post) {
    const { postId, postType, reaction } = post;
    const promise = await postApi.addPostHeart(postId, postType, reaction, this.$nodeAxios)
      .then((res) => res.data)
      .catch((err) => log(err));
    
    return promise;
  },
  async removeUserReaction(context, post) {
    const { postId, postType } = post;
    const promise = await postApi.removePostHeart(postId, postType, this.$nodeAxios)
      .then((res) => res.data)
      .catch((err) => log(err));

    return promise;
  },
  toggleUserHeartedPost(context, params) {
    let promise;

    if (!context.rootGetters.isLoggedIn) {
      context.dispatch('notLoggedInAction');
      return Promise.reject();
    }

    let isAddHeart = true;
    let oldHeartCount = 0;

    const item = params.post;
    isAddHeart = !item.hearted;
    oldHeartCount = item.action_counts && item.action_counts.hearts ? item.action_counts.hearts : 0;

    log('isAddHeart', isAddHeart, oldHeartCount);
    // new user-post heart
    if (isAddHeart) {
      // sends heart to api
      promise = postApi.addPostHeart(params.post.id || params.postId, params.post.type, null, this.$nodeAxios).then(() => {
        context.commit('heartPost', { post: params.post });
      });

    // existent user-post heart
    } else {
      // sends heart removal to api
      promise = postApi.removePostHeart(params.post.id || params.postId, params.post.type, this.$nodeAxios).then(() => {
        context.commit('unHeartPost', { post: params.post });
      });
    }
    // dispatch the global event for updating heart count in the menu
    window.dispatchEvent(new CustomEvent('togglePostHeart', { detail: { post_id: params.post.id } }));

    const userId = _.get(context.rootState, 'session.user._id', '');
    const sessionId = _.get(context.rootState, 'session.user.sessionId', '');
    if (isAddHeart) {
      segmentAnalytics.heart({
        ...params.segmentData,
        userId,
        sessionId,
      });
    } else {
      segmentAnalytics.unheart({
        ...params.segmentData,
        userId,
        sessionId,
      });
    }

    return promise;
  },

  addProfileBookmark(context, { post }) {
    const postId = post._id;
    if (!postId) {
      return false;
    }

    return postApi.addProfileBookmark(postId, this.$nodeAxios)
      .then(() => {
        context.commit('setProfileBookmark', { post });
      })
      .catch((err) => {
        log('Error adding post bookmark to profile', err);
      });
  },

  removeProfileBookmark(context, { post }) {
    const postId = post._id;
    if (!postId) {
      return false;
    }

    return postApi.removeProfileBookmark(postId, this.$nodeAxios)
      .then(() => {
        context.commit('resetProfileBookmark', { post });
      })
      .catch((err) => {
        log('Error removing post bookmark from profile', err);
      });
  },

  updateCommunityBookmarks(context, { post, communities }) {
    const postId = post._id;
    if (!postId) {
      return false;
    }

    return postApi.setCommunityBookmarks(postId, communities, this.$nodeAxios)
      .then(() => {
        context.commit('setCommunityBookmarks', { post, communities });
      })
      .catch((err) => {
        log('Error setting post bookmarks for communities', err);
      });
  },

  loadGroupRelatedToPost(context, { groupSlug }) {
    if (!groupSlug) {
      return false;
    }

    return groupsApi.groupBySlug(groupSlug, this.$nodeAxios)
      .then((rawData) => {
        if (rawData.data) {
          context.commit('setGroupRelatedToPost', { group: rawData.data });
        }
      })
      .catch((err) => {
        log('Error getting group:', err);
      });
  },

  updatePendingApprovalFlag(context, { pending }) {
    context.commit('setPendingApprovalFlag', { pending });
  },
};

// mutations
const mutations = {
  /**
   * userHearts is synced from user session
   * @from session.js mutation commit
   */
  setCurrentUser(currentState, userObject) {
    Vue.set(currentState, 'userHearts', _.get(userObject, 'settings.hearts', []));
  },
  setLogoutUser(currentState) {
    Vue.set(currentState, 'userHearts', []);
  },

  setUserReaction(currentState, post) {
    const idx = _.findIndex(currentState.userReactions, r => r.postId === post.postId);
    if (idx > -1) {
      currentState.userReactions.splice(idx, 1, post);
    } else {
      currentState.userReactions.push(post);
    }
  },

  heartPost(currentState, { post }) {
    Vue.set(post, 'hearted', true);
    Vue.set(post.action_counts, 'hearts', (_.get(post, 'action_counts.hearts', 0) + 1));
    Vue.set(currentState, 'userHearts', currentState.userHearts.concat(post.id));
  },

  unHeartPost(currentState, { post }) {
    Vue.set(post, 'hearted', false);
    Vue.set(post.action_counts, 'hearts', (_.get(post, 'action_counts.hearts', 1) - 1));
    const postIndex = currentState.userHearts.indexOf(post.id);
    if (postIndex > -1) {
      currentState.userHearts.splice(postIndex, 1);
    }
  },

  setProfileBookmark(currentState, { post }) {
    Vue.set(post, 'bookmarked', 1);
    Vue.set(currentState, 'userProfileBookmarks', currentState.userProfileBookmarks.concat(post._id));
  },

  resetProfileBookmark(currentState, { post }) {
    Vue.set(post, 'bookmarked', 0);
    const idx = currentState.userProfileBookmarks.indexOf(post._id);
    if (idx > -1) {
      currentState.userProfileBookmarks.splice(idx, 1);
    }
  },

  setPollVote(currentState, { post }) {
    currentState.votedPolls.push(post);
  },

  setCommunityBookmarks(currentState, { post, communities }) {
    Vue.set(post, 'bookmarked_to_groups', communities);
    const newCommunities = {
      postId: post._id,
      communities,
    };

    const index = _.findIndex(currentState.userCommunityBookmarks, bookmark => post._id === bookmark.postId);
    if (index > -1) {
      currentState.userCommunityBookmarks.splice(index, 1, newCommunities);
    } else {
      currentState.userCommunityBookmarks.push(newCommunities);
    }
  },

  setGroupRelatedToPost(currentState, { group }) {
    Vue.set(currentState, 'groupRelatedToPost', group);
  },

  setPendingApprovalFlag(currentState, { pending }) {
    Vue.set(currentState.groupRelatedToPost, 'pending_approval', pending);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
