/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from "vue";
import { map } from "lodash";

const state = () => ({
  adSlots: [],
  timeOutId: null,
  firstRequest: false,
  firstAdLoaded: false
});

const getters = {
  getAdSlots: (currentState) => currentState.adSlots,
  getTimeoutId: (currentState) => currentState.timeOutId,
  getFirstRequest: (currentState) => currentState.firstRequest,
  firstAdLoaded: (currentState) => currentState.firstAdLoaded,
};

const actions = {
  adSlot(context, adSlot) {
    const { adUnit, size, id } = adSlot || {};

    if ((adUnit, size, id)) {
      context.commit("adSlot", adSlot);
    }
  },
  setTimeoutId(context, id) {
    context.commit("setTimeoutId", id)
  },
  editAdSlotStatus(context, adSlot) {
    const { status, id } = adSlot || {};

    if ((status, id)) {
      context.commit("editAdSlotStatus", { status, id });
    }
  },
  firstAdLoaded(context, v = false) {
    context.commit('setFirstAdLoaded', v)
  }
};

const mutations = {
  setTimeoutId(currentState, id) {
    Vue.set(currentState, "timeOutId", id);
  },
  adSlot(currentState, adSlot) {
    Vue.set(currentState, "adSlots", [...currentState.adSlots, adSlot]);
  },
  editAdSlotStatus(currentState, adSlot) {
    Vue.set(currentState, "adSlots", map(currentState.adSlots, (_adSlot) => {
        if (adSlot.id === _adSlot.id) {
          _adSlot.status = adSlot.status;
        }
        return _adSlot;
      })
    );
  },
  setFirstRequest(currentState, value) {
    Vue.set(currentState, "firstRequest", value);
  },
  setFirstAdLoaded(currentState, value) {
    Vue.set(currentState, "firstAdLoaded", value);

  }
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
