import { Quill } from 'vue2-editor';

const Embed = Quill.import('blots/embed');

class User extends Embed {
  static create(data) {
    const node = super.create();
    node.innerHTML += `${data.denotationChar}${data.value}`;
    return User.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });

    domNode.id = data.id;
    domNode.classList.add('mentioned-user');
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

User.blotName = 'user';
User.className = 'user';
User.tagName = 'user';

export default User;
