
import { mapGetters } from 'vuex';
import NonLoggedInNav from './NonLoggedInNav';
import LoggedInNav from './LoggedInNav';

export default {
  name: 'Navbar',
  components: { LoggedInNav, NonLoggedInNav },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    loggedIn() {
      return this.user && this.user.id;
    },
  },
};
