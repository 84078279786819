import { log, isString, isSet, isArray } from './helpers';

const cache = {};

/**
 * keyToArray
 *
 * @example
 *    string: user[name][first]
 *    output: ["user","name","first"]
 *
 * take a url query string parameter, and convert it to a list of keys for use in the assignFromKeyArray
 *
 * @param string key  the string version of the query string parameter
 * @return array  array of keys and sub-keys that represent the path to the final value inside a complex object
 */
function keyToArray(key) {
  const parts = key.split(/\[/);
  return parts.map(p => p.replace(/\]$/, ''));
}

/**
 * assignFromKeyArray
 *
 * assign a value to a possibly-nested complex-object/array key
 *
 * @param object|array obj  the object to assign the key-value pair too
 * @param array keyArray array  the list of keys/sub-keys that need to be nested before assigning the value
 * @param mixed value  the value to assign to the key/sub-key
 */
function assignFromKeyArray(obj, keyArray, value) {
  /* eslint-disable no-param-reassign */
  // the current key in the list of keys/sub-keys
  const key = keyArray.shift();

  // are there more sub-keys to process?
  const more = keyArray.length > 0;

  // what is the length of the next sub key. determines if this object should be an array or an object
  const nextLen = more ? keyArray[0].length : -1;

  // if the current key is empty, then assume that object is an array, and append a new value
  if (!key.length) {
    // if there are more sub-keys, then construct a sub object (or array), process the next key, then assign the result of that to this entry in the array
    if (more) {
      const stub = nextLen > 0 ? {} : [];
      assignFromKeyArray(stub, keyArray, value);
      obj.push(stub);
    // otherwise, make this key have the value passed in
    } else {
      obj.push(value);
    }
  // if we have a string key, and there are more sub-keys, then recurse
  } else if (more) {
    // fisrt figure out if this key should be an array or an object though, based on the next key's length
    if (nextLen > 0) {
      obj[key] = {};
    } else {
      obj[key] = isArray(obj[key]) ? obj[key] : [];
    }
    assignFromKeyArray(obj[key], keyArray, value);
  // if this is a string key, but there are no more sub-keys _AND_ the key is already set in the main object, then convert to an array, and add a value to it
  // this handles query strings that have params like:    items=dog&items=cat&items=bird
  } else if (isSet(obj[key])) {
    obj[key] = isArray(obj[key]) ? obj[key] : [obj[key]];
    obj[key].push(value);
  // finally, if we have a string key, and there are no more sub-keys, then
  } else {
    obj[key] = value;
  }
  /* eslint-enable no-param-reassign */
}

/**
 * getObj
 *
 * convert the current url query string to a traversable object of key-value pairs
 *
 * @return object  object that describes the query string
 */
function getObj() {
  // container
  const obj = {};

  // actually query string without the leading ? character
  const qs = window.location.search.substring(1);

  if (isString(qs) && qs.length) {
    // only do this once per querystring
    if (isSet(cache[qs])) {
      return cache[qs];
    }

    // each key-value pair
    const parts = qs.split('&');

    // actually do the creation of the object from the string
    parts.forEach((pair) => {
      const pieces = pair.split(/=/, 2);
      const key = keyToArray(pieces.shift());
      const value = pieces.shift();
      assignFromKeyArray(obj, key, value);
    });

    // set cache for next time
    cache[qs] = obj;
  }

  return obj;
}

/**
 * purgeCache
 *
 * purge the cache of a specific query string
 *
 * @param string queryString  the query string to purge the cache of
 */
function purgeCache(queryString) {
  delete cache[queryString];
}

export {
  getObj,
  purgeCache,
};
