import extend from 'extend';
import _ from 'lodash';
import segmentAnalytics from '@/libs/events';
import { clamp, isNumber, log, err } from '../helpers';

/**
 * sortResults
 *
 * sort the results list so that 'exact matches' are followed by 'starts with', which are then followed by 'contains'
 *
 * @param array data  the raw list of results
 * @param string search  the search term
 * @return array  a sorted result list
 */


/**
 * topicBySlug
 *
 * fetch a topic object for a given slug
 *
 * @param topic_slug string
 * @return Topic object
 */
function groupBySlug(groupSlug, $nodeAxios) {
  const promise = $nodeAxios.get(`/api/v1/group/details/${groupSlug.toLowerCase()}`, {})
    .then(response => response)
    .catch(err);

  return promise;
}

function loadUserGroups(username, $nodeAxios) {
  const promise = $nodeAxios.get(`/api/v1/follow/user_follows/${username}?off=0&filter=group`, {})
      .then(response => response)
      .catch(err);

  return promise;
}

/**
 * postsForGroup
 *
 * fetch posts for a group id
 *
 * @param groupId String
 * @param offset int default 1
 * @param limit int default 10
 * @param types array , content types,  [ 'THOUGHT', 'QUESTION', 'WORDPRESS_POST', 'WORDPRESS_VIDEO', 'NONE' ]
 */
function postsForGroup(groupSlug, feedName, offset, limit, types = ['THOUGHT', 'QUESTION'], $nodeAxios) {
  const query = {
    pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
    off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
  };

  if (types === 'photos') {
    query.filter = 'photos';
  } else {
    query.types = types;
  }

  if (feedName === 'trending') {
    query.trending = 1;
  }

  const promise = $nodeAxios.get(`/api/v1/content/for_group/${groupSlug.toLowerCase()}`, { params: query })
    .then(res => extend({ query }, res.data))
    .catch(err);

  return promise;
}

function groupMembers(groupSlug, offset, limit, roles = null, $nodeAxios) {
  const query = {
    pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
    off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    roles,
  };
  const promise = $nodeAxios.get(`/api/v1/group/${groupSlug}/members`, { params: query })
    .then((res) => {
      const data = {
        members: res.data,
        append: res.config.params.off !== 0,
        done: res.data.length < res.config.params.pp,
      };
      return data;
    })
    .catch(err);
  return promise;
}

function joinGroup(groupSlug, $nodeAxios) {
  const promise = $nodeAxios.post(`/api/v1/group/${groupSlug}/join`)
    .then(res => res)
    .catch(err);
  return promise;
}

function leaveGroup(groupSlug, $nodeAxios) {
  const promise = $nodeAxios.post(`/api/v1/group/${groupSlug}/leave`)
    .then(res => res)
    .catch(err);
  return promise;
}

function updateBanner(id, data, $nodeAxios) {
  const promise = $nodeAxios
    .put(`/api/v1/groups/update/${id}/image`, data)
    .catch(err);
  return promise;
}

/**
 * updateGroupImage
 *
 * handles the upload for either community banner or avatar image
 *
 * @param string groupId  id of the group
 * @param string imageType  banner or avatar
 * @param file data  file to be uploaded
 */
function updateGroupImage(groupId, imageType, data, $nodeAxios) {
  const settings = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return $nodeAxios.put(`/api/v1/group/update/${groupId}/images/upload/${imageType}`, data, settings)
    .then(res => _.assign({ imageType, url: res.data.images[imageType].url }))
    .catch((e) => {
      log('Error uploading image', e);
    });
}

/**
 * removeGroupImage
 *
 * handles the upload for either community banner or avatar image
 *
 * @param string groupId  id of the group
 * @param string imageType  banner or avatar
 * @param file data  file to be uploaded
 */
function removeGroupImage(groupId, imageType, $nodeAxios) {
  const settings = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return $nodeAxios.delete(`/api/v1/group/update/${groupId}/images/upload/${imageType}`, {}, settings)
    .then(res => res)
    .catch((e) => {
      log('Error removing image', e);
    });
}

function groupApplications(groupSlug, offset, limit, statuses, $nodeAxios) {
  const query = {
    pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
    off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    statuses,
  };
  const promise = $nodeAxios.get(`/api/v1/group/${groupSlug}/applications`, { params: query })
    .then((res) => {
      log('group applications');
      const data = {
        applications: res.data.applications,
        append: res.config.params.off !== 0,
        done: res.data.applications.length < res.config.params.pp,
      };
      return data;
    })
    .catch(err);
  return promise;
}

function updateGroupApplications(groupSlug, action, applicationIds, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/group/${groupSlug}/applications`, { action, application_ids: applicationIds })
    .then(res => res)
    .catch(err);
  return promise;
}

function updateGroup(groupSlug, updatedGroup, $nodeAxios) {
  const newGroup = { ...updatedGroup,
    questions: updatedGroup.application_questions,
    guidelines: updatedGroup.walkthrough.guidelines,
    guideline_consent: updatedGroup.walkthrough.guideline_consent,
    welcome_messages: updatedGroup.walkthrough.welcomes,
    blacklist: updatedGroup.moderation_settings.blacklist.words,
    riskylist: updatedGroup.moderation_settings.riskylist.words,
    overriddenlist: updatedGroup.moderation_settings.overriddenlist.words,
    use_mighty_moderation: updatedGroup.moderation_settings.use_mighty_moderation,
    auto_approve_content: updatedGroup.moderation_settings.auto_approve_content,
    auto_approve_join_requests: updatedGroup.moderation_settings.auto_approve_join_requests,
    related_topics: updatedGroup.related_topics,
    related_topics_onboarding: updatedGroup.related_topics_onboarding,
    use_custom_guideline_consent: updatedGroup.walkthrough.use_custom_guideline_consent,
    status: (updatedGroup.status.type === 'PRIVATE' || updatedGroup.convert_to_private === true) ? 'PRIVATE' : 'PUBLIC',
    sponsor_branding_message: updatedGroup.sponsor_branding_message,
  };
  const promise = $nodeAxios.put(`/api/v1/group/${groupSlug}`, newGroup)
    .then(res => res)
    .catch(err);
  return promise;
}

/**
 * validateGroupFields
 *
 * validate array of strings to see whether each one passes moderation
 *
 * @param array strings array of strings to validate
 */
function validateGroupFields(content, $nodeAxios) {
  return $nodeAxios.post('/api/v1/moderation/validate', { content })
    .then(res => res.data.results)
    .catch((e) => {
      log('Error getting users create community limit', e);
      return e.response.data;
    });
}

function flagGroupUser(reportedUserId, type, feedback, context, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/user/${reportedUserId}/report`, { type, feedback, context })
    .then(res => res)
    .catch(err);
  return promise;
}

function banGroupMember(groupSlug, reportedUserId, $nodeAxios) {
  const promise = $nodeAxios.post(`/api/v1/group/${groupSlug}/ban/${reportedUserId}`, {})
    .then(res => res.data)
    .catch(err);
  return promise;
}

function unbanGroupMember(groupSlug, userId, $nodeAxios) {
  const promise = $nodeAxios.delete(`/api/v1/group/${groupSlug}/unban/${userId}`, {})
    .then()
    .catch(err);
  return promise;
}

function submitGroupApplication(groupSlug, application, $nodeAxios) {
  const promise = $nodeAxios.post(`/api/v1/group/${groupSlug}/apply`, application)
    .then(res => res.data)
    .catch(err);
  return promise;
}

async function acceptGroupGuidelines(groupSlug, group, $nodeAxios) {
  try {
    const { data } = await $nodeAxios.post(`/api/v1/group/${groupSlug}/completed_onboarding`, {});
    if (group) {
      // eslint-disable-next-line camelcase
      const { community_type, name: groupName, status: { type }, related_topics_onboarding: related_topics } = group;

      // TODO: trigger joinGroup
      segmentAnalytics.joinGroup({
        userId: data.user, // data.user is the user id
        context: {
          group: {
            id: data.group, // data.group has the group id
            name: groupName,
            community_type,
            type: type.toLowerCase(),
            related_topics,
          },
        },
        target: {
          type: 'member',
        },
      });
    }

    log('api:acceptGroupGuidelines', data);
    return data;
  } catch (err) {
    log('api:acceptGroupGuidelines', err);
  }
  return null;
}

function roleCounts(groupSlug, $nodeAxios) {
  const promise = $nodeAxios.get(`/api/v1/group/${groupSlug}/members/grouped`, {})
    .then(res => res.data)
    .catch(err);
  return promise;
}

function reportGroup(groupId, $nodeAxios) {
  const promise = $nodeAxios.put(`/api/v1/group/${groupId}/report`, {})
    .then(res => res)
    .catch(err);
  return promise;
}

function searchGroupMembers({ search, groupId, $nodeAxios }) {
  const promise = $nodeAxios.get(`/api/v1/home/explore/?search=${search}&types[]=community_members&community_id=${groupId}`, {})
    .then(res => res.data)
    .catch(err);
  return promise;
}

export default {
  groupBySlug,
  loadUserGroups,
  postsForGroup,
  groupMembers,
  joinGroup,
  leaveGroup,
  updateBanner,
  updateGroupImage,
  removeGroupImage,
  updateGroup,
  groupApplications,
  updateGroupApplications,
  flagGroupUser,
  banGroupMember,
  unbanGroupMember,
  roleCounts,
  reportGroup,
  submitGroupApplication,
  acceptGroupGuidelines,
  validateGroupFields,
  searchGroupMembers,
};
