import _ from 'lodash';
import { err, log } from '../helpers';

// this is the list of Prompts user can select from
function getPromptsList($nodeAxios) {
  return $nodeAxios.get(`/api/v1/prompts`, {})
      .then(res => res.data)
      .catch(err);
}

function savePromptResponse(userId, prompt, $nodeAxios) {
  const body = {
    prompt: prompt._id,
    text: prompt.text,
  }
  return $nodeAxios.put(`/api/v1/user/${userId}/prompts`, body)
      .then(res => res.data)
      .catch(err);
}

function deletePromptResponse(userId, promptId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/user/${userId}/prompts/${promptId}`, {})
      .then(res => res.data)
      .catch(err);
}

export default {
  getPromptsList,
  savePromptResponse,
  deletePromptResponse,
};