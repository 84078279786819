import Vue from "vue";
import extend from "extend";
import voicesApi from "@/data/api/voices";
import { log, isSet } from "@/data/helpers";
import { removeTags, textMaybeTags } from "@/data/card-helpers";
import { replaceLinks } from "@/data/submission-helpers";

function transformTitle(data, getKnownHashtags) {
  if (isSet(data.textOnly) && data.textOnly) {
    data.title = textMaybeTags(data.title, getKnownHashtags, "title");
  }
  data.title = removeTags(data.title);
  data.title = replaceLinks(data.title);
  return data.title;
};

function transformBody(data, getKnownHashtags) {
  if (isSet(data.textOnly) && data.textOnly) {
    data.body = textMaybeTags(data.body, getKnownHashtags, 'content');
  }
  data.body = removeTags(data.body);
  data.body = replaceLinks(data.body);
  let topic;
  let openTag;
  const value = data.body.split(' ');
  for (let i = 0; i < value.length; i += 1) {
    if (value[i][0] === '#' && /#[a-zA-Z0-9-]+$/.test(value[i])) {
      topic = value[i].slice(1, value[i].length);
      openTag = `<topic-new name="${topic}">`;
      value[i] = `${openTag}</topic-new>`;
    }
  }
  data.body = value.join(' ');
  return data.body;
};

// initial state
const state = () => ({
  // args for the last-started voices post
  lastPostArgs: {},

  // the currently viewed post, in fullpost mode
  currentlyViewedPost: {},
});

// getters for states
const getters = {
  /**
   * getLastPostArgs
   *
   * gets all the args from the last post that was started
   *
   * @param object currentState  the current state of this handle
   * @return object  the object that describes the last post that was started
   */
  getLastPostArgs: (currentState) => currentState.lastPostArgs,

  /**
   * getCurrentFullPostSegmentData
   *
   * gets the segmentData of the post that is currently being viewed in full mode
   *
   * @param object currentState  the current state of this handle
   * @return object  the object that describes the segmentData of the post in view
   */
  getCurrentFullPostSegmentData: (currentState) =>
    currentState.currentlyViewedPostSegmentData,

  /**
   * getCurrentFullPost
   *
   * gets the object of the post that is currently being vieed in full mode
   *
   * @param object currentState  the current state of this handle
   * @return object  the object that describes the post in view
   */
  getCurrentFullPost: (currentState) => currentState.currentlyViewedPost,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  /**
   * updateCurrentFullPostSegmentData
   *
   * update the segmentData of the post currently in full view mode
   *
   * @param object context  the context of the current handler
   * @param object data  the data that describes the segmentData of the post in full view
   */
  updateCurrentFullPostSegmentData(context, { data }) {
    context.commit("setCurrentFullPostSegmentData", { data });
  },

  /**
   * updateCurrentFullPost
   *
   * update the post data that is currently in full view mode
   *
   * @param object context  the context of the current handler
   * @param object data  the data that describes the post in full view
   */
  updateCurrentFullPost(context, { data, options = null }) {
    context.commit("setCurrentlyViewedPost", { data: data || {}, options });
    context.commit("setCurrentFullPostURL", { data: data || {}, options });
  },

  /**
   * savePost
   *
   * save the last updated post
   *
   * @param object context  the context of the current handler
   * @param function after  the action to perform after the save is complete
   * @param function failue  the action to perform if the save failed
   */
  savePost(context) {
    const st = context.state;
    // require, even empty value, for all three needed fields
    if (
      !isSet(st.lastPostArgs.type) ||
      !isSet(st.lastPostArgs.title) ||
      !isSet(st.lastPostArgs.body)
    ) {
      return Promise.reject({
        success: 0,
        error: new Error("missing required data"),
      });
    }

    const data = extend({}, st.lastPostArgs);
    // process the ajax request
    try {
      // sanitize some data now
      if (isSet(data.title)) {
        data.title = transformTitle(data, context.getters.getKnownHashtags);
      }
      if (isSet(data.body)) {
        data.body = transformBody(data, context.getters.getKnownHashtags);
      }
      delete data.textOnly;

      const res = voicesApi
        .createPost(data, this.$nodeAxios)
        .then((rawResp) => {
          context.commit("finishedSavingNewPost", {});
          return rawResp;
        });
      res.then((rawResp) => {
        context.commit("setLastPostArgs", {});
        return rawResp;
      });
      return res;
    } catch (e) {
      return Promise.reject({ success: 0, error: e.message, errObj: e, data });
    }
  },

  /**
   * savePoll
   *
   * save the last updated poll
   *
   * @param object context  the context of the current handler
   * @param function after  the action to perform after the save is complete
   * @param function failue  the action to perform if the save failed
   */
  savePoll(context) {
    const st = context.state;
    // require, even empty value, for all three needed fields
    if (!isSet(st.lastPostArgs.type) || !isSet(st.lastPostArgs.title) || !isSet(st.lastPostArgs.body)) {
      return Promise.reject({ success: 0, error: new Error('missing required data') });
    }

    const data = extend({}, st.lastPostArgs);
    // process the ajax request
    try {
      // sanitize some data now
      if (isSet(data.title)) {
        data.title = transformTitle(data, context.getters.getKnownHashtags);
      }
      if (isSet(data.body)) {
        data.body = transformBody(data, context.getters.getKnownHashtags);
      }
      delete data.textOnly;

      const res = voicesApi.createPoll(data, this.$nodeAxios)
        .then((rawResp) => {
          context.commit('finishedSavingNewPost', {});
          return rawResp;
        });
      res.then((rawResp) => {
        context.commit('setLastPostArgs', {});
        return rawResp;
      });
      return res;
    } catch (e) {
      return Promise.reject({ success: 0, error: e.message, errObj: e, data });
    }
  },

  deletePost(context, { postId }) {
    return voicesApi.deletePost(postId, this.$nodeAxios);
  },

  editPost(context, postId) {
    const data = extend({}, context.state.lastPostArgs);
    if (isSet(data.title)) {
      data.title = transformTitle(data, context.getters.getKnownHashtags);
    }
    if (isSet(data.body)) {
      data.body = transformBody(data, context.getters.getKnownHashtags);
    }
    const { title, body } = data;
    return voicesApi.editPost(postId, { title, body }, this.$nodeAxios)
      .then((post) => {
        context.commit("setLastPostArgs", {});
        return post;
      })
  },

  reportPost(context, { contentId, reportData }) {
    // process the request to report the comment
    return voicesApi
      .reportPost(contentId, reportData, this.$nodeAxios)
      .then((response) => {
        // we are no longer in the process of reporting....
        context.commit("setCurrentlyReporting", 0);

        // if the report was successful, then update the last reported comment id. used to decide what to show in the menu pane for the comment
        if (response.statusCode === 200) {
          context.commit("setLastReported", contentId);
        }
      });
  },

  reportHighAlertPost(context, { contentId }) {
    // process the request to report the comment
    return voicesApi
      .reportHighAlertPost(contentId, this.$nodeAxios)
      .then((response) => {
        // we are no longer in the process of reporting....
        context.commit("setCurrentlyReporting", 0);

        // if the report was successful, then update the last reported comment id. used to decide what to show in the menu pane for the comment
        if (response.statusCode === 200) {
          context.commit("setLastReported", contentId);
        }
      });
  },
};

// mutate the data in the states
const mutations = {
  /**
   * setCurrentFullPostSegmentData
   *
   * sets the segmentData of the currently viewed full post
   *
   * @param object currentState  the current state of this handle
   * @param object rawData the segmentData of the post being viewed
   */
  setCurrentFullPostSegmentData(currentState, rawData) {
    const data = rawData.data;
    log("VOICES_MODULE[setCurrentFullPostSegmentData]:", data);
    Vue.set(currentState, "currentlyViewedPostSegmentData", data);
  },

  /**
   * setCurrentlyViewedPost
   *
   * sets the currently viewed full post
   *
   * @param object currentState  the current state of this handle
   * @param object rawData the object describing the post being viewed
   */
  setCurrentlyViewedPost(currentState, rawData) {
    const data = rawData.data;
    const args = extend(
      {
        type: "THOUGHT",
        title: "",
        body: "",
      },
      data
    );
    log("VOICES_MODULE[setCurrentFullPost]:", args);
    Vue.set(currentState, "currentlyViewedPost", args);
  },

  /**
   * setCurrentFullPostURL
   *
   * sets the URL for the currently viewed full post
   * setCurrentlyViewedPost and this mutation were originally one long mutation call
   * I separated them so that I could call the former without invoking this mutation
   *
   * @param object currentState  the current state of this handle
   * @param object rawData the object describing the post being viewed
   */
  setCurrentFullPostURL(currentState, rawData) {
    const data = rawData.data;
    const options = rawData.options || {};
    if (window.location.pathname.indexOf("content") !== -1 || window.location.pathname.indexOf("poll") !== -1) {
      return;
    }
    if (["THOUGHT", "QUESTION", "WORDPRESS_POST", "POLL"].includes(data.type)) {
      let url = `${window.location.pathname}${
        window.location.pathname[window.location.pathname.length - 1] === "/"
          ? ""
          : "/"
      }${data.type === "POLL" ? 'poll' : 'content'}/${data.id || data._id}`;
      if (options && options.commentId) {
        url = `${url}/#comment=${options.commentId}`;
      }
      history.pushState({}, "", url);
    }
  },

  /**
   * setLastPostArgs
   *
   * sets the last post data
   *
   * @param object currentState  the current state of this handle
   */
  setLastPostArgs(currentState, data) {
    const args = extend(
      {
        type: "THOUGHT",
        title: "",
        body: "",
        image: null,
      },
      data
    );
    log("VOICES_MODULE[setLastPostArgs]:", args);
    Vue.set(currentState, "lastPostArgs", args);
  },

  /**
   * emptyLastPostArgs
   *
   * empty out the last post args, back to the starting state of no-data
   *
   * @param object currentState  the current state of this handle
   */
  emptyLastPostArgs(currentState) {
    Vue.set(currentState, "lastPostArgs", {});
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
