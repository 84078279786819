import _ from 'lodash';
import { err, log } from '../helpers';


/**
 * loadSavedContent
 *
 * routes for getting content saved to group by either leader or by user
 *
 * @param groupId String
 * @param limit int default 10
 * @param initiator_type string bookmarks for which item (ie. GROUP VS USER)
 * @param contentType string ie. 'THOUGHT', 'QUESTION', not required
 * @param before timestamp of last content, essentially an offset
 */
function loadSavedContent({ $nodeAxios, groupId, limit, initiatorType, contentType = '', before = '' }) {
  let url;
  if (initiatorType === 'GROUP') {
    url = `/api/v1/bookmarks/group/${groupId}/all?limit=${limit}&only_types=${contentType}&before=${before}`;
  } else if (initiatorType === 'USER') {
    let filterIds = '';
    if (!_.isNil(groupId)) {
      filterIds = `g_${groupId}`;
    }
    url = `/api/v1/bookmarks/all?limit=${limit}&before=${before}&filter_ids[]=${filterIds}`;
    
    if (contentType === 'POST') {
      url += `&only_types[]=QUESTION&only_types[]=THOUGHT`;
    } else if (contentType.length) {
      url += `&only_types=${contentType}`;
    }  
  }


  const promise = $nodeAxios.get(url, {})
      .then(res => res.data)
      .catch(err);
  return promise;
}

function unsaveGroupContent(groupId, contentId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/bookmarks/group/${groupId}/remove/${contentId}`, {})
      .then(res => res.data)
      .catch(err);
}

function unsaveProfileContent(contentId, $nodeAxios) {
  const promise = $nodeAxios.delete(`/api/v1/bookmarks/remove/${contentId}`, {})
      .then(res => res.data)
      .catch(err);
  return promise;
}

function removeExternalResource(bookmarkId, $nodeAxios) {
  const promise = $nodeAxios.delete(`/api/v1/bookmarks/${bookmarkId}`, {})
      .then(res => res.data)
      .catch(err);
  return promise;
}

export default {
  loadSavedContent,
  unsaveGroupContent,
  unsaveProfileContent,
  removeExternalResource,
};
