import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faTimes as freeFasFaTimes,
  faTimesCircle as freeFasFaTimesCircle,
  faHeart as freeFasFaHeart,
  faAngleDown as freeFasFaAngleDown,
  faExclamationCircle as freeFasFaExclamationCircle,
  faExclamationTriangle as freeFasFaExclamationTriangle,
  faPlus as freeFasFaPlus,
  faTrash as freeFasFaTrash,
  faPencilAlt as freeFasFaPencilAlt,
  faAsterisk as freeFasFaAsterisk,
  faClock as freeFasFaClock,
  faSearch as freeFasFaSearch,
  faChevronRight as freeFasFaChevronRight,
  faArrowRight as freeFasFaArrowRight,
  faArrowLeft as freeFasFaArrowLeft,
  faCheck as freeFasFaCheck,
  faDoorOpen as freeFasFaDoorOpen,
  faAt as freeFasFaAt
} from '@fortawesome/free-solid-svg-icons'

import {
  faHeart as freeFarFaHeart,
  faCheckCircle as freeFarFaCheckCircle,
  faCaretSquareLeft as freeFarFaCaretSquareLeft
} from '@fortawesome/free-regular-svg-icons'

library.add(
  freeFasFaTimes,
  freeFasFaTimesCircle,
  freeFasFaHeart,
  freeFasFaAngleDown,
  freeFasFaExclamationCircle,
  freeFasFaExclamationTriangle,
  freeFasFaPlus,
  freeFasFaTrash,
  freeFasFaPencilAlt,
  freeFasFaAsterisk,
  freeFasFaClock,
  freeFasFaSearch,
  freeFasFaChevronRight,
  freeFasFaArrowRight,
  freeFasFaArrowLeft,
  freeFasFaCheck,
  freeFasFaDoorOpen,
  freeFasFaAt,
  freeFarFaHeart,
  freeFarFaCheckCircle,
  freeFarFaCaretSquareLeft
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
