/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from "vue";
import topicsApi from "@/data/api/topics";
import { sanitizeFollowType, sanitizeFollowId } from "@/data/follow-helpers";
import { log, toInt, isSet, isObject } from "@/data/helpers";
import _ from "lodash";

function getDefaultTopic() {
  return {
    _id: "",
    title: "",
    description: "",
    topic_type: "",
    action_counts: {
      followers: 0,
      contributors: 0,
      partners: 0,
      heart: 0,
    },
    avatars: {
      followers: [],
      contributors: [],
      partners: [],
    },
    flags: {
      mighty_topic: false,
    },
    hash_tag: "",
  };
}

function getTopicHelper(currentState) {
  if (currentState.topic === null) {
    Vue.set(currentState, "topic", getDefaultTopic());
  }

  return currentState.topic;
}

const state = () => ({
  slug: "",
  feedType: null,
  topic: null,
  contributors: [],
  doneLoadingContributors: false,
  partners: [],
  doneLoadingPartners: false,
  groups: [],
  doneLoadingGroups: false,
  groupCount: 0,
  hasGroups: false,
  resources: [],
  doneLoadingResources: false,
  allPosts: {},
  topicFeeds: {
    counts: {
      stories: 0,
      thoughts: 0,
      questions: 0,
      total: 0,
    },
    gotInitialCount: false,
    trending: {
      loading: {
        pending: true,
        failed: true,
      },
      items: [],
      offset: 0,
      last_fetched_item_count: 0,
      types: [],
    },
    latest: {
      loading: {
        pending: true,
        failed: true,
      },
      items: [],
      offset: 0,
      last_fetched_item_count: 0,
      types: [],
    },
  },
  isTopicDataLoaded: false,
});

const getters = {
  /**
   * getFeedItems
   *
   * get the items inside the feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return array  list of items in the feed
   */
  getTopicFeedItems: (currentState) =>
    function privGetFeedItems(feedName) {
      return isSet(currentState.topicFeeds[feedName]) &&
        isObject(currentState.topicFeeds[feedName])
        ? currentState.topicFeeds[feedName].items
        : [];
    },

  getTopicFeedItemTotal: (currentState) =>
    function privGetFeedItems(feedName) {
      return isSet(currentState.topicFeeds) && isObject(currentState.topicFeeds)
        ? currentState.topicFeeds.counts.total
        : 0;
    },

  getTopicFeedItemCounts: (currentState) =>
    function privGetFeedItems(feedName) {
      return isSet(currentState.topicFeeds) && isObject(currentState.topicFeeds)
        ? currentState.topicFeeds.counts
        : {
            stories: 0,
            thoughts: 0,
            questions: 0,
            total: 0,
          };
    },

  /**
   * getTopicFeedItemLoadingState
   *
   * get the state of loading the feed items
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return object  the state describing the loading status
   */
  getTopicFeedItemLoadingState: (currentState) =>
    function privGetFeedItemLoadingState(feedName) {
      return isSet(currentState.topicFeeds[feedName]) &&
        isObject(currentState.topicFeeds[feedName])
        ? currentState.topicFeeds[feedName].loading
        : { pending: false, failed: true };
    },

  getTopic: (currentState) => {
    if (currentState.topic === null) {
      return getDefaultTopic();
    }
    return currentState.topic;
  },
  getTopicSlug: (currentState) => {
    return currentState.slug;
  },

  getTopicContributors: (currentState) => currentState.contributors,
  doneLoadingTopicContributors: (currentState) =>
    currentState.doneLoadingContributors,
  getTopicPartners: (currentState) => currentState.partners,
  doneLoadingTopicPartners: (currentState) => currentState.doneLoadingPartners,
  getTopicGroups: (currentState) => currentState.groups,
  doneLoadingTopicGroups: (currentState) => currentState.doneLoadingGroups,
  getTopicGroupCount: (currentState) => currentState.groupCount,
  getTopicFeedResources: (currentState) => currentState.resources,
  doneLoadingTopicFeedResources: (currentState) =>
    currentState.doneLoadingResources,
  topicHasGroups: (currentState) => currentState.hasGroups,
  getLastFetchedItemCount: (currentState) =>
    function privGetFeedItems(feedName) {
      return isSet(currentState.topicFeeds[feedName]) &&
        isObject(currentState.topicFeeds[feedName])
        ? currentState.topicFeeds[feedName].last_fetched_item_count
        : 0;
    },
  isTopicDataLoaded: (currentState) => currentState.isTopicDataLoaded
};

const actions = {
  /**
   * loadTopicFeedItems
   *
   * get all the items in a given feed, and update the store with the result
   *
   * @param object context  the context of the current store
   * @param string feedName  the name of the feed to load the items of
   * @return Promise  handles the updating of the internal store data
   */
  loadTopicFeedItems(context, { feedName, offset, limit, types }) {
    const slug = context.state.slug;

    if (!slug) {
      log("No topic slug to load feed");
      return false;
    }

    types = types || context.state.topicFeeds[feedName].types;

    if (!types) {
      log("Feed has not types");
    }

    context.commit("setTopicFeedLoadingState", {
      feedName,
      stateName: "pending",
      value: true,
    });

    // obtain the feed item list from the api
    return (
      topicsApi
        .postsForTopic(slug, feedName, offset, limit, types, this.$nodeAxios)
        // with successful results...
        .then((rawData) => {
          if (rawData) {
            context.commit("setTopicFeedLoadingState", {
              feedName,
              stateName: "pending",
              value: false,
            });
            // set the post list
            if (rawData.hits) {
              // on the first page, update the total, because it is not soured by a date range query
              if (offset == 0) {
                context.commit("appendTopicFeedItems", {
                  feedName,
                  items: rawData.hits,
                  counts: rawData.counts,
                });
                // on all other pages, only update the items
              } else {
                context.commit("appendTopicFeedItems", {
                  feedName,
                  items: rawData.hits,
                  appendData: true,
                });
              }
            } else {
              context.commit("setLastFetchedItemCount", { feedName, total: 0 });
            }
          }
        })
        .catch((e) => {
          log("ERROR FEED ITEMS:", feedName, e);
          context.commit("setTopicFeedLoadingState", {
            feedName,
            stateName: "pending",
            value: false,
          });
          context.commit("setTopicFeedLoadingState", {
            feedName,
            stateName: "failed",
            value: true,
          });
        })
    );
  },

  loadTopic(context, data) {
    const slug = context.state.slug || _.get(data, "t");

    if (!slug) {
      log("No topic slug to load feed");
      return false;
    }

    return topicsApi
      .topicBySlug(slug, this.$nodeAxios)
      .then((rawData) => {
        if (rawData.data) {
          context.commit("setTopic", rawData.data);
          context.commit("isTopicDataLoaded", true);
          return rawData.data;
        } else {
          return rawData;
        }
      })
      .catch((err) => {
        log("Error getting topic:", err);
        return undefined;
      });
  },

  loadTopicContributors(context, { off = 0, pp = 100 }) {
    const slug = context.state.slug;

    if (!slug) {
      log("No topic slug");
      return false;
    }

    return topicsApi
      .topicContributors(slug, off, pp, this.$nodeAxios)
      .then((data) => {
        const contributors = data.items || [];
        const append = off > 0;
        const done = contributors.length < pp;
        context.commit("setContributors", { contributors, append, done });
        context.commit('setActionCount', { field: 'contributors', count: data.total || 0 });
      });
  },

  loadTopicPartners(context, { off = 0, pp = 100 }) {
    const slug = context.state.slug;

    if (!slug) {
      log("No topic slug");
      return false;
    }

    return topicsApi
      .topicPartners(slug, off, pp, this.$nodeAxios)
      .then((data) => {
        const partners = data.items || [];
        const append = off > 0;
        const done = partners.length < pp;
        context.commit("setPartners", { partners, append, done });
        context.commit('setActionCount', { field: 'partners', count: data.total || 0 });
      });
  },

  loadTopicFeaturedContent(context) {
    const slug = context.state.slug;

    if (!slug) {
      log("No topic slug");
      return false;
    }
    return topicsApi
      .topicFeaturedContent(slug, this.$nodeAxios)
      .then((contents) => {
        if (contents && contents.length > 0) {
          context.commit("setFeaturedContent", contents[0]);
        }
      });
  },

  loadTopicGroups(context, { off, pp, status }) {
    const slug = context.state.slug;

    if (!slug) {
      log("No topic slug");
      return false;
    }

    return topicsApi
      .groupsForTopic({ slug, off, pp, status }, this.$nodeAxios)
      .then((data) => {
        const groups = data.items || [];
        const append = off > 0;
        const done = groups.length < pp;
        context.commit("setGroups", {
          groups,
          append,
          done,
          count: data.total,
        });
        // for the initial load (or any filtering of type 'All'), signifying whether topic has groups
        // to get around tricky issue for when a user filters on Public/Private and there are no groups
        if (_.isNil(status)) {
          context.commit("setHasGroups", groups.length > 0);
        }
      });
  },

  loadTopicFeedResources(context, { off = 0, pp = 100, types }) {
    const slug = context.state.slug;

    if (!slug) {
      log("No topic slug");
      return false;
    }

    return topicsApi
      .topicResources({ slug, off, pp, types }, this.$nodeAxios)
      .then((resources) => {
        const append = off > 0;
        const done = resources.length < pp;
        context.commit("setResources", { resources, append, done });
      });
  },

  toggleUserFollowingTopic(context, params) {
    console.log("params:", params);
  },

  removePostFromTopicFeed(context, postId) {
    context.commit("removeTopicFeedItem", postId);
  },

  pinPost(context, params) {
    return topicsApi.pinPostToTopic(
      params.post.id,
      params.topicId,
      this.$nodeAxios
    );
  },

  unpinPost(context, params) {
    return topicsApi.unpinPostToTopic(
      params.post.id,
      params.topicId,
      this.$nodeAxios
    );
  },
};

// helpers

/**
 * Must update post in each feed.
 */
const updateHeartCount = (currentState, incBy, params) => {
  Object.entries(currentState.topicFeeds).forEach((entry) => {
    if (entry.items) {
      const feedData = entry[1];
      const heartPost = feedData.items.find(
        (x) => x._id === params.postId.toString()
      );
      if (heartPost) {
        heartPost.action_counts.hearts += incBy;
        heartPost.hearted = incBy > 0;
      }
    }
  });
};

const updateCommentCount = (currentState, incBy, contentId) => {
  Object.entries(currentState.topicFeeds).forEach((entry) => {
    if (entry.items) {
      const feedData = entry[1];
      const commentPost = feedData.items.find((post) => post.id === contentId);
      if (commentPost) {
        if (commentPost.card_data) {
          commentPost.card_data["card-comment-count"] += incBy;
        } else {
          commentPost.action_counts.comments += incBy;
        }
      }
    }
  });
};

const mutations = {
  /**
   * appendFeedItems.
   *
   * adds items to the end of the list of the specified feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to modify
   * @param array items  the list of items to add to the end of the list
   * @param object authors  id indexed list of authors
   * @param object topics  id indexed list of topics
   */
  appendTopicFeedItems(
    currentState,
    { feedName, items, counts, appendData } = { feedName: "", items: [] }
  ) {
    if (!appendData) {
      currentState.topicFeeds[feedName].items = [];
      if (feedName === "photos") {
        Vue.set(currentState.topicFeeds, "gotInitialCount", false);
      }
      if (!currentState.topicFeeds.gotInitialCount) {
        Vue.set(currentState.topicFeeds, "counts", counts);
        Vue.set(currentState.topicFeeds, "total", counts.total);
        Vue.set(currentState.topicFeeds, "gotInitialCount", true);
      }
      if (feedName === "photos") {
        Vue.set(currentState.topicFeeds, "gotInitialCount", false);
      }
    }
    const list = currentState.topicFeeds[feedName].items || [];
    const itemIds = list.map((a) => a._id);
    const all = currentState.allPosts;

    // cycle through the new items
    items.forEach((_, i) => {
      const itm = items[i];
      itm.author = itm.author_id;
      itm.author_id = itm.author._id;

      // add the item to the list
      if (itemIds.indexOf(itm._id) === -1) {
        list.push(itm);
        all[itm._id] = itm;
      }
    });

    // set the final list in vue
    Vue.set(currentState.topicFeeds[feedName], "items", list);
    Vue.set(currentState.topicFeeds[feedName], "offset", list.length);
    Vue.set(
      currentState.topicFeeds[feedName],
      "last_fetched_item_count",
      items.length
    );

    Vue.set(currentState, "allPosts", all);
  },

  removeTopicFeedItem(currentState, postId) {
    Object.entries(currentState.topicFeeds).forEach((entry) => {
      if (entry.items) {
        const [name, data] = entry;
        const filtered = data.items.filter((x) => x._id !== postId);
        if (filtered.length < data.items.length) {
          Vue.set(currentState.topicFeeds[name], "items", filtered);
        }
      }
    });
    const all = currentState.allPosts;
    delete all[postId];
    Vue.set(currentState, "allPosts", all);
  },

  /**
   * setFeedLoadingState
   *
   * sets the state of the feed's loading situation
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to modify
   * @param string stateName  the state to update
   * @param bool value  the new value for the state
   */
  setTopicFeedLoadingState(currentState, { feedName, stateName, value }) {
    Vue.set(currentState.topicFeeds[feedName].loading, stateName, value);
  },

  /**
   * setTopicSlug
   *
   * sets the slug for this topic feed
   *
   * @param currentState
   * @param slug
   */
  setTopicSlug(currentState, slug) {
    Vue.set(currentState, "slug", slug);
  },

  setFeedType(currentState, feedType) {
    Vue.set(currentState, "feedType", feedType);
  },

  setContributors(currentState, { contributors, append, done }) {
    if (!append) {
      Vue.set(currentState, "contributors", contributors);
    } else {
      Vue.set(currentState, "contributors", [
        ...currentState.contributors,
        ...contributors,
      ]);
    }

    Vue.set(currentState, "doneLoadingContributors", done);
  },
  setPartners(currentState, { partners, append, done }) {
    if (!append) {
      Vue.set(currentState, "partners", partners);
    } else {
      Vue.set(currentState, "partners", [
        ...currentState.partners,
        ...partners,
      ]);
    }

    Vue.set(currentState, "doneLoadingPartners", done);
  },

  /**
   * setTopic
   *
   *
   * @param currentState
   * @param item
   */
  setTopic(currentState, item) {
    const newItem = item;
    Vue.set(currentState, "topic", newItem);
  },

  setLastFetchedItemCount(currentState, { feedName, total }) {
    Vue.set(
      currentState.topicFeeds[feedName],
      "last_fetched_item_count",
      total
    );
  },

  addUserHeart(currentState, params) {
    updateHeartCount(currentState, 1, params);
  },

  removeUserHeart(currentState, params) {
    updateHeartCount(currentState, -1, params);
  },

  /**
   * Update comment counts
   * see comments.js module mutations
   */
  prependComment(currentState, comment) {
    updateCommentCount(currentState, 1, comment.content_id);
  },
  prependReply(currentState, { reply }) {
    updateCommentCount(currentState, 1, reply.content_id);
  },
  removeCommentFromList(currentState, params) {
    if (params.comment && params.comment.content_id) {
      updateCommentCount(
        currentState,
        -(1 + params.replyCount),
        params.comment.content_id
      );
    }
  },

  /**
   * followItem
   *
   * store the follow action for an item
   *
   * @param object currentState  the state of the current handlers
   * @param string itemType  the type of item that needs to be followed
   * @param string itemId  the id of the item that neesd to be followed
   */
  followItem(currentState, { itemType, itemId, initiator }) {
    const testType = sanitizeFollowType(itemType);
    const testId = sanitizeFollowId(itemId);
    // only run for topics
    if (testType !== "category") {
      return;
    }

    const currentTopic = getTopicHelper(currentState);
    // only run for this topic
    if (!currentTopic._id || testId !== currentTopic._id) {
      return;
    }

    // update the follow count
    currentTopic.action_counts.follows += 1;
    Vue.set(currentState, "topic", currentTopic);
  },

  /**
   * unfollowItem
   *
   * store the unfollow action for an item
   *
   * @param object currentState  the state of the current handlers
   * @param string itemType  the type of item that needs to be followed
   * @param string itemId  the id of the item that neesd to be followed
   */
  unfollowItem(currentState, { itemType, itemId }) {
    const testType = sanitizeFollowType(itemType);
    const testId = sanitizeFollowId(itemId);
    // only run for topics
    if (testType !== "category") {
      return;
    }

    const currentTopic = getTopicHelper(currentState);
    // only run for this topic
    if (!currentTopic._id || testId !== currentTopic._id) {
      return;
    }

    // update the follow count
    currentTopic.action_counts.follows -= 1;
    Vue.set(currentState, "topic", currentTopic);
  },

  /**
   * subscribePost
   *
   * update the subscription status of the post, if it belongs to this feed
   *
   * @param object currentState  the state of this handler
   * @param string itemId  the id of the item that needs updating
   * @param object subscription  the subscription object from the server
   */
  subscribePost(currentState, { itemId }) {
    if (!isSet(currentState.allPosts[itemId])) {
      return;
    }

    const all = currentState.allPosts;
    all[itemId].subscribed = true;
    Vue.set(currentState, "allPosts", all);
  },

  /**
   * unsubscribePost
   *
   * update the subscription status of the post, if it belongs to this feed
   *
   * @param object currentState  the state of this handler
   * @param string itemId  the id of the item that needs updating
   * @param object subscription  the subscription object from the server
   */
  unsubscribePost(currentState, { itemId }) {
    if (!isSet(currentState.allPosts[itemId])) {
      return;
    }

    const all = currentState.allPosts;
    all[itemId].subscribed = false;
    Vue.set(currentState, "allPosts", all);
  },

  setGroups(currentState, { groups, append, done, count }) {
    if (!append) {
      Vue.set(currentState, "groups", groups);
      Vue.set(currentState, "groupCount", count);
    } else {
      Vue.set(currentState, "groups", [...currentState.groups, ...groups]);
    }

    Vue.set(currentState, "doneLoadingGroups", done);
  },

  setHasGroups(currentState, hasGroups) {
    Vue.set(currentState, "hasGroups", hasGroups);
  },

  setResources(currentState, { resources, append, done }) {
    if (!append) {
      Vue.set(currentState, "resources", resources);
    } else {
      Vue.set(currentState, "resources", [
        ...currentState.resources,
        ...resources,
      ]);
    }

    Vue.set(currentState, "doneLoadingResources", done);
  },

  setFeaturedContent(currentState, content) {
    Vue.set(currentState.topic, "featured", content);
  },

  resetTopicState(currentState) {
    Object.assign(currentState, state());
  },

  isTopicDataLoaded(currentState, value) {
    Vue.set(currentState, "isTopicDataLoaded", value);
  },

  setActionCount(currentState, { field, count }) {
    const currentTopic = getTopicHelper(currentState);
    if (currentTopic.action_counts) {
      currentTopic.action_counts[field] = count;
    }
    Vue.set(currentState, 'topic', currentTopic);
  }
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
