/* eslint no-param-reassign: 0 */
/* eslint no-underscore-dangle:0 */

/**
 * The User Profile store
 *
 * Responsible for managing state of:
 *
 * - User Profile info
 * - User Profile stories
 */
import Vue from 'vue';
import _ from 'lodash';
import { FOLLOW_TYPE_USER } from '@/data/enum/follow';
import user from '@/data/api/user';
import awardsApi from '@/data/api/awards';
import communitiesApi from '@/data/api/communities';
import { log, err, toInt, isObject } from '@/data/helpers';

// initial state
const state = () => ({
  nicename: null,
  partnerId: null,
  user: {
    profile: {},
    action_counts: {
      following: {}
    }
  },
  stories: [],
  storiesTotal: false,
  defaultActivities: {
    content: [],
    done: true,
  },
  activities: [],
  activitiesCount: {},
  activityFeedLoading: false,
  doneLoading: true,
  // currentTab is legacy profile
  currentTab: 'activity',
  // profileTab is new user profile
  profileTab: 'About',
  selectedFilter: ['WORDPRESS_POST', 'WORDPRESS_VIDEO', 'THOUGHT', 'QUESTION', 'COMMENT', 'POLL'],
  sort: 'latest',
  pagination: {
    pp: 10,
    off: 0,
  },
  previewPublicProfile: false,
  // these are the communities that the profile owner is a leader of
  communities: [],
  featuredContent: [],
  awards: [],
});

function cleanPost(s) {
  s.post_id = s._id;
  if (isObject(s.author_id)) {
    s.author = s.author_id;
    s.author_id = s.author._id;
  }
  return s;
}

// getters
const getters = {
  getUsername: state => state.nicename,
  getUserProfile: state => state.user,
  profileGetFollowers: state => state.user.action_counts.followers,
  profileGetFollowing: state => state.user.action_counts.following.total,
  getActivities: state => state.activities,
  getDefaultActivities: state => state.defaultActivities,
  getActivitiesCount: state => state.activitiesCount,
  getActivitiesIsLoading: state => state.activityFeedLoading,
  getActivitiesDoneLoading: state => state.doneLoading,
  // currentTab is legacy profile
  getCurrentTab: state => state.currentTab,
  // profileTab is new user profile
  getProfileTab: state => state.profileTab,
  getSelectedFilter: state => state.selectedFilter,
  previewPublicProfile: state => state.previewPublicProfile,
  getProfileCommunities: state => state.communities,
  getProfileFeaturedContent: state => state.featuredContent,
  getProfileAwards: state => state.awards,
};

// actions
const actions = {
  /**
   * Load profile stories data and commit to the state
   * Loads 10 posts for each call
   */
  loadUserActivity(context) {
    const userId = context.state.user._id;
    const { pp, off } = context.state.pagination;
    const { selectedFilter, sort } = context.state;
    return user.loadActivity(userId, selectedFilter, pp, off, sort, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('setUserActivity', { data });
        }
      })
      .catch((er) => { log(er); });
  },

  /**
   * Load profile details data and commit to the state
   */
  loadProfileDetails(context) {
    const nicename = context.state.nicename;

    if (!nicename) {
      log('No user profile nicename set to load profile');
      return false;
    }

    return this.$nodeAxios.get(`/api/v1/user/${nicename}`).then(response => {
      if (response.data) {
        context.commit('setProfileDetails', response.data.user)
      }
    }).catch(err);
  },

  /*
  / given this legacy code was kind of convoluted
  /
  */
  async loadInitialActivityFeed(context, { feedName }) {
    await context.dispatch('loadActivityFeed', { feedName});
    await context.commit('setDefaultActivities');
  },

  async loadActivityFeed(context, { feedName, resetFeed = false }) {
    context.commit('setActivityFeedLoading', true);
    if (resetFeed) {
      context.commit('resetActivityState');
    }
    switch (feedName) {
      case 'partner_feed':
        await context.dispatch('loadPartnerContent');
        break;
      case 'profile_feed':
      default:
        await context.dispatch('loadUserActivity');
    }

    context.commit('setActivityFeedLoading', false);
    const { pp, off } = context.state.pagination;
    await context.dispatch('updatePagination', { pp, off: off + pp });
  },

  loadPartnerContent(context) {
    const partnerId = context.state.partnerId;
    const last = context.state.activities.length - 1;
    const lastId = last > -1 ? context.state.activities[last]._id : '';
    const { pagination } = context.state;
    // selected posts filter
    const filterAnchor = context.getters.getSelectedFilter;

    return user.partnerContent(partnerId, pagination.pp, lastId, filterAnchor, this.$nodeAxios)
      .then((data) => {
        // make post_id available to components (to match old data format)
        // const hits = data.hits.map(cleanPost);
        if (data) {
          context.commit('setUserActivity', { data });
          // context.commit('addProfileStories', { userStories: hits });
          if (!lastId) {
            context.commit('setStoriesTotal', data.total);
          }
        }
      });
  },

  addPostToProfileFeed(context, postData) {
    // only add post if it's your own profile feed
    const loggedInUser = context.rootGetters.user;
    const profileUser = context.state.user;
    if (profileUser._id !== loggedInUser._id) {
      return;
    }
    const updatedPostData = { ...postData, justPosted: true };
    const prepend = true;
    context.commit('addProfileStories', { userStories: [updatedPostData], prepend });
    context.commit('addPostToUserActivity', postData);
  },

  removePostFromProfileFeed(context, postId) {
    context.commit('removeProfileStory', postId);
  },

  async selectCurrentTab(context, { tab, feedName }) {
    context.commit('setCurrentTab', { tab });
    if (tab === 'activity') {
      await context.dispatch('loadActivityFeed', { feedName, resetFeed: true });
    }
  },

  updatePagination(context, { pp, off }) {
    context.commit('setPagination', { pp, off });
  },

  loadProfileCommunities(context) {
    return communitiesApi.loadLeaderCommunities(context.state.user._id, this.$nodeAxios)
      .then((data) => {
        if (data && data.communities) {
          context.commit('setProfileCommunites', data.communities);
        }
      })
      .catch((er) => { log(er); });
  },

  loadProfileFeaturedContent(context) {
    return user.profileFeaturedContent(context.state.user._id, this.$nodeAxios)
      .then((content) => context.commit('setProfileFeaturedContent', content))
      .catch((er) => { log(er); });
  },

  loadProfileAwards(context) {
    return awardsApi.userAwards(context.state.user._id, this.$nodeAxios)
    .then((awards) => context.commit('setProfileAwards', awards))
    .catch((er) => { log(er); });
  },
};

// helpers
const updateHeartCount = (currentState, incBy, params) => {
  const heartPost = currentState.stories.find(post => post.id === params.postId.toString());
  if (heartPost) {
    heartPost.action_counts.hearts += incBy;
    heartPost.hearted = incBy > 0;
  }
};

const updateCommentCount = (currentState, incBy, contentId) => {
  const commentPost = currentState.stories.find(post => post.id === contentId);
  if (commentPost) {
    if (commentPost.card_data) {
      commentPost.card_data['card-comment-count'] += incBy;
    } else {
      commentPost.action_counts.comments += incBy;
    }
  }
};

// mutations
const mutations = {
  setProfileNicename(currentState, userNicename) {
    if (userNicename) {
      currentState.nicename = userNicename;
      currentState.user = {};
      currentState.stories = [];
      currentState.activities = [];
      currentState.getActivitiesCount = {};
      currentState.storiesTotal = false;
    }
  },

  setProfilePartnerId(currentState, partnerId) {
    if (partnerId) {
      currentState.partnerId = partnerId;
      currentState.stories = [];
      currentState.storiesTotal = false;
    }
  },

  setProfileDetails(currentState, userProfile) {
    currentState.user = userProfile;
  },

  addProfileStories(currentState, { userStories, prepend }) {
    if (prepend) {
      currentState.stories = [...userStories, ...currentState.stories];
    } else {
      currentState.stories.push(...userStories);
    }
  },

  removeProfileStory(currentState, postId) {
    const filtered = currentState.stories.filter(x => x._id !== postId);
    if (filtered.length < currentState.stories.length) {
      currentState.stories = filtered;
      currentState.storiesTotal -= 1;
    }

    currentState.activities = [...currentState.activities.filter((activity) => { return activity._id !== postId})];
  },

  setStoriesTotal(currentState, totalStories) {
    currentState.storiesTotal = totalStories;
  },

  /**
   * When user adds or removes a heart, do the incrementation
   *   in the profile stories post card data
   * @from post.js mutation commit
   */
  addUserHeart(currentState, params) {
    updateHeartCount(currentState, 1, params);
  },
  removeUserHeart(currentState, params) {
    updateHeartCount(currentState, -1, params);
  },

  /**
   * Keep followers count in sync with user follow button activity
   * @from user.js mutation commit
   */
  followItem(currentState, itm) {
    if (itm.itemType === FOLLOW_TYPE_USER && itm.itemId === currentState.user._id) {
      const current = currentState.user;
      current.profile.follower_count += 1;
      // must use Vue.set() and update the entire object, so that vuejs is aware of the changes
      Vue.set(currentState, 'user', current);
    }
  },
  unfollowItem(currentState, itm) {
    if (itm.itemType === FOLLOW_TYPE_USER && itm.itemId === currentState.user._id) {
      const current = currentState.user;
      current.profile.follower_count -= 1;
      // must use Vue.set() and update the entire object, so that vuejs is aware of the changes
      Vue.set(currentState, 'user', current);
    }
  },

  /**
   * Update comment counts
   * see comments.js module mutations
   */
  prependComment(currentState, comment) {
    updateCommentCount(currentState, 1, comment.content_id);
  },
  prependReply(currentState, { reply }) {
    updateCommentCount(currentState, 1, reply.content_id);
  },
  removeCommentFromList(currentState, params) {
    if (params.comment && params.comment.content_id) {
      updateCommentCount(currentState, -(1 + toInt(params.replyCount)), params.comment.content_id);
    }
  },
  setUserActivity(currentState, data) {
    let posts = data.data.hits;
    currentState.activities = [...currentState.activities, ...posts];
    currentState.doneLoading = posts.length < currentState.pagination.pp;
    if (data.data.counts && data.data.counts.total) {
      currentState.activitiesCount = data.data.counts;
    }
  },
  /*
  / the default activities are for the new profile, so we can
  / easily flip back to them if a user clicks the back arrow
  */
  setDefaultActivities(currentState) {
    currentState.defaultActivities.content = [ ...currentState.activities ];
    currentState.defaultActivities.done = currentState.activities < currentState.pagination.pp;
  },
  /*
  / similarly as above, I'm updating some fields to match the default data
  */
  resetToDefaultActivity(currentState) {
    const defaultState = state();
    currentState.activities = currentState.defaultActivities.content;
    currentState.pagination.off = currentState.defaultActivities.content.length;
    currentState.doneLoading = currentState.defaultActivities.done;
    currentState.selectedFilter = defaultState.selectedFilter;
    currentState.sort = defaultState.sort;
  },
  addPostToUserActivity(currentState, post) {
    currentState.activities = [post, ...currentState.activities];
    currentState.activitiesCount = currentState.activitiesCount + 1;
  },
  setCurrentTab(currentState, { tab }) {
    currentState.currentTab = tab;
  },
  setProfileTab(currentState, tab) {
    currentState.profileTab = tab;
  },
  setSelectedFilter(currentState, { filter, sort }) {
    let filterArray = filter;
    if (filter[0] === 'all') {
      filterArray = ['WORDPRESS_POST', 'WORDPRESS_VIDEO', 'THOUGHT', 'QUESTION', 'COMMENT', 'POLL'];
    }
    currentState.selectedFilter = filterArray;
    if (sort) {
      currentState.sort = sort;
    }
  },
  setPagination(currentState, { pp, off }) {
    currentState.pagination.pp = pp;
    currentState.pagination.off = off;
  },
  setActivityFeedLoading(currentState, isLoading) {
    currentState.activityFeedLoading = isLoading;
  },
  resetActivityState(currentState) {
    const defaultState = state();
    currentState.activities = defaultState.activities;
    currentState.activitiesCount = defaultState.activitiesCount;
    currentState.pagination = defaultState.pagination;
  },
  resetProfileState(currentState) {
    Object.assign(currentState, state());
  },
  updateActivityItem(currentState, post) {
    const idx = currentState.activities.findIndex(x => x._id === post._id);
    if (idx > -1) {
      const items = [...currentState.activities];
      items[idx] = post;
      Vue.set(currentState, 'activities', items);
    }
  },

  togglePreviewPublicProfile(currentState, { bool = null }) {
    let value = (bool === null) ? !currentState.previewPublicProfile : bool;
    Vue.set(currentState, 'previewPublicProfile', value);
  },

  setProfileCommunites(currentState, communities) {
    Vue.set(currentState, 'communities', communities);
  },

  setProfileFeaturedContent(currentState, content) {
    Vue.set(currentState, 'featuredContent', content);
  },

  addProfileFeaturedContent(currentState, content) {
    const idx = currentState.featuredContent.findIndex(x => x._id === content._id);
    if (idx === -1) {
      currentState.featuredContent.push(content);
    }
  },

  removeProfileFeaturedContent(currentState, itemId) {
    const idx = currentState.featuredContent.findIndex(x => x._id === itemId);
    if (idx > -1) {
      currentState.featuredContent.splice(idx, 1);
    }
  },

  setProfileAwards(currentState, awards) {
    Vue.set(currentState, 'awards', awards);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
