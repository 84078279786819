const FOLLOW_TYPE_USER = 'user';
const FOLLOW_TYPE_CATEGORY = 'category';
const FOLLOW_TYPE_TOPIC = 'topic';

const VALID_FOLLOW_TYPES = [
  FOLLOW_TYPE_USER,
  FOLLOW_TYPE_CATEGORY,
  FOLLOW_TYPE_TOPIC,
];

export {
  VALID_FOLLOW_TYPES,
  FOLLOW_TYPE_USER,
  FOLLOW_TYPE_CATEGORY,
  FOLLOW_TYPE_TOPIC,
};

export default {
  VALID_FOLLOW_TYPES,
  FOLLOW_TYPE_USER,
  FOLLOW_TYPE_CATEGORY,
  FOLLOW_TYPE_TOPIC,
};
