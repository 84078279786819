/* eslint no-param-reassign: 0 */
import resources from '@/data/api/resources';
// initial state
const state = () => ({
  // list of resources currently loaded
  current: {
    found: 0,
    results: [{
      name: '',
      url: '',
      key: '',
      partner: {
        id: '',
        name: '',
        'nice-name': '',
      },
    }],
  },
});

const getters = {
  getTopicResources: ctxState => ctxState.current,
};

const actions = {
  loadTopicResources(context, { topicSlug }) {
    context.commit('setTopicResources', { found: 0, results: [] });
    return resources.getResources(topicSlug, this.$wpAxios).then((data) => {
      if (data) {
        context.commit('setTopicResources', data);
      }
    });
  },
};

const mutations = {
  setTopicResources(currentState, topicResources) {
    currentState.current = topicResources;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
