/* eslint no-underscore-dangle:0 */
import { get } from 'lodash';
import { stripHtml } from 'string-strip-html';
import xss from 'xss';
import { log, isSet, isArray, isObject, sameDomainUrl } from '@/data/helpers';
import { renderAllEmbeds } from '@/data/submission-helpers';


/**
 * sanitizeText
 *
 * functionality to sanitize title/body for posts
 *
 * @param string str  the html string that needs conversion
 * @param array topics  the list of topic objects to translate
 */

 function sanitizeText({ content = '', topics = [], mentions = [], embeds = {}, textOnly = false }) {
  return renderAllEmbeds(transformMentions(transformHashTags(content, topics), mentions), embeds, textOnly);
 }

/**
 * transformHashTags
 *
 * consumes a string and a list of topics, and converts all topic tags in the string to a-tags pointing to the topic pages
 *
 * @param string str  the html string that needs conversion
 * @param array topics  the list of topic objects to translate
 * @param boolean quillEditor   for when editing an existing post, reusing this to convert to proper quill editor form
 */
function transformHashTags(str = '', topics, quillEditor = false) {
  const map = {};
  // create a map of topics
  if (isArray(topics)) {
    // create an ID indexed map of topic data
    topics.forEach((t) => {
      const id = t.id || t._id;
      if (isSet(id) && isSet(t.slug) && isSet(t.hash_tag)) {
        map[id] = {
          id,
          mighty: isObject(t.flags) && !!t.flags.mighty_topic,
          url: `/topic/${t.slug}/`,
          title: t.title || t.hash_tag,
          label: `#${t.hash_tag}`,
        };
      }
    });
  }

  const matchedTopics = [...str.matchAll(/<topic id=\"(.+?)\"><\/topic>/g)];
  for (const [matchedTag, topicId] of matchedTopics) {
    const topicData = map[topicId];
    if (topicData && !quillEditor) {
      const attributes = [
       `class="tm-topic-link ${topicData.mighty ? 'mighty' : 'ugc'}-topic"`,
        `title="${topicData.title}"`,
        `href="${topicData.url}"`,
        `data-id="${topicId}"`,
        `data-name="${topicData.title}"`,
        `aria-label="hashtag ${topicData.title}"`,
      ];
      const topicLinkTag = `<a ${attributes.join(' ')}>${topicData.label}</a>`;
      str = str.replace(matchedTag, topicLinkTag);
    } else if (topicData && quillEditor) {
      const mightyTopic = get(topicData, 'flags.mighty_topic', false);
      const attributes = [
        `class="topic ${mightyTopic ? 'mighty' : 'ugc'}-topic"`,
         `data-denotion-char="#"`,
         `data-id="${topicId}"`,
         `data-value="${topicData.title}"`,
         `data-mighty_topic="${mightyTopic}"`,
         `id="${topicId}"`,
       ];
       const topicLinkTag = `<topic ${attributes.join(' ')}>${topicData.label}</topic>`;
      str = str.replace(matchedTag, topicLinkTag);
    } else {
      str = str.replace(matchedTag, '');
    }
  }

  return str;
}

/**
 * transformMentions
 *
 * consumes a string and a list of mentions, and converts all mentions tags in the string to a-tags pointing to the topic pages
 *
 * @param string str  the html string that needs conversion
 * @param array topics  the list of topic objects to translate
 * @param boolean quillEditor   for when editing an existing post, reusing this to convert to proper quill editor form
 */
function transformMentions(str = '', mentions, quillEditor = false) {
  const map = {};
  // create a map of topics
  if (isArray(mentions)) {
    // create an ID indexed map of mention data
    mentions.forEach((m) => {
      const id = m.id || m._id;
      if (isSet(id) && isSet(m.username)) {
        map[id] = {
          id,
          url: `/u/${m.username}/`,
          title: m.display_name || m.username,
          label: `@${m.username}`,
        };
      }
    });
  }

  const matchedMentions = [...str.matchAll(/<user id=\"(.+?)\"><\/user>/g)];
  for (const [matchedMention, userId] of matchedMentions) {
    const userData = map[userId];
    if (userData && !quillEditor) {
      const attributes = [
       `class="tm-mention-link"`,
        `title="${userData.title}"`,
        `href="${userData.url}"`,
        `data-id="${userId}"`,
        `data-name="${userData.title}"`,
        `aria-label="mention ${userData.title}"`,
      ];
      const userLinkTag = `<a ${attributes.join(' ')}>${userData.label}</a>`;
      str = str.replace(matchedMention, userLinkTag);
    } else if (userData && quillEditor) {
        const attributes = [
            `class="user"`,
            `data-denotion-char="@"`,
            `data-id="${userId}"`,
            `data-value="${userData.username}"`,
            `id="${userId}"`,
        ];
        const userLinkTag = `<user ${attributes.join(' ')}>${userData.label}</user>`;
        str = str.replace(matchedMention, userLinkTag);
    } else {
      str = str.replace(matchedMention, '');
    }
  }

  return str;
}

function textNodeFromNode(node) {
  const text = node.textContent;
  Array.from(node.children).forEach((c) => { node.removeChild(c); });
  return document.createTextNode(text);
}

function textNodeBefore(node) {
  const textNode = textNodeFromNode(node);
  node.parentNode.insertBefore(textNode, node);
  node.parentNode.removeChild(node);
}

function nodeTextOnly(node) {
  const textNode = textNodeFromNode(node);
  node.appendChild(textNode);
  return node;
}

/**
 * rOnlyTags
 *
 * recursively remove any tags that are not text nodes, or do not match the tag list
 *
 * @param Element node  the topmost element to check within
 * @param array tags  the list of valid tags
 * @return Element  updated element
 */
function rOnlyTags(node, tags, curDepth = 0) {
  const newDepth = curDepth + 1;
  // reached max depth? assume only text inside this node
  if (newDepth > 2) {
    return nodeTextOnly(node);
  }

  // cycle through the child nodes
  Array.from(node.children).forEach((c) => {
    // text nodes?
    if (c.nodeType === Node.TEXT_NODE) {
    // valid tags
    } else if (tags.indexOf(c.tagName) > -1) {
      rOnlyTags(c, tags, newDepth);
    // clean everything else
    } else {
      textNodeBefore(c);
    }
  });

  return true;
}

/**
 * cleanHTML
 *
 * take the text of a post, and normalize, strip all the irrelevant html tags, and add a p-tag around paragraphs
 *
 * @param string content  the strint to normalize
 * @return string  a normalized version of that string
 */
function cleanHTML(content) {
  // first, wrap this shit in p-tags
  let out = content.replace(/^([\s\S]*)$/, '<p>$1</p>');

  // next replace \n\n with </p><p>
  out = out.replace(/\n\n/g, '</p><p>');

  // replace \n with <br>
  out = out.replace(/\n/g, '<br>');
  out = xss(out);
  out = stripHtml(out, { ignoreTags: ['p', 'a', 'topic', 'br', 'link', 'user']}).result
  return out;
}

/**
 * removeTags
 *
 * removes all the unwanted tags, and replaces some with markers
 *
 * @param string str  the string to sanitize
 * @return string  the sanitized string
 */
function removeTags(str, ignoreSpacing = false) {
  let out = str;
  // remove empty p-tags
  out = out.replace(/<p><\/p>/gi, '');

  /*
  / when Editing a post (ie. editing an already created post) within the quill editor,
  / it's wrapping any prior topic tags in p tags, which then the regex statement after this one
  / is removing the p tags and adding double carriage returns, this was my solution to essentially
  / remove the p tags surrounding the topic tags, to avoid unnecessary spacing
  */
  out = out.replace(/<\/p><p><topic(.*?)<\/p><p>/g, (full, one) => {
    const newOne = full.replace(/<\/p><p>(.*?)<\/p><p>/g, (outer, inner) => inner);
    return newOne;
  });

  // remove p-tags, and replace them with double carriage returns
  out = out.replace(/<p>(.*?)<\/p>/g, (full, one) => {
    const newOne = one.replace(/(\n|\r){2,}/, '\n');
    return `${newOne}\n\n`;
  });

  // fix BRs. make them single carriage returns
  out = out.replace(/<br\/?>/gi, '\n');

  // fix new topics
  out = out.replace(/(<topic-new ).*?(name="[^"]+").*?>.*?<\/topic-new>/gi, '$1$2></topic-new>');

  // fix existing tags
  out = out.replace(/(<topic ).*?(id="[^"]+").*?>.*?<\/topic>/gi, '$1$2></topic>');

  // fix user mention tags
  out = out.replace(/(<user ).*?(id="[^"]+").*?>.*?<\/user>/gi, '$1$2></user>');

  // fix ampersands
  // out = out.replace(/&amp;/gi, '&');

  // cleanup leading and trailing spaces
  out = out.replace(/^(&nbsp;|\s)+/, '');
  out = out.replace(/(&nbsp;|\s)+$/, '');

  return out;
}

function textMaybeTags(text, tags) {
  let out = text.substr(0);
  out = out.replace(/#([a-zA-Z0-9]+?)(?=$|[^a-zA-Z0-9])/g, (whole, first) => {
    const lower = first.toLowerCase();
    if (isSet(tags[lower])) {
      const tag = tags[lower];
      if (isSet(tag._id)) {
        return `<topic id="${tag._id}"></topic> `;
      }

      return `<topic-new name="${tag._normalized_hash_tag}"></topic-new> `;
    }
    return `<topic-new name="${first}"></topic-new> `;
  });
  return out;
}

export {
  sanitizeText,
  transformHashTags,
  transformMentions,
  cleanHTML,
  removeTags,
  textMaybeTags,
};

export default {};
