window.addEventListener('load', () => {
  const script = document.createElement('script')
  script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  script.defer = true;
  document.head.appendChild(script)
  script.addEventListener('load', () => {
    AppleID.auth.init({
      clientId: 'com.themighty.TheMightyWeb', // This is the service ID we created.
      scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
      redirectURI: process.env.SITE_BASE_URL, // As registered along with our service ID
      state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      usePopup: true, // Important if we want to capture the data apple sends on the client side.
    });
  });
});
