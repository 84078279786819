const TmHumanNumber = {
  install(Vue) {
    Vue.filter('tm_human_number', (v, zeroValue = 'Be First') => {
      let letter = '';
      let num = parseInt(v, 10);

      const sigFigsFromVal = (val) => {
        const rnd = Math.round(val).toString();
        return (rnd.length === 1) ? 2 : 3;
      };

      const ceilSigFigs = (n, sig) => {
        const mult = 10 ** (sig - Math.floor(Math.log(n) / Math.LN10) - 1);
        return Math.round(Math.round(n * mult) / mult);
      };
      // if the number is 0 or less, return an empty string
      if (v <= 0) {
        return zeroValue;
      }

      // figure out the letter to use, if any
      if (v >= 10 ** 12) {
        letter = 'T';
        num = ceilSigFigs(num, sigFigsFromVal(num / (10 ** 12))) / (10 ** 12);
      } else if (v >= 10 ** 9) {
        letter = 'B';
        num = ceilSigFigs(num, sigFigsFromVal(num / (10 ** 9))) / (10 ** 9);
      } else if (v >= 10 ** 6) {
        letter = 'M';
        num = ceilSigFigs(num, sigFigsFromVal(num / (10 ** 6))) / (10 ** 6);
      } else if (v >= 10 ** 3) {
        letter = 'K';
        num = ceilSigFigs(num, sigFigsFromVal(num / (10 ** 3))) / (10 ** 3);
      }

      return `${num}${letter}`;
    });
  },
};

export default TmHumanNumber;
