import SetCookieParser from 'set-cookie-parser';
const isDevelopment = process.env.NODE_ENV === 'development';

export default function ({ $axios, $cookies, store, query }, inject) {
  const apis = {
    wpAxios: process.env.SITE_BASE_URL,
    apiAxios: process.env.API_BASE_URL,
    nodeAxios: process.env.VOICES_BASE_URL,
    streamAxios: process.env.STREAM_BASE_URL,
  };

  const axiosInstances = {};

  const refreshToken = async () => {
    const response = await axiosInstances.wpAxios.post(`/wp-admin/admin-ajax.php?action=refresh_token`);
    const cookies = SetCookieParser.parse(response, { map: true });

    if (!cookies.token) {
      return;
    }

    $cookies.set('token', cookies.token.value, {
      path: cookies.token.path,
      expires: cookies.token.expires,
      maxAge: cookies.token.maxAge,
      domain: cookies.token.domain,
      secure: true,
      httpOnly: cookies.token.httpOnly,
    });

    if (process.env.LOCAL) {
      $cookies.set('token', cookies.token.value, {
        path: cookies.token.path,
        expires: cookies.token.expires,
        maxAge: cookies.token.maxAge,
      });
    }

    return cookies.token.value;
  }

  const authorizeUser = async () => {
    return axiosInstances.apiAxios.post('/auth/me/', {
      settings: true,
      profile: true,
    }).then(async response => {
      store.commit('setCurrentUser', response.data);
      store.commit('setSessionLoaded', true);
      await store.dispatch('loadMyFeaturedContent', response.data._id);
      // this is for keeping track of the user's login streak for the respective Badge
      await store.dispatch('connectUser', response.data._id);
      await store.dispatch('loadMyBadges', response.data._id);
    });
  };

  const tokenInterceptor = (config) => {
    const token = $cookies.get('token', { fromRes: true }) || $cookies.get('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

  const checkPageViews = () => {
    // originally this was only for Articles, but now entails all pages
    const articleCount = $cookies.get('articleCount');
    if (articleCount) {
      store.commit('setArticleCount', parseInt(articleCount));
    } else {
      $cookies.set('articleCount', 0);
    }
  };

  const retryFailedApiSessionToken = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        return refreshToken().then(() => {
          const config = { ...error.config };
          console.log('retrying', error.config.url);
          const axiosInstance = $axios.create(config);
          axiosInstance.interceptors.request.use(tokenInterceptor);
          return axiosInstance.request();
        });
      }
    }
    console.log('[axios] Error', error.config.url)
    throw error;
  }

  for (const [key, baseURL] of Object.entries(apis)) {
    const config = {
      headers: {
        common: {
          'Content-Type': 'application/json'
        }
      },
      withCredentials: true,
      baseURL,
    };

    if (isDevelopment) {
      config.headers.common['Basic-Authorization'] = 'Basic dGhlbWlnaHR5Om1pZ2h0eTE=';
    }

    const api = $axios.create(config);

    if ('axiosExecutionTimeDebug' in query) {
      api.interceptors.request.use( x => {
        x.meta = x.meta || {}
        x.meta.requestStartedAt = new Date().getTime();
        return x;
      });

      api.interceptors.response.use(x => {
          console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
          return x;
        },
        x => {
          console.error(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
          throw x;
        }
      );
    }

    if (key !== 'wpAxios') {
      api.interceptors.request.use(tokenInterceptor);
      api.interceptors.response.use(config => config, retryFailedApiSessionToken);
    }

    inject(key, api);
    axiosInstances[key] = api;
  }

  inject('refreshToken', refreshToken);
  inject('authorizeUser', authorizeUser);
  inject('checkPageViews', checkPageViews);
}
