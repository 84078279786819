// helper functions
import { get, map } from 'lodash';
import { isNumber, isSet, clamp, log, err, DataError } from '../helpers';
import searchApi from './search';

function sanitizePost(post) {
  // eslint-disable-next-line
  if (isSet(post._id)) {
    // eslint-disable-next-line
    post.id = post._id;
  }

  if (post.type === 'THOUGHT' || post.type === 'QUESTION') {
    // eslint-disable-next-line
    post.commentUrl = `/#post=${post._id}&comments=1`;
  }
}

/**
 * sanitizePostsResponse
 *
 * consumes the raw response from ajax, and makes sure that all the required fields are present on each returned post
 *
 * @param object response  the raw data from the ajax
 * @return object  the translated version of the data
 */
function sanitizePostsResponse(response) {
  response.posts.forEach(sanitizePost);
  return response;
}

/**
 * getFeedFromUrl
 *
 * gets the feed items from a url
 *
 * @param string url  the url of the feed
 * @param object query  the query parameters for the url for the feed
 * @return Promise  the promise containing the results
 */
function getFeedFromUrl(url, query, $nodeAxios) {
  // perform the request and generate a promise for the response
  const promise = $nodeAxios.get(url, { params: query })
    // get the response
    .then((response) => {
      const posts = sanitizePostsResponse(response.data);

      // return the results
      return posts;
    })
    .catch(err);

  return promise;
}

/**
 * getFeaturedStories
 *
 * obtain the list of featured stories for FeatureFeed
 */
async function getFeaturedStories($nodeAxios) {
  const res = await searchApi.getSearchResults({ tab: 'stories' }, $nodeAxios);
  const posts = map(get(res, 'data.hits', []), hit => {
    const post = { ...hit._source };
    // need to manipulate for NormalizeAuthor, getStoryUrl and getContentPermalInk functions to work on ContentCards
    const topicSlug = post.topic.slug ?? '';
    post.card_data = {
      'card-author-url': `/u/${post.author.username ?? ''}`,
      'card-author-byline': post.topic.title ?? '',
      'card-author-byline-url': `/${topicSlug}`,
      'card-byline-avatar': post.author.avatar ?? '',
      'card-byline-author': post.author.name ?? '',
      'card-link': `/${topicSlug}/${post.slug}`,
      'card-slug': post.slug,
      'card-title': post.title,
    };

    delete post.author;
    return post;
  });
  return posts;
}

/**
 * getMainStories
 *
 * obtain the list of main stories on the page, from a variable within the page
 */
function getMainStories() {
  return window.homeMainStory || [];
}

/**
 * getFeedHandler
 *
 * get the function that will be used to generate the feed items list for the given feed
 *
 * @param string feedName  the name of the feed that we need the url for
 * @return function  the function that will be used to fetch the results
 */
function getFeedHandler(feedName, query, $nodeAxios, $wpAxios) {
  let func;
  if (['personalized', 'stories', 'polls', 'new_members', 'posts'].includes(feedName)) {
    func = () => {
      const url = '/api/v2/home/recommended';
      return getFeedFromUrl(url, query, $nodeAxios);
    };
  } else if (feedName === 'featured') {
    func = async () => ({ posts: await getFeaturedStories($nodeAxios) });
  } else if (feedName === 'main') {
    func = () => {
      const data = getMainStories();
      return Promise.resolve({ posts: data });
    };
  } else {
    func = false;
  }

  return func;
}

/**
 * getFeedItems
 *
 * get the list of items in a feed, based on the feed name and the current offset
 *
 * @param string feedName  the name of the feed to get items for
 * @param integer offset  the offset of the list of items to get the items after. (default 0)
 * @param integer limit  the number of items to return. (default 8)
 */
function getFeedItems(feedName, offset, limit, extra, $nodeAxios, $wpAxios) {
  try {
    // build the core url query
    const query = {
      ...extra,
      pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
      off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    };

    // get the function that will fetch the requested feed items
    const func = getFeedHandler(feedName, query, $nodeAxios, $wpAxios);
    if (!func) {
      throw new DataError(`No api endpoint handler is defined for the feed '${feedName}'.`, { feedName, query });
    }

    return func();
  } catch (e) {
    return {};
  }
}

export async function getOne(contentId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/${contentId}`)
    .then((rawData) => {
      log('got content:', rawData);
      sanitizePost(rawData.data);
      return rawData.data;
    })
    .catch((e) => {
      err('AJAX ERROR[getOne]:', e);
    });
}

/**
 * getRecommendedFollows
 *
 * no params, retrieves the list of recommended follows for logged in user
 *
 */
function getRecommendedFollows($nodeAxios) {
  return $nodeAxios.get('api/v1/user/me/recommended/follows')
    .then(res => res.data)
    .catch((e) => {
      log('Error getting recommended follows:', e);
    });
}

function getAffirmation(id, $nodeAxios) {
  return $nodeAxios.get(`api/v1/affirmations/${id}`)
    .then(res => res.data)
    .catch((e) => {
      log('Error getting affirmation:', e);
      return { status: 404, message: 'affirmation does not exist' }
    });
}

function getCategoryAffirmations({ pp = 1000, off = 0, category = '' }, $nodeAxios) {
  const params = { limit: pp, offset: off, category };

  return $nodeAxios.get(`api/v1/affirmations`, { params })
    .then(res => res.data)
    .catch((e) => {
      log('Error getting affirmations category:', e);
    });
}

/**
 * getAffirmationCategories
 *
 * retrieve categories for affirmations
 *
 */
function getAffirmationCategories($nodeAxios) {
  return $nodeAxios.get('api/v1/affirmations/categories')
    .then(res => res.data)
    .catch((e) => {
      log('Error getting affirmation categories:', e);
    });
}

function saveAffirmation(affirmationId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/affirmations/saved/${affirmationId}`)
    .then(res => res.data)
    .catch((e) => {
      log('Error getting saving affirmation:', e);
    });
}

function unsaveAffirmation(affirmationId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/affirmations/saved/${affirmationId}`)
    .then(res => res.data)
    .catch((e) => {
      log('Error getting unsaving affirmation:', e);
    });
}

function getHomeFeedTopics($nodeAxios) {
  return $nodeAxios.get(`/api/v1/topic/homepage_featured`)
    .then(res => res.data)
    .catch((e) => {
      log('Error retrieving homefeed topics:', e);
    });
}

export default {
  getOne,
  getFeedItems,
  getFeaturedStories,
  getRecommendedFollows,
  getMainStories,
  getAffirmation,
  getCategoryAffirmations,
  getAffirmationCategories,
  saveAffirmation,
  unsaveAffirmation,
  getHomeFeedTopics,
};

