import { log } from '@/data/helpers';


function getStoryTopics($wpAxios) {
    return $wpAxios.get('/wp-json/tm/v1/story_topics')
        .then(response => response.data.choices || {})
        .catch(error => log('error: getStoryTopics', error));
}

// data from Mongo, using this for Newsletters page
function getNewsletters($nodeAxios) {
    return $nodeAxios.get('/api/v1/newsletter/list')
      .then(response => response.data)
      .catch(error => log('error: getNewsletters', error));
}


function getUsersSubscriptions(cordialId, $apiAxios) {
  return $apiAxios.get(`/newsletter/subscriptions?cid=${cordialId}`, {})
    .then(response => response.data)
    .catch(error => log('error: getUsersSubscriptions', error));
}

function updateAccountDetails(userId, body, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/user/${userId}`, body)
    .then(response => response.data)
    .catch(error => log('error: updateAccountDetails', error));
}

function getUserConditions($wpAxios) {
  return $wpAxios.get('/wp-json/tm/v1/user_conditions')
    .then(response => response.data)
    .catch(error => log('error: getUserConditions', error));
}

export default {
    getStoryTopics,
    getNewsletters,
    getUsersSubscriptions,
    updateAccountDetails,
    getUserConditions,
}
