/* eslint no-param-reassign: 0 */
/* eslint no-console: 0 */
/* eslint no-underscore-dangle: 0 */
import extend from "extend";
import { stubHashtag } from "@/data/selection-helpers";
import { clamp, isNumber, log, err } from "../helpers";

/**
 * sortResults
 *
 * sort the results list so that 'exact matches' are followed by 'starts with', which are then followed by 'contains'
 *
 * @param array data  the raw list of results
 * @param string search  the search term
 * @return array  a sorted result list
 */
function sortResults(data, search) {
  const lower = search.toLowerCase();
  const less = [];
  const more = [];
  const exact = [];
  // cycle through all results. proiritize them on relevance.
  // less = tags that contain the search term
  // more = tags that START with the search term
  // exact = tags that EXACTLY match what the user typed
  data.forEach((itm) => {
    // exact matches
    if (lower === itm._normalized_hash_tag) {
      itm.match = "exact";
      exact.push(itm);
      return;
      // starts with the search term
    } else if (lower === itm._normalized_hash_tag.substr(0, lower.length)) {
      itm.match = "starts-with";
      more.push(itm);
      return;
    }
    itm.match = "contains";
    // contains only
    less.push(itm);
  });

  // stub exact result if we found none
  if (!exact.length) {
    exact.push(stubHashtag(lower));
  }

  return exact.concat(more).concat(less);
}

/**
 * topicSearch
 *
 * do an ajax call to search topics with a partial string
 *
 * @param string search  text to search for in topics
 * @return array  list of topic objects
 */
function topicSearch(search, $nodeAxios) {
  const lower = search.toLowerCase();
  // send the request
  const promise = $nodeAxios
    .get(`/api/v1/topic/search/${lower}`, { params: { pp: 50 } })
    // get the response
    .then((response) => {
      const modResponse = response;
      // rebuild the prioritized results
      modResponse.data = sortResults(response.data, search);
      return modResponse;
    })
    .catch(err);

  return promise;
}

/**
 * topicBySlug
 *
 * fetch a topic object for a given slug
 *
 * @param topic_slug string
 * @return Topic object
 */
function topicBySlug(topicSlug, $nodeAxios) {
  const promise = $nodeAxios
    .get(`/api/v1/topic/${topicSlug.toLowerCase()}`, {})
    .then((response) => response)
    .catch(err);

  return promise;
}

/**
 * postsForTopic
 *
 * fetch posts for a topic id
 *
 * @param topicId String
 * @param offset int default 1
 * @param limit int default 10
 * @param types array , content types,  [ 'THOUGHT', 'QUESTION', 'WORDPRESS_POST', 'WORDPRESS_VIDEO', 'NONE' ]
 */
function postsForTopic(
  topicSlug,
  feedName,
  offset,
  limit,
  types = ["THOUGHT", "QUESTION"],
  $nodeAxios
) {
  const query = {
    pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
    off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
  };

  if (types === "photos") {
    query.filter = "photos";
  } else {
    query.types = types;
  }

  if (feedName === "trending") {
    query.trending = 1;
  }

  const promise = $nodeAxios
    .get(`/api/v2/content/for_topic/${topicSlug.toLowerCase()}`, {
      params: query,
    })
    .then((res) => extend({ query }, res.data))
    .catch(err);

  return promise;
}

function topicContributors(topicSlug, off, pp, $nodeAxios) {
  const promise = $nodeAxios
    .get(`/api/v1/topic/${topicSlug}/contributors?off=${off}&&pp=${pp}`)
    .then((res) => {
      return res.data;
    })
    .catch(err);
  return promise;
}

function topicPartners(topicSlug, off, pp, $nodeAxios) {
  const promise = $nodeAxios
    .get(`/api/v1/topic/${topicSlug}/partners?off=${off}&&pp=${pp}`)
    .then((res) => {
      return res.data;
    })
    .catch(err);
  return promise;
}

function pinPostToTopic(itemId, topicId, $nodeAxios) {
  const promise = $nodeAxios
    .post(
      `/api/v1/internal/pins/pin/${topicId}/${itemId}/?themighty=join-us-be-mighty`
    )
    .catch(err);
  return promise;
}

function unpinPostToTopic(itemId, topicId, $nodeAxios) {
  const promise = $nodeAxios
    .delete(
      `/api/v1/internal/pins/pin/by_content_id/${topicId}/${itemId}/?themighty=join-us-be-mighty`
    )
    .catch(err);
  return promise;
}

/**
 * Report a topic by its ID.
 *
 * @param {String} topicId : mongoId
 */
function reportTopic(topicId, $nodeAxios) {
  const endpoint = `/api/v1/topic/${topicId}/report/`;
  return $nodeAxios
    .put(endpoint)
    .then((response) => response.data)
    .catch(err);
}

function groupsForTopic({ slug, status = null, off, pp }, $nodeAxios) {
  const promise = $nodeAxios
    .get(
      `/api/v1/group/by_topic/${slug}${
        status ? `/${status}` : ""
      }?off=${off}&pp=${pp}`
    )
    .then((res) => {
      return res.data;
    })
    .catch(err);
  return promise;
}

function relatedConditions(slug, $nodeAxios) {
  const promise = $nodeAxios
    .get(`/api/v1/topic/${slug}/related_conditions`)
    .then((res) => {
      return res.data;
    })
    .catch(err);
  return promise;
}

function topicResources({ slug, off, pp, types = null }, $nodeAxios) {
  const query = {
    off,
    pp,
  };

  if (types) {
    query.types = types;
  }

  const promise = $nodeAxios
    .get(`/api/v1/topic/${slug}/resources`, { params: query })
    .then((res) => {
      return res.data;
    })
    .catch(err);
  return promise;
}

function topicFeaturedContent(slug, $nodeAxios) {
  const promise = $nodeAxios
    .get(`/api/v1/topic/${slug}/featured`)
    .then((res) => {
      return res.data;
    })
    .catch(err);
  return promise;
}


export default {
  sortResults,
  topicSearch,
  postsForTopic,
  topicBySlug,
  topicContributors,
  topicPartners,
  pinPostToTopic,
  unpinPostToTopic,
  reportTopic,
  groupsForTopic,
  relatedConditions,
  topicResources,
  topicFeaturedContent,
};
