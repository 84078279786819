
import _ from 'lodash';
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import Icon from '@/components/DirectMessage/Icon';
import { staticPath } from '@/data/helpers';

export default {
  name: 'Notifications',
  props: ['isHovered', 'handleHover', 'hoveredState'],
  components: { Icon },
  data() {
    return {
      notificationsDropdownOpened: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getMyNotificationsDropdown: 'getMyNotificationsDropdown',
    }),
    loggedIn() {
      return !_.isEmpty(this.user._id);
    },
    hasNotifications() {
      return !_.isEmpty(this.getMyNotificationsDropdown);
    },
    reactions() {
      return {
         'HEART': 'heart',
         'HELPFUL': 'bulb',
         'NOT_ALONE': 'sun',
         'BEEN_THERE': 'hand',
         'ENERGY': 'battery',
         'CHEER': 'balloons',
      }
    },
    isNotificationsPath() {
      return this.$route.path.startsWith('/notifications');
    },
  },
  methods: {
    staticPath,
    toggleNotificationsDropdown() {
      this.notificationsDropdownOpened = !this.notificationsDropdownOpened;
    },
    closeNotificationsDropdown() {
      if (this.notificationsDropdownOpened) {
        this.notificationsDropdownOpened = false;
      }
    },
    setCommunityState(e, notification, navigate) {
      if (notification.type !== 'JOIN_REQUEST_APPROVED') {
        localStorage.setItem('notification', notification.type);
      }
      navigate(e);
    },
    getNotificationIcon(notification) {
      return _.get(notification, 'reference.group.status.type', 'PUBLIC') === 'PUBLIC' ? staticPath('group/public-community.svg') : staticPath('group/private-community.svg');
    },
    message(notification) {
      let message = '';
      const title =  _.get(notification, 'context._normalized_title');
      if (['COMMENT_REPLY', 'COMMENT_HEART'].includes(notification.type)) {
        message = _.get(notification, 'context._normalized_body', _.get(notification, 'context.body', ''));
      } else if (notification.type === 'POLL' && title) {
        message = title;
      } else {
        message = _.get(notification, 'message.suffix', _.get(notification, 'context.title', ''));
      }

      if (message.length >= 60) message = `${message.substring(0, 60)}...`;
      return message;
    },
    messageSuffix(notification) {
      let message = '';
      if (notification.message.suffix) message = notification.message.suffix;
      if (message.length >= 60) message = `${message.substring(0, 60)}...`;
      return message;
    },
    reactionNotification(notification) {
      if (notification.type === 'COMMENT_HEART') {
        return null;
      }
      return _.get(notification, 'reference.hearts[0]');
    },
    showReactionNotification(notification) {
      return _.get(this.reactionNotification(notification), 'reaction_type', 'HEART') !== 'HEART';
    },
    heartNotification(notification) {
      return ['HEART', 'COMMENT_HEART'].includes(notification.type);
    },
    mentionNotification(notification) {
      return ['POST_MENTION', 'COMMENT_MENTION'].includes(notification.type);
    },
    reactionUser(notification) {
      return _.get(this.reactionNotification(notification), 'user_id.username');
    },
    reactionType(notification) {
      return _.get(this.reactionNotification(notification), 'reaction_type', 'HEART');
    },
    mentioner(notification) {
      return _.get(notification, 'reference.mention_author.username');
    }
  },
  watch: {
    isHovered: {
      immediate: true,
      handler(val) {
        this.notificationsDropdownOpened = val;
      }
    },
    loggedIn: {
      immediate: true,
      async handler(val) {
          // when /logout/ is being hit the user is still logged in, so implementing this to avoid unnecessary action
        if (process.browser && this.loggedIn && _.get(this.$route, 'path', '/') !== '/logout/') {
          this.$store.dispatch('loadNotifications', { off: 0, pp: 5, location: 'dropdown' });
        }
      },
    },
  }
};
