import { log } from "../helpers";

/**
 * Collections for a given topic
 */
function collectionsForTopic({ slug, off, pp }, $nodeAxios) {
  const params = {
    off,
    pp,
  };

  const promise = $nodeAxios
    .get(`/api/v1/topic/${slug}/collections`, { params })
    .then((res) => {
      return res.data;
    })
    .catch((err) => log("error getting collectionsForTopic: ", err.response));
  return promise;
}

/**
 * Get individual Collection
 */
function collectionById(collectionId, $nodeAxios) {
  const promise = $nodeAxios
    .get(`/api/v1/topic/getCollectionById/${collectionId}`, {})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      log("error getting collectionById: ", err.response);
      return [];
    });
  return promise;
}

/**
 * Content for a given collection
 */
function collectionContent({ collectionId, off, pp }, $nodeAxios) {
  const params = {
    off,
    pp,
  };

  const promise = $nodeAxios
    .get(`/api/v1/content/by_collection/${collectionId}`, { params })
    .then((res) => {
      return res.data.hits;
    })
    .catch((err) => {
      log("collectonContent err: ", err.response);
    });
  return promise;
}

/**
 * Save a collection
 */
function saveCollection(collectionId, $nodeAxios) {
  return $nodeAxios
    .post(`/api/v1/bookmarks/add/collection/${collectionId}`, {})
    .then((response) => response.data)
    .catch((error) => {
      log("error running saveCollection: ", error.response);
      return Promise.reject(error);
    });
}

/**
 * Unsave a collection
 */
function unsaveCollection(collectionId, $nodeAxios) {
  return $nodeAxios
    .delete(`/api/v1/bookmarks/remove/collection/${collectionId}`, {})
    .then((response) => response.data)
    .catch((error) => {
      log("error running removeProfileBookmark: ", error.response);
      return Promise.reject(error);
    });
}

/**
 * Report a collection
 */
function reportCollection(collectionId, $nodeAxios) {
  return $nodeAxios
    .put(`/api/v1/topic/collection/${collectionId}/report`, {})
    .then((response) => response.data)
    .catch((error) => {
      log("error running removeProfileBookmark: ", error.response);
      return Promise.reject(error);
    });
}

/**
 * Get Shuffled Collections
 */
function getShuffledCollections({ quantity = 3, featured = true }, $nodeAxios) {
  const params = {
    quantity,
    featured,
  };

  return $nodeAxios
    .get(`/api/v1/topic/collections/shuffled`, { params })
    .then((response) => response.data)
    .catch((error) => {
      log("error running getShuffledCollections: ", error.response);
      return Promise.reject(error);
    });
}

/**
 * Retrieves list of topics that have at least one collection
 */
function getTopicsWithCollections({ off = 0, pp = 100 }, $nodeAxios) {
  const params = {
    off,
    pp
  };

  return $nodeAxios
    .get(`/api/v1/topic/with_collections`, { params })
    .then((response) => response.data)
    .catch((error) => {
      log("error running getTopicsWithCollections: ", error.response);
      return Promise.reject(error);
    });
}

/**
 * Retrieves list of topics that a specified collection belongs to
 */
function getCollectionAssociatedTopics(collectionId, $nodeAxios) {
  return $nodeAxios
    .get(`/api/v1/topic/getCollectionAssociatedTopics/${collectionId}`, {})
    .then((response) => response.data)
    .catch((error) => {
      log("error running getCollectionAssociatedTopics: ", error.response);
      return Promise.reject(error);
    });
}

export default {
  collectionsForTopic,
  collectionById,
  collectionContent,
  saveCollection,
  unsaveCollection,
  reportCollection,
  getShuffledCollections,
  getTopicsWithCollections,
  getCollectionAssociatedTopics,
};
