/* eslint no-param-reassign: 0 */
/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import { log, isObject, isSet, isFunction, sameDomainUrl } from '@/data/helpers';

const state = () => ({
  sessionUser: null,
});

// santize the post on the payload, to include event_data, if not present
function sanePost(post) {
  // if it is already set, then do nothing
  if (isObject(post.event_data) && isSet(post.event_data.slug)) {
    return;
  }

  const cardData = post.card_data || {};
  const topicIds = post.topics.map(c => c._id);
  const topicSlugs = post.topics.map(c => c.slug);
  const author = post.author || {};
  // otherwise, build it
  post.event_data = {
    title: post.title,
    slug: post.slug,
    topic_id: topicIds[0],
    topic_slug: topicSlugs[0],
    author_slug: author.username,
    author: author.display_name,
    post_id: post._id,
    permalink: cardData['card-link'] || sameDomainUrl(`/#post_id${post._id}`),
    card_media_url: cardData['card-media-url'] || '',
  };
}

function maybeCall(func) {
  if (isFunction(func)) {
    func();
  }
}

/**
 * analytics events mutations
 */
const mutations = {
  addUserHeart(currentState, payload) {
    try {
      sanePost(payload.post);
      const eventData = {
        category: payload.gaCategory,
        action: 'heart.add',
        label: payload.post.event_data.post_id,
      };
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[addUserHeart]:', e);
      maybeCall(payload.callback);
    }
  },
  removeUserHeart(currentState, payload) {
    try {
      sanePost(payload.post);
      const eventData = {
        category: payload.gaCategory,
        action: 'heart.remove',
        label: payload.post.event_data.post_id,
      };
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[removeUserHeart]:', e);
      maybeCall(payload.callback);
    }
  },
  triggerCardShareMenu(currentState, payload) {
    try {
      sanePost(payload.post);
      log('triggerCardShareMenu', payload);
      const eventData = {
        category: payload.gaCategory,
        action: 'share.menu',
        label: payload.post.id,
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[triggerCardShareMenu]:', e);
      maybeCall(payload.callback);
    }
  },
  triggerCardShareMenuButton(currentState, payload) {
    try {
      sanePost(payload.post);
      const eventData = {
        category: payload.gaCategory,
        action: `share.button.${payload.mediaName}`,
        label: payload.post.id,
        callback: payload.callback,
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[triggerCardShareMenuButton]:', e);
      maybeCall(payload.callback);
    }
  },
  triggerCardAuthorLink(currentState, payload) {
    try {
      sanePost(payload.post);
      const eventData = {
        category: payload.gaCategory,
        action: 'choose',
        label: payload.post.author_id,
        callback: payload.callback,
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[triggerCardAuthorLink]:', e);
      maybeCall(payload.callback);
    }
  },
  triggerCardCategoryLink(currentState, payload) {
    try {
      sanePost(payload.post);
      const eventData = {
        category: payload.gaCategory,
        action: 'choose',
        label: payload.post.primary_cat_id || payload.post.topics[0].id || payload.post.author_id,
        callback: payload.callback,
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[triggerCardCategoryLink]:', e);
      maybeCall(payload.callback);
    }
  },

  triggerFeedLoadMore(currentState, payload) {
    try {
      const eventData = {
        category: payload.gaCategory,
        action: 'choose',
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[triggerFeedLoadMore]:', e);
      maybeCall(payload.callback);
    }
  },

  triggerStickyItemClick(currentState, payload) {
    try {
      const eventData = {
        category: payload.category || '',
        label: payload.label || '',
        action: 'choose',
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[feed_nav_bar]:', e);
      maybeCall(payload.callback);
    }
  },

  triggerCardOpenLink(currentState, payload) {
    try {
      sanePost(payload.post);

      const eventData = {
        category: payload.gaCategory,
        action: 'choose',
        label: payload.post.event_data.post_id,
        callback: payload.callback,
      }
      this.$sendAnalyticsEvent(eventData, ['gacallback', 'tm_stats', 'cordial']);
    } catch (e) {
      log('ANALYTICS ERROR[triggerCardOpenLink]:', e);
      maybeCall(payload.callback);
    }
  },

  triggerCardInview(currentState, payload) {
    try {
      const post = { ...payload.post };
      sanePost(post);
      const eventData = {
        category: payload.gaCategory,
        action: 'view',
        label: post.event_data.post_id,
      }
      this.$sendAnalyticsEvent(eventData);
    } catch (e) {
      log('ANALYTICS ERROR[triggerCardInview]:', e);
      maybeCall(payload.callback);
    }
  },


  /**
   * Stores required data for creating the events
   */
  setCurrentUser(currentState, userObject) {
    currentState.sessionUser = userObject;
  },
  setLogoutUser(currentState) {
    currentState.sessionUser = null;
  },
};

export default {
  state,
  getters: {},
  actions: {},
  mutations,
};
