import _ from 'lodash';
import { log } from '@/data/helpers';
import { verifyUser, startChat, authUser } from '@/data/api/chat';
import { StreamChat } from 'stream-chat';
import segmentAnalytics from '@/libs/events';

const state = () => ({
  client: null,
  pendingInitializeStream: false, // while stream initialization is occuring
  verifyingUser: false, // similary for when verifying a user, to make sure it doesn't run twice
  streamInitialized: false,
  streamAccount: false,
  inboxInitialized: false,
  unreadMessageCount: 0,
  user: null,
  partnerId: null,
  streamActiveChannel: null,
  // open/hidden/rejected Channels are 3 separate lists dependent on the channels status
  openChannels: [],
  hiddenChannels: [],
  rejectedChannels: [],
  chatRequestsCount: 0,
  // streamChannels will essentially be the source of the list of channels for the component, can contain any mixture of channels
  streamChannels: [],
  streamChannelsLimit: 30,
  streamChannelsPage: 0,
  streamChannelsHasMore: true,
  streamChannelsLoading: false,
  messages: [],
  replies: {},
  messageImages: 0,
  imagesLoaded: 0,
  newMessage: '',
  imageUploads: [],
  toggles: {
    newConversation: false,
    showSavedMessages: false,
    showArchivedConversations: false,
    editConversationOptions: false,
    channelLoading: false,
    viewSupportSystem: false,
  },
  conversationWindow: {
    enterMessageHeight: 0,
    messagesWindowHeightDefault: 0,
    messagesWindowHeight: 0,
    enterMessagesHeightDefault: 0,
    photoPreviewsHeightDefault: 60,
    chatHeight: {
      enterMessageHeight: 0,
      messagesWindowHeight: 0,
      photoPreviewsHeight: 0,
    },
  },
  inviteChoice: null,
});

const getters = {
  pendingInitializeStream: state => state.pendingInitializeStream,
  verifyingUser: state => state.verifyingUser,
  streamChannels: state => state.streamChannels,
  streamActiveChannel: state => state.streamActiveChannel,
  archivedChannels: state => state.hiddenChannels.map(c => c.id),
  messages: state => state.messages,
  partnerId: state => state.partnerId,
  newMessage: state => state.newMessage,
  imageUploads: state => state.imageUploads,
  toggles: state => state.toggles,
  replies: state => state.replies,
  unreadMessageCount: state => state.unreadMessageCount,
  streamInitialized: state => state.streamInitialized,
  streamAccount: state => state.streamAccount,
  inboxInitialized: state => state.inboxInitialized,
  chatRequestsCount: state => state.chatRequestsCount,
  conversationWindow: state => state.conversationWindow,
  inviteChoice: state => state.inviteChoice,
  streamChannelsLoading: state => state.streamChannelsLoading,
  streamChannelsHasMore: state => state.streamChannelsHasMore,
};

const actions = {
  setUnreadMessageCount(context, n) {
    context.commit('unreadMessageCount', n);
  },
  setChatRequestedCount(context, n) {
    context.commit('chatRequestsCount', n);
  },
  async initializeInbox(context) {
    await context.dispatch('getChannels', { offset: 0, filters: { hidden: true, frozen: false } });
    await context.dispatch('loadDMChannels');
    context.commit('inboxInitialized', true);
  },
  // mainly a function that will just indicate when we should mark them as having a Stream Account, to allow for initializing Stream
  async verifyAndConnect(context, user ) {
    context.commit('verifyingUser', true);
    await verifyUser(this.$nodeAxios).then(async (response) => {
      context.commit('verifyingUser', false);
      if (response && response.status === 200) {
        // if user has unread messages or on Inbox page, we can connect
        if (_.get(response, 'data.unread_count', 0) > 0 || this.$router.currentRoute.path.includes('inbox')) {
          await context.dispatch('initializeStream', user);
          return;
        }
      }
    });
  },
  // second param is for when we want to initialize a newUser after they send their first message
  async initializeStream(context, { user, newUser = false } = {}) {
    if (context.state.pendingInitializeStream) {
      // To avoid calling initialize stream multiple times
      return;
    }

    context.commit('pendingInitializeStream', true);
    context.commit('user', user);
    const { username, _id } = user;

    const client = new StreamChat(process.env.STREAM_KEY, { timeout: 10000 });
    const response = await authUser({ username, _id }, this.$nodeAxios);
    const token = _.get(response, 'data.token');

    if (!token) {
      context.commit('streamInitialized', false);
      context.commit('pendingInitializeStream', false);
      return;
    }

    await client.setUser(
      {
        id: _id,
        name: username,
      },
      token,
    );
    context.commit('client', client);
    context.commit('streamAccount', true);
    await context.dispatch('setUnreadMessageCount', _.get(context.state.client, 'user.total_unread_count', 0));
    context.state.client.on(async (event) => {
      if (!_.isNil(event.total_unread_count)) {
        await context.dispatch('setUnreadMessageCount', event.total_unread_count);
      }
      if (event.type === 'notification.invited') {
        await context.dispatch('getChannels', { offset: 0, filters: { id: event.channel_id } });
        await context.dispatch('setChatRequestedCount', context.state.chatRequestsCount + 1);
      }
      if (event.type === 'notification.invite_rejected' || event.type === 'notification.invite_accepted') {
        await context.dispatch('getChatRequests');
        await context.dispatch('setChatRequestedCount', context.state.chatRequestsCount - 1);
      }
    });

    // in order to know whether we need to show Nav Icon bubble
    await context.dispatch('getChatRequests');
    context.commit('streamInitialized', true);
    context.commit('pendingInitializeStream', false);
  },
  async preparePartner(context) {
    const partnerId = context.state.partnerId;

    if (partnerId) {
      const openChannel = await context.dispatch('findOpenChannelByPartnerId');
      const hiddenChannelIndex = await context.dispatch('findHiddenChannelByPartnerId');
      const rejectedChannelIndex = await context.dispatch('findRejectedChannelByPartnerId');

      if (!openChannel && hiddenChannelIndex < 0 && rejectedChannelIndex < 0) {
        await context.dispatch('createNewChannel');
      } else {
        if (openChannel) {
          await context.dispatch('setActiveChannel', openChannel.id);
        } else if (hiddenChannelIndex > -1) {
          const hiddenChannel = context.state.hiddenChannels[hiddenChannelIndex];
          context.commit('addStreamChannel', hiddenChannel)
          await context.dispatch('setActiveChannel', hiddenChannel.id);
        } else if (rejectedChannelIndex > -1) {
        const rejectedChannel = context.state.rejectedChannels[rejectedChannelIndex];
        context.commit('addStreamChannel', rejectedChannel);
        await context.dispatch('setActiveChannel', rejectedChannel.id);
      }
      }
    }

  },
  async getChannels(context, { offset = 0, filters = {} } = {}) {
    const filter = {
      type: 'messaging',
      members: {
        $in: [context.state.user._id.toString()],
      },
    };
    if (filters) {
      Object.assign(filter, filters);
    }
    const sort = { last_message_at: -1 };
    const pagination = { limit: context.state.streamChannelsLimit, message_limit: 500 };

    pagination.offset = offset;
    const channels = await context.state.client.queryChannels(filter, sort, pagination);
    // const [channel] = channels;

    // if (channel) {
    //   channels = Array.from(Array(30).keys()).map(i => ({ ...channel, id: (Math.random() + 1).toString(36).substring(7), on: () => {} }));
    // }
    _.forEach(channels, (channel) => {
    // add event listeners to each channel, doing it here to ensure it's only done once
    // otherwise events are firing multiple times
      channel.on(async (event) => {
        if (event.type === 'message.new' && context.state.streamActiveChannel && event.channel_id === context.state.streamActiveChannel.id) {
          const message = event.message;
          if (message.parent_id) {
            await context.dispatch('updateRepliesState', { parentMessageId: message.parent_id, messages: [message]});
          } else {
            context.commit('messages', [...context.state.messages, message]);
          }
        }

        if (event.type === 'message.updated') {
          const messages = context.state.messages;
          const index = _.findIndex(messages, m => m.id === event.message.id);
          if (index > -1) {
            messages[index] = event.message;
          }
          context.commit('messages', messages);
        }

        if (event.type === 'channel.updated') {
          if (event.channel.frozen === true && event.channel.ended_by === context.state.user._id) {
            await context.dispatch('archiveChannel');
          }
        }
      });

      if (filter.hidden || channel.data.frozen) {
        context.commit('addHiddenChannel', channel);
      } else if (_.get(channel, `context.members[${context.state.user._id}].invite_rejected_at`, false)) {
        context.commit('addRejectedChannel', channel);
      } else {
        context.commit('addOpenChannel', channel);
        context.commit('addStreamChannel', channel);
      }
    });
    // // keep querying for channels until the query returns less than we asked for
    // if (channels.length >= pagination.limit) {
    //   await context.dispatch('getChannels', { offset: offset + pagination.limit, filters });
    // }

    return channels;
  },
  async loadDMChannels(context) {
    context.commit('setStreamChannelsLoading', true);
    const offset = context.state.streamChannelsLimit * context.state.streamChannelsPage;
    const channels = await context.dispatch('getChannels', { offset, filters: { frozen: false } });
    context.commit('setStreamChannelsPage', context.state.streamChannelsPage + 1)
    if (channels.length < context.state.streamChannelsLimit) {
      context.commit('setStreamChannelsHasMore', false);
    } else {
      context.commit('setStreamChannelsHasMore', true);
    }
    context.commit('setStreamChannelsLoading', false);
  },
  async setActiveChannel(context, id) {
    if (context.state.inviteChoice && context.state.inviteChoice.channelId !== id) {
      context.commit('inviteChoice', null);
    }
    context.commit('setToggle', { toggleName: 'channelLoading', value: true });
    segmentAnalytics.choose({
      context: {
        section: 'Inbox_sidebar',
        view: 'Inbox',
      },
      target: {
        type: 'conversation',
      },
      userId: context.state.user?._id,
      sessionId: context.state.user?.sessionId,
    });
    await context.dispatch('deselectChannel');
    const channelSelected = context.state.streamChannels.find(ch => ch.id === id);
    await channelSelected.markRead();
    context.commit('streamActiveChannel', channelSelected)
    context.commit('messages', channelSelected.state.messages);
    const messages = await context.dispatch('loadMessages');
    await context.dispatch('loadSavedMessages', { messages }, { root: true });
    if (!context.state.messageImages) { // images have lazy load event handler @see imageLoaded()
      context.commit('setToggle', { toggleName: 'channelLoading', value: false });
    }
  },
  // method for essentially refreshing to a clean state
  deselectChannel(context) {
    context.commit('streamActiveChannel', null);
    context.commit('messages', []);
    context.commit('setToggle', { toggleName: 'newConversation', value: false });
    context.commit('setToggle', { toggleName: 'showSavedMessages', value: false });
    context.commit('partnerId', null);
    context.commit('newMessage', '');
    context.commit('imageUploads', []);
  },
  // to get around lazy loading images, I'm keeping the count of images to be shown (from non-violated messages) in messageImages
  // then when as Images load via the MessageImageDisplay component they are firing the imageLoaded() method
  async loadMessages(context) {
    context.commit('messageImages', 0);
    context.commit('imagesLoaded', 0);

    return _.map(context.state.messages, (message) => {
      if (!(message.type === 'error' || message.deleted)) {
        context.commit('messageImages', context.state.messageImages + _.get(message, 'image_classification_results.length', 0));
      }
      return message.id;
    });
  },
  async createNewChannel(context) {
    await context.commit('setToggle', { toggleName: 'channelLoading', value: true });
    const newChannel = await startChat(context.state.partnerId, this.$nodeAxios);
    // run getChannel with channelId so the channel can be queried for
    // the event listeners can be added, and then it can be added to our array
    await context.dispatch('getChannels', { offset: 0, filters: { id: newChannel.channel_id }});
    await context.dispatch('setActiveChannel', newChannel.channel_id);
    await context.commit('setToggle', { toggleName: 'channelLoading', value: false });
  },
  async findOpenChannelByPartnerId(context) {
    return _.find(context.state.streamChannels, channel => !_.isNil(channel.state.members[context.state.partnerId]));
  },
  async findHiddenChannelByPartnerId(context) {
    return _.findIndex(context.state.hiddenChannels, channel => !_.isNil(channel.state.members[context.state.partnerId]));
  },
  async findRejectedChannelByPartnerId(context) {
    return _.findIndex(context.state.rejectedChannels, channel => !_.isNil(channel.state.members[context.state.partnerId]));
  },
  // searches across all channels for an existing channel, returns if found or not
  async setPartner(context, params) {
    const { partnerId, callback } = params;

    if (callback) {
      callback();
    }

    context.commit('partnerId', partnerId);

    if (!callback) {
      const openChannel = await context.dispatch('findOpenChannelByPartnerId');

      if (openChannel) {
        await context.dispatch('setActiveChannel', openChannel.id);
        return true;
      }

      const hiddenChannelIndex = await context.dispatch('findHiddenChannelByPartnerId');

      if (hiddenChannelIndex > -1) {
        const hiddenChannel = context.state.hiddenChannels[hiddenChannelIndex];
        context.commit('addStreamChannel', hiddenChannel)
        await context.dispatch('setActiveChannel', hiddenChannel.id);
        return true;
      }

      const rejectedChannelIndex = await context.dispatch('findRejectedChannelByPartnerId');

      if (rejectedChannelIndex > -1) {
        const rejectedChannel = context.state.rejectedChannels[rejectedChannelIndex];
        context.commit('addStreamChannel', rejectedChannel);
        await context.dispatch('setActiveChannel', rejectedChannel.id);

        return true;
      }

      if (!openChannel && hiddenChannelIndex < 0 && rejectedChannelIndex < 0) {
        context.dispatch('deselectChannel');
      }

      context.commit('partnerId', partnerId);


      return false;
    }
  },
  // allowing a channelId to be passed in, mainly for Invite actions
  async archiveChannel(context, channelId) {
    let channel;
    if (channelId) {
      channel = _.find(context.state.streamChannels, c => c.id === channelId);
    } else {
      channel = context.state.streamActiveChannel;
    }

    await channel.hide();
    await context.commit('addHiddenChannel', channel);
    await context.dispatch('removeFromOpenChannels', channelId);
    await context.dispatch('updateClientUserArchivedChannels');
  },
  async unarchiveChannel(context) {
    await context.state.streamActiveChannel.show();
    context.commit('removeHiddenChannel', context.state.streamActiveChannel.id);
    context.commit('addOpenChannel', context.state.streamActiveChannel);
    segmentAnalytics.choose({
      context: {
        view: 'Direct Message Thread',
      },
      target: {
        name: 'Unarchive',
        type: 'button',
      },
      userId: user?._id,
      userId: context.state.user?._id,
      sessionId: context.state.user?.sessionId,
    });

    await context.dispatch('updateClientUserArchivedChannels');
  },
  async updateClientUserArchivedChannels(context) {
    const update = { id: context.state.user._id.toString() };

    const archivedChannels = {};
    _.forEach(context.state.hiddenChannels, (channel) => {
      archivedChannels[channel.id] = true;
    });

    update.set = { archivedChannels };
    await context.state.client.partialUpdateUser(update);
  },
  // function for removing a channel from the StreamChannels list and then deselecting it
  removeFromChannelsList(context, channelId) {
    const idToBeRemoved = channelId || context.state.streamActiveChannel.id;
    context.commit('removeStreamChannel', idToBeRemoved);
  },
  // remove a channel from the openChannels list, ie. when Rejected or Archived
  removeFromOpenChannels(context, channelId) {
    const idToBeRemoved = channelId || context.state.streamActiveChannel.id;
    context.commit('removeOpenChannel', idToBeRemoved);
  },
  async rejectChannelInvite(context, channel) {
    await context.dispatch('openToast', { choice: 'decline', channelId: channel.id });
    await context.dispatch('removeFromChannelsList', channel.id);
    await context.dispatch('removeFromOpenChannels', channel.id);

    context.commit('addRejectedChannel', channel);
  },
  async acceptChannelInvite(context, channelId) {
    const filter = {
      id: channelId,
    };

    // for reactivity reasons we need to requery for the channel or else we can't detect when it is accepted
    const channels = await context.state.client.queryChannels(filter);
    await context.dispatch('removeFromChannelsList', channelId);
    await context.dispatch('removeFromOpenChannels', channelId);
    context.commit('addOpenChannel', channels[0]);
    context.commit('addStreamChannel', channels[0]);
    await context.dispatch('setActiveChannel', channelId);

    // open toast
    await context.dispatch('openToast', { choice: 'accept', channelId });
  },
  async openToast(context, { choice, channelId }) {
    context.commit('inviteChoice', {
      choice,
      channelId,
    });
    setTimeout(() => {
      context.commit('inviteChoice', null);
    }, 3000);
  },
  setMessage(context, val) {
    context.commit('newMessage', val);
  },
  addEmoji(context, emoji) {
    context.commit('newMessage', `${context.state.newMessage}${emoji} `);
  },
  resetImageUploads(context) {
    context.commit('imageUploads', []);
  },
  async toggleConversationsList(context) {
    await context.dispatch('deselectChannel');
    const newValue = !context.state.toggles.showArchivedConversations;
    context.commit('setToggle', { toggleName: 'showArchivedConversations', value: newValue });
    if (newValue) {
      segmentAnalytics.screen({
        context: {
          section: 'Inbox_sidebar',
          card: {
            type: 'Inbox overflow menu',
          },
          view: 'Inbox',
        },
        target: {
          type: 'button',
        },
        userId: context.state.user?._id,
        sessionId: context.state.user?.sessionId,
      });
      segmentAnalytics.view({
        context: {
          card: {
            type: 'Inbox overflow menu',
          },
          view: 'Archived_Mailbox',
        },
        target: {
          name: 'Archive',
          type: 'conversation',
        },
        userId: context.state.user?._id,
        sessionId: context.state.user?.sessionId,
      });
      context.commit('setStreamChannels', [...context.state.hiddenChannels]);
    } else {
      context.commit('setStreamChannels', [...context.state.openChannels]);
    }
  },
  toggleMightySupportSystem(context) {
    const newValue = !context.state.toggles.viewSupportSystem;
    context.commit('setToggle', { toggleName: 'viewSupportSystem', value: newValue });
    if (newValue) {
      segmentAnalytics.screen({
        context: {
          view: 'Direct Message Thread',
        },
        target: {
          type: 'icon',
        },
        userId: context.state.user?._id,
        sessionId: context.state.user?.sessionId,
      });
    }

  },
  // the purpose of this function and using it in the MessageImageDisplay is to not render the channel until all images have loaded
  // Otherwise it will mess up the scroll within the window
  imageLoaded(context) {
    const imagesLoaded = context.state.imagesLoaded + 1;
    context.commit('imagesLoaded', imagesLoaded);
    if (imagesLoaded === context.state.messageImages) {
      context.commit('setToggle', { toggleName: 'channelLoading', value: false });
    }
  },
  async getChatRequests(context) {
    const invites = await context.state.client.queryChannels({
      invite: 'pending',
    });
    context.commit('chatRequestsCount', invites.length);
  },
  updateRepliesState(context, { parentMessageId, messages, replace = false }) {
    const repliesState = { ...context.state.replies };
    let replies = repliesState[parentMessageId] || [];

    if (replace) {
      replies = [...messages];
    } else {
      replies = [...replies, ...messages];
    }

    repliesState[parentMessageId] = replies;
    context.commit('replies', repliesState);
  },
  async loadReplies(context, { channel, parentMessage }) {
    const response = await channel.getReplies(parentMessage.id, { limit: 1000 });
    await context.dispatch('updateRepliesState', { parentMessageId: parentMessage.id, messages: response.messages, replace: true });
    await context.dispatch('loadSavedMessages', { messages: response.messages.map(message => message.id), append: true }, { root: true });
  },
  followAcceptedUser(context, channel) {
    const otherUser = Object.keys(channel.state.members).filter(member => member !== context.state.user._id).toString();
    const index = _.findIndex(context.rootGetters.getMyFollows, follow => follow._type === 'user' && follow._id.toString() === otherUser);
    if (index === -1) {
      context.dispatch(
        'follow',
        {
          itemType: 'USER',
          itemId: otherUser,
          initiator: 'channel-invite',
        },
        { root: true }
      );
    }
  },
  async inviteAction(context, { type, channelId, userId }) {
    const channel = context.state.streamChannels.find(x => x.id === channelId);
    switch (type) {
    case 'accept': {
      segmentAnalytics.choose({
        context: {
          section: 'Inbox_sidebar',
          view: 'Inbox',
        },
        target: {
          name: 'Accept',
          type: 'button',
        },
        userId: context.state.user?._id,
        sessionId: context.state.user?.sessionId,
      });
      await context.dispatch('followAcceptedUser', channel);
      await channel.acceptInvite();
      await context.dispatch('acceptChannelInvite', channel.id);
      break;
    }
    case 'decline': {
      segmentAnalytics.choose({
        context: {
          section: 'Inbox_sidebar',
          view: 'Inbox',
        },
        target: {
          name: 'Decline',
          type: 'button',
        },
        userId: context.state.user?._id,
        sessionId: context.state.user?.sessionId,
      });
      await channel.rejectInvite();
      await channel.markRead();
      await context.dispatch('rejectChannelInvite', channel);
      await context.dispatch('deselectChannel');
      break;
    }
    case 'block': {
      segmentAnalytics.choose({
        context: {
          section: 'Inbox_sidebar',
          view: 'Inbox',
        },
        target: {
          name: 'Block',
          type: 'button',
        },
        userId: context.state.user?._id,
        sessionId: context.state.user?.sessionId,
      });
      const callback = async () => {
        await context.dispatch('archiveChannel', channel.id);
        await channel.rejectInvite();
        await channel.markRead();
        await context.dispatch('removeFromChannelsList', channel.id);
        await context.dispatch('deselectChannel');
        await context.dispatch('openToast', { choice: 'block', channelId: channel.id });
      };
      await context.dispatch('openModal', {
        id: 'messaging-block-conversation',
        args: { userId, callback },
      }, { root: true });
      break;
    }
    default: {
      break;
    }
    }
  },
  setToggle(context, data) {
    context.commit('setToggle', data);
  },
  addMessage(context, message) {
    context.commit('messages', [...context.state.messages, message]);
  },
  setConversationWindow(context, data) {
    context.commit('conversationWindow', data);
  },
  toggleSavedMessages(context) {
    context.commit('setToggle', { toggleName: 'showSavedMessages', value: !context.state.toggles.showSavedMessages })
  },
};

const mutations = {
  unreadMessageCount(currentState, unreadMessageCount) {
    currentState.unreadMessageCount = unreadMessageCount;
  },
  chatRequestsCount(currentState, chatRequestsCount) {
    currentState.chatRequestsCount = chatRequestsCount;
  },
  verifyingUser(currentState, verifyingUserState) {
    currentState.verifyingUser = verifyingUserState
  },
  user(currentState, user) {
    currentState.user = user
  },
  client(currentState, client) {
    currentState.client = client
  },
  streamAccount(currentState, streamAccount) {
    currentState.streamAccount = streamAccount
  },
  streamInitialized(currentState, streamInitialized) {
    currentState.streamInitialized = streamInitialized
  },
  inboxInitialized(currentState, inboxInitialized) {
    currentState.inboxInitialized = inboxInitialized
  },
  streamAccount(currentState, streamAccount) {
    currentState.streamAccount = streamAccount
  },
  streamActiveChannel(currentState, streamActiveChannel) {
    currentState.streamActiveChannel = streamActiveChannel;
  },
  messages(currentState, messages) {
    currentState.messages = messages;
  },
  replies(currentState, replies) {
    currentState.replies = replies;
  },
  partnerId(currentState, partnerId) {
    currentState.partnerId = partnerId;
  },
  newMessage(currentState, newMessage) {
    currentState.newMessage = newMessage;
  },
  imageUploads(currentState, imageUploads) {
    currentState.imageUploads = imageUploads;
  },
  inviteChoice(currentState, inviteChoice) {
    currentState.inviteChoice = inviteChoice;
  },
  messageImages(currentState, messageImages) {
    currentState.messageImages = messageImages;
  },
  imagesLoaded(currentState, imagesLoaded) {
    currentState.imagesLoaded = imagesLoaded;
  },


  pendingInitializeStream(currentState, pendingInitializeStream) {
    currentState.pendingInitializeStream = pendingInitializeStream
  },
  addHiddenChannel(currentState, channel) {
    currentState.hiddenChannels = [...currentState.hiddenChannels, channel]
  },
  addRejectedChannel(currentState, channel) {
    currentState.rejectedChannels = [...currentState.rejectedChannels, channel]
  },
  addOpenChannel(currentState, channel) {
    currentState.openChannels = [...currentState.openChannels, channel]
  },
  addStreamChannel(currentState, channel) {
    currentState.streamChannels = [...currentState.streamChannels, channel]
  },
  removeHiddenChannel(currentState, channelId) {
    currentState.hiddenChannels = [...currentState.hiddenChannels.filter(channel => channel.id !== channelId)]
  },
  removeRejectedChannel(currentState, channelId) {
    currentState.rejectedChannels = [...currentState.rejectedChannels.filter(channel => channel.id !== channelId)]
  },
  removeOpenChannel(currentState, channelId) {
    currentState.openChannels = [...currentState.openChannels.filter(channel => channel.id !== channelId)]
  },
  removeStreamChannel(currentState, channelId) {
    currentState.streamChannels = [...currentState.streamChannels.filter(channel => channel.id !== channelId)]
  },
  setStreamChannels(currentState, channels) {
    currentState.streamChannels = channels
  },
  setToggle(currentState, { toggleName, value }) {
    currentState.toggles = {
      ...currentState.toggles,
      [toggleName]: value,
    };
  },
  conversationWindow(currentState, { paramName, value }) {
    currentState.conversationWindow = {
      ...currentState.conversationWindow,
      [paramName]: value
    };
  },
  setStreamChannelsPage(currentState, value) {
    currentState.streamChannelsPage = value;
  },
  setStreamChannelsHasMore(currentState, value) {
    currentState.streamChannelsHasMore = value;
  },
  setStreamChannelsLoading(currentState, value) {
    currentState.streamChannelsLoading = value;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
