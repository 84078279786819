/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
// @ts-ignore
import { Post } from '@/types/models/post';
import { Collection } from '@/types/models/collection';
import segmentAnalytics, { getEventContextView } from '.';

// export const initViewEvents = async ($nodeAxios: AxiosInstance, data: any) => {
export const initViewEvents = async (data: any, event?: string) => {
  const context = {
    view: getEventContextView({ gaCategory: '' }),
    card: { type: 'expanded_module' },
    section: '',
  };

  // @ts-ignore
  const storyPagePost = data;

  // const isStoryPage = document.querySelectorAll('.story-main');
  const isStoryPage = storyPagePost.type === 'WORDPRESS_POST' || storyPagePost.type === 'WORDPRESS_VIDEO';

  if (isStoryPage) {
    context.view = 'story_page';
    context.card.type = 'expanded_module';

    if (event === 'Resources') {
      context.section = event === 'Resources' ? 'hubs_resources' : '';
    }
  }
  if (storyPagePost) {
    // @ts-ignore
    const post = data;
    segmentAnalytics.view({
      context,
      post: {
        title: post.title || post.story_title,
        id: post.id,
        type: post.type,
        topic: {
          names: (post.topics || []).map((t: {title: string}) => t.title),
        },
        ...post.type === 'WORDPRESS_POST' && Array.isArray(post.topics) ? {
          topic0: post.topics && post.topics[0],
          primary_topic: post?.card_data?.['card-author-byline'],
        } : {},
        user: {
          username: post.author_id?.username,
          id: post.author_id?.id,
        },
      },
    });
  }
};

interface Context {
  view: string,
  group: {
    id: string;
    name: string;
  }
}

export const triggerViewPost = (post: Post, context: Context, userId: string, sessionId: string) => {
  if (!post.id) {
    return;
  }

  if (context.view === 'profile_feed') {
    context.view = 'user_profle';
  }

  if (Array.isArray(post.groups) && post.groups[0]) {
    context.group = {
      id: post.groups[0].id,
      name: post.groups[0].name,
    };
  }

  segmentAnalytics.view({
    //@ts-ignore
    userId,
    sessionId,
    post: {
      title: post.title,
      id: post.id,
      type: post.type,
      user: {
        // @ts-ignore
        username: post.author ? post.author.username : post.author_id.username,
        // @ts-ignore
        userId: post.author_id || post.author.id || post.author._id,
      },
      // @ts-ignore
      pinned: post.isPinned,
      topic: {
        names: (post.topics || []).map(t => t.title),
      },
      ...post.type === 'WORDPRESS_POST' && Array.isArray(post.topics) ? {
        topic0: post.topics && post.topics[0],
        primary_topic: post.card_data && post.card_data['card-author-byline'],
      } : {},
    },
    context: {
      ...context,
      // @ts-ignore
      path: window.location.pathname,
    },
    target: {
      /**
         * TODO: Confirm with product preview vs preview_module is it static or dynamic
         * - the data needs keep evolving! for the View NLM module event,
         * could you please change the target.type to “preview_module” instead of “preview”
         * so we will be able to differentiate between the stories in a module versus non-module preview view
         *  https://airtable.com/appa5LWXTPe1X04z1/tbltdFV9GAj0oj344/viwaL3shwztksDE3G/recBC2vncnlwrDXC7?blocks=hide
         */
      type: 'preview_module',
    },
  });
};

export const triggerViewCollection = (collection: Collection) => {
  const { _id, title } = collection;

  if (!_id) {
    return;
  }

  segmentAnalytics.view({
    //@ts-ignore
    post: {
      title: title,
      id: _id,
      type: 'collection',
    },
  });
};
