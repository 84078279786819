/* eslint no-param-reassign: 0 */
/* eslint no-console: 0 */
/* eslint no-underscore-dangle: 0 */
import { isObject, isNumber, isArray, log, err, clamp, authorFromUser, authorFromComment, tmAnalytics, DataError, getCommentLink } from '../helpers';

/**
 * normalizeComment
 *
 * update some of the keys in the comment object, and standardize the author record.
 *
 * @param object comment  the comment that needs the user normalized
 * @return object  the updated comment object
 */
function normalizeComment(comment) {
  // create universal author data
  // from user record
  if (comment.user) {
    comment.author = authorFromUser(comment.user);
  // or from facebook data
  } else {
    comment.author = authorFromComment(comment);
  }

  // change _id to id, since web-pack complains a bunch otherwise
  comment.id = comment._id;
  delete comment._id;

  // setup the link for this comment
  comment.link = getCommentLink(comment);

  return comment;
}

/**
 * sanitizeCommentsResponse
 *
 * consume the data from the comments endpoint response, and output a normalized version of it
 *
 * @param object response  the complete response object from the ajax endpoint
 * @return object  the normalized version of the response
 */
function sanitizeCommentsResponse(response) {
  const cmts = { total: 0, maxOffset: 0, items: [] };
  let i = 0;
  let j = 0;
  // if the response is an array...
  if (isObject(response.data) && isNumber(response.data.total) && isArray(response.data.items)) {
    cmts.total = response.data.total;
    cmts.maxOffset = response.data.maxOffset;
    // add each array item to the list of results
    for (i = 0; i < response.data.items.length; i += 1) {
      // create universal author data
      response.data.items[i] = normalizeComment(response.data.items[i]);
      // do the same for each reply
      if (response.data.items[i].replies && response.data.items[i].replies.length) {
        for (j = 0; j < response.data.items[i].replies.length; j += 1) {
          response.data.items[i].replies[j] = normalizeComment(response.data.items[i].replies[j]);
        }
      }

      // add to list
      cmts.items.push(response.data.items[i]);
    }
  }

  return cmts;
}

/**
 * commentsForPost
 *
 * get the list of comments for the specified post_id
 *
 * @param integer postId  the id of the story to fetch comments for
 * @return array  list of objects that describe the comments for a post
 */
function commentsForPost(postId, pp, last, first, $nodeAxios, $ga) {
  const limit = clamp(pp, 1, 500);
  // const offset = clamp(off, 0, 100000000);
  // if (offset) {
  if (last || first) {
    tmAnalytics({ ga: $ga, category: 'story_card_full.conversation_module.comment.see_more', action: 'choose', label: postId }, ['ga']);
  }
  // send the request
  const promise = $nodeAxios.get(`/api/v1/comment/for/${postId}/`, { params: { pp: limit, last, first } })
    // get the response
    .then((response) => {
      const cmts = sanitizeCommentsResponse(response);

      // return the results
      return cmts;
    })
    .catch(err);

  return promise;
}


/**
 * repliesForComment
 *
 * get more replies for a comment
 *
 * @param string commentId  the id of the comment to load more replies for
 * @param integer pp  the limit of the results to fetch
 * @param string last  the id of the last comment displayed (used to get older comments)
 * @param string first  the id of the first comment displayed (used to get newer comments)
 * @return Promise  the promise of returning a list of replies
 */
function repliesForComment(commentId, pp, last = '', first = '', $nodeAxios) {
  const limit = clamp(pp, 1, 500);
  // send the request
  const promise = $nodeAxios.get(`/api/v1/reply/for/${commentId}/`, { params: { pp: limit, last, first } })
    // get the response
    .then((response) => {
      const cmts = [];
      let i = 0;
      // if the response is an array...
      if (Array.isArray(response.data)) {
        // add each array item to the list of results
        for (i = 0; i < response.data.length; i += 1) {
          // create universal author data
          response.data[i] = normalizeComment(response.data[i]);

          // add to list
          cmts.push(response.data[i]);
        }
      }

      // return the results
      return cmts;
    })
    .catch(err);

  return promise;
}


/**
 * commentById
 *
 * get a single comment, based on the id of the comment
 *
 * @param string id  the uuid of the comment to fetch
 * @return object  the object that describes the comment
 */
function commentById(id, $nodeAxios) {
  // send the request
  const promise = $nodeAxios.get(`/api/v1/comment/${id}/`)
    .then(response => sanitizeCommentsResponse(response))
    .catch(error => log('api:commentsForPost', error));

  return promise;
}


/**
 * reportComment
 *
 * report a comment, via the api
 *
 * @param string id  the uuid of the comment to report
 * @return object  the object that describes the result of the reporting process
 */
function reportComment(id, reportData, $nodeAxios, $ga) {
  // send the request
  tmAnalytics({ ga: $ga, category: 'story_card_full.conversation_module.comment', action: 'comment.flag', label: id }, ['ga']);
  const promise = $nodeAxios.put(`/api/v1/comment/${id}/report/`, reportData)
    .then(response => response.data)
    .catch(error => log('api:reportComment', error));

  return promise;
}


/**
 * reportHighAlertComment
 *
 * report a high-alert comment, via the api
 *
 * @param string id  the uuid of the comment to report
 */
function reportHighAlertComment(id, reportData, $nodeAxios, $ga) {
  // send the request
  tmAnalytics({ ga: $ga, category: 'story_card_full.conversation_module.comment', action: 'comment.flag_alert', label: id }, ['ga']);
  const promise = $nodeAxios.put(`/api/v1/comment/${id}/report-alert/`, reportData)
    .then(response => response.data)
    .catch(error => log('api:reportHighAlertComment', error));

  return promise;
}


/**
 * deleteComment
 *
 * delete a comment, via the api
 *
 * @param string id  the uuid of the comment to delete
 * @return object  the object that describes the result of the deleting process
 */
function deleteComment(id, $nodeAxios, $ga) {
  tmAnalytics({ ga: $ga, category: 'story_card_full.conversation_module.comment', action: 'comment.remove', label: id }, ['ga']);
  // send the request
  return $nodeAxios.delete(`/api/v1/comment/${id}/`, { })
    .then(response => response.data)
    .catch(error => log('api:deleteComment', error));
}


/**
 * addComment
 *
 * add a new comment to this post, via the api
 *
 * @param object data  the data describing the comment to add
 * @return object  the object that describes the result of the deleting process
 */
function addComment(data, context) {
  // verify we have a content_id and comment body
  if (typeof data.body === 'undefined' || !data.body) {
    throw new Error('The comment does not have a body.');
  }
  if (typeof data.content_id === 'undefined' || !data.content_id) {
    throw new Error('What post is this comment for?');
  }

  try {
    let gaLabel = data.content_id;
    let gaAction = 'comment.add';
    let gaCat = data.gaCategory;

    if (data.parent_id) {
      gaCat += '.comment';
      gaAction = 'reply.add';
      gaLabel = data.parent_id;
    }

    context.$sendAnalyticsEvent({ category: gaCat, action: gaAction, label: gaLabel });
  } catch (e) {
    console.error('ERROR!!!', e);
  }

  const parentCommentAuthor = data.comment.author ?
    `<link url='${window.location.origin}/u/${data.comment.author.username}' originalText='${data.comment.author.username}'/> ` :
    '';
  data.body = `${parentCommentAuthor}${data.body}`;

  // send the request
  return context.$nodeAxios
    .post('/api/v1/comment/new/', { params: data })
    .then((raw) => {
      let comment = raw.data;
      // but first, stub missing info
      comment.replies = comment.replies || [];

      // create universal author data
      comment = normalizeComment(comment);
      return comment;
    })
    .catch((error) => {
      // if there was an error in the response, throw it to the next handler
      if (error.response && error.response.data && error.response.data.error) {
        if (isObject(error.response.data.error)) {
          throw new DataError('ajax error', error.response.data.error);
        } else {
          throw new Error(error.response.data.error);
        }
      // otherwise, throw the same error
      } else {
        throw error;
      }
    });
}


/**
 * addCommentHeart
 *
 * Adds a heart to comment on the current user session
 *
 * @param string commentId  the id of the comment getting the heart
 */
function addCommentHeart(params) {
  const { commentId, gaCategory, $nodeAxios, $ga } = params;

  tmAnalytics({ ga: $ga, category: gaCategory, action: 'heart.add', label: commentId }, ['ga']);

  return $nodeAxios.put('/api/v1/heart/', { object_id: commentId })
    .then(response => response.data)
    .catch((error) => {
      log('api:addCommentHeart', error);
      return Promise.reject(error);
    });
}


/**
 * removeCommentHeart
 *
 * Remove a heart to comment on the current user session
 *
 * @param string commentId  the id of the comment getting the heart removed
 */
function removeCommentHeart(params) {
  const { commentId, gaCategory, $nodeAxios, $ga } = params;
  tmAnalytics({ ga: this.$ga, category: gaCategory, action: 'heart.remove', label: commentId }, ['ga']);

  return $nodeAxios.delete('/api/v1/heart/', { params: { object_id: commentId } })
    .then(response => response.data)
    .catch((error) => {
      log('api:addCommentHeart', error);
      return Promise.reject(error);
    });
}

export default {
  commentsForPost,
  repliesForComment,
  commentById,
  reportComment,
  reportHighAlertComment,
  deleteComment,
  addComment,
  addCommentHeart,
  removeCommentHeart,
};
