export type ViewName =
  | 'home_feed'
  | 'topic_feed'
  | 'profile'
  | 'story'
  | 'thought'
  | 'question'
  | 'inbox'
  | 'search'

export default function getCurrentViewName() : ViewName {
  const { pathname } = window.location;
  let viewName = 'home_feed';

  switch (true) {
  case pathname.startsWith('/topic/'):
    viewName = 'topic_feed';
    break;
  case pathname.startsWith('/u/'):
    viewName = 'profile';
    break;
  case pathname === '/inbox/':
    viewName = 'inbox';
    break;
  case pathname === '/search/':
    viewName = 'search';
    break;
  case !!document.querySelector('.view-cls-thought'):
    viewName = 'thought';
    break;
  case !!document.querySelector('.view-cls-question'):
    viewName = 'question';
    break;
  case !!document.querySelector('.story-content'):
    viewName = 'story';
    break;
  default:
    break;
  }

  return viewName as ViewName;
}
