/* eslint no-console:0 */
/**
 * ElementIds
 *
 * assigns every vue element a unique id, which can be accessed inside the view at this.$vId
 */
// the prefix for every id
let prfx = 'tm_';

// the suffix for every id
const now = (new Date()).getTime();
let sffx = `_${now}`;

// an inc that changes for every element that receives a new id
let inc = 0;

// the main interface
const ElementIds = {
  /**
   * install
   *
   * called when the plugin is loaded. initializes the plugin
   *
   * @param object Vue  the global Vue object that the plugin was registered with
   * @param object options  the options for this plugin
   */
  install(Vue, options) {
    // if we have options
    if (typeof options === 'object') {
      // allow overriding the prefix
      if (options.prefix) {
        prfx = options.prefix;
      }

      // allow overriding the suffix
      if (options.suffix) {
        sffx = options.suffix;
      }
    }

    // add a Vue mixin that defines functions that run for every element
    Vue.mixin({
      // when any element is mounted, create a new $vId if one has not been assigned yet
      mounted() {
        if (!this.$vId) {
          inc += 1;
          this.$vId = `${prfx}${inc}${sffx}`;
        }
      },
    });
  },
};

export default ElementIds;
