/* eslint-disable import/prefer-default-export */
/* eslint-disable no-case-declarations */
/* eslint-disable arrow-parens */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-cond-assign */
import { get } from 'lodash';
import { staticPath, getStoryUrl, dateDifference } from '../../data/helpers';


const creationDate = (date) => {
  const notificationDate = new Date(date);
  if (!isFinite(notificationDate)) return '';
  const now = new Date();

  const diff = dateDifference(notificationDate, now);
  const t = diff['t'] === 'month' ? 'mo' : diff['t'][0];

  return `${diff['q']}${t}`;
};

const userOwnsContext = (notification, currentUser) => {
  const { context } = notification;

  if (!context.author_id) {
    return false;
  }

  if (currentUser._id === context.author_id || (typeof (context.author_id) === 'object' && currentUser._id === context.author_id._id)) {
    return true;
  }

  return false;
};

const getPostType = () => {
  let postType;
  if (postType === 'WORDPRESS_POST' || postType === 'WORDPRESS_VIDEO') {
    postType = 'story';
  } else {
    postType = 'post';
  }
  return postType.toLowerCase();
};

const getCommentType = (notification) => (notification.context.parent_id === null ? 'comment' : 'reply');

const getContextType = (notification) => {
  let contextType = 'post';
  switch (notification.type) {
  case 'COMMENT_REPLY':
  case 'COMMENT_HEART':
    contextType = getCommentType(notification);
    break;
  case 'COMMENT':
  case 'HEART':
    if (notification.context.type === 'THOUGHT' || notification.context.type === 'QUESTION') {
      return 'post';
    }
    break;
  case 'UNKNOWN':
  case 'POST':
    contextType = getPostType(notification);
    break;
  default:
    break;
  }

  return contextType;
};

const ownership = (notification, currentUser) => (userOwnsContext(notification, currentUser) ? `your ${getContextType(notification)}` : `a ${getContextType(notification)} you follow`);


const pluralize = (count) => count > 1 ? ' people ' : ' person ';

const generateNotificationPrefix = (notification, currentUser) => {
  const { type, reference } = notification;
  let prefix = '';
  let displayName;

  if (type === 'COMMENT' || type === 'COMMENT_REPLY') {
    displayName = reference.author_id.display_name ?? reference.author_id.username;
  }

  switch (type) {
  case 'COMMENT':
    prefix = ownership(notification, currentUser) === 'your question'
      ? `${displayName} answered ${ownership(notification, currentUser)}: `
      : `${displayName} responded to ${ownership(notification, currentUser)}: `;
    break;
  case 'HEART':
    const word = pluralize(reference.count);
    prefix = `${reference.count + word} hearted ${ownership(notification, currentUser)}: `;
    break;
  case 'COMMENT_REPLY':
    const source = ownership(notification, currentUser);
    prefix = source === 'your comment' || source === 'your reply'
      ? `${displayName} replied to ${source} on: `
      : `${displayName} replied to ${source}: `;
    break;
  case 'COMMENT_HEART':
    const refCount = reference.count;
    const person = pluralize(refCount);
    prefix = `${refCount} ${person} just hearted your ${getCommentType(notification)}: `;
    break;
  case 'COMMENT_MENTION':
    prefix = 'mentioned you in their comment: ';
    break;
  case 'POST_MENTION':
    prefix = 'mentioned you in their post: ';
    break;
  case 'POST':
  case 'UNKNOWN':
    prefix = '';
    return '';
  case 'GROUP_APPLICATION':
    return 'New join request in the group ';
  case 'GROUP_MODERATION_COMMENT':
    return 'New comment to moderate in the group ';
  case 'GROUP_MODERATION_CONTENT':
    return 'New post to moderate in the group ';
  case 'JOIN_REQUEST_APPROVED':
    return 'Your join request was approved for group ';
  case 'POLL':
    const mine = ownership(notification, currentUser) === 'your post';
    return `${mine ? 'Your' : 'This'} poll has closed. Check out the final results: `;
  default:
    return '';
  }

  return prefix;
};

const generateNotificationSuffix = (notification) => {
  let suffix = '';
  const { type, context, reference } = notification;
  switch (type) {
  case 'POST':
  case 'UNKNOWN':
    suffix = '';
    break;
  case 'HEART':
  case 'COMMENT':
  case 'POST_MENTION':
  case 'COMMENT_MENTION':
    if (context.type === 'WORDPRESS_POST') {
      suffix = context.card_data['card-title'];
    } else if (context._normalized_title) {
      suffix = context._normalized_title;
    } else {
      suffix = context._normalized_body;
    }
    break;
  case 'GROUP_MODERATION_COMMENT':
  case 'GROUP_MODERATION_CONTENT':
    suffix = context.name;
    break;
  case 'GROUP_APPLICATION':
  case 'JOIN_REQUEST_APPROVED':
    suffix = reference.group.name;
    break;
  case 'COMMENT_REPLY':
  case 'COMMENT_HEART':
    suffix = context.content_id.title;
    break;
  case 'POLL':
    suffix = context.title;
    break;
  default:
    return '';
  }

  return suffix;
};

const generateContextUrl = (notification) => {
  const { type, context, reference } = notification;

  let url;

  switch (type) {
  case 'COMMENT':
  case 'HEART':
  case 'POST_MENTION':
    // if card_data exists = it's a story otherwise it's a regular post
    if (context.card_data) {
      url = getStoryUrl({ post: context });
    } else {
      url = `/content/${context._id}`;
    }
    break;
  case 'COMMENT_REPLY':
  case 'COMMENT_HEART':
  case 'COMMENT_MENTION':
    if (context.card_data) {
      url = getStoryUrl({ post: context });
    } else if (context.content_id.card_data) {
      url = getStoryUrl({ post: context.content_id });
    } else {
      url = `/content/${context.content_id._id}`;
    }
    break;
  case 'POST':
  case 'UNKNOWN':
    url = '';
    break;
  case 'JOIN_REQUEST_APPROVED':
  case 'GROUP_APPLICATION':
    url = `/groups/${reference.group.slug}`;
    break;
  case 'GROUP_MODERATION_COMMENT':
  case 'GROUP_MODERATION_CONTENT':
    url = `/groups/${context.slug}`;
    break;
  case 'POLL':
    url = `/poll/${context.id}`
    break;
  default:
    url = '';
    break;
  }

  return url;
};

const generateAvatar = (notification) => {
  const { type, reference } = notification;

  let avatar;
  switch (type) {
  case 'COMMENT':
  case 'HEART':
    avatar = staticPath('group/groups_horizontal.png');
    break;
  case 'COMMENT_REPLY':
  case 'COMMENT_HEART':
    avatar = staticPath('group/groups_horizontal.png');
    break;
  case 'COMMENT_MENTION':
  case 'POST_MENTION':
    avatar = get(reference, 'mention_author.profile.avatar_thumbnail', staticPath('group/groups_horizontal.png'));
  case 'POST':
  case 'UNKNOWN':
    break;
  case 'JOIN_REQUEST_APPROVED':
  case 'GROUP_APPLICATION':
    if (reference.group.images && reference.group.images.avatar) {
      avatar = reference.group.images.avatar.url;
    } else {
      avatar = staticPath('group/groups_horizontal.png');
    }
    break;
  case 'GROUP_MODERATION_COMMENT':
  case 'GROUP_MODERATION_CONTENT':
    avatar = reference.author_id.profile.avatar_thumbnail;
    break;
  case 'POLL':
    avatar = staticPath('svgs/poll-icon.svg');
  default:
    break;
  }

  return avatar;
};

const generateMessage = (notification, currentUser) => {
  const prefix = generateNotificationPrefix(notification, currentUser);
  const suffix = generateNotificationSuffix(notification);
  const url = generateContextUrl(notification);
  const avatar = generateAvatar(notification);

  return {
    prefix,
    suffix,
    url,
    avatar,
  };
};

export {
  generateMessage,
  creationDate,
};
