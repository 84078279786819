import { getWindowSize } from '../data/helpers';
import _ from 'lodash';

/**
 * this mixin adds a property to the element which tells whether the current screensize is large or small, based on our mobile/desktop breakpoint
 */
export default {
  // actions that need to occur inside this element
  methods: {
    // re-evaluate whether the current screen is large enough for desktop
    recalcLargeSize() {
      const windowSize = getWindowSize();
      this.$store.commit('setLargeScreen', windowSize.width >= 768);
      this.$store.commit('setPostWithImageScreen', windowSize.width >= 900);
      this.$store.commit('setWindowDimensions', windowSize);
    },
  },

  // setup event listeners when the element is created, and run the initial desktop size calculation
  mounted() {
    // attempting to reduce unnecessary actions/mutations on logout page
    if (_.get(this.$route, 'path', '/') !== '/logout/' ) {
      this.recalcLargeSize();
      window.addEventListener('resize', _.throttle(this.recalcLargeSize, 250));
      window.addEventListener('orientationchange', _.throttle(() => this.recalcLargeSize, 250));
    }
  },

  // before we remove this element, remove the event listeners
  beforeDestory() {
    window.addEventListener('resize', _.throttle(this.recalcLargeSize, 250));
    window.addEventListener('orientationchange', _.throttle(() => this.recalcLargeSize, 250));
  },
};
