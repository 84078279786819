import Vue from 'vue';
// initial state
const state = () => ({
  // the id of the item who's menu is currently open
  menuOpenItemId: '',

  // the id of the item that is currently having it's link copied
  currentlyBeingCopiedItemId: '',
});

// getters for states
const getters = {
  /**
   * menuOpenFor
   *
   * find the item id of the item that currently has their menu open
   *
   * @param object currentState  the current state of this handler
   */
  menuOpenFor: currentState => currentState.menuOpenItemId,

  /**
   * getCurrentlyCopying
   *
   * the id of the comment that is in the process of having it's link copied
   *
   * @param object currentState  the current state of this handler
   */
  getCurrentlyCopying: currentState => currentState.currentlyBeingCopiedItemId,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
};

// mutate the data in the states
const mutations = {
  /**
   * setCurrentlyCopying
   *
   * sets the id of the item that is currently having it's link copied
   *
   * @param object currentState  the object that describes the current state of this handle
   * @param string id  the id of the item that is being reported currently
   */
  setCurrentlyCopying(currentState, id) {
    Vue.set(currentState, 'currentlyBeingCopiedItemId', id);
  },

  /**
   * setMenuOpen
   *
   * set the value of the item menu open flag for the specified item
   *
   * @param object currentState  the object describing the curetn state of this handler
   * @param string itemId  the id of the item that currently has their menu open
   */
  setMenuOpen(currentState, itemId) {
    Vue.set(currentState, 'menuOpenItemId', itemId);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
