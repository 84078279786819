import _, { divide } from 'lodash';
import { tmAnalytics } from "@/data/helpers";
import { getWindowSize } from "@/data/helpers";
import { stripHtml } from 'string-strip-html';
import events from '../embed/helpers/events';
const AD_MEASURE_SIZE = 150;

const getQATargeting = () => {
  const targeting = {};
  if (process.env.NODE_ENV !== 'production') {
    targeting.test = "qa"
  }
  return targeting
}

const uniqId = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export const setEventListener = (ga) => {

  const googletag = window.googletag || {cmd: []};
  if ( window.declaredAdEvents ) {
    return false
  }
  
  const windowSize = getWindowSize();
  window.declaredAdEvents = true
  const device = windowSize.width >= 768 ? 'desktop' : 'mobile';
  const currentAds = {};

  googletag.cmd.push(() => {

    // This listener will be called when a slots creative iframe load event fires.
    googletag.pubads().addEventListener('slotOnload', function(event) {
      const _id = event.slot.getSlotElementId();
      const page = googletag.pubads().getTargeting('page')

      if(_id && page.length) {
        const label = `${page}_${_id}_${device}`;
        tmAnalytics({ ga: ga, category: 'ad.onLoad', action: "ad_onload", label }, ['ga']);
      }
    });
    
    // Send event when an ad is visible at least 30% or more than 50%.
    googletag.pubads().addEventListener('slotVisibilityChanged', function(event) {
      const slotId = event.slot.getSlotElementId();
      const inViewPercentage = event.inViewPercentage;
      const page = googletag.pubads().getTargeting('page');

      const visbility = inViewPercentage >= 50 ? 'viewable' : 'notViewable';
      !currentAds[slotId] &&  (currentAds[slotId] = {});
      const ad_slot = currentAds[slotId];

      // Avoid sending multiple requests
      if(!ad_slot[visbility] && visbility && inViewPercentage > 0) {
        ad_slot[visbility] = true;
        const label = `${page}_${slotId}_${device}`;
        const category = visbility === "viewable" ? 'ad.visibility > 50%' : 'ad.visibility < 50%';

        tmAnalytics({ ga: ga, category, action: "visibility_changed", label }, ['ga']);
      }
    });

    // This listener will be called when an impression is considered viewable.
    googletag.pubads().addEventListener('impressionViewable', function(event) {
      const slotId = event.slot.getSlotElementId();
      const page = googletag.pubads().getTargeting('page');

      const label = `${page}_${slotId}_${device}`;
      tmAnalytics({ ga: ga, category: 'ad.impression_viewable', action: "impression_viewable", label }, ['ga']);
    });
    
  })
}

export const getUserAdTargeting = ({ user = null }) => {
  if (user && user.id) {
    const targeting = {};

    if (_.get(user, 'settings.type') === 'partner') {
      targeting['user.username'] = user.username
    }
    return targeting
  } else {
    return { 
      'user.role': 'anonymous'
    }
  }
}

export const setStoryAdTargeting = ({ storyContent, user, follows, ga }) => {
  const googletag = window.googletag || {cmd: []};

  googletag.cmd.push(() => {
    googletag.pubads().clearTargeting();
  });

  const targeting = getStoryAdTargeting({ storyContent, user, follows })
  googletag.cmd.push(() => {
    Object.entries(targeting).forEach(([key,value]) => {
      googletag.pubads().setTargeting(key, value);
    });
  });

}

export const getStoryAdTargeting = ({ storyContent, user }) => {
  const { card_data, topics = [], _id } = storyContent;
  const mongoTopicSlugs = topics.map(t => t.slug);
  let beatsArray = [];
  let cardDataTopics = [];

  try {
    const postData = JSON.parse(_.unescape(card_data['card-post-data']));
    const tags = postData.tags || []
    const categories = postData.categories || []

    for (const category of categories) {
      cardDataTopics.push(category.slug);
    }

    for (const tag of tags) {
      cardDataTopics.push(tag.slug);
    }

    const beats = _.get(postData, 'topic.beat');
    if (beats) {
      beatsArray = beats.map(b => b)
    }
  } catch (error) {
    console.log('Error while parsing post data')
  }

  return {
    ...getQATargeting(),
    ...getUserAdTargeting({ user }),
    "post.topic.condition": card_data['card-author-byline-url'].replace('/', ''),
    "post_topiccondition": card_data['card-author-byline-url'].replace('/', ''),
    "post.topics": _.uniq(cardDataTopics).join(','),
    "post.m.topics": _.uniq(mongoTopicSlugs).join(','),
    "post_topics": _.uniq(cardDataTopics).join(','),
    "post_mtopics": _.uniq(mongoTopicSlugs).join(','),
    "post.wp_id": _id,
    "post.beat": beatsArray.join(','),
    "post_beat": beatsArray.join(','),
    "page": 'Story',
  }
}

export const setTopicAdTargeting = ({ topic, user, follows, ga }) => {
  const googletag = window.googletag || {cmd: []};
  const targeting = getTopicAdTargeting({ topic, user, follows })

  googletag.cmd.push(() => {
    googletag.pubads().clearTargeting();
    
    Object.entries(targeting).forEach(([key,value]) => {
      googletag.pubads().setTargeting(key, value);
    });
  });

}

export const getTopicAdTargeting = ({ topic, user }) => {
  return {
    ...getQATargeting(),
    ...getUserAdTargeting({ user }),
    "topic.slug": topic.slug,
    "topic_slug": topic.slug,
    "page": 'Topic',
  }
}

export const setHomeAdTargeting = () => {
  const googletag = window.googletag || {cmd: []};
  const targeting = getQATargeting();
  googletag.cmd.push(() => {
    googletag.pubads().clearTargeting();

    // set targeting
    Object.entries(targeting).forEach(([key,value]) => {
      googletag.pubads().setTargeting(key, value);
    });
  });
}

export const getPagAdTargeting = (route, path) => {
  let page = route;
  
  if(route === 'index') {
    page = "HomePage";
  } else if(path.includes('/collections/all')) {
    page = 'Collections';
  } else if(path.startsWith('/collection/')) {
    page = 'HomePage';
  }

  return {
    ...getQATargeting(),
    page,
  }
}

const tags = {
  "<p": {
    "<figure": false,
    "<img": false,
    "<embed": false,
    "<video": false,
    "<object": false,
    "<iframe": false,
    "</p>": true,
  },
  "<img": { ">": true, "</p>": true },
  "<figure": { "figure>": true, "</p>": true },
  '<div class="video-container"': { "VIDEO -->": true, "</p>": true },
  '<section class="sm-partner-promo"': { "</section>": true },
  "<embed": { "</embed>": true },
  "<object": { "</object>": true },
  "<video": { "</video>": true },
  "<iframe": { "</iframe>": true },
  "<blockquote": { "</blockquote>": true },
};

// split each content block into smaller sections by looking for specific tags
const splitContent = (content) => {
  let content2 = `${content}`;
  const keys = Object.keys(tags);
  const re = new RegExp(`${keys.join("|")}`);
  const dividedContent = [];

  // while we still have text to parse
  while (content2.length) {
    // find the first occurrence of any tag in the content
    const first = content2.match(re);

    // if there is no first occurrence, use the remainder of the string as the last segment, and bail
    if (!first) {
      dividedContent.push(content2);
      break;
    }

    // if the next tag is not at the beginning of this string, slice up the bit in front as a value for new_content
    if (0 !== first.index) {
      dividedContent.push(content2.slice(0, first.index));
      content2 = content2.slice(first.index).trim();
    }

    // find the ending tag for the matched tag
    const closingTags = Object.keys(tags[first[0]]);
    const re2 = new RegExp(`${closingTags.join("|")}`);
    const end = content2.match(re2);

    // if the end was not found, use the remainder of the content as the last segment, and bail
    if (!end) {
      dividedContent.push(content2);
      break;
    }

    const position = tags[first[0]][end[0]] ? end.index + end[0].length : end.index;

    // otherwise, make a segment, and iterate
    dividedContent.push(content2.slice(0, position));
    content2 = content2.slice(position).trim();
  }
  return dividedContent;
};




const maybe_dfp_campaigns = (segment, settings, isLast, tally) => {
  let output = "";
  // test each type for needing an additional ad at the end
  if (isLast) {
    settings.forEach((group) => {
      if (group.min <= group.tally && segment.size > group.min) {
        output += group.html;
      }
    });
    // if any of the groups have an output, use it
    if (output) {
      return { content: output, size: 1, reset_size: 1 };
    }
  }

  // build the final output
  settings.forEach((group) => {
    group.tally = tally - group.last;
    // if the segment is within the accepable range and we have not exhausted all the available slots
    if (
      // if we are not over the limit for this ad type
      group.cnt < group.max_cnt &&
      // if the position is at least the minimum distance for this slot
      group.min < group.tally
    ) {
      // figure out the appropriate offset for this entry
      const mark = group.every;
      group.cnt
      // calculate the min and max acceptable range for the placement
      group.min = parseInt(mark * 0.8);
      group.max = parseInt(mark * 1.4);
      const _id = uniqId()
      group.ids.push(_id)

      group.last = tally;
      output += group.html.replace('{{ID_SLOT}}', _id).replace('{{DEVICE}}', group.device);
    }
  });

  if (output) {
    return { content: output, size: 1 };
  }

  return false;
};

// use our algorithm to measure all the segments
const _measure_segments = (segments) => {
  let total = 0;
  const _segments = segments.map((segment) => {
    // count images, videos, objects, iframes and embeds inside the content
    const imgs = (segment.match(/<img/g) || []).length * AD_MEASURE_SIZE;
    const videos = (segment.match(/<video/g) || []).length * AD_MEASURE_SIZE;
    const embeds = (segment.match(/<embed/g) || []).length * AD_MEASURE_SIZE;
    const objects = (segment.match(/<object/g) || []).length * AD_MEASURE_SIZE;
    const iframes = (segment.match(/<iframe/g) || []).length * AD_MEASURE_SIZE;
    const tweets = (segment.match(/class="twitter-tweet"/g) || []).length * AD_MEASURE_SIZE;

    // strip all tags from the content, and count the words
    const words = stripHtml(segment).result.trim().split(' ');

    // assign final calculated size
    const size = imgs + videos + embeds + objects + iframes + tweets + words.length;
  
    // tally the total
    total += size;
    return { content: segment, size };
  });
  return { total, segments: _segments };
};

// Split content and add ads
export const initAds = (body, adSlot) => {
  let content = splitContent(body);
  const measure = _measure_segments(content);

  content = measure.segments;

  const settings = {
    // desktop
    d: {
      first: 200,
      every: 300,
      min: false,
      max: false,
      html: adSlot,
      cnt: 0,
      max_cnt: 200,
      last: 0,
      tally: false,
      device: "desktop",
      ids: []
    },
    // mobile
    m: {
      first: 100,
      every: 300,
      min: false,
      max: false,
      html: adSlot,
      cnt: 0,
      max_cnt: 200,
      last: 0,
      tally: false,
      device: "mobile",
      ids: []
    },
  };

  let tally = 0;
  let final = "";

  const arraySettings = Object.values(settings);

  arraySettings.forEach((group) => {
    // calculate the min and max acceptable range for the placement
    group.min = parseInt(group.first * 0.8);
    group.max = parseInt(group.first * 1.4);
  });

  // iterate all segments and add ads if necessary
  content.forEach((segment, i, array) => {
    const content_segment = maybe_dfp_campaigns(segment, arraySettings, false, tally);
    if (content_segment) {
      tally += content_segment.size;
      final += content_segment["content"];
    }
    tally += segment.size;
    final += segment["content"];
  });

  // get all ad ids
  const ads = {
    mobile: settings.m.ids,
    desktop: settings.d.ids,
  }
  return {content: final, ads};
}

export const getStoryWithAds = (body) => {
  const adSlot = `<aside class="tm-ads tm-ads--{{DEVICE}} tm-ads--story"><h4>Advertisement</h4><div class="ad-display"><div id="{{ID_SLOT}}"></div></div></aside>`;
  const result = initAds(body, adSlot);
  return result
}


export default null;
