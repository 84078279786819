/* eslint no-underscore-dangle:0 */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Vue from 'vue';
import moderationApi from '@/data/api/moderation';
import { log, err, isSet, isObject } from '@/data/helpers';
import _ from 'lodash';

// initial state
const state = () => ({
  allPosts: {},

  groupModerationItems: {
    items: [],
    groupId: '',
  },

  // contianer for the multiple feeds that will exist on the moderation page
  moderationFeeds: {
    // flagged feed
    highAlert: {
      items: [],
      offset: 0,
      isLoading: false
    },

    // flagged feed
    flagged: {
      items: [],
      offset: 0,
      isLoading: false,
    },

    // reviewed feed
    reviewed: {
      items: [],
      offset: 0,
      isLoading: false
    },

    '2ndReview': {
      items: [],
      offset: 0,
      isLoading: false
    },

    // private communities
    private_communities: {
      items: [],
      offset: 0,
      isLoading: false
    },

    // private communities
    community_feed: {
      items: [],
      offset: 0,
      isLoading: false
    },

    notFlagged: {
      items: [],
      offset: 0,
      isLoading: false
    },

    singleItem: {
      items: [],
      offset: 0,
      isLoading: false
    },

    contentItems: {
      items: [],
      offset: 0,
      isLoading: false
    },

    userItems: {
      items: [],
      offset: 0,
      isLoading: false
    },

    // stats tabs
    usersStats: {
      items: [],
      offset: 0,
      isLoading: false
    },
    topicsStats: {
      items: [],
      offset: 0,
      isLoading: false
    },
    groupsStats: {
      items: [],
      offset: 0,
      isLoading: false
    },
    contentComments: {
      items: [],
      offset: 0,
      isLoading: false
    },
  },
});

// getters for states
const getters = {
  /**
   * getModerationFeedItems
   *
   * get the items inside the feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return array  list of items in the feed
   */
  getModerationFeedItems: currentState => function privGetFeedItems(feedName) {
    return isObject(currentState.moderationFeeds[feedName])
      ? currentState.moderationFeeds[feedName].items
      : [];
  },

  getModerationFeedTotal: currentState => function privGetFeedTotal(feedName) {
    return currentState.moderationFeeds[feedName].total || 0;
  },

  getModerationFeedOffset: currentState => function privGetFeedOffset(feedName) {
    return isSet(currentState.moderationFeeds[feedName]) && isObject(currentState.moderationFeeds[feedName])
      ? currentState.moderationFeeds[feedName].offset
      : 0;
  },

  getModerationFeedLoadingState: currentState => function privGetLoadingState(feedName) {
    return currentState.moderationFeeds[feedName].isLoading;
  },

  getGroupModerationItems: (currentState) => {
    const { groupModerationItems } = currentState;
    if (groupModerationItems.items.length > 0) {
      return groupModerationItems.items.reduce((sum, curr) => {
        if (groupModerationItems.groupId !== _.get(curr, 'group._id')) {
          return sum;
        }
        const moderationItem = { ...curr };
        // instead of using get(curr.action_log[0], 'assigned_by', {}) we're using the || {} because assigned_by can be null but we need to assign a report time to it on line 122
        moderationItem.assigned_by = _.get(curr.action_log[0], 'assigned_by') || {};
        const createdAt = new Date(_.get(curr, 'action_log[0].created_at', new Date()));
        moderationItem.created_at = `${createdAt.toLocaleString("en-US", { month: "numeric", day: "numeric", year: "2-digit" })} at ${createdAt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
        moderationItem.assigned_by.report_time = moderationItem.created_at;
        sum.push(moderationItem);
        return sum;
      }, []);
    }
    return [];
  },
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  /**
   * loadModerationFeedItems
   *
   * get all the items in a given feed, and update the store with the result
   *
   * @param object context  the context of the current store
   * @param string feedName  the name of the feed to load the items of
   * @return Promise  handles the updating of the internal store data
   */
  loadModerationFeedItems(context, { feedName, offset, limit, status }) {
    // obtain the feed item list from the api
    context.commit('setLoadingState', { feedName, isLoading: true });
    context.commit('replaceFeedItems', { feedName, items: [], total: context.state.moderationFeeds[feedName].total || 0 });
    return moderationApi.getFeedItems(feedName, offset, limit, status, this.$nodeAxios)
      // with successful results...
      .then((rawData) => {
        // set the post list
        if (rawData && rawData.items) {
          context.commit('setLoadingState', { feedName, isLoading: false });
          context.commit('replaceFeedItems', { feedName, items: rawData.items, total: rawData.total });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', feedName, e);
      });
  },

  loadSingleItemFeed(context, { itemId }) {
    return moderationApi.getSingleItem(itemId, this.$nodeAxios)
    // with successful results...
      .then((rawData) => {
        // set the post list
        if (rawData && rawData.items) {
          context.commit('replaceFeedItems', { feedName: 'singleItem', items: rawData.items, total: 1 });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'singleItem', e);
      });
  },

  loadGroupModerationItems(context, { groupId, offset, limit, tabItem }) {
    return moderationApi.getFlaggedGroupItems(groupId, offset, limit, tabItem, this.$nodeAxios)
    // with successful results...
      .then((rawData) => {
        // set the post list
        if (rawData && rawData.items) {
          context.commit('replaceGroupModerationItems', { groupId, items: rawData.items });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'loadGroupModerationItems', e);
      });
  },

  acceptGroupModerationItem(context, moderation) {
    return moderationApi.acceptGroupModerationItem(moderation.group._id, moderation.id, this.$nodeAxios)
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'acceptGroupModerationItem', e);
      });
  },

  denyGroupModerationItem(context, moderation) {
    return moderationApi.denyGroupModerationItem(moderation.group._id, moderation.id, this.$nodeAxios)
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'denyGroupModerationItem', e);
      });
  },

  flagGroupModerationUser(context, moderation) {
    // TODO: get feedback from modal input?
    const { content: { author_id: author }, type, feedback } = moderation;
    return moderationApi.flagGroupModerationUser(moderation.group._id, author._id, feedback, this.$nodeAxios)
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'flagGroupModerationUser', e);
      });
  },

  banGroupModerationMember(context, moderation) {
    const { content: { author_id: author } } = moderation;
    return moderationApi.banGroupModerationMember(moderation.group._id, author._id, this.$nodeAxios)
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'banGroupModerationMember', e);
      });
  },

  loadContentItemsFeed(context, { itemId, offset, limit }) {
    return moderationApi.getContentItems(itemId, offset, limit, this.$nodeAxios)
    // with successful results...
      .then((rawData) => {
        // set the list of moderation items for this content
        if (rawData && rawData.items) {
          context.commit('replaceFeedItems', { feedName: 'contentItems', items: rawData.items, total: rawData.total });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'contentItems', e);
      });
  },

  loadUserItemsFeed(context, { userName, offset, limit }) {
    return moderationApi.getUserItems(userName, offset, limit, this.$nodeAxios)
      // with successful results...
      .then((rawData) => {
        // set the list of moderation items for this content
        if (rawData && rawData.items) {
          context.commit('replaceFeedItems', { feedName: 'userItems', items: rawData.items, total: rawData.total });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', 'userItems', e);
      });
  },
  updateModerationFeedItem(context, { postId, data }) {
    context.commit('extendModerationFeedItem', { postId, data });
  },

  approveAction(context, { feedName, item }) {
    return moderationApi.approveItem(item._id, this.$nodeAxios)
      .then((data) => {
        if (feedName === 'contentComments') {
          return moderationApi.getSingleItem(item._id, this.$nodeAxios).then((data) => {
            const [moderationItem] = data.items;
            context.commit('replaceItemById', { feedName, itemId: item._id, item: moderationItem });
          })
        } else {
          context.commit('removeItemFromFeed', { feedName, item });
        }
      });
  },

  async bulkApproveAction(context, { feedName, items }) {
    const promises = [];
    _.forEach(items, item => { promises.push(moderationApi.approveItem(item, this.$nodeAxios)); });
    await Promise.all(promises)
      .then(() => {
        context.commit('removeBulkItemsFromFeed', { feedName, items });
      });
  },

  denyAction(context, { feedName, item }) {
    return moderationApi.denyItem(item._id, this.$nodeAxios)
      .then((data) => {
        if (feedName === 'contentComments') {
          return moderationApi.getSingleItem(item._id, this.$nodeAxios).then((data) => {
            const [moderationItem] = data.items;
            context.commit('replaceItemById', { feedName, itemId: item._id, item: moderationItem });
          })
        } else {
          context.commit('removeItemFromFeed', { feedName, item });
        }
      });
  },

  banUserAction(context, { itemId, feedName, userId }) {
    return moderationApi.banUser(userId, itemId, this.$nodeAxios)
      .then(() => {
        if (feedName === 'contentComments') {
          return moderationApi.getSingleItem(itemId, this.$nodeAxios).then((data) => {
            const [moderationItem] = data.items;
            context.commit('replaceItemById', { feedName, itemId, item: moderationItem });
          })
        } else {
          context.commit('removeUserItemsFromFeed', { feedName, userId });
        }
      });
  },

  unbanUserAction(context, { itemId, feedName, userId }) {
    return moderationApi.unbanUser(userId, itemId, this.$nodeAxios)
      .then(() => moderationApi.getSingleItem(itemId, this.$nodeAxios))
      .then((data) => {
        const [item] = data.items;
        context.commit('replaceItem', { feedName, item });
      });
  },

  flagAction(context, { feedName, item }) {
    return moderationApi.flagItem(item._id, item.type.toLowerCase(), this.$nodeAxios)
      .then((moderationItemId) => {
        if (feedName === 'contentComments') {
          return moderationApi.getSingleItem(moderationItemId, this.$nodeAxios).then((data) => {
            const [moderationItem] = data.items;
            context.commit('replaceItemById', { feedName, itemId: item._id, item: moderationItem });
          })

        } else {
          context.commit('removeItemFromFeed', { feedName, item });
        }
      });
  },

  makePrivateAction(context, { feedName, item }) {
    return moderationApi.makeItemPrivate(item._id, this.$nodeAxios)
      .then(() => {
        if (feedName === 'contentComments') {
          return moderationApi.getSingleItem(item._id, this.$nodeAxios).then((data) => {
            const [moderationItem] = data.items;
            context.commit('replaceItemById', { feedName, itemId: item._id, item: moderationItem });
          })
        } else {
          context.commit('removeItemFromFeed', { feedName, item });
        }
      });
  },

  set2ndReviewAction(context, { feedName, item }) {
    return moderationApi.set2ndReviewStatus(item._id, this.$nodeAxios)
      .then(() => {
        if (feedName === 'contentComments') {
          return moderationApi.getSingleItem(item._id, this.$nodeAxios).then((data) => {
            const [moderationItem] = data.items;
            context.commit('replaceItemById', { feedName, itemId: item._id, item: moderationItem });
          })
        } else {
          context.commit('removeItemFromFeed', { feedName, item });
        }
      });
  },

  updateImageStatusAction(context, { feedName, item, imageId, status, safe }) {
    return moderationApi.updateImageStatus(imageId, item._id, status, safe, this.$nodeAxios)
      .then((data) => {
        context.commit('updateImageStatus', { feedName, item, status, safe });
        context.commit('replaceItem', { feedName, item: data });
      });
  },

  silenceStatItemAction(context, { feedName, item }) {
    return moderationApi.silenceStatItem(feedName, item._id, this.$nodeAxios)
      .then((data) => {
        context.commit('removeItemFromFeed', { feedName, item });
      });
  },

  banStatItemAction(context, { feedName, item }) {
    return moderationApi.banStatItem(feedName, item._id, this.$nodeAxios)
      .then((data) => {
        context.commit('removeItemFromFeed', { feedName, item });
      });
  },

  resetStatItemAction(context, { feedName, item }) {
    return moderationApi.resetStatItem(feedName, item._id, this.$nodeAxios)
      .then((data) => {
        context.commit('removeItemFromFeed', { feedName, item });
      });
  },

  banGroup(context, { feedName, item }) {
    return moderationApi.banGroup(item.group._id, item._id, this.$nodeAxios)
      .then((data) => {
        context.commit('removeItemFromFeed', { feedName, item });
      });
  },

  unbanGroup(context, { feedName, item }) {
    return moderationApi.unbanGroup(item.group._id, item._id, this.$nodeAxios)
      .then((data) => {
        context.commit('removeItemFromFeed', { feedName, item });
      });
  },

  loadCommentsForContent(context, { contentId, limit, offset = 0 }) {
    const feedName = 'contentComments';
    context.commit('setLoadingState', { feedName, isLoading: true });
    context.commit('replaceFeedItems', { feedName, items: [], total: context.state.moderationFeeds[feedName].total || 0 });
    return moderationApi.commentsForPost(contentId, limit, offset, this.$nodeAxios)
      .then(({ data }) => {
        if (data && data.items) {
          context.commit('setLoadingState', { feedName, isLoading: false });
          context.commit('replaceFeedItems', { feedName, items: data.items, total: data.total || context.state.moderationFeeds[feedName].total });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', feedName, e);
      });
  },
};

// mutate the data in the states
const mutations = {

  replaceFeedItems(currentState, { feedName, items, total }) {
    Vue.set(currentState.moderationFeeds[feedName], 'items', items);
    Vue.set(currentState.moderationFeeds[feedName], 'total', total);
  },

  setLoadingState(currentState, { feedName, isLoading }) {
    Vue.set(currentState.moderationFeeds[feedName], 'isLoading', isLoading);
  },

  removeItemFromFeed(currentState, { feedName, item }) {
    const list = currentState.moderationFeeds[feedName].items;
    const i = list.map(theItem => theItem._id).indexOf(item._id);
    list.splice(i, 1);

    Vue.set(currentState.moderationFeeds[feedName], 'items', list);
  },

  removeUserItemsFromFeed(currentState, { feedName, userId }) {
    const list = currentState.moderationFeeds[feedName].items;
    const newList = _.filter(list, feedItem => {
      if (_.get(feedItem, 'content_author_id') === userId) return false;
      if (_.get(feedItem, 'comment_author_id') === userId) return false;
      if (_.get(feedItem, 'chat.reported_message.author_id._id') === userId) return false;
      return true;
    })
    Vue.set(currentState.moderationFeeds[feedName], 'items', newList);
  },


  removeBulkItemsFromFeed(currentState, { feedName, items }) {
    const list = currentState.moderationFeeds[feedName].items;
    const newList = _.filter(list, feedItem => !_.includes(items, feedItem._id))

    Vue.set(currentState.moderationFeeds[feedName], 'items', newList);
  },

  updateImageStatus(currentState, { feedName, item, status, safe }) {
    const list = currentState.moderationFeeds[feedName].items;
    const i = list.map(theItem => theItem._id).indexOf(item._id);

    const newItem = item;
    newItem.content.embeds.images[0].status = status;
    newItem.content.embeds.images[0].safe = safe;

    list[i] = newItem;
    Vue.set(currentState.moderationFeeds[feedName], 'items', list);
  },

  replaceItem(currentState, { feedName, item }) {
    const list = currentState.moderationFeeds[feedName].items;
    const i = list.map(theItem => theItem._id).indexOf(item._id);
    list.splice(i, 1, item);
    Vue.set(currentState.moderationFeeds[feedName], 'items', list);
  },

  replaceItemById(currentState, { feedName, itemId, item }) {
    const list = currentState.moderationFeeds[feedName].items;
    const i = list.map(theItem => theItem._id).indexOf(itemId);
    list.splice(i, 1, item);
    Vue.set(currentState.moderationFeeds[feedName], 'items', list);
  },

  replaceGroupModerationItems(currentState, { groupId, items }) {
    Vue.set(currentState.groupModerationItems, 'items', items);
    Vue.set(currentState.groupModerationItems, 'groupId', groupId);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
