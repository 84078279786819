/* eslint no-param-reassign: 0 */
/* eslint no-underscore-dangle: ["error", { "allow": ["_normalized_hash_tag", "_id"] }] */
import Vue from 'vue';
import { log, isSet, isArray, isObject, dynamicStoreVariableAssignment, hasContentHashtags } from '@/data/helpers';
import topics from '@/data/api/topics';


/**
 * buildResultFromCache
 *
 * reconstruct a search resultset from the cached data
 *
 * @param array resultList  list of string keys that should make up the resultset
 * @param object knownTags  list of tags, indexed by name, which represents all the tags we know about
 * @return array  result list, rebuilt from the available information
 */
function buildResultFromCache(resultList, knownTags) {
  if (!isArray(resultList) || !isObject(knownTags) || !resultList.length || !Object.keys(knownTags).length) {
    return [];
  }

  const finalResults = [];
  // build the resulting list from the known list
  resultList.forEach((key) => {
    const itm = isSet(knownTags[key]) ? knownTags[key] : null;
    if (itm) {
      finalResults.push(itm);
    }
  });

  return finalResults;
}

const state = () => ({
  // list of known hashtags, indexed by the sanitized version of the tagname
  knownHashtags: {},

  // cached list of searches and respective results
  hashtagSearchCache: {},

  promptClosed: false,
});

const getters = {
  /**
   * getKnownHashtags
   *
   * get the list of all hashtags we know of
   */
  getKnownHashtags: currentState => currentState.knownHashtags,

  /**
   * getKnownHashtag
   *
   * get the object that describes a specific hashtag, based on the tagname
   *
   * @param string name  the name of the hashtag to fetch information on
   * @return object|null  information about the hashtag
   */
  getKnownHashtag: currentState => name => currentState.knownHashtags[name.toLowerCase()],

  isHashtagsPromptDisabled: currentState => currentState.promptClosed,
};

const actions = {
  /**
   * fetchSuggestions
   *
   * grab the suggestions from ajax/cache, based on the supplied search string
   *
   * @param object context  the current context object for the state of this handler
   * @param string search  the string to search for
   * @return Promise  a promise that contains the resulting ajax/cache results
   */
  fetchSuggestions(context, search) {
    const saneSearch = search.toLowerCase();
    // do we have a cache for this search result?
    if (context.state.hashtagSearchCache[saneSearch]) {
      try {
        return Promise.resolve(buildResultFromCache(context.state.hashtagSearchCache[saneSearch], context.state.knownHashtags));
      } catch (e) {
        return Promise.reject(e);
      }
    }

    // otherwise, return
    return topics.topicSearch(search, this.$nodeAxios)
      .then((results) => {
        context.commit('updateKnownSuggestions', { items: results.data });
        context.commit('setSuggestionCache', { search: saneSearch, results: results.data.map(x => x._normalized_hash_tag) });
        return results.data;
      })
      .catch(e => Promise.reject(e));
  },

  // will look up for the post creation in order to identify hashtags
  savePost(context) {
    const data = context.rootGetters.getLastPostArgs;

    if (data.title && hasContentHashtags(data.title)) {
      context.commit('closeHashtagsPrompt');
    }
    if (data.body && hasContentHashtags(data.body)) {
      context.commit('closeHashtagsPrompt');
    }
  },
  // will look up for the comment creation in order to identify hashtags
  submitComment(context, { data }) {
    if (data.body && hasContentHashtags(data.body)) {
      context.commit('closeHashtagsPrompt');
    }
  },
};

const mutations = {
  /**
   * setSuggestionCache
   *
   * set the cache of a specific suggestion result
   *
   * @param object currentState  the state of the current handler
   * @param string search  the string that was searched
   * @param array results  the results of the search, in truncated key-only form
   */
  setSuggestionCache(currentState, { search, results }) {
    dynamicStoreVariableAssignment(currentState, 'hashtagSearchCache', search, results);
  },

  /**
   * updateKnownSuggestions
   *
   * update the list of known hashtag data with the results from the most recnet ajax request
   *
   * @param object currentState  the state of the current handler
   * @param array items  the results of the search that need updating in the index
   */
  updateKnownSuggestions(currentState, { items }) {
    items.forEach((itm) => {
      if (isSet(itm._normalized_hash_tag) && !isSet(currentState.knownHashtags[itm._normalized_hash_tag])) {
        dynamicStoreVariableAssignment(currentState, 'knownHashtags', itm._normalized_hash_tag, itm);
      }
    });
  },

  closeHashtagsPrompt(currentState) {
    currentState.promptClosed = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
