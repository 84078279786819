import { Quill } from 'vue2-editor';

const Embed = Quill.import('blots/embed');

class Topic extends Embed {
  static create(data) {
    const node = super.create();
    node.innerHTML += `${data.denotationChar}${data.value}`;
    return Topic.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });

    domNode.id = data.id;
    if (data.mighty_topic) {
      domNode.classList.add('mighty-topic');
    } else {
      domNode.classList.add('ugc-topic');
    }
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

Topic.blotName = 'topic';
Topic.className = 'topic';
Topic.tagName = 'topic';

export default Topic;
