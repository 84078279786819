import { log } from '../helpers';
import axios from 'axios';

function contentRelated(storyId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/related/${storyId}/`)
    .then(res => res.data)
    .catch(error => log('story.contentRelated', error));
}

function content(storyId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/${storyId}/`)
    .then(res => res.data)
    .catch(error => log('story.content', error));
}

function getStoryContentBySlug(slug, type, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/by_slug/${slug}/${type}`)
    .then(response => response.data)
    .catch(error => log('story.getStoryContentBySlug', error));
}

function getStoryRecPoll(storyId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/${storyId}/story_recommendation`)
    .then(res => res.data)
    .catch(error => log('story.storyRecommendations', error));
}

function storyRecPollVote(storyId, pollId, optionId, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/content/story_recommendation/${pollId}/${optionId}/${storyId}`)
    .then(res => res.data)
    .catch(error => log('story.storyRecommendations', error));
}

function getStoryTopicRecs(topic) {
  const url = `https://c5vw1d25ek.execute-api.us-west-2.amazonaws.com/qa/recommendations/stories?object=story_topic&ranking=textrazor_score&id=${topic}`;
  return axios.get(url)
    .then((res) => res.data)
    .catch((err) => log('story.getStoryTopicRecs', err));
};

export default {
  contentRelated,
  content,
  getStoryContentBySlug,
  getStoryRecPoll,
  storyRecPollVote,
  getStoryTopicRecs
};
