import { log, err } from '@/data/helpers';
import { sanitizeFollowType, sanitizeFollowId } from '@/data/follow-helpers';

/**
 * followItem
 *
 * initiate a follow action on an item that is followable
 *
 * @param string itemType  the type of item being followed
 * @param string itemId  the id of the item being followed
 */
function followItem({ itemType, itemId, auto = false }, $nodeAxios) {
  const saneType = sanitizeFollowType(itemType);
  const saneId = sanitizeFollowId(itemId);
  const isAuto = auto ? `${auto}/` : '';
  return $nodeAxios.put(`/api/v1/follow/${saneType}/${saneId}/${isAuto}`)
    .then((response) => {
      log('FOLLOW AJAX RESPONSE:', itemType, itemId, response);
      return response.data;
    })
    .catch((e) => {
      err('FOLLOW AJAX ERROR:', e);
    });
}

/**
 * unfollowItem
 *
 * stop following an item
 *
 * @param string itemType  the type of item being followed
 * @param string itemId  the id of the item being followed
 */
function unfollowItem({ itemType, itemId, auto = false }, $nodeAxios) {
  const saneType = sanitizeFollowType(itemType);
  const saneId = sanitizeFollowId(itemId);
  const isAuto = auto ? `${auto}/` : '';
  return $nodeAxios.delete(`/api/v1/follow/${saneType}/${saneId}/${isAuto}`)
    .then((response) => {
      log('UNFOLLOW AJAX RESPONSE:', itemType, itemId, response);
      return response.data;
    })
    .catch((e) => {
      err('UNFOLLOW AJAX ERROR:', e);
    });
}

/**
 * getMyFollows
 *
 * get the follows, formatted, for the current user
 */
function getMyFollows($nodeAxios) {
  return $nodeAxios.get('/api/v1/follow/my-follows')
    .then((raw) => raw.data);
}

/**
 * markNotified
 *
 * mark a number of items as having been notified of the auto follow
 *
 * @param array ids  list of string ids to mark as notified
 */
function markNotified({ ids, $nodeAxios }) {
  return $nodeAxios.put('/api/v1/follow/auto-follow-notified', { item_ids: ids })
    .then((raw) => {
      log('NOTIFIED AJAX', ids, raw);
    })
    .catch((e) => {
      err('NOTIFIED AJAX FAILED', ids, e);
    });
}

/**
 * loadUserFollows
 *
 * load follow types of user for given username
 *
 * @param {String} username  username of user to grab user follows for
 */
function loadUserFollows({ username, pp, off, $nodeAxios }) {
  return $nodeAxios.get(`/api/v1/follow/user_follows/${username}?off=${off}&pp=${pp}&filter=user`, {})
    .then(res => res.data)
    .catch((e) => {
      err('Failed to load user follows', e);
    });
}

/**
 * loadFollows
 *
 * load follow types of user for given username
 *
 * @param {String} username  username of user to grab user follows for
 * @param {Integer} pp  limit of how many items to grab (default is 300)
 * @param {Integer} off  offset of list (default is 0)
 * @param {String} followType  follow type for filter ('category', 'group', 'user')
 */
function loadFollows({ username, pp = 300, off = 0, followType = '', $nodeAxios }) {
  return $nodeAxios.get(`/api/v1/follow/user_follows/${username}?pp=${pp}&off=${off}&filter=${followType}`).then(res => res.data.follows);
}

/* not sure what this ones for, but could be similar to searchFollows below */
function search({ value, pp = 300, off = 0, $nodeAxios }) {
  const url = `/api/v1/follow/search/${value}?pp=${pp}&off=${off}`;
  return $nodeAxios.get(url).then(res => res.data);
}

/**
 * this was created for Mentions, so a user could search the users they followed
 * additionally, the user can't be blocked by those users
 */
function searchFollows({ query, pp = 300, off = 0 }, $nodeAxios) {
  const params = { query, pp, off };
  return $nodeAxios.get(`/api/v1/user/for_mention/`, { params })
    .then(res => res.data)
    .catch((e) => {
      err('Failed to load search results', e);
    });
}

function followsMe(userId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/follow/${userId}/followees`, {})
    .then(res => res.data)
    .catch((e) => {
      err('Failed to execute: followsMe', e);
    });
}

export default {
  followItem,
  unfollowItem,
  getMyFollows,
  markNotified,
  loadUserFollows,
  loadFollows,
  search,
  searchFollows,
  followsMe
};

