const script1 = document.createElement('script');
script1.type = 'text/javascript';
// script1.addEventListener('load', () => {
//     var blogherads = blogherads || {};
//     blogherads.adq = blogherads.adq || [];
//     blogherads.adq.push(function() {
//         blogherads.disableAds();
//     });    
// });
script1.src = `${window.location.origin}/static/js/she_media.js`;

const script2 = document.createElement('script');
script2.async = 'async';
script2.setAttribute('data-cfasync', false);
script2.src = 'https://ads.blogherads.com/static/blogherads.js';

const script3 = document.createElement('script');
script3.async = 'async';
script3.setAttribute('data-cfasync', false);
script3.src = 'https://ads.blogherads.com/sk/12/124/1241529/31047/header.js';

// document.head.appendChild(script1);
document.head.appendChild(script2);
document.head.appendChild(script3);