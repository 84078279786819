/* eslint no-param-reassign: 0 */

/**
 * The main User store
 *
 * Responsible for managing state of:
 *
 * - User Following
 */
import Vue from "vue";
import { mapGetters } from 'vuex';
import _ from "lodash";
import { FOLLOW_TYPE_USER, FOLLOW_TYPE_CATEGORY } from "@/data/enum/follow";
import { sanitizeFollowType, sanitizeFollowId } from "@/data/follow-helpers";
import followApi from "@/data/api/follow";
import userApi from "@/data/api/user";
import awardsApi from "@/data/api/awards";
import subscriptionApi from "@/data/api/subscription";
import { log, err, isFunction } from "@/data/helpers";
import segmentAnalytics from "@/libs/events";

const state = () => ({
  // list of follows current session user has
  userFollows: [],

  // list of this user's follows (and topics below it), as informed by the api
  follows: [],
  topics: [],
  // this is the featured content the user has pinned to his profile
  featuredContent: [],
  myBadges: [],

  isLoadingFollows: { loadingState: false, loaded: false },

  blockedUsers: [],

  // failed transactions
  failures: {},
  lastFailure: null,
  // allowed to trigger auto-follow on this page?
  allowAutoFollow: false,

  // was the auto follow triggered already?
  autoFollowTriggeredAlready: false,

  //
  activities: [],
  myFollowers: [],
});

const getters = {
  userFollows: (ctxState) => ctxState.userFollows,

  // get the current user follows
  getMyFollows: (ctxState) => ctxState.follows,
  getMyTopics: currentState => currentState.topics,
  getMyFeaturedContent: ctxState => ctxState.featuredContent,
  getMyBadges: ctxState => ctxState.myBadges,
  isPinnedFeaturedContent: ctxState => contentId => ctxState.featuredContent.includes(contentId),

  // are we loading the follows for this user
  isLoadingFollows: (ctxState) => ctxState.isLoadingFollows,

  getMyBlockedUsers: (ctxState) => ctxState.blockedUsers,

  // method to find a user followed item
  userFollowingItem: (ctxState, ctxGetters) => (itemId, itemType) =>
    ctxGetters.userFollows.find(
      (following) =>
        following.item_id === itemId &&
        following.item_type === sanitizeFollowType(itemType)
    ),

  // user is followed?
  userFollowedUser: (ctxState, ctxGetters) => (userId) =>
    !!ctxGetters.userFollowingItem(userId, FOLLOW_TYPE_USER),

  // category is followed?
  userFollowedCategory: (ctxState, ctxGetters) => (categoryId) =>
    !!ctxGetters.userFollowingItem(categoryId, FOLLOW_TYPE_CATEGORY),

  /**
   * isAutoFollowAllowed
   *
   * determine if auto-following is allowed on this page
   *
   * @return bool  yes or no, is auto-following allowed on this page?
   */
  isAutoFollowAllowed: (currentState) => currentState.allowAutoFollow,

  /**
   * wasAutoFollowTriggered
   *
   * has the auto-follow mechanism been triggered already on this page?
   *
   * @return bool  yes or no, has it been triggered already?
   */
  wasAutoFollowTriggered: (currentState) =>
    currentState.autoFollowTriggeredAlready,
  getActivies: (currentState) => currentState.activities,
  getMyFollowers: currentState => currentState.myFollowers,
};

const actions = {

  connectUser(context, userId) {
    return userApi.connectUser(userId, this.$nodeAxios)
      .then()
      .catch((e) => {
        log("There was an issue connecting user");
      });
  },
  /**
   * loadMyFollows
   *
   * load the current user's follows from the api
   */
  loadMyFollows(context) {
    if (
      context.state.isLoadingFollows.loadingState ||
      context.state.isLoadingFollows.loaded
    ) {
      log("Already initialized my follows");
      return false;
    }

    context.commit("setIsLoadingFollows", { loadingState: true });
    return followApi
      .getMyFollows(this.$nodeAxios)
      .then((results) => {
        if (results.success) {
          let follows = results.follows;
          context.commit("setFollows", { follows });
          context.commit('setTopics', { topics: _.filter(follows, f => f._type === 'category') });
        }
        context.commit("setIsLoadingFollows", {
          loadingState: false,
          loaded: true,
        });
      })
      .catch((e) => {
        context.commit("setIsLoadingFollows", {
          loadingState: false,
          loaded: true,
        });
        err(e);
      });
  },

  /**
   * toggleUserFollow
   *
   * toggles the follow state of this user following another user
   *
   * @param object context  the entire store handler
   * @param object params  the data describing the thing we want to toggle follow of
   */
  toggleUserFollow(context, params) {
    const updated = Object.assign({}, params, { itemType: FOLLOW_TYPE_USER });
    context.dispatch("toggleFollow", updated);
  },

  /**
   * toggleTopicFollow
   *
   * toggles the follow state of this user following a topic
   *
   * @param object context  the entire store handler
   * @param object params  the data describing the thing we want to toggle follow of
   */
  toggleTopicFollow(context, params) {
    const updated = Object.assign({}, params, {
      itemType: FOLLOW_TYPE_CATEGORY,
    });
    context.dispatch("toggleFollow", updated);
  },

  /**
   * toggleFollow
   *
   * if the user is currently following this item, unfollow it. if they are unfollowing the item, follow it
   *
   * @param object context  the entire store handler
   * @param object params  the data used to power this request. includes itemType, itemId, initiator, and any other random data that will be ignored
   */
  toggleFollow(context, params) {
    const itemId = sanitizeFollowId(params.itemId);
    const followName = params.followName;
    const itemType = sanitizeFollowType(params.itemType);

    const auto = params.auto || false;
    let promise;

    if (!context.rootGetters.isLoggedIn) {
      context.dispatch("notLoggedInAction");
      return Promise.reject();
    }

    const userId = _.get(context.rootState, 'session.user._id', '');
    const sessionId = _.get(context.rootState, 'session.user.sessionId', '');
    // new user-user follow
    if (!params.following) {
      segmentAnalytics.follow({
        target: {
          type: itemType,
          name: followName,
        },
        userId,
        sessionId,
      });
    } else {
      segmentAnalytics.unfollow({
        target: {
          type: itemType,
          name: followName,
        },
        userId,
        sessionId,
      });
    }
    if (!context.getters.userFollowingItem(itemId, itemType)) {
      promise = context.dispatch("follow", {
        itemType,
        itemId,
        initiator: params.initiator,
        auto,
      });
      if (itemType === "category") {
        context.dispatch("toggleFollowTopicEvent", {
          type: "follow",
          topicId: itemId,
          auto,
        });
      }
      // existent user-user follow
    } else {
      promise = context.dispatch("unfollow", {
        itemType,
        itemId,
        initiator: params.initiator,
        auto,
      });
      if (itemType === "category") {
        context.dispatch("toggleFollowTopicEvent", {
          type: "unfollow",
          topicId: itemId,
          auto,
        });
      }
    }

    return promise;
  },

  /**
   * follow
   *
   * initiate a follow on an item
   *
   * @param object context  the context of the current handler
   * @param string itemType  the type of item that needs to be followed
   * @param string itemId  the id of the item that neesd to be followed
   * @param string initiator  the words that describe what the user interacted with, that triggered this action
   */
  follow(context, { itemType, itemId, initiator, auto = false }) {
    // let the ui respond right away with the follow
    context.commit("followItem", { itemType, itemId, initiator, auto });

    return followApi
      .followItem({ itemType, itemId, auto }, this.$nodeAxios)
      .then((response) => {
        // there was a successful response..
        // otherwise, handle failure
        if (!response.result) {
          context.commit("unfollowItem", { itemType, itemId, initiator, auto });
          context.commit("addFailure", {
            itemType,
            itemId,
            initiator,
            type: "follow",
          });
        }
      })
      .catch(() => {
        context.commit("unfollowItem", { itemType, itemId, initiator, auto });
        context.commit("addFailure", {
          itemType,
          itemId,
          initiator,
          type: "follow",
        });
      });
  },

  /**
   * unfollow
   *
   * stop following an item
   *
   * @param object context  the context of the current handler
   * @param string itemType  the type of item that needs to be followed
   * @param string itemId  the id of the item that neesd to be followed
   * @param string initiator  the words that describe what the user interacted with, that triggered this action
   */
  unfollow(context, { itemType, itemId, initiator, auto = false }) {
    // let the ui respond right away with the unfollow
    context.commit("unfollowItem", { itemType, itemId, initiator, auto });

    return followApi
      .unfollowItem({ itemType, itemId, auto }, this.$nodeAxios)
      .then((response) => {
        // there was a successful response..
        // otherwise, handle failure
        if (!response.result) {
          context.commit("followItem", { itemType, itemId, initiator, auto });
          context.commit("addFailure", {
            itemType,
            itemId,
            initiator,
            type: "unfollow",
          });
        }
      })
      .catch(() => {
        context.commit("followItem", { itemType, itemId, initiator, auto });
        context.commit("addFailure", {
          itemType,
          itemId,
          initiator,
          type: "unfollow",
        });
      });
  },

  /**
   * followPost
   *
   * start following a post
   *
   * @param object context  the context for the current handler
   * @param string itemId  the id of the post to follow
   * @param string initiator  the thing that intiated the follow
   */
  followPost(context, { itemId, initiator, after }) {
    return subscriptionApi
      .followItem({ itemId, initiator }, this.$nodeAxios)
      .then((itm) => {
        if (isFunction(after)) {
          after(itm);
        }
        context.commit("subscribePost", { itemId, subscription: itm });
        return itm;
      })
      .catch((e) => {
        err("Failed to follow post:", itemId, e);
      });
  },

  /**
   * unfollowPost
   *
   * stop following a post
   *
   * @param object context  the context for the current handler
   * @param string itemId  the id of the post to unfollow
   * @param string initiator  the thing that intiated the unfollow
   */
  unfollowPost(context, { itemId, initiator, after }) {
    return subscriptionApi
      .unfollowItem({ itemId, initiator }, this.$nodeAxios)
      .then((itm) => {
        if (isFunction(after)) {
          after(itm);
        }
        return itm;
      })
      .catch((e) => {
        err("Failed to follow post:", itemId, e);
      });
  },

  /**
   * loadBlockedUsers
   *
   * load all of the users blocked by the current user
   *
   */
  loadBlockedUsers(context) {
    if (this.state.session.loggedIn) {
      return this.$nodeAxios
        .get("/api/v1/user/blocks")
        .then(({ data }) => {
          if (data) {
            const blockedUsers = _.map(
              _.filter(data, (user) => user.status === "BLOCKED"),
              (user) => user.blocked_user_id._id
            );
            context.commit("setBlockedUsers", { blockedUsers });
          }
        })
        .catch(err);
    }

    return Promise.resolve();
  },

  /**
   * blockUser
   *
   * block a certain user
   *
   * @param string { userId }  the id of the user to block
   *
   */
  blockUser(context, { userId }) {
    return userApi
      .blockUser(userId, this.$nodeAxios)
      .then((data) => {
        if (data.success) {
          context.commit("addBlockedUser", { userId });
        }
      })
      .catch((e) => {
        err("Failed to add a Blocked User", e);
      });
  },

  /**
   * unblockUser
   *
   * unblock a certain user
   *
   * @param string { userId }  the id of the user to unblock
   *
   */

  unblockUser(context, { userId }) {
    return userApi
      .unblockUser(userId, this.$nodeAxios)
      .then((data) => {
        if (data.success) {
          context.commit("removeBlockedUser", { userId });
        }
      })
      .catch((e) => {
        err("Failed to add a Blocked User", e);
      });
  },

  // action retrieves list of users who are currently following me
  loadMyFollowers(context, { userId }) {
    return followApi.followsMe(userId, this.$nodeAxios)
      .then(res => {
        if (res.success) {
          context.commit('setMyFollowers', { myFollowers: res.followees });
        }
      })
      .catch((e) => {
        err("Failed to retrieve followers", e);
      });
  },


  /**
   * loadMyFeaturedContent
   *
   * this retrieves the content that the user is featuring on their profile
   * we need this to keep track of what they pinned as the move along the site
   */
  loadMyFeaturedContent(context, userId) {
    return userApi.profileFeaturedContent(userId, this.$nodeAxios)
      .then((content) => context.commit('setMyFeaturedContent', content.map(v => v._id)))
      .catch((er) => { log(er); });
  },

  pinToMyFeaturedContent(context, { userId, itemId, content }) {
    return userApi.pinFeaturedContent(userId, itemId, this.$nodeAxios)
      .then(() => {
        context.commit('addToMyFeaturedContent', itemId);

        // if the user happens to be on their own profile, make sure to add it there
        if (_.get(context.rootState, 'userProfile.user._id') === userId) {
          context.commit('addProfileFeaturedContent', content);
        }

        context.dispatch('addAlertData', { name: 'pin-alert', data: {}, });
        context.dispatch('openAlert', 'pin-alert');
    
        setTimeout(() => {
          context.dispatch('closeAlert', 'pin-alert');
        }, 5000);
      })
      .catch((er) => { log(er); });
  },

  removeMyFeaturedContent(context, { userId, itemId }) {
    return userApi.removeFeaturedContent(userId, itemId, this.$nodeAxios)
    .then(() => {
      context.commit('removeFromMyFeaturedContent', itemId);

      // if the user happens to be on their own profile, make sure to remove it from there
      if (_.get(context.rootState, 'userProfile.user._id') === userId) {
        context.commit('removeProfileFeaturedContent', itemId);
      }
    })
    .catch((er) => { log(er); });
  },

  /**
   * loadMyBadges
   *
   * this retrieves the user's badges/awards progress
   * this data will be used to pop up the earned badge modal when necessary
   */
  loadMyBadges(context, userId) {
    return awardsApi.userAwards(userId, this.$nodeAxios)
      .then((awards) => context.commit('setMyBadges', awards))
      .catch((er) => { log(er); });
  },

  /**
   * updateCommentsBadge
   *
   * after a user Comments, update their progress for the Comments badge
   */
  updateCommentsBadge(context) {
    let badges = [...context.state.myBadges];
    let idx = _.findIndex(badges, badge => badge.award.slug === 'comments');
    if (idx === -1) return
    let badge = badges[idx];
    badge.progress++;
    // if they have now earned a new badge, update respectively and open earned badge modal
    if (badge.progress === badge.earned_at) {
      // this is representative of the badge they are currently on
      let level = _.get(badge, 'tier.level', 0);
      // if already at level 3, just increase multiplier, otherwise move to next tier
      if (level === 3) {
        badge.progress = 0;
        badge.multiplier++;
      } else {
        badge.tier = badge.tiers[level];
        let earned_at;
        // if we were at level 2, now meaning we will have earned badge level 3,
        // also reset progress to 0 to start earning multipliers
        if (level === 2) {
          earned_at = badge.tier.earned_at;
          badge.progress = 0;
        }
        // otherwise get the earned_at from the tier after
        badge.earned_at = earned_at ?? badge.tiers[level + 1].earned_at;
      }
      // pop up earned badge modal
      setTimeout(() => { context.dispatch('openModal', { id: 'badge-earned', args: { badge }}); }, 1000);
    }
    badges[idx] = badge;
    context.commit('setMyBadges', badges)
    // update badge for User Profiles if same user
    if (_.get(context.rootState, 'userProfile.user._id', 'A') === _.get(context.rootState, 'session.user._id', 'B')) {
      context.commit('setProfileAwards', badges);
    }
  }
};

const mutations = {
  /**
   * finishedSavingNewPost
   *
   * after saving a new post, clear the follows list again, to force a new fetch
   */
  finishedSavingNewPost(currentState) {
    Vue.set(currentState, "follows", []);
  },

  /**
   * setIsLoadingFollows
   *
   * set whether the follows are currently being loaded or not
   *
   * @param bool loadingState  if the list is being loaded now
   */
  setIsLoadingFollows(currentState, data) {
    Vue.set(currentState, "isLoadingFollows", { ...currentState.isLoadingFollows, ...data });
  },

  /**
   * setFollows
   *
   * set the list of follows for the current user
   *
   * @param array follows  the list of follows to use
   */
  setFollows(currentState, { follows }) {
    Vue.set(currentState, "follows", follows);
  },

  /**
   * setBlockedUsers
   *
   * set the list of users blocked by the current user
   *
   * @param array follows  the list of follows to use
   */
  setBlockedUsers(currentState, { blockedUsers }) {
    Vue.set(currentState, "blockedUsers", blockedUsers);
  },

  /**
   * userFollows is synced from loading user session
   * @from session.js mutation commit
   */
  setCurrentUser(currentState, userObject) {
    // user follows that aren't empty
    const userFollows = _.clone(_.get(userObject, "settings.follows", [])).map(
      (follow) => ({
        item_id: follow.item_id,
        item_type: follow.item_type,
        auto: follow.auto,
      })
    );
    _.remove(userFollows, (f) => !_.get(f, "item_type", false));
    _.each(userFollows, (itm) => {
      itm.item_type = sanitizeFollowType(itm.item_type);
      itm.initiator = "existing";
    });
    currentState.userFollows = userFollows;
  },
  setLogoutUser(currentState) {
    currentState.userFollows = [];
  },

  /**
   * setAutoFollowAllowed
   *
   * sets the new state of whether the auto follow is allowed on this page or not
   *
   * @param object currentState  the object that contains the current state of the session
   * @param bool value  whether the auto follow is allowed
   */
  setAutoFollowAllowed(currentState, value) {
    Vue.set(currentState, "allowAutoFollow", value);
  },

  /**
   * setAutoFollowTriggered
   *
   * sets the new state of whether the auto follow was triggered or not
   *
   * @param object currentState  the object that contains the current state of the session
   * @param bool value  whether the auto follow was triggered
   */
  setAutoFollowTriggered(currentState, value) {
    Vue.set(currentState, "autoFollowTriggeredAlready", value);
  },

  /**
   * followItem
   *
   * store the follow action for an item
   *
   * @param object currentState  the state of the current handlers
   * @param string itemType  the type of item that needs to be followed
   * @param string itemId  the id of the item that neesd to be followed
   */
  followItem(currentState, { itemType, itemId, initiator }) {
    // sanitize the `insane` follow type for normalizing the store data
    currentState.userFollows.push({
      item_type: sanitizeFollowType(itemType),
      item_id: itemId,
      initiator,
    });
  },

  /**
   * unfollowItem
   *
   * store the unfollow action for an item
   *
   * @param object currentState  the state of the current handlers
   * @param string itemType  the type of item that needs to be followed
   * @param string itemId  the id of the item that neesd to be followed
   */
  unfollowItem(currentState, { itemType, itemId }) {
    const i = _.findIndex(
      currentState.userFollows,
      (follow) =>
        follow.item_type === sanitizeFollowType(itemType) &&
        follow.item_id === itemId
    );
    currentState.userFollows.splice(i, 1);
  },

  /**
   * addFailure
   *
   * add a failure to the list of failures, so we can track which requests failed, mostly for debugging
   *
   * @param object currentState  the state of the current handlers
   * @param object failure  the description of the item that failed
   */
  addFailure(currentState, failure) {
    Vue.set(currentState, "lastFailure", failure);
    Vue.set(
      currentState.failures,
      `${failure.itemType}:${failure.itemId}`,
      failure
    );
  },

  /**
   * addBlockedUser
   *
   * add a user to the list of blocked users for the current user
   *
   * @param string userId the userId of the user to block
   */
  addBlockedUser(currentState, { userId }) {
    currentState.blockedUsers.push(userId);
  },

  /**
   * addBlockedUser
   *
   * add a user to the list of blocked users for the current user
   *
   * @param string userId the userId of the user to block
   */
  removeBlockedUser(currentState, { userId }) {
    const index = _.indexOf(currentState.blockedUsers, userId);
    if (index > -1) {
      currentState.blockedUsers.splice(index, 1);
    }
  },
  
  setTopics(currentState, { topics }) {
    Vue.set(currentState, "topics", topics);
  },

  removeTopic(currentState, { id }) {
    const index = _.findIndex(currentState.topics, topic => topic._id.toString() === id.toString());
    if (index > -1) {
      currentState.topics.splice(index, 1);
    }
  },

  setMyFollowers(currentState, { myFollowers }) {
    Vue.set(currentState, 'myFollowers', myFollowers);
  },

  setMyFeaturedContent(currentState, content) {
    Vue.set(currentState, 'featuredContent', content);
  },

  addToMyFeaturedContent(currentState, contentId) {
    currentState.featuredContent.push(contentId);
  },

  removeFromMyFeaturedContent(currentState, contentId) {
    const index = _.findIndex(currentState.featuredContent, id => id === contentId);
    if (index > -1) {
      currentState.featuredContent.splice(index, 1);
    }
  },

  setMyBadges(currentState, badges) {
    Vue.set(currentState, 'myBadges', badges);
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations,
};
