/* eslint no-param-reassign: 0 */

import _ from 'lodash';
import { FOLLOW_TYPE_USER } from '@/data/enum/follow';
import { genAnalyticsEventDefaultData } from '@/data/helpers';

const state = () => ({
  sessionUser: null,
  profileUser: null,
});

function getUserAttributesData(currentState) {
  return {
    user_slug: currentState.profileUser.username,
    user_display_name: currentState.profileUser.display_name,
    user_id: currentState.sessionUser.id,
  };
}

// default event data generator for about user section
const getAboutUserEventData = genAnalyticsEventDefaultData({
  category: 'profile_feed.about_user',
  context: {
    path: 'profile_feed.about_user',
    view: 'profile_feed',
    section: { name: 'about_user' },
  },
  object: {
    purpose: 'engagement',
    type: 'button',
  },
});
const getProfileEventData = genAnalyticsEventDefaultData({
  category: 'profile_feed',
  action: 'choose',
  context: {
    path: 'profile_feed',
    view: 'profile_feed',
  },
  object: {
    purpose: 'engagement',
    type: 'button',
  },
});


/**
 * analytics events mutations
 */
const mutations = {
  /**
   * Listen for mutation events to dispatch analytics tracking
   */
  triggerProfileEdit(currentState, payload) {
    if (!currentState.profileUser) {
      return;
    }

    const eventData = getAboutUserEventData({
      action: 'choose',
      label: 'edit_profile',
      object: {
        name: 'edit_profile',
        attributes: getUserAttributesData(currentState),
      },
      callback: payload.callback,
    });
    this.$sendAnalyticsEvent(eventData);
  },


  // follow item. check for this user. if so, then send event
  followItem(currentState, itm) {
    // if not following a user, or the user is not this profile's user, then skip
    if (!currentState.profileUser || itm.itemType !== FOLLOW_TYPE_USER || itm.itemId !== currentState.profileUser._id) {
      return;
    }

    // fire event
    const eventData = getAboutUserEventData({
      action: 'follow',
      label: currentState.profileUser.username,
      object: {
        name: 'follow',
        attributes: getUserAttributesData(currentState),
      },
    });
    this.$sendAnalyticsEvent(eventData);
  },
  unfollowItem(currentState, itm) {
    // if not following a user, or the user is not this profile's user, then skip
    if (!currentState.profileUser || itm.itemType !== FOLLOW_TYPE_USER || itm.itemId !== currentState.profileUser._id) {
      return;
    }

    // fire event
    const eventData = getAboutUserEventData({
      action: 'unfollow',
      label: currentState.profileUser.username,
      object: {
        name: 'unfollow',
        attributes: getUserAttributesData(currentState),
      },
    });
    this.$sendAnalyticsEvent(eventData);
  },


  triggerProfileContactLink(currentState, payload) {
    if (!currentState.profileUser) {
      return;
    }

    const eventData = getAboutUserEventData({
      category: 'profile_feed.about_user.contact_bar',
      action: 'choose',
      label: payload.url,
      context: {
        path: 'profile_feed.about_user.contact_bar',
        module: { name: 'contact_bar' },
      },
      object: {
        name: payload.urlName,
        type: 'link',
        attributes: getUserAttributesData(currentState),
      },
      callback: payload.callback,
    });
    this.$sendAnalyticsEvent(eventData);
  },
  triggerProfileSubmitStory(currentState, payload) {
    if (!currentState.profileUser) {
      return;
    }

    const eventData = getProfileEventData({
      category: 'profile_feed.submit_story',
      label: 'Submit a Story',
      context: {
        path: 'profile_feed.submit_story',
        section: { name: 'submit_story' },
      },
      object: {
        name: 'submit_story',
        type: 'button',
        attributes: getUserAttributesData(currentState),
      },
      callback: payload.callback,
    });
    this.$sendAnalyticsEvent(eventData);
  },
  triggerProfilePartnerSpecialty(currentState, payload) {
    if (!currentState.profileUser) {
      return;
    }

    const eventData = getProfileEventData({
      category: 'profile_feed.partner_advocate_conditions',
      label: payload.specialtySlug,
      context: {
        path: 'profile_feed.partner_advocate_conditions',
        section: { name: 'partner_advocate_conditions' },
      },
      object: {
        name: 'topic',
        type: 'link',
        attributes: getUserAttributesData(currentState),
      },
      callback: payload.callback,
    });
    this.$sendAnalyticsEvent(eventData);
  },
  triggerProfilePartnerResource(currentState, payload) {
    if (!currentState.profileUser) {
      return;
    }

    const resourceAttributes = {
      user_id: _.get(currentState, 'sessionUser.id', ''),
      url: payload.resourceUrl,
      label: payload.resourceLabel,
    };
    const eventData = getProfileEventData({
      category: 'profile_feed.partner_resources',
      label: payload.resourceUrl,
      context: {
        path: 'profile_feed.partner_resources',
        section: { name: 'partner_resources' },
      },
      object: {
        name: 'resource_link',
        type: 'link',
        attributes: resourceAttributes,
      },
      callback: payload.callback,
    });
    this.$sendAnalyticsEvent(eventData);
  },

  /**
   * Stores required data for creating the events
   */
  setCurrentUser(currentState, userObject) {
    currentState.sessionUser = userObject;
  },
  setLogoutUser(currentState) {
    currentState.sessionUser = null;
  },
  setProfileDetails(currentState, userProfile) {
    currentState.profileUser = userProfile;
  },
};

export default {
  state,
  getters: {},
  actions: {},
  mutations,
};
