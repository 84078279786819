/* eslint no-param-reassign: 0 */
/* eslint no-underscore-dangle:0 */

/**
 * The Story store
 *
 * Responsible for managing state of:
 *
 * - Story [related] Feed
 * -
 */
import { get } from 'lodash';
import story from '@/data/api/story';
import topics from '@/data/api/topics';
import { log, contentPrimaryCategory } from '@/data/helpers';

// initial state
const state = () => ({
  storyId: null,
  content: null,
  relatedPosts: [],
  primaryTopic: {},
  loading: {},
  storyExpanded: true,
});

// getters
const getters = {
  storyContent: currentState => currentState.content,
  storyHasInitialized: currentState => !!currentState.storyId,
  storyHasLoadedMethod: currentState => method => currentState.loading[method] === currentState.storyId,
  storyTopic: currentState => currentState.primaryTopic,
  storyExpanded: currentState => currentState.storyExpanded,
  storyAds: currentState => currentState.storyAds,
  useUpdatedStoryUI: currentState => get(currentState, 'content.storyUI_update2023', true),
};

// actions
const actions = {
  loadStoryContentBySlug(context, { slug, type }) {
    return story.getStoryContentBySlug(slug, type, this.$nodeAxios).then((data) => {
      if (data) {
        if (data.body) {
          data.body = data.body.replace(/\<img\s/g, '<img loading="lazy" ');
          const baseUrl = process.env.SITE_BASE_URL;
          // All internal links should be opened in same tab
          data.body = data.body.replace(new RegExp(`(href="${baseUrl}.*?")\\starget="_blank"`, 'g'), "$1");
        }
        context.commit('setContent', data);
      }
      return data;
    });
  },
  loadStoryRelatedPosts(context) {
    const storyId = context.state.storyId;
    // have to set storyId
    if (!storyId) {
      log('No story initialized');
      return false;
    }

    // load content api only once
    if (context.getters.storyHasLoadedMethod('relatedPosts')) {
      log('Already initialized relatedPosts for this storyId');
      return false;
    }
    context.commit('setStoryLoadingMethod', { method: 'relatedPosts', storyId });

    return story.contentRelated(storyId, this.$nodeAxios)
      .then((data) => {
        context.commit('setRelatedPosts', data);
      });
  },
  loadStoryContent(context) {
    // have to set storyId
    const storyId = context.state.storyId;
    if (!storyId) {
      log('No story initialized');
      return false;
    }

    // load content api only once
    if (context.getters.storyHasLoadedMethod('content')) {
      log('Already initialized content for this storyId');
      return false;
    }
    context.commit('setStoryLoadingMethod', { method: 'content', storyId });

    return story.content(storyId, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('setContent', data);
        }
      });
  },
  async loadStoryPrimaryTopic(context) {
    // load this only once
    const storyId = context.state.storyId;
    if (context.getters.storyHasLoadedMethod('primaryTopic')) {
      log('Already initialized primaryTopic for this storyId');
      return false;
    }
    context.commit('setStoryLoadingMethod', { method: 'primaryTopic', storyId });

    const content = context.state.content;
    if (!content._id) {
      // content not loaded
      return false;
    }

    const topic = contentPrimaryCategory(context.state.content);
    // voices topic is loaded
    if (topic._id) {
      return context.commit('setPrimaryTopic', topic);
    }

    const contentTopicsData = get(context, 'state.content.topics', []);
    const contentTopic = contentTopicsData.find(_topic => topic.slug === _topic.slug)
    if (contentTopic) {
      context.commit('setPrimaryTopic', contentTopic);
    } else {
      // wp card topic
      const topicBySlug = await topics.topicBySlug(topic.slug, this.$nodeAxios);
      context.commit('setPrimaryTopic', topicBySlug.data);
    }
  },
};

// mutations
const mutations = {
  setStoryAds(currentState, ads) {
    currentState.storyAds = ads
  },

  setStoryId(currentState, storyId) {
    // already set the same story? dont reset
    if (storyId === currentState.storyId) {
      log('Already initialized this storyId');
      return false;
    }

    currentState.storyId = storyId;
    return true;
  },

  setRelatedPosts(currentState, posts) {
    currentState.relatedPosts.push(...posts);
  },

  setContent(currentState, content) {
    currentState.content = content;
  },
  setPrimaryTopic(currentState, topic) {
    currentState.primaryTopic = topic;
  },
  setStoryLoadingMethod(currentState, { method, storyId }) {
    currentState.loading[method] = storyId;
  },
  toggleFullArticle(currentState) {
    currentState.storyExpanded = true;
  },
  resetStoryState(currentState) {
    Object.assign(currentState, state());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
