/* eslint-disable no-param-reassign */
// import Vue from 'vue';
import sourcesApi from '@/data/api/sources';
import { uniq } from 'lodash';

const state = () => ({
  sources: [],
  sourcesList: {},
  locations: [],
  locationsList: {},
  keywords: [],
  keywordsList: {},
});

const getters = {
  getSources: currentState => currentState.sources.map(sourceId => currentState.sourcesList[sourceId]),
  getLocations: currentState => currentState.locations.map(locationId => currentState.locationsList[locationId]),
  getKeywords: currentState => currentState.keywords.map(keywordId => currentState.keywordsList[keywordId]),
};

const actions = {
  loadSourcesList(context, params = {}) {
    return sourcesApi.getSourcesList(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'sources',
            items: data,
            action: params.after ? 'insertAfter' : 'replace',
          });
        }

        return data;
      });
  },
  loadLocationsList(context, params = {}) {
    return sourcesApi.getLocationsList(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'locations',
            items: data,
            action: params.after ? 'insertAfter' : 'replace',
          });
        }

        return data;
      });
  },
  addLocation(context, params) {
    return sourcesApi.addLocation(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'locations',
            items: [data],
            action: 'insertBefore',
          });
        }
      });
  },
  updateLocation(context, params) {
    return sourcesApi.updateLocation(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'locations',
            items: [data],
            action: 'update',
          });
        }
      });
  },
  deleteLocation(context, id) {
    return sourcesApi.deleteLocation(id, this.$nodeAxios)
      .then(() => {
        context.commit('updateList', {
          listName: 'locations',
          items: [{ _id: id }],
          action: 'remove',
        });
      });
  },
  loadKeywordsList(context, params = {}) {
    return sourcesApi.getKeywordsList(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'keywords',
            items: data,
            action: params.after ? 'insertAfter' : 'replace',
          });
        }

        return data;
      });
  },
  addKeyword(context, params) {
    return sourcesApi.addKeyword(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'keywords',
            items: [data],
            action: 'insertBefore',
          });
        }
      });
  },
  updateKeyword(context, params) {
    return sourcesApi.updateKeyword(params, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('updateList', {
            listName: 'keywords',
            items: [data],
            action: 'update',
          });
        }
      });
  },
  deleteKeyword(context, id) {
    return sourcesApi.deleteKeyword(id, this.$nodeAxios)
      .then(() => {
        context.commit('updateList', {
          listName: 'keywords',
          items: [{ _id: id }],
          action: 'remove',
        });
      });
  },
  addSource(context, params) {
    return sourcesApi.addSource(params, this.$nodeAxios).then((data) => {
      if (data) {
        Promise.all(Object.keys(data.sources).map(sourceId => sourcesApi.getSourcesById(sourceId, this.$nodeAxios))).then((sources) => {
          context.commit('updateList', {
            listName: 'sources',
            items: sources,
            action: 'insertBefore',
          });
        });
      }
    });
  },
  deleteSource(context, id) {
    return sourcesApi.deleteSource(id, this.$nodeAxios)
      .then(() => {
        context.commit('updateList', {
          listName: 'sources',
          items: [{ _id: id }],
          action: 'remove',
        });
      });
  },
  addKeywordToSource(context, { sourceId, keywordId }) {
    return sourcesApi.addKeywordToSource(sourceId, keywordId, this.$nodeAxios)
      .then(() => sourcesApi.getSourcesById(sourceId, this.$nodeAxios))
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
  removeKeywordFromSource(context, { sourceId, keywordId }) {
    return sourcesApi.removeKeywordFromSource(sourceId, keywordId, this.$nodeAxios)
      .then(() => sourcesApi.getSourcesById(sourceId, this.$nodeAxios))
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
  addLocationToSource(context, { sourceId, locationId }) {
    return sourcesApi.addLocationToSource(sourceId, locationId, this.$nodeAxios)
      .then(() => sourcesApi.getSourcesById(sourceId, this.$nodeAxios))
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
  removeLocationFromSource(context, { sourceId, locationId }) {
    return sourcesApi.removeLocationFromSource(sourceId, locationId, this.$nodeAxios)
      .then(() => sourcesApi.getSourcesById(sourceId, this.$nodeAxios))
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
  updateSource(context, params) {
    return sourcesApi.updateSource(params, this.$nodeAxios)
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
  addSourceItem(context, params) {
    return sourcesApi.addSourceItem(params, this.$nodeAxios)
      .then(() => sourcesApi.getSourcesById(params.sourceId, this.$nodeAxios))
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
  removeSourceItem(context, params) {
    return sourcesApi.removeSourceItem(params, this.$nodeAxios)
      .then(() => sourcesApi.getSourcesById(params.sourceId, this.$nodeAxios))
      .then((source) => {
        context.commit('updateList', {
          listName: 'sources',
          items: [source],
          action: 'update',
        });
        return source;
      });
  },
};

const mutations = {
  updateList(currentState, { items, listName, action = 'insertAfter' }) {
    if (action === 'insertAfter') {
      items.forEach((item) => {
        currentState[`${listName}List`][item._id] = item;
      });

      currentState[listName] = uniq([...currentState[listName], ...items.map(item => item._id)]);
    } else if (action === 'insertBefore') {
      items.forEach((item) => {
        currentState[`${listName}List`][item._id] = item;
      });

      currentState[listName] = uniq([...items.map(item => item._id), ...currentState[listName]]);
    } else if (action === 'update') {
      items.forEach((item) => {
        currentState[`${listName}List`][item._id] = item;
      });

      currentState[`${listName}List`] = { ...currentState[`${listName}List`] };
    } else if (action === 'remove') {
      items.forEach((item) => {
        delete currentState[`${listName}List`][item._id];
      });

      const idsToRemove = items.map(item => item._id);

      currentState[listName] = currentState[listName].filter(item => !idsToRemove.includes(item));
    } else if (action === 'replace') {
      currentState[`${listName}List`] = items.reduce((data, el) => {
        data[el._id] = el;
        return data;
      }, {});

      currentState[listName] = items.map(item => item._id);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
