/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from "vue";
import _ from "lodash";
import collectionsApi from "@/data/api/collections";
import { log } from "@/data/helpers";

const state = () => ({
  collections: [],
  // using this to keep track of saves/unsaved across session
  savedCollections: {},
  collection: {},
  content: [],
  doneLoadingContent: true,
});

const getters = {
  getCollections: (currentState) => currentState.collections,
  getSavedCollections: (currentState) => currentState.savedCollections,
  getCurrentCollection: (currentState) => currentState.collection,
  getCollectionContent: (currentState) => currentState.content,
  doneLoadingCollectionContent: (currentState) =>
    currentState.doneLoadingContent,
};

const actions = {
  loadTopicCollections(context, { slug, off = 0, pp = 100 }) {
    return collectionsApi
      .collectionsForTopic({ slug, off, pp }, this.$nodeAxios)
      .then((data) => {
        const collections = _.map(data, (collection) => {
          return collection;
        });
        context.commit("setCollections", { collections });
      });
  },

  loadCollectionContent(context, { collectionId, off, pp }) {
    return collectionsApi
      .collectionContent({ collectionId, off, pp }, this.$nodeAxios)
      .then((content) => {
        const done = content.length < pp;
        const append = off > 0;
        context.commit("setCollectionContent", { content, done, append });
      })
      .catch((err) => {
        log("error loading Content for Collection", err.response);
      });
  },
  // its possible we will need to close the modal from outside of the Modal, for example if the user clicks a StoryCard within the modal
  closeCollectionModal(context) {
    context.commit('setCollection', {});
    context.dispatch("closeModals");
  },
  saveCollection(context, collectionId) {
    return collectionsApi
      .saveCollection(collectionId, this.$nodeAxios)
      .then(() => {
        context.commit("setSavedStatus", { collectionId, status: true });
      })
      .catch((err) => {
        log("error saving collection", err.response);
      });
  },

  unsaveCollection(context, collectionId) {
    return collectionsApi
      .unsaveCollection(collectionId, this.$nodeAxios)
      .then(() => {
        context.commit("setSavedStatus", { collectionId, status: false });
      })
      .catch((err) => {
        log("error unsaving collection", err.response);
      });
  },
};

const mutations = {
  setCollections(currentState, { collections }) {
    Vue.set(currentState, "collections", collections);
  },

  setCollection(currentState, collection) {
    Vue.set(currentState, "collection", collection);
  },

  setCollectionContent(currentState, { content, done, append }) {
    if (!append) {
      Vue.set(currentState, "content", content);
    } else {
      Vue.set(currentState, "content", [...currentState.content, ...content]);
    }

    Vue.set(currentState, "doneLoadingContent", done);
  },

  setSavedStatus(currentState, { collectionId, status }) {
    const collections = {...currentState.savedCollections};
    collections[collectionId] = status;
    Vue.set(currentState, "savedCollections", collections);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
