import extend from 'extend';
import _ from 'lodash';

// helper functions
import { log, err } from '../helpers';

function getSearchResults({ tab, search, limit, offset, extra, section } = { type: 'search', search: '', limit: 30, offset: 0, types: [], subTypes: [], extra: {} }, $nodeAxios) {
  const params = extend({ query: search, pp: limit, off: offset, section }, extra);
  return $nodeAxios.get(`/api/v1/search/${tab}/`, { params })
    .then(rawData => rawData.data)
    .catch((e) => {
      err('AJAX ERROR[getOne]:', e);
    });
}

function getDefaultSearchResults({ type, limit, offset }, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/home/defaults?type=${type}&limit=${limit}&offset=${offset}`)
    .then(rawData => rawData.data)
    .catch((e) => {
      err('AJAX ERROR[getOne]:', e);
    });
}

function getLatest({ limit, offset, type }, $nodeAxios) {
  let queryString;
  if (type === 'PHOTOS') {
    queryString = '&filter=photos';
  } else if (_.isEmpty(type)) {
    queryString = '';
  } else if (type === 'STORIES') {
    queryString = '&types[]=WORDPRESS_POST&types=[]=WORDPRESS_VIDEO';
  } else {
    queryString = `&types[]=${type}`;
  }

  return $nodeAxios.get(`/api/v1/home/latest?pp=${limit}&off=${offset}${queryString}`, {})
    .then(response => response.data)
    .catch((e) => {
      err('AJAX ERROR[getOne]:', e);
    });
}

export default {
  getSearchResults,
  getDefaultSearchResults,
  getLatest,
};
