import { log, err } from '@/data/helpers';
import { sanitizeFollowId } from '@/data/follow-helpers';

/**
 * followItem
 *
 * initiate a follow action on an item that is followable
 *
 * @param string itemType  the type of item being followed
 * @param string itemId  the id of the item being followed
 */
function followItem({ itemId }, $nodeAxios) {
  const saneId = sanitizeFollowId(itemId);
  return $nodeAxios.put(`/api/v1/content/subscribe/${saneId}/`)
    .then((response) => {
      log('FOLLOW POST AJAX RESPONSE:', itemId, response);
      return response.data;
    })
    .catch((e) => {
      err('FOLLOW AJAX ERROR:', e);
    });
}

/**
 * unfollowItem
 *
 * stop following an item
 *
 * @param string itemType  the type of item being followed
 * @param string itemId  the id of the item being followed
 */
function unfollowItem({ itemId }, $nodeAxios) {
  const saneId = sanitizeFollowId(itemId);
  return $nodeAxios.delete(`/api/v1/content/subscribe/${saneId}/`)
    .then((response) => {
      log('UNFOLLOW POST AJAX RESPONSE:', itemId, response);
      return response.data;
    })
    .catch((e) => {
      err('UNFOLLOW AJAX ERROR:', e);
    });
}

export default {
  followItem,
  unfollowItem,
};

