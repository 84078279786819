
/**
 * Vuejs DFP Integration
 * - does require gtp.js from google servers
 * - does initialize dfp services
 * - works together with `@/components/Ads/DfpAd.vue`
 */
export default {
  /**
   * Install DoubleClick Plugin
   * @param Vue
   * @param options
   */
  install(Vue, options) {
    this.vm = Vue;
    this.googletag = null;
    this.initDFP(options);
  },

  /**
   * Initialize google tag manager
   * @param options
   */
  initDFP(options) {
    window.googletag = window.googletag || {cmd: []};
    this.vm.prototype.$doubleclick = options;
    if(window.dfp && window.dfp.declaredTargeting) {
      return true
    } else {
      window.googletag.cmd.push(() => this.enableServices(window.googletag));
    }
  },

  enableServices(googletag) {
    if (googletag.tmServicesEnabled) {
      return;
    }

    // set dfp render mode
    googletag.pubads().enableSingleRequest();
    googletag.pubads().enableAsyncRendering();
    googletag.pubads().collapseEmptyDivs();

    // Use lazy loading from GPT, is needed is we want to use the current and the new ad logic
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent: 120, // Fetch slots within 1.2 viewports.
      renderMarginPercent: 105, // Render slots within .5 viewports.
      mobileScaling: 2.0, // Double the above values on mobile.
    });

    // enable dfp services
    googletag.enableServices();

    // eslint-disable-next-line
    googletag.tmServicesEnabled = true;
  },
};
