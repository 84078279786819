import { log, debug, isSet, tmAnalytics } from '@/data/helpers'

export default function ({ $ga }, inject) {

  const sendAnalyticsEvent = (data, types) => {
    types = types || ['ga', 'tm_stats', 'cordial'];

    if (!isSet(data.cordial_event)) {
      data.cordial_event = data.action;
    }

    try {
      if (types.includes('ga')) {
        tmAnalytics({ ...data, ga: $ga });
      } else {
        log(`Unsupported event types: ${types.join(', ')}`)
      }
    } catch (e) {
      log('sendAnalyticsEvent failed', e.message, data, types);
    } finally {
      if (data.callback && !data.callback.handled) {
        data.callback.call();
      }
    }

  };

  inject('sendAnalyticsEvent', sendAnalyticsEvent);
}
