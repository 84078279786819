/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import extend from 'extend';
import _ from 'lodash';
import { log, isSet, isArray, isObject, isFunction } from '@/data/helpers';
import searchApi from '@/data/api/search';

// initial state
const state = () => ({
  topics: {
    results: [],
    defaults: [],
    doneLoading: false,
    doneLoadingDefaults: false,
    total: 0,
  },
  posts: {
    results: [],
    defaults: [],
    doneLoading: false,
    doneLoadingDefaults: false,
    totals: {
      post: 0,
      question: 0,
      thought: 0,
      _total: 0,
    },
  },
  groups: {
    results: [],
    defaults: [],
    doneLoading: false,
    doneLoadingDefaults: false,
    total: 0,
  },
  users: {
    results: [],
    defaults: [],
    doneLoading: false,
    doneLoadingDefaults: false,
    total: 0,
  },
  stories: {
    results: [],
    defaults: [],
    doneLoading: false,
    doneLoadingDefaults: false,
    total: 0,
  },
});

// getters for states
const getters = {
  // get the tab list
  getAllSearchTabs: currentState => currentState.tabs,

  // get results by the tab specified
  getSearchResultsByTab: currentState => tab => currentState[tab].results,
  doneLoadingSearchResultsByTab: currentState => tab => currentState[tab].doneLoading,

  // get defaults by the tab specified
  getSearchDefaultsByTab: currentState => tab => currentState[tab].defaults,
  doneLoadingSearchDefaultsByTab: currentState => tab => currentState[tab].doneLoadingDefaults,

  // get the total available results for the tab
  getSearchResultsTotalByTab: currentState => tab => currentState[tab].total,
};

const actions = {

  async loadInitialDefaults(context) {
    const tabs = _.keys(context.state);
    const promises = [];
    _.forEach(tabs, tab => { promises.push(context.dispatch('loadSearchResults', { tab, search: '', offset: 0, limit: 10 })); });
    await Promise.all(promises);
  },

  loadSearchResults(context, { tab, search, limit, offset }) {
    // keeping search defaults separate so we only have to call for them once
    const mutation = _.isEmpty(search) ? 'setSearchDefaults' : 'setSearchResults';

    return searchApi.getSearchResults({ tab, search, limit, offset }, this.$nodeAxios)
      .then((response) => {
          const results = _.map(_.get(response, 'data.hits', []), result => result._source);
          context.commit(mutation, { tab, results, done: results.length < limit, append: offset > 0 });
      });
  },
};

// mutate the data in the states
const mutations = {
  updateTabTotals(currentState, { totals }) {
    Object.keys(totals).forEach((tab) => {
      const current = currentState.tabs[tab];
      // eslint-disable-next-line no-underscore-dangle
      current.total = isObject(totals[tab]) ? totals[tab]._total : totals[tab];
      current.typeTotals = isObject(totals[tab]) ? totals[tab] : {};
      Vue.set(currentState.tabs, tab, current);
    });
  },

  clearResults(currentState, { tab }) {
    const current = currentState[tab];
    current.results = [];
    Vue.set(currentState[tab], current);
  },

  appendTabSearchResults(currentState, { tab, results, done }) {
    if (currentState.tabs[tab]) {
      const current = currentState.tabs[tab];
      results.forEach(c => current.results.push(c));
      current.doneLoading = done;
      Vue.set(currentState.tabs, tab, current);
    }
  },  
  
  setSearchDefaults(currentState, { tab, results, done, append }) {
    const latest = append ? [...currentState[tab].defaults, ...results] : results;
    currentState[tab].defaults = [...latest];
    currentState[tab].doneLoadingDefaults = done;
  },

  setSearchResults(currentState, { tab, results, done, append }) {
    const latest = append ? [...currentState[tab].results, ...results] : results;
    currentState[tab].results = [...latest];
    currentState[tab].doneLoading = done;
  },

  setContentTotals(currentState, { totals }) {
    Vue.set(currentState.posts, 'totals', totals);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
