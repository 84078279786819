
import { mapGetters } from 'vuex';
import { staticPath } from '@/data/helpers';

export default {
  name: 'MenuDropdownMobile',
  props: ['closeMenu'],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getMyNotifications: 'getMyNotifications',
    }),
  },
  mounted() {
    // i have no idea why this is here, I'm afraid to touch it
    this.closeMenu(false);
  },
  methods: {
    staticPath,
    // only for sprint because sprint is using a SPA feature
    clearWindowSessionSkip($event, navigate) {
      window.localStorage.removeItem('skippedAcceptPrivacyPolicy');
      navigate($event);
      this.closeMenu(true);
    },
    exploreHandler(navigate) {
      navigate();
      this.closeMenu(true);
    },
    openRegistration(action) {
      this.$store.dispatch('openModal', {
        id: 'login-registration-onboarding',
        args: { action },
      });
    },
  },
};
