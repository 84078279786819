import Vue from 'vue';

// initial state
const state = () => ({
  // current active 'tab' on feeds
  feedActiveTabs: {
  },
});

// getters for states
const getters = {
  /**
   * getActiveFeedTab
   *
   * gets the active tab from a feed
   *
   * @param object currentState  the current state of this handle
   * @return string  the string anchor of the active tab
   */
  getActiveFeedTab: currentState => name => currentState.feedActiveTabs[name],

  /**
   * getActiveFeedTabList
   *
   * get all the active feed tabs indexed by feed name
   *
   * @param object currentState  the current state of this handle
   */
  getActiveFeedTabList: currentState => currentState.feedActiveTabs,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  /**
   * updateActiveTab
   *
   * update the active tab of a feed
   *
   * @param object context  the context of the current handler
   * @param string feedName  the name of the feed to update the active tab for
   * @param string anchor  the anchor of the active tab
   */
  updateActiveTab(context, { feedName, anchor }) {
    context.commit('setFeedActiveTab', { feedName, anchor });
  },
};

// mutate the data in the states
const mutations = {
  /**
   * setFeedActiveTab
   *
   * sets the active tab of a feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to update the tab of
   * @param string anchor  anchor of the tab
   */
  setFeedActiveTab(currentState, { feedName, anchor }) {
    Vue.set(currentState.feedActiveTabs, feedName, anchor);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
