
import _ from 'lodash';
import { mapGetters } from 'vuex';
import segmentAnalytics from '@/libs/events';
import getCurrentViewName from '@/libs/view-name';
import Notifications from '../Notifications/index';
import PostButton from '../NewPostButton/index';

export default {
    name: 'WebNav',
    components: { Notifications, PostButton },
    data() {
        return {
            hovered: null,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            unreadMessageCount: 'streamClient/unreadMessageCount',
            chatRequestsCount: 'streamClient/chatRequestsCount'
        }),
        hangoutsPrototypeEnabled() {
            return Boolean(localStorage.getItem('hangoutsPrototypeEnabled')) === true;
        },
        directMessagingEnabled() {
            return _.get(this.user, 'settings.direct_messaging_enabled', false);
        },
    },
    methods: {
        handleHover(value, setting) {
            if (setting === 'in') {
                this.hovered = value;
            } else if (value === this.hovered) {
                this.hovered = null;
            }
        },
        hoveredState(value, isActive) {
            if (this.hovered === value) {
                return 'hovered';
            }
            if (this.hovered) {
                return 'non-hovered';
            }
            // only return active state if no live hovering
            return isActive ? 'active' : '';
        },
        onNavItemClick(navigate, $event) {
            if (!this.user._id) {
                $event.preventDefault();
                this.$store.dispatch('notLoggedInAction');
            } else {
                segmentAnalytics.choose({
                    context: {
                        section: 'NavBar',
                        view: getCurrentViewName(),
                    },
                    target: {
                        type: 'icon',
                    },
                    userId: this.user._id,
                    sessionId: this.user.sessionId,
                });
                navigate($event);
            }
        },
    },
}
