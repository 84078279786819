import Vuex from "vuex";

import session from "./session";
import post from "./post";
import user from "./user";
import userProfile from "./user-profile";
import commentData from "./comments";
import formStates from "./forms";
import modalStates from "./modals";
import voicesStates from "./voices";
import windowStates from "./window";
import homeFeedStates from "./home-feed";
import homeNLMFeedStates from "./home-nlm-feed";
import moderationFeedStates from "./moderation";
import feedsStates from "./feeds";
import actionMenuStates from "./action-menu";
import hashtagStates from "./hashtags";
import searchStates from "./search";
import imageUpload from "./upload";
import story from "./story";
import profileAnalytics from "./analytics/profile";
import cardsAnalytics from "./analytics/cards";
import voiceEvents from "./analytics/events";
import postCreationEvents from "./analytics/post-creation";
import topicFeedStates from "./topic-feed";
import groupFeedStates from "./group-feed";
import chatState from "./chat";
import collectionsState from "./collections";
import resourcesState from "./resources";
import sourcesState from "./sources";
import communitiesState from "./communities";
import notificationsState from "./notifications";
import bookmarksState from "./bookmarks";
import groupNavigationState from "./group-navigation";
import streamClient from "./streamClient";
import ads from "./ads";
import dashboard from "./dashboard";

let plugins = [];

if (process.browser && process.env.NODE_ENV === "development") {
  plugins.push(Vuex.createLogger());
}

export default () =>
  new Vuex.Store({
    debug: true,
    modules: {
      session,
      homeFeedStates,
      homeNLMFeedStates,
      moderationFeedStates,
      post,
      user,
      story,
      userProfile,
      commentData,
      modalStates,
      voicesStates,
      windowStates,
      feedsStates,
      formStates,
      profileAnalytics,
      cardsAnalytics,
      actionMenuStates,
      topicFeedStates,
      groupFeedStates,
      chatState,
      collectionsState,
      voiceEvents,
      postCreationEvents,
      resourcesState,
      hashtagStates,
      searchStates,
      imageUpload,
      communitiesState,
      notificationsState,
      sourcesState,
      bookmarksState,
      groupNavigationState,
      streamClient,
      ads,
      dashboard,
    },
    plugins,
  });
