import axios from 'axios';
import _ from 'lodash';

const url = process.env.AMPLIFY_URL;
const config = {
  headers: {
    "Content-Type": "application/json",
    "X-API-Key": process.env.AMPLIFY_KEY,
  },
};

async function getButtonMessages() {
    let messages = [];
    await axios({ method: "post", url, data: {
          query: `
            query ListJoinUsPrompts {
              listJoinUsPrompts {
                items {
                  id
                  text
                }
              }
            }
            `
        },
        ...config,
      }).then((res) => {
        messages = _.get(res, 'data.data.listJoinUsPrompts.items', []);
      });
      return messages;
}

async function getConditionsList() {
    let conditions = [];
    await axios({ method: "post", url, data: {
          query: `
            query ListConditionFilters {
                listConditionFilters {
                items {
                  id
                  name
                  order
                }
              }
            }
            `
        },
        ...config,
      }).then((res) => {
        conditions = _.get(res, 'data.data.listConditionFilters.items', []);
      });
      return conditions;
}

async function getCollections(ids) {
    let collections = [];
    await axios({ method: "post", url, data: {
          query: `
            query ListCollections {
                listCollections(filter: {
                    or: [${_.map(ids, id => `{ conditionId: { eq: \"${id}\"}}`)}]
                }) {
                items {
                    id
                    mongoId
                    title
                    description
                    imageSrc
                    conditionId
                    slug
                    order
                }
              }
            }
            `
        },
        ...config,
      }).then((res) => {
        collections = _.get(res, 'data.data.listCollections.items', []);
      });
      return collections;
}

async function getFeaturedTopics() {
    let topics = [];
    await axios({ method: "post", url, data: {
          query: `
            query ListHomepageTopics {
                listHomepageTopics {
                items {
                    id
                    name
                    slug
                    mongoId
                    order
                }
              }
            }
            `
        },
        ...config,
      }).then((res) => {
          topics = _.get(res, 'data.data.listHomepageTopics.items', []);
      });
      return topics;
}

async function getModuleSettings() {
  let settings = {};
  await axios({ method: "post", url, data: {
        query: `
          query ListDisplayFeaturedModules(
            $filter: ModelDisplayFeaturedModuleFilterInput
            $limit: Int
            $nextToken: String
            ) {
            listDisplayFeaturedModules(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                id
                enabled
                currentModuleId
                createdAt
                updatedAt
                current {
                    id
                    background_color
                    banner_text
                    img_src
                    subtext_label
                    title
                    body
                    cta_text
                    cta_link
                    createdAt
                    updatedAt
                }
                }
                nextToken
            }
            }
          `
      },
      ...config,
    }).then((res) => {
        const items = _.get(res, 'data.data.listDisplayFeaturedModules.items', []);
        if (items.length > 0) {
          settings = items[0];
        }
    });
    return settings;
}

export {
    getButtonMessages,
    getConditionsList,
    getCollections,
    getFeaturedTopics,
    getModuleSettings,
}
