
import Vue from 'vue';
import _ from 'lodash';
import { chatSavedMessages, addSavedMessage, removeSavedMessage } from '@/data/api/chat';
import { log } from '@/data/helpers';


const state = () => ({
  savedMessages: [],
});

const getters = {
  getSavedMessages: currentState => currentState.savedMessages,
};

const actions = {
  loadSavedMessages(context, { messages, append = false }) {
    return chatSavedMessages(messages, this.$nodeAxios)
        .then((savedMessages) => {
          if (savedMessages) {
            if (append) {
              savedMessages.forEach((message) => {
                context.commit('appendToSavedMessages', message.id);
              });
            } else {
              context.commit('setSavedMessages', savedMessages);
            }
          }
        })
        .catch((err) => {
          log('Error getting list of saved messages', err);
        });
  },
  addSavedMessage(context, { messageId }) {
    return addSavedMessage(messageId, this.$nodeAxios)
        .then(() => {
          context.commit('appendToSavedMessages', messageId);
        })
        .catch((err) => {
          log('Error saving message', err);
        });
  },
  removeSavedMessage(context, { messageId }) {
    return removeSavedMessage(messageId, this.$nodeAxios)
        .then(() => {
          context.commit('removeFromSavedMessages', messageId);
        })
        .catch((err) => {
          log('Error deleting saved message', err);
        });
  },
};

const mutations = {
  setSavedMessages(currentState, savedMessages) {
    Vue.set(currentState, 'savedMessages', savedMessages.map(message => message.id));
  },
  appendToSavedMessages(currentState, messageId) {
    if (_.indexOf(currentState.savedMessages, messageId) === -1) {
      currentState.savedMessages.push(messageId);
    }
  },
  removeFromSavedMessages(currentState, messageId) {
    const index = _.indexOf(currentState.savedMessages, messageId);
    if (index > -1) {
      currentState.savedMessages.splice(index, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
