/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';
import bookmarksApi from '@/data/api/bookmarks';
import homeFeedApi from '@/data/api/home-feed';
import { log } from '@/data/helpers';

const state = () => ({
  savedContent: [],
  savedContentTotal: 0,
  doneLoadingSavedContent: false,
});

const getters = {
  getSavedContent: currentState => currentState.savedContent,
  getSavedContentTotal: currentState => currentState.savedContentTotal,
  getDoneLoadingSavedContent: currentState => currentState.doneLoadingSavedContent,
};

const actions = {
  loadSavedContent(context, { groupId, limit, initiatorType, contentType, before }) {
    // assuming an array with multiple items means grab all types, which is done by leaving contentType blank
    // if (Array.isArray(contentType) && contentType.length > 1) {
    //   contentType = '';
    // }

    const obj = {
      $nodeAxios: this.$nodeAxios,
      groupId,
      limit,
      initiatorType,
      contentType,
    };

    if (before) {
      obj.before = before;
    }

    return bookmarksApi.loadSavedContent(obj)
      .then((data) => {
        const append = !_.isEmpty(before);
        const done = data.items.length < limit;
        context.commit('setSavedContent', { content: data.items, append, done, total: data.query.total });
      })
      .catch((err) => {
        log('Error getting saved content for group:', err);
      });
  },

  unsaveGroupContent(context, { groupId, contentId }) {
    return bookmarksApi.unsaveGroupContent(groupId, contentId, this.$nodeAxios)
      .then(data => context.commit('removeSavedContent', { contentId }))
      .catch((err) => {
        log('Error getting unsaving content from group', err);
      });
  },

  unsaveProfileContent(context, { contentId }) {
    return bookmarksApi.unsaveProfileContent(contentId, this.$nodeAxios)
      .then(data => context.commit('removeSavedContent', { contentId }))
      .catch((err) => {
        log('Error getting unsaving content from profile', err);
      });
  },

  unsaveProfileAffirmation(context, { affirmationId }) {
    return homeFeedApi.unsaveAffirmation(affirmationId, this.$nodeAxios)
      .then(() => context.commit('removeSavedContent', { affirmationId }))
      .catch((err) => {
        log('Error unsaving affirmation from profile', err);
      });
  },

  removeExternalResource(context, { contentId, bookmarkId }) {
    context.commit('removeSavedContent', { contentId });
    return bookmarksApi.removeExternalResource(bookmarkId, this.$nodeAxios)
      .then()
      .catch((err) => {
        log('Error getting removing external resource from group', err);
      });
  },
};

const mutations = {
  setSavedContent(currentState, { content, append, done, total }) {
    if (!append) {
      Vue.set(currentState, 'savedContent', content);
    } else {
      Vue.set(currentState, 'savedContent', currentState.savedContent.concat(content));
    }
    Vue.set(currentState, 'savedContentTotal', total);
    Vue.set(currentState, 'doneLoadingSavedContent', done);
  },

  removeSavedContent(currentState, { contentId, affirmationId = null }) {
    let index;
    if (affirmationId) {
      index = _.findIndex(currentState.savedContent, (item) => item.affirmation_id === affirmationId);
    } else {
      index = _.findIndex(currentState.savedContent, (item) => item.content_id === contentId);
    }
    if (index > -1) {
      currentState.savedContent.splice(index, 1);
    }
  },
};


// export this module
export default {
    state,
    getters,
    actions,
    mutations,
  };
