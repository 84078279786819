import { log } from '../helpers';

/**
 * Adds a heart to post on the current user session
 * @param  int   post_id [description]
 * @return {[type]}        [description]
 */
function addPostHeart(postId, postType, reaction_type, $nodeAxios) {
  return $nodeAxios.put('/api/v1/heart/', { object_id: postId, content_type: postType, reaction_type })
          .then(response => response.data)
          .catch((error) => {
            log('addPostHeart', error);
            return Promise.reject(error);
          });
}

/**
 * Removes the heart from post on the current user session
 * @param  int   post_id [description]
 * @return {[type]}        [description]
 */
function removePostHeart(postId, postType, $nodeAxios) {
  return $nodeAxios.delete('/api/v1/heart/', { params: { object_id: postId, content_type: postType } })
            .then(response => response.data)
            .catch((error) => {
              log('addPostHeart', error);
              return Promise.reject(error);
            });
}

/**
 * Bookmarks a post to the user profile
 * @param  int   contentId [description]
 * @return {[type]}        [description]
 */
function addProfileBookmark(contentId, $nodeAxios) {
  return $nodeAxios.post(`/api/v1/bookmarks/add/${contentId}`, {})
          .then(response => response.data)
          .catch((error) => {
            log('addProfileBookmark', error);
            return Promise.reject(error);
          });
}

/**
 * Removes a Bookmark of a post from the user profile
 * @param  int   contentId [description]
 * @return {[type]}        [description]
 */
function removeProfileBookmark(contentId, $nodeAxios) {
  return $nodeAxios.delete(`/api/v1/bookmarks/remove/${contentId}`, {})
          .then(response => response.data)
          .catch((error) => {
            log('removeProfileBookmark', error);
            return Promise.reject(error);
          });
}

/**
 * Sets bookmarks for a post to list of groups
 * @param  int   post_id [description]
 * @return {[type]}        [description]
 */
function setCommunityBookmarks(contentId, groups, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/bookmarks/groups/set/${contentId}`, { group_ids: groups })
          .then(response => response.data)
          .catch((error) => {
            log('setCommunityBookmarks', error);
            return Promise.reject(error);
          });
}

/**
 * Submit vote on Poll content
 * @param  int   pollId
 * @param  int   responseId
 * @return {[type]}        [description]
 */
function submitVote(pollId, responses, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/content/poll/vote/${pollId}`, { responses })
          .then(response => {
            return response.data
          })
          .catch((error) => {
            log('submitVote', error);
            return Promise.reject(error);
          });
}

/**
 * Submit vote on Poll content
 */
function uncommentedPosts($nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/uncommented?pp=20&off=0`, {})
          .then(response => {
            return response.data
          })
          .catch((error) => {
            log('submitVote', error);
            return Promise.reject(error);
          });
}

/**
 * Returns whether a user can create a Poll or not
 * @param  int   pollId
 * @param  int   responseId
 * @return {[type]}        [description]
 */
function canCreatePoll($nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/poll/limit`, {})
    .then(response => response.data)
    .catch((error) => {
      log('canCreatePoll', error);
      return Promise.reject(error);
  });
}

export default {
  addPostHeart,
  removePostHeart,
  addProfileBookmark,
  removeProfileBookmark,
  setCommunityBookmarks,
  submitVote,
  canCreatePoll,
  uncommentedPosts,
};
