export default async function ({ $refreshToken, $authorizeUser, $cookies, $checkPageViews, store }) {
  const cookies = $cookies.getAll();
  const wpLoggedInCookie = Object.keys(cookies).find(cookieName => cookieName.startsWith('wordpress_logged_in_'));
  if (!wpLoggedInCookie) {
    await $checkPageViews();
    if (process.server) {
      // the purpose of this is to run the functionality that retrieves/creates a sessionId for each user
      await store.commit('setCurrentUser', { settings: {} });
    }
    return;
  }

  let token = $cookies.get('token');

  try {
    if (!token) {
      await $refreshToken();
    }

    if (process.server) {
      await $authorizeUser();
    }
  } catch(err) {
    console.log(err);
  }
}
