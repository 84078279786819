/* eslint no-console:0 */
import Vue from "vue";
import _ from "lodash";
import { log, isString, isFunction } from "@/data/helpers";

// initial state
const state = () => ({
  // which alerts are currently visible
  alertStates: {},

  // container for the alert data for each alert
  alertData: {},

  // the id of the modal that is currently open
  currentlyOpenModal: [],

  // the extra args for the currently open modal
  openModalExtraArgs: [],

  currentContextView: "",

  // trigger for opening the Cookies Preference Center anywhere globally
  openCookiesPreferenceCenter: false,
});

// getters for states
const getters = {
  /**
   * getAlertState
   *
   * returns the state of the specified alert box
   *
   * @param string name  the name of the alert
   * @return boolean  is the alert currently in a shown state?
   */
  getAlertState: (currentState) =>
    function privGetAlertState(name) {
      return isString(name) ? currentState.alertStates[name] : false;
    },

  /**
   * getAlertData
   *
   * returns the data of the specified alert box
   *
   * @param string name  the name of the alert
   * @return mixed  the data stored for this alert box
   */
  getAlertData: (currentState) =>
    function privGetAlertData(name) {
      return isString(name) ? currentState.alertData[name] : null;
    },

  /**
   * getOpenModal
   *
   * gets the currently open modal id
   *
   * @param object currentState  the current state of this handle
   * @return string  the id of the currently open modal
   */
  getOpenModal: (currentState) => _.last(currentState.currentlyOpenModal),

  /**
   * getOpenModalExtraArgs
   *
   * gets the currently open modal extra args, if any
   *
   * @param object currentState  the current state of this handle
   * @return string  the id of the currently open modal
   */
  getOpenModalExtraArgs: (currentState) =>
    _.last(currentState.openModalExtraArgs),

  isModalOpen: (ctxState, ctxGetters) => (modalName) =>
    ctxGetters.getOpenModal === modalName,

  getContextView: (currentState) => currentState.currentContextView,

  openCookiesPreferenceCenter: (currentState) => currentState.openCookiesPreferenceCenter,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  /**
   * openAlert
   *
   * opens a given alert, at least sets the state to open
   *
   * @param object context  the current context of this state manager
   * @param string name  the name of the alert to open
   */
  openAlert(context, name) {
    context.commit("setAlertState", { name, open: true });
  },

  /**
   * closeAlert
   *
   * closes a given alert, or at least sets the state to closed
   *
   * @param object context  the current context of this state manager
   * @param string name  the name of the alert to open
   */
  closeAlert(context, name) {
    context.commit("setAlertState", { name, open: false });
  },

  /**
   * addAlertData
   *
   * stores data that is used by an alert box, indexed by the name of the alert. each alert can only have one set of stored data
   *
   * @param object context  the current context of this state manager
   * @param string name  the name of the alert to open
   * @param mixed data  the data to store for this alert
   */
  addAlertData(context, { name, data }) {
    context.commit("setAlertData", { name, data });
  },

  /**
   * openModal
   *
   * open a specific modal
   *
   * @param object context  the context of the current handler
   * @param string id  id of the modal to open
   * @param object args  the args to send to the newly open modal
   */
  openModal(context, { id, args }) {
    context.commit("setOpenModal", id || "");
    context.commit("setOpenModalExtraArgs", args || {});
  },

  /**
   * addModal
   *
   * add a modal for subsequent opening
   *
   * @param object context  the context of the current handler
   * @param string id  id of the modal to open
   * @param object args  the args to send to the newly open modal
   */
  addModal(context, { id, args }) {
    context.commit("setSubsequentModal", id || "");
    context.commit("setSubsequentModalExtraArgs", args || {});
  },

  /**
   * closeModal
   *
   * close the latest modal
   *
   * @param object context  the context of the current handler
   */
  closeModal(context, { callback } = { callback: undefined }) {
    context.commit("closeLastModal");
    if (isFunction(callback)) {
      callback();
    }
  },

  /**
   * closeModals
   *
   * close all modals
   *
   * @param object context  the context of the current handler
   */
  closeModals(context, { callback } = { callback: undefined }) {
    context.commit("closeAllModals");
    if (isFunction(callback)) {
      callback();
    }
  },

  setContextView(context, contextViewName) {
    context.commit("setContextViewData", contextViewName);
  },
};

// mutate the data in the states
const mutations = {
  /**
   * setAlertState
   *
   * sets the state of the given alert
   *
   * @param object currentState  the current state of this handle
   * @param string name  the name of the alert to update the state of
   * @param boolean open  is it supposed to be open right now?
   */
  setAlertState(currentState, { name, open }) {
    Vue.set(currentState.alertStates, name, open);
  },

  /**
   * setAlertData
   *
   * sets the data of the given alert
   *
   * @param object currentState  the current state of this handle
   * @param string name  the name of the alert to update the state of
   * @param mixed data  the data to store for this alert
   */
  setAlertData(currentState, { name, data }) {
    Vue.set(currentState.alertData, name, data);
  },

  /**
   * setOpenModal
   *
   * sets the currently open modal id
   *
   * @param object currentState  the current state of this handle
   */
  setOpenModal(currentState, modalId) {
    currentState.currentlyOpenModal.push(modalId || "");
  },

  /**
   * setOpenModal
   *
   * sets the currently open modal args
   *
   * @param object currentState  the current state of this handle
   */
  setOpenModalExtraArgs(currentState, args) {
    currentState.openModalExtraArgs.push(args || {});
  },

  /**
   * setSubsequentModal
   *
   * sets the modal to open when the current one is done
   * main use for this is whn we need to have the Content Gate open up
   * after the Registration screen has close
   *
   * @param object currentState  the current state of this handle
   */
  setSubsequentModal(currentState, modalId) {
    currentState.currentlyOpenModal.unshift(modalId || "");
  },

    /**
   * setSubsequentModalExtraArgs
   *
   * sets the args for any subsequent modal
   *
   * @param object currentState  the current state of this handle
   */
  setSubsequentModalExtraArgs(currentState, args) {
    currentState.openModalExtraArgs.push(args || {});
  },


  /**
   * closeLastModal
   *
   * closes the most recently openned modal
   *
   * @param object currentState  the current state of this handle
   */
  closeLastModal(currentState) {
    currentState.currentlyOpenModal.pop();
    currentState.openModalExtraArgs.pop();
    setTimeout(() => {
      // window.tm_update_menu_tab_indexes(); TODO
    }, 0);
  },

  /**
   * closeAllModals
   *
   * sets the currently open modal to '', forcing all modals to close
   *
   * @param object currentState  the current state of this handle
   */
  closeAllModals(currentState) {
    Vue.set(currentState, "currentlyOpenModal", []);
    Vue.set(currentState, "openModalExtraArgs", []);
    if (process.browser) {
      if (window.location.pathname.includes('/content')) {
        const url = `${window.location.pathname.split('content/')[0]}`;
        this.app.router.push(url);
      } else if (window.location.pathname.includes('/poll')) {
        const url = `${window.location.pathname.split('poll/')[0]}`;
        this.app.router.push(url);
      } else if (window.location.pathname.includes("/collection")) {
        // Maintain the same query parameters when closing a collection
        const url = `${window.location.pathname.split("collection/")[0]}${window.location.search}`;
        this.app.router.push(url);
      }
    }
  },
  setContextViewData(currentState, contextViewName) {
    Vue.set(currentState, "currentContextView", contextViewName);
  },
  setCookiesPreferenceCenter(currentState, bool) {
    Vue.set(currentState, 'openCookiesPreferenceCenter', bool);
  }
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
