/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';
import followsApi from '@/data/api/follow';
import communitiesApi from '@/data/api/communities';
import { log } from '@/data/helpers';
import followApi from '@/data/api/follow';
import groupApi from '@/data/api/groups';

const state = () => ({
    followedCommunities: [],
    doneLoadingFollowedCommunities: false,
    createdCommunities: [],
    defaultCommunities: [],
});

const getters = {
    getMyFollowedCommunities: currentState => currentState.followedCommunities,
    getMyCreatedCommunities: currentState => currentState.createdCommunities,
    doneLoadingFollowedCommunities: currentState => currentState.doneLoadingFollowedCommunities,
    getDefaultCommunities: currentState => currentState.defaultCommunities,
};

const actions = {
    // the route for getting a user_follows for some reason returns all of that users groups as well, no matter the filter, if the offset is 0
    loadFollowedCommunities(context, { username, pp = 100, off = 0 }) {
        return followsApi.loadFollows({ username, pp, off, followType: 'category', $nodeAxios: this.$nodeAxios })
            .then((follows) => {
                let topics = follows;
                if (off === 0) {
                    topics = _.filter(follows, follow => follow._type === 'category');
                }
                // if the length of follows is less than asked for than done loading
                const done = topics.length < pp;
                context.commit('setFollowedCommunities', { follows, done });
            })
            .catch((err) => {
                log('Error retrieving the followed communities:', err);
            });
    },

    loadCreatedCommunities(context) {
        return communitiesApi.loadCreatedCommunities(this.$nodeAxios)
            .then(communities => {
                communities.reverse();
                context.commit('setCreatedCommunities', { communities });
            })
            .catch((err) => {
                log('Error retrieving the users created communities:', err);
            });
    },

    loadDefaultCommunities(context) {
        return communitiesApi.loadFeaturedCommunities(this.$nodeAxios)
            .then(communities => {
                context.commit('setDefaultCommunities', { communities });
            })
            .catch((err) => {
                log('Error retrieving the default communities:', err);
            });
    },

    unfollowFollowedTopic(context, { itemId }) {
        return followApi.unfollowItem({ itemType: 'CATEGORY', itemId }, this.$nodeAxios)
            .then(() => {
                context.commit('removeFollowedItem', { itemId })
            })
            .catch((err) => {
                log('Error unfollowing topic', err);
            });
    },

    leaveFollowedGroup(context, group) {
        return groupApi.leaveGroup(group.slug, this.$nodeAxios)
            .then(() => {
                context.commit('removeFollowedItem', { itemId: group._id })
            })
            .catch((err) => {
                log('Error leaving group', err);
            });
    },

};

const mutations = {
    setFollowedCommunities(currentState, { follows, done }) {
        Vue.set(currentState, 'followedCommunities', currentState.followedCommunities.concat(follows));
        Vue.set(currentState, 'doneLoadingFollowedCommunities', done);
    },

    setCreatedCommunities(currentState, { communities }) {
        Vue.set(currentState, 'createdCommunities', communities);
    },

    setDefaultCommunities(currentState, { communities }) {
        Vue.set(currentState, 'defaultCommunities', communities);
    },

    removeFollowedItem(currentState, { itemId }) {
        const index = _.findIndex(currentState.followedCommunities, item => item._id === itemId);
        if (index > -1) {
            currentState.followedCommunities.splice(index, 1);
        }
    },
}

// export this module
export default {
    state,
    getters,
    actions,
    mutations,
  };
