
import { staticPath } from '@/data/helpers';

export default {
  name: 'Title',
  props: ['name', 'width', 'height'],
  data() {
    return {
    };
  },
  methods: {
    staticPath,
  },
};
