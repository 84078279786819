import Vue from 'vue';

// initial state
const state = () => ({
  // is this a large window or a small window (usually indicates device too)
  largeScreen: false,

  // is the screen big enough for the new tnq image modal?
  postWithImageScreen: false,

  // current screen dimensions
  dimensions: {
    width: 0,
    height: 0,
  },
});

// getters for states
const getters = {
  /**
   * isLargeScreen
   *
   * returns whether this is a large screen or not
   *
   * @param object currentState  the current state of this handle
   * @return bool  if this is a large screen or not
   */
  isLargeScreen: currentState => currentState.largeScreen,

  /**
   * isPostWithImageScreen
   *
   * returns whether this is a post-with-image modal screen or not
   *
   * @param object currentState  the current state of this handle
   * @return bool  if this is a post-with-image screen or not
   */
  isPostWithImageScreen: currentState => currentState.postWithImageScreen,

  /**
   * getWindowDimensions
   *
   * returns the dimensions object
   *
   * @param object currentState  the current state of this handler
   * @return object  the object that describes the current size of the window
   */
  getWindowDimensions: currentState => currentState.dimensions,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
};

// mutate the data in the states
const mutations = {
  /**
   * setWindowDimensions
   *
   * sets the current window dimensions
   *
   * @param object currentState  the current state of this handle
   * @param object dims  the object that describes the current dimensions of the screen
   */
  setWindowDimensions(currentState, dims) {
    Vue.set(currentState, 'dimensions', dims || { width: 0, height: 0 });
  },

  /**
   * setLargeScreen
   *
   * sets the current value for largeScreen. logic for this is handled elsewhere. this is just a data warehouse
   *
   * @param object currentState  the current state of this handle
   * @param bool lrgSize  is this a large screen or not
   */
  setLargeScreen(currentState, lrgSize) {
    Vue.set(currentState, 'largeScreen', lrgSize || false);
  },

  /**
   * setPostWithImageScreen
   *
   * sets the current value for postWithImageScreen. logic for this is handled elsewhere. this is just a data warehouse
   *
   * @param object currentState  the current state of this handle
   * @param bool isSize  is this a large screen or not
   */
  setPostWithImageScreen(currentState, isSize) {
    Vue.set(currentState, 'postWithImageScreen', isSize || false);
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
