/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import { sendSegmentEvent } from '@/data/helpers';
import * as Segment from './segment'

export interface Cta {
	/**
	 * what does the cta say after you post it?
	 */
	confirmation?: string
	/**
	 * what does the cta button say?
	 */
	text?: string
}
export interface Card {
	/**
	 * body text of the card
	 */
	body?: string
	/**
	 * cta object
	 */
	cta?: Cta
	index?: number
	/**
	 * title text of the card
	 */
	title?: string
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Group {
	/**
	 * (member_led, revenue, research)
	 */
	community_type?: string
	id?: string
	name?: string
	/**
	 * {related topics for group}
	 */
	related_topics?: any[]
	/**
	 * (private, public)
	 */
	type?: string
}
export interface Context {
	card?: Card
	group?: Group
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * url path of story or topic page ("/" for home page)
	 */
	path?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Onboarding {
	groups_selected?: any[]
	selections_total?: number
	users_selected?: any[]
}
export interface Topic {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User {
	/**
	 * user age
	 */
	age?: number
	/**
	 * user birthday
	 */
	birthday_date?: string
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post {
	/**
	 * post id
	 */
	id?: string
	pinned?: boolean
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User
}
export interface Target {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Choose {
	context?: Context
	onboarding?: Onboarding
	/**
	 * href or url this event redirects to
	 */
	path?: string
	/**
	 * On what post is this happening?
	 */
	post?: Post
	/**
	 * Object being Acted On
	 */
	target?: Target
}
export interface Context1 {
	/**
	 * home_feed, topic_feed, group_feed, story_page, user_profile
	 */
	view?: string
}
export interface Topic1 {
	names?: any[]
}
export interface User1 {
	id?: string
	name?: string
}
export interface Post1 {
	id?: string
	title?: string
	topic?: Topic1
	/**
	 * thought, question, wordpress_post, wordpress_video
	 */
	type?: string
	user?: User1
}
export interface Target1 {
	/**
	 * comment, reply
	 */
	type?: string
}
export interface Comment {
	context?: Context1
	post?: Post1
	target?: Target1
	/**
	 * userId of user performing action
	 */
	userId?: string
}
export interface Context2 {
	/**
	 * home_feed, topic_feed, group_feed, story_page, user_profile
	 */
	view?: string
}
export interface Topic2 {
	names?: any[]
}
export interface User2 {
	id?: string
	name?: string
}
export interface Post2 {
	id?: string
	title?: string
	topic?: Topic2
	/**
	 * thought, question, wordpress_post, wordpress_video
	 */
	type?: string
	user?: User2
}
export interface Target2 {
	/**
	 * comment, reply
	 */
	type?: string
}
export interface CommentReply {
	context?: Context2
	post?: Post2
	target?: Target2
	/**
	 * userId of user performing action
	 */
	userId?: string
}
export interface Card1 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Group1 {
	id?: string
	name?: string
}
export interface Context3 {
	card?: Card1
	group?: Group1
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic3 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User3 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post3 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic3
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User3
}
export interface Target3 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface CreatePoll {
	context?: Context3
	/**
	 * On what post is this happening?
	 */
	post?: Post3
	/**
	 * Object being Acted On
	 */
	target?: Target3
}
export interface Cta1 {
	/**
	 * what does the cta say after you post it?
	 */
	confirmation?: string
	/**
	 * what does the cta button say?
	 */
	text?: string
}
export interface Card2 {
	/**
	 * body text of the card
	 */
	body?: string
	/**
	 * cta object
	 */
	cta?: Cta1
	index?: number
	/**
	 * title text of the card
	 */
	title?: string
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context4 {
	card?: Card2
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic4 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User4 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post4 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic4
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User4
}
export interface Target4 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Deselect {
	context?: Context4
	/**
	 * On what post is this happening?
	 */
	post?: Post4
	/**
	 * Object being Acted On
	 */
	target?: Target4
}
export interface Room {
	duration_min?: string
	id?: string
	is_moderator?: boolean
	moderator_username?: Record<string, any>
	name?: string
	num_removed_participants?: string
}
export interface Context5 {
	room?: Room
}
export interface EndRoom {
	context?: Context5
}
export interface Card3 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context6 {
	card?: Card3
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic5 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User5 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post5 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic5
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User5
}
export interface Target5 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Filter {
	context?: Context6
	/**
	 * On what post is this happening?
	 */
	post?: Post5
	/**
	 * Object being Acted On
	 */
	target?: Target5
}
export interface Cta2 {
	/**
	 * what does the cta say after you post it?
	 */
	confirmation?: string
	/**
	 * what does the cta button say?
	 */
	text?: string
}
export interface Card4 {
	/**
	 * body text of the card
	 */
	body?: string
	/**
	 * cta object
	 */
	cta?: Cta2
	index?: number
	/**
	 * title text of the card
	 */
	title?: string
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context7 {
	card?: Card4
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic6 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User6 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post6 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic6
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User6
}
export interface Target6 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Flag {
	context?: Context7
	/**
	 * On what post is this happening?
	 */
	post?: Post6
	/**
	 * Object being Acted On
	 */
	target?: Target6
}
export interface Card5 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context8 {
	card?: Card5
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic7 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User7 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post7 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic7
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User7
}
export interface Target7 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Follow {
	context?: Context8
	/**
	 * On what post is this happening?
	 */
	post?: Post7
	/**
	 * Object being Acted On
	 */
	target?: Target7
}
export interface Group2 {
	id?: string
	name?: string
}
export interface Context9 {
	group?: Group2
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic8 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User8 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post8 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic8
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User8
}
export interface Target8 {
	/**
	 * (heart)
	 */
	type?: string
}
export interface Heart {
	context?: Context9
	/**
	 * On what post is this happening?
	 */
	post?: Post8
	/**
	 * Object being Acted On
	 */
	target?: Target8
}
export interface Group3 {
	/**
	 * (member_led, revenue, research)
	 */
	community_type?: string
	id?: string
	name?: string
	/**
	 * {related topics for group}
	 */
	related_topics?: any[]
	/**
	 * (private, public)
	 */
	type?: string
}
export interface Context10 {
	group?: Group3
}
export interface Target9 {
	/**
	 * (member)
	 */
	type?: string
}
export interface JoinGroup {
	context?: Context10
	/**
	 * Object being Acted On
	 */
	target?: Target9
	/**
	 * userID
	 */
	userId?: string
}
export interface Group4 {
	/**
	 * (member_led, revenue, research)
	 */
	community_type?: string
	id?: string
	name?: string
	/**
	 * {related topics for group}
	 */
	related_topics?: any[]
	/**
	 * (private, public)
	 */
	type?: string
}
export interface Context11 {
	group?: Group4
}
export interface JoinRoom {
	context?: Context11
}
export interface Group5 {
	/**
	 * (member_led, revenue, research)
	 */
	community_type?: string
	id?: string
	name?: string
	/**
	 * {related topics for group}
	 */
	related_topics?: any[]
	/**
	 * (private, public)
	 */
	type?: string
}
export interface Context12 {
	group?: Group5
}
export interface LeaveRoom {
	context?: Context12
}
export interface Target10 {
	/**
	 * (continue_with_email, continue_with_facebook, continue_with_apple)
	 */
	name?: string
}
export interface LogIn {
	target?: Target10
}
export interface Topic9 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User9 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post9 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic9
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User9
}
export interface PageViewed {
	keywords?: any[]
	name?: string
	path?: string
	/**
	 * On what post is this happening?
	 */
	post?: Post9
	referrer?: string
	search?: string
	title?: string
	url?: string
}
export interface Card6 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Group6 {
	id?: string
	name?: string
}
export interface Context13 {
	card?: Card6
	group?: Group6
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic10 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User10 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post10 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic10
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User10
}
export interface Target11 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Post11 {
	context?: Context13
	/**
	 * On what post is this happening?
	 */
	post?: Post10
	/**
	 * Object being Acted On
	 */
	target?: Target11
}
export interface Group7 {
	/**
	 * (member_led, revenue, research)
	 */
	community_type?: string
	id?: string
	is_leader?: boolean
	name?: string
	/**
	 * {related topics for group}
	 */
	related_topics?: any[]
	/**
	 * (private, public)
	 */
	type?: string
}
export interface Context14 {
	group?: Group7
	view?: Record<string, any>
}
export interface Target12 {
	name?: Record<string, any>
	/**
	 * (member_deferred_join)
	 */
	type?: string
}
export interface RequestToJoin {
	context?: Context14
	/**
	 * Object being Acted On
	 */
	target?: Target12
	/**
	 * userID
	 */
	userId?: string
}
export interface Group8 {
	id?: string
	name?: string
}
export interface Context15 {
	group?: Group8
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic11 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User11 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post12 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic11
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User11
}
export interface Save {
	context?: Context15
	/**
	 * On what post is this happening?
	 */
	post?: Post12
}
export interface Card7 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Topic12 {
	/**
	 * array of topic ids
	 */
	ids?: any[]
	/**
	 * array of topic names
	 */
	names?: any[]
}
export interface View {
	/**
	 * What is the name of the view (internally)
	 */
	name?: string
	/**
	 * what is the topic of the Feed (topic feed) or what topics does the user follow? (home)
	 */
	topic?: Topic12
}
export interface Context16 {
	card?: Card7
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which section? Usually a grouping of cards
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: View
}
export interface Topic13 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User12 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post13 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic13
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User12
}
export interface Target13 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Screen {
	context?: Context16
	/**
	 * On what post is this happening?
	 */
	post?: Post13
	/**
	 * Object being Acted On
	 */
	target?: Target13
}
export interface Cta3 {
	/**
	 * what does the cta say after you post it?
	 */
	confirmation?: string
	/**
	 * what does the cta button say?
	 */
	text?: string
}
export interface Card8 {
	/**
	 * body text of the card
	 */
	body?: string
	/**
	 * cta object
	 */
	cta?: Cta3
	index?: number
	/**
	 * title text of the card
	 */
	title?: string
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context17 {
	card?: Card8
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic14 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User13 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post14 {
	/**
	 * post id
	 */
	id?: string
	primary_topic?: Record<string, any>
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic14
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User13
}
export interface Target14 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Search {
	context?: Context17
	/**
	 * On what post is this happening?
	 */
	post?: Post14
	/**
	 * Object being Acted On
	 */
	target?: Target14
	userId?: string
}
export interface Cta4 {
	/**
	 * what does the cta say after you post it?
	 */
	confirmation?: string
	/**
	 * what does the cta button say?
	 */
	text?: string
}
export interface Card9 {
	/**
	 * body text of the card
	 */
	body?: string
	/**
	 * cta object
	 */
	cta?: Cta4
	index?: number
	/**
	 * title text of the card
	 */
	title?: string
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context18 {
	card?: Card9
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic15 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User14 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post15 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic15
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User14
}
export interface Target15 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Select {
	context?: Context18
	/**
	 * On what post is this happening?
	 */
	post?: Post15
	/**
	 * Object being Acted On
	 */
	target?: Target15
}
export interface Cta5 {
	/**
	 * what does the cta say after you post it?
	 */
	confirmation?: string
	/**
	 * what does the cta button say?
	 */
	text?: string
}
export interface Card10 {
	/**
	 * body text of the card
	 */
	body?: string
	/**
	 * cta object
	 */
	cta?: Cta5
	index?: number
	/**
	 * title text of the card
	 */
	title?: string
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context19 {
	card?: Card10
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * (dm, room)
	 */
	section?: string
}
export interface Topic16 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User15 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post16 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic16
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User15
}
export interface Target16 {
	/**
	 * (Send Message)
	 */
	name?: string
	/**
	 * e.g (text, emoji, gallery) based on content of the message
	 */
	type?: string
}
export interface SendMessage {
	context?: Context19
	/**
	 * On what post is this happening?
	 */
	post?: Post16
	/**
	 * Object being Acted On
	 */
	target?: Target16
}
export interface Context20 {
	/**
	 * (inbox, group_feed)
	 */
	section?: string
}
export interface Topic17 {
	names?: any[]
}
export interface User16 {
	id?: string
	username?: string
}
export interface Post17 {
	id?: string
	title?: string
	topic?: Topic17
	type?: string
	user?: User16
}
export interface Target17 {
	/**
	 * (room_invite_link)
	 */
	name?: string
	/**
	 * (link)
	 */
	type?: string
}
export interface Share {
	context?: Context20
	post?: Post17
	/**
	 * Object being Acted On
	 */
	target?: Target17
	userId?: string
}
export interface Context21 {
	/**
	 * can be either apple, google, facebook or email
	 */
	section?: string
	/**
	 * can be new_account
	 */
	view?: string
}
export interface SignUp {
	context?: Context21
}
export interface Card11 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context22 {
	card?: Card11
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic18 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User17 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post18 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic18
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User17
}
export interface Target18 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Unfollow {
	context?: Context22
	/**
	 * On what post is this happening?
	 */
	post?: Post18
	/**
	 * Object being Acted On
	 */
	target?: Target18
}
export interface Card12 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Context23 {
	card?: Card12
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic19 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User18 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post19 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic19
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User18
}
export interface Target19 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: string
	type?: string
}
export interface Unheart {
	context?: Context23
	/**
	 * On what post is this happening?
	 */
	post?: Post19
	/**
	 * Object being Acted On
	 */
	target?: Target19
}
export interface Group9 {
	id?: string
	name?: string
}
export interface Context24 {
	group?: Group9
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic20 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User19 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post20 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic20
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User19
}
export interface Unsave {
	context?: Context24
	/**
	 * On what post is this happening?
	 */
	post?: Post20
}
export interface Card13 {
	/**
	 * card type (preview, expanded_module, story_page, full_post)
	 */
	type?: string
}
export interface Context25 {
	card?: Card13
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic21 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User20 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post21 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic21
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User20
}
export interface View1 {
	context?: Context25
	/**
	 * On what post is this happening?
	 */
	post?: Post21
}
export interface Context26 {
	/**
	 * can be either apple, google, facebook or email
	 */
	section?: string
	/**
	 * can be new_account
	 */
	view?: string
}
export interface ViewSignUp {
	context?: Context26
}
export interface Card14 {
	/**
	 * card type (preview, expanded, full)
	 */
	type?: string
}
export interface Group10 {
	id?: string
	name?: string
}
export interface Context27 {
	card?: Card14
	group?: Group10
	/**
	 * If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
	 */
	module?: string
	/**
	 * Which feed/section
	 */
	section?: string
	/**
	 * top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
	 */
	view?: string
}
export interface Topic22 {
	/**
	 * array of the ids
	 */
	ids?: any[]
	/**
	 * array of the hashtag/topic names
	 */
	names?: any[]
}
export interface User21 {
	displayname?: string
	/**
	 * user id (wordpress)
	 */
	id?: string
	type?: string
	username?: string
}
export interface Post22 {
	/**
	 * post id
	 */
	id?: string
	/**
	 * title of post
	 */
	title?: string
	/**
	 * What topics does the post have?
	 */
	topic?: Topic22
	/**
	 * post type (Story, Thought, Question)
	 */
	type?: string
	/**
	 * Who authored the post?
	 */
	user?: User21
}
export interface Target20 {
	/**
	 * id of user or topic being followed or post being clicked on
	 */
	id?: string
	name?: any[]
	type?: string
}
export interface Vote {
	context?: Context27
	/**
	 * On what post is this happening?
	 */
	post?: Post22
	/**
	 * Object being Acted On
	 */
	target?: Target20
	userId?: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef Cta
 * @property {string} [confirmation] - what does the cta say after you post it?
 * @property {string} [text] - what does the cta button say?
 */
/**
 * @typedef Card
 * @property {string} [body] - body text of the card
 * @property {Cta} [cta] - cta object
 * @property {number} [index] -
 * @property {string} [title] - title text of the card
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Group
 * @property {string} [community_type] - (member_led, revenue, research)
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {any[]} [related_topics] - {related topics for group}
 * @property {string} [type] - (private, public)
 */
/**
 * @typedef Context
 * @property {Card} [card] -
 * @property {Group} [group] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [path] - url path of story or topic page ("/" for home page)
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Onboarding
 * @property {any[]} [groups_selected] -
 * @property {number} [selections_total] -
 * @property {any[]} [users_selected] -
 */
/**
 * @typedef Topic
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User
 * @property {number} [age] - user age
 * @property {string} [birthday_date] - user birthday
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post
 * @property {string} [id] - post id
 * @property {boolean} [pinned] -
 * @property {string} [title] - title of post
 * @property {Topic} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User} [user] - Who authored the post?
 */
/**
 * @typedef Target
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Choose
 * @property {Context} [context] -
 * @property {Onboarding} [onboarding] -
 * @property {string} [path] - href or url this event redirects to
 * @property {Post} [post] - On what post is this happening?
 * @property {Target} [target] - Object being Acted On
 */
/**
 * @typedef Context1
 * @property {string} [view] - home_feed, topic_feed, group_feed, story_page, user_profile
 */
/**
 * @typedef Topic1
 * @property {any[]} [names] -
 */
/**
 * @typedef User1
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Post1
 * @property {string} [id] -
 * @property {string} [title] -
 * @property {Topic1} [topic] -
 * @property {string} [type] - thought, question, wordpress_post, wordpress_video
 * @property {User1} [user] -
 */
/**
 * @typedef Target1
 * @property {string} [type] - comment, reply
 */
/**
 * @typedef Comment
 * @property {Context1} [context] -
 * @property {Post1} [post] -
 * @property {Target1} [target] -
 * @property {string} [userId] - userId of user performing action
 */
/**
 * @typedef Context2
 * @property {string} [view] - home_feed, topic_feed, group_feed, story_page, user_profile
 */
/**
 * @typedef Topic2
 * @property {any[]} [names] -
 */
/**
 * @typedef User2
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Post2
 * @property {string} [id] -
 * @property {string} [title] -
 * @property {Topic2} [topic] -
 * @property {string} [type] - thought, question, wordpress_post, wordpress_video
 * @property {User2} [user] -
 */
/**
 * @typedef Target2
 * @property {string} [type] - comment, reply
 */
/**
 * @typedef CommentReply
 * @property {Context2} [context] -
 * @property {Post2} [post] -
 * @property {Target2} [target] -
 * @property {string} [userId] - userId of user performing action
 */
/**
 * @typedef Card1
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Group1
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Context3
 * @property {Card1} [card] -
 * @property {Group1} [group] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic3
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User3
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post3
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic3} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User3} [user] - Who authored the post?
 */
/**
 * @typedef Target3
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef CreatePoll
 * @property {Context3} [context] -
 * @property {Post3} [post] - On what post is this happening?
 * @property {Target3} [target] - Object being Acted On
 */
/**
 * @typedef Cta1
 * @property {string} [confirmation] - what does the cta say after you post it?
 * @property {string} [text] - what does the cta button say?
 */
/**
 * @typedef Card2
 * @property {string} [body] - body text of the card
 * @property {Cta1} [cta] - cta object
 * @property {number} [index] -
 * @property {string} [title] - title text of the card
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context4
 * @property {Card2} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic4
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User4
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post4
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic4} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User4} [user] - Who authored the post?
 */
/**
 * @typedef Target4
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Deselect
 * @property {Context4} [context] -
 * @property {Post4} [post] - On what post is this happening?
 * @property {Target4} [target] - Object being Acted On
 */
/**
 * @typedef Room
 * @property {string} [duration_min] -
 * @property {string} [id] -
 * @property {boolean} [is_moderator] -
 * @property {Record<string, any>} [moderator_username] -
 * @property {string} [name] -
 * @property {string} [num_removed_participants] -
 */
/**
 * @typedef Context5
 * @property {Room} [room] -
 */
/**
 * @typedef EndRoom
 * @property {Context5} [context] -
 */
/**
 * @typedef Card3
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context6
 * @property {Card3} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic5
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User5
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post5
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic5} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User5} [user] - Who authored the post?
 */
/**
 * @typedef Target5
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Filter
 * @property {Context6} [context] -
 * @property {Post5} [post] - On what post is this happening?
 * @property {Target5} [target] - Object being Acted On
 */
/**
 * @typedef Cta2
 * @property {string} [confirmation] - what does the cta say after you post it?
 * @property {string} [text] - what does the cta button say?
 */
/**
 * @typedef Card4
 * @property {string} [body] - body text of the card
 * @property {Cta2} [cta] - cta object
 * @property {number} [index] -
 * @property {string} [title] - title text of the card
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context7
 * @property {Card4} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic6
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User6
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post6
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic6} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User6} [user] - Who authored the post?
 */
/**
 * @typedef Target6
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Flag
 * @property {Context7} [context] -
 * @property {Post6} [post] - On what post is this happening?
 * @property {Target6} [target] - Object being Acted On
 */
/**
 * @typedef Card5
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context8
 * @property {Card5} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic7
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User7
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post7
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic7} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User7} [user] - Who authored the post?
 */
/**
 * @typedef Target7
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Follow
 * @property {Context8} [context] -
 * @property {Post7} [post] - On what post is this happening?
 * @property {Target7} [target] - Object being Acted On
 */
/**
 * @typedef Group2
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Context9
 * @property {Group2} [group] -
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic8
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User8
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post8
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic8} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User8} [user] - Who authored the post?
 */
/**
 * @typedef Target8
 * @property {string} [type] - (heart)
 */
/**
 * @typedef Heart
 * @property {Context9} [context] -
 * @property {Post8} [post] - On what post is this happening?
 * @property {Target8} [target] - Object being Acted On
 */
/**
 * @typedef Group3
 * @property {string} [community_type] - (member_led, revenue, research)
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {any[]} [related_topics] - {related topics for group}
 * @property {string} [type] - (private, public)
 */
/**
 * @typedef Context10
 * @property {Group3} [group] -
 */
/**
 * @typedef Target9
 * @property {string} [type] - (member)
 */
/**
 * @typedef JoinGroup
 * @property {Context10} [context] -
 * @property {Target9} [target] - Object being Acted On
 * @property {string} [userId] - userID
 */
/**
 * @typedef Group4
 * @property {string} [community_type] - (member_led, revenue, research)
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {any[]} [related_topics] - {related topics for group}
 * @property {string} [type] - (private, public)
 */
/**
 * @typedef Context11
 * @property {Group4} [group] -
 */
/**
 * @typedef JoinRoom
 * @property {Context11} [context] -
 */
/**
 * @typedef Group5
 * @property {string} [community_type] - (member_led, revenue, research)
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {any[]} [related_topics] - {related topics for group}
 * @property {string} [type] - (private, public)
 */
/**
 * @typedef Context12
 * @property {Group5} [group] -
 */
/**
 * @typedef LeaveRoom
 * @property {Context12} [context] -
 */
/**
 * @typedef Target10
 * @property {string} [name] - (continue_with_email, continue_with_facebook, continue_with_apple)
 */
/**
 * @typedef LogIn
 * @property {Target10} [target] -
 */
/**
 * @typedef Topic9
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User9
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post9
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic9} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User9} [user] - Who authored the post?
 */
/**
 * @typedef PageViewed
 * @property {any[]} [keywords] -
 * @property {string} [name] -
 * @property {string} [path] -
 * @property {Post9} [post] - On what post is this happening?
 * @property {string} [referrer] -
 * @property {string} [search] -
 * @property {string} [title] -
 * @property {string} [url] -
 */
/**
 * @typedef Card6
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Group6
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Context13
 * @property {Card6} [card] -
 * @property {Group6} [group] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic10
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User10
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post10
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic10} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User10} [user] - Who authored the post?
 */
/**
 * @typedef Target11
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Post11
 * @property {Context13} [context] -
 * @property {Post10} [post] - On what post is this happening?
 * @property {Target11} [target] - Object being Acted On
 */
/**
 * @typedef Group7
 * @property {string} [community_type] - (member_led, revenue, research)
 * @property {string} [id] -
 * @property {boolean} [is_leader] -
 * @property {string} [name] -
 * @property {any[]} [related_topics] - {related topics for group}
 * @property {string} [type] - (private, public)
 */
/**
 * @typedef Context14
 * @property {Group7} [group] -
 * @property {Record<string, any>} [view] -
 */
/**
 * @typedef Target12
 * @property {Record<string, any>} [name] -
 * @property {string} [type] - (member_deferred_join)
 */
/**
 * @typedef RequestToJoin
 * @property {Context14} [context] -
 * @property {Target12} [target] - Object being Acted On
 * @property {string} [userId] - userID
 */
/**
 * @typedef Group8
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Context15
 * @property {Group8} [group] -
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic11
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User11
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post12
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic11} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User11} [user] - Who authored the post?
 */
/**
 * @typedef Save
 * @property {Context15} [context] -
 * @property {Post12} [post] - On what post is this happening?
 */
/**
 * @typedef Card7
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Topic12
 * @property {any[]} [ids] - array of topic ids
 * @property {any[]} [names] - array of topic names
 */
/**
 * @typedef View
 * @property {string} [name] - What is the name of the view (internally)
 * @property {Topic12} [topic] - what is the topic of the Feed (topic feed) or what topics does the user follow? (home)
 */
/**
 * @typedef Context16
 * @property {Card7} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which section? Usually a grouping of cards
 * @property {View} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic13
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User12
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post13
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic13} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User12} [user] - Who authored the post?
 */
/**
 * @typedef Target13
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Screen
 * @property {Context16} [context] -
 * @property {Post13} [post] - On what post is this happening?
 * @property {Target13} [target] - Object being Acted On
 */
/**
 * @typedef Cta3
 * @property {string} [confirmation] - what does the cta say after you post it?
 * @property {string} [text] - what does the cta button say?
 */
/**
 * @typedef Card8
 * @property {string} [body] - body text of the card
 * @property {Cta3} [cta] - cta object
 * @property {number} [index] -
 * @property {string} [title] - title text of the card
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context17
 * @property {Card8} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic14
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User13
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post14
 * @property {string} [id] - post id
 * @property {Record<string, any>} [primary_topic] -
 * @property {string} [title] - title of post
 * @property {Topic14} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User13} [user] - Who authored the post?
 */
/**
 * @typedef Target14
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Search
 * @property {Context17} [context] -
 * @property {Post14} [post] - On what post is this happening?
 * @property {Target14} [target] - Object being Acted On
 * @property {string} [userId] -
 */
/**
 * @typedef Cta4
 * @property {string} [confirmation] - what does the cta say after you post it?
 * @property {string} [text] - what does the cta button say?
 */
/**
 * @typedef Card9
 * @property {string} [body] - body text of the card
 * @property {Cta4} [cta] - cta object
 * @property {number} [index] -
 * @property {string} [title] - title text of the card
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context18
 * @property {Card9} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic15
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User14
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post15
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic15} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User14} [user] - Who authored the post?
 */
/**
 * @typedef Target15
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Select
 * @property {Context18} [context] -
 * @property {Post15} [post] - On what post is this happening?
 * @property {Target15} [target] - Object being Acted On
 */
/**
 * @typedef Cta5
 * @property {string} [confirmation] - what does the cta say after you post it?
 * @property {string} [text] - what does the cta button say?
 */
/**
 * @typedef Card10
 * @property {string} [body] - body text of the card
 * @property {Cta5} [cta] - cta object
 * @property {number} [index] -
 * @property {string} [title] - title text of the card
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context19
 * @property {Card10} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - (dm, room)
 */
/**
 * @typedef Topic16
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User15
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post16
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic16} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User15} [user] - Who authored the post?
 */
/**
 * @typedef Target16
 * @property {string} [name] - (Send Message)
 * @property {string} [type] - e.g (text, emoji, gallery) based on content of the message
 */
/**
 * @typedef SendMessage
 * @property {Context19} [context] -
 * @property {Post16} [post] - On what post is this happening?
 * @property {Target16} [target] - Object being Acted On
 */
/**
 * @typedef Context20
 * @property {string} [section] - (inbox, group_feed)
 */
/**
 * @typedef Topic17
 * @property {any[]} [names] -
 */
/**
 * @typedef User16
 * @property {string} [id] -
 * @property {string} [username] -
 */
/**
 * @typedef Post17
 * @property {string} [id] -
 * @property {string} [title] -
 * @property {Topic17} [topic] -
 * @property {string} [type] -
 * @property {User16} [user] -
 */
/**
 * @typedef Target17
 * @property {string} [name] - (room_invite_link)
 * @property {string} [type] - (link)
 */
/**
 * @typedef Share
 * @property {Context20} [context] -
 * @property {Post17} [post] -
 * @property {Target17} [target] - Object being Acted On
 * @property {string} [userId] -
 */
/**
 * @typedef Context21
 * @property {string} [section] - can be either apple, google, facebook or email
 * @property {string} [view] - can be new_account
 */
/**
 * @typedef SignUp
 * @property {Context21} [context] -
 */
/**
 * @typedef Card11
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context22
 * @property {Card11} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic18
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User17
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post18
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic18} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User17} [user] - Who authored the post?
 */
/**
 * @typedef Target18
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Unfollow
 * @property {Context22} [context] -
 * @property {Post18} [post] - On what post is this happening?
 * @property {Target18} [target] - Object being Acted On
 */
/**
 * @typedef Card12
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Context23
 * @property {Card12} [card] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic19
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User18
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post19
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic19} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User18} [user] - Who authored the post?
 */
/**
 * @typedef Target19
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Unheart
 * @property {Context23} [context] -
 * @property {Post19} [post] - On what post is this happening?
 * @property {Target19} [target] - Object being Acted On
 */
/**
 * @typedef Group9
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Context24
 * @property {Group9} [group] -
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic20
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User19
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post20
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic20} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User19} [user] - Who authored the post?
 */
/**
 * @typedef Unsave
 * @property {Context24} [context] -
 * @property {Post20} [post] - On what post is this happening?
 */
/**
 * @typedef Card13
 * @property {string} [type] - card type (preview, expanded_module, story_page, full_post)
 */
/**
 * @typedef Context25
 * @property {Card13} [card] -
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic21
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User20
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post21
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic21} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User20} [user] - Who authored the post?
 */
/**
 * @typedef View1
 * @property {Context25} [context] -
 * @property {Post21} [post] - On what post is this happening?
 */
/**
 * @typedef Context26
 * @property {string} [section] - can be either apple, google, facebook or email
 * @property {string} [view] - can be new_account
 */
/**
 * @typedef ViewSignUp
 * @property {Context26} [context] -
 */
/**
 * @typedef Card14
 * @property {string} [type] - card type (preview, expanded, full)
 */
/**
 * @typedef Group10
 * @property {string} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef Context27
 * @property {Card14} [card] -
 * @property {Group10} [group] -
 * @property {string} [module] - If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit
 * @property {string} [section] - Which feed/section
 * @property {string} [view] - top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)
 */
/**
 * @typedef Topic22
 * @property {any[]} [ids] - array of the ids
 * @property {any[]} [names] - array of the hashtag/topic names
 */
/**
 * @typedef User21
 * @property {string} [displayname] -
 * @property {string} [id] - user id (wordpress)
 * @property {string} [type] -
 * @property {string} [username] -
 */
/**
 * @typedef Post22
 * @property {string} [id] - post id
 * @property {string} [title] - title of post
 * @property {Topic22} [topic] - What topics does the post have?
 * @property {string} [type] - post type (Story, Thought, Question)
 * @property {User21} [user] - Who authored the post?
 */
/**
 * @typedef Target20
 * @property {string} [id] - id of user or topic being followed or post being clicked on
 * @property {any[]} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Vote
 * @property {Context27} [context] -
 * @property {Post22} [post] - On what post is this happening?
 * @property {Target20} [target] - Object being Acted On
 * @property {string} [userId] -
 */

/**
 * Fires a 'Choose' track call.
 *
 * @param {Choose} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function choose(
	props?: Choose,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									body: {
										description: 'body text of the card',
										type: 'string',
									},
									cta: {
										description: 'cta object',
										properties: {
											confirmation: {
												description: 'what does the cta say after you post it?',
												type: 'string',
											},
											text: {
												description: 'what does the cta button say?',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									index: {
										description: '',
										type: 'integer',
									},
									title: {
										description: 'title text of the card',
										type: 'string',
									},
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							group: {
								description: '',
								properties: {
									community_type: {
										description: '(member_led, revenue, research) ',
										type: 'string',
									},
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
									related_topics: {
										description: '{related topics for group}',
										type: 'array',
									},
									type: {
										description: '(private, public)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							path: {
								description:
									'url path of story or topic page ("/" for home page)',
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					onboarding: {
						description: '',
						properties: {
							groups_selected: {
								description: '',
								type: 'array',
							},
							selections_total: {
								description: '',
								type: 'integer',
							},
							users_selected: {
								description: '',
								type: 'array',
							},
						},
						required: [],
						type: 'object',
					},
					path: {
						description: 'href or url this event redirects to',
						type: 'string',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							pinned: {
								description: '',
								type: 'boolean',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									age: {
										description: 'user age',
										type: 'integer',
									},
									birthday_date: {
										description: 'user birthday',
										format: 'date-time',
										type: 'string',
									},
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Choose',
		type: 'object',
	}
	const message = {
		event: 'Choose',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Choose', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Choose', props || {}, callback);
}
/**
 * Fires a 'Comment' track call.
 *
 * @param {Comment} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function comment(
	props?: Comment,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							view: {
								description:
									'home_feed, topic_feed, group_feed, story_page, user_profile',
								id: '/properties/properties/properties/context/properties/view',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: '',
						id: '/properties/properties/properties/post',
						properties: {
							id: {
								description: '',
								id: '/properties/properties/properties/post/properties/id',
								type: 'string',
							},
							title: {
								description: '',
								id: '/properties/properties/properties/post/properties/title',
								type: 'string',
							},
							topic: {
								description: '',
								id: '/properties/properties/properties/post/properties/topic',
								properties: {
									names: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/topic/properties/names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description:
									'thought, question, wordpress_post, wordpress_video',
								id: '/properties/properties/properties/post/properties/type',
								type: 'string',
							},
							user: {
								description: '',
								id: '/properties/properties/properties/post/properties/user',
								properties: {
									id: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/id',
										type: 'string',
									},
									name: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/name',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: '',
						id: '/properties/properties/properties/target',
						properties: {
							type: {
								description: 'comment, reply',
								id: '/properties/properties/properties/target/properties/type',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: 'userId of user performing action',
						id: '/properties/properties/properties/userId',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Comment',
		type: 'object',
	}
	const message = {
		event: 'Comment',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Comment', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Comment', props || {}, callback);
}
/**
 * Fires a 'Comment Reply' track call.
 *
 * @param {CommentReply} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function commentReply(
	props?: CommentReply,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							view: {
								description:
									'home_feed, topic_feed, group_feed, story_page, user_profile',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: '',
						properties: {
							id: {
								description: '',
								type: 'string',
							},
							title: {
								description: '',
								type: 'string',
							},
							topic: {
								description: '',
								properties: {
									names: {
										description: '',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description:
									'thought, question, wordpress_post, wordpress_video',
								type: 'string',
							},
							user: {
								description: '',
								properties: {
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: '',
						properties: {
							type: {
								description: 'comment, reply',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: 'userId of user performing action',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Comment Reply',
		type: 'object',
	}
	const message = {
		event: 'Comment Reply',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Comment Reply',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}

	sendSegmentEvent('Comment Reply', props || {}, callback);
}
/**
 * Fires a 'Create Poll' track call.
 *
 * @param {CreatePoll} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createPoll(
	props?: CreatePoll,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							card: {
								description: '',
								id: '/properties/properties/properties/context/properties/card',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										id:
											'/properties/properties/properties/context/properties/card/properties/type',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							group: {
								description: '',
								id:
									'/properties/properties/properties/context/properties/group',
								properties: {
									id: {
										description: '',
										id:
											'/properties/properties/properties/context/properties/group/properties/id',
										type: 'string',
									},
									name: {
										description: '',
										id:
											'/properties/properties/properties/context/properties/group/properties/name',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								id:
									'/properties/properties/properties/context/properties/module',
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								id:
									'/properties/properties/properties/context/properties/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								id: '/properties/properties/properties/context/properties/view',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						id: '/properties/properties/properties/post',
						properties: {
							id: {
								description: 'post id',
								id: '/properties/properties/properties/post/properties/id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								id: '/properties/properties/properties/post/properties/title',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								id: '/properties/properties/properties/post/properties/topic',
								properties: {
									ids: {
										description: 'array of the ids',
										id:
											'/properties/properties/properties/post/properties/topic/properties/ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										id:
											'/properties/properties/properties/post/properties/topic/properties/names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								id: '/properties/properties/properties/post/properties/type',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								id: '/properties/properties/properties/post/properties/user',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/displayname',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										id:
											'/properties/properties/properties/post/properties/user/properties/id',
										type: 'string',
									},
									type: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/type',
										type: 'string',
									},
									username: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/username',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						id: '/properties/properties/properties/target',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								id: '/properties/properties/properties/target/properties/id',
								type: 'string',
							},
							name: {
								description: '',
								id: '/properties/properties/properties/target/properties/name',
								type: 'string',
							},
							type: {
								description: '',
								id: '/properties/properties/properties/target/properties/type',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Create Poll',
		type: 'object',
	}
	const message = {
		event: 'Create Poll',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Create Poll',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}

	sendSegmentEvent('Create Poll', props || {}, callback);
}
/**
 * Fires a 'Deselect' track call.
 *
 * @param {Deselect} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deselect(
	props?: Deselect,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									body: {
										description: 'body text of the card',
										type: 'string',
									},
									cta: {
										description: 'cta object',
										properties: {
											confirmation: {
												description: 'what does the cta say after you post it?',
												type: 'string',
											},
											text: {
												description: 'what does the cta button say?',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									index: {
										description: '',
										type: 'integer',
									},
									title: {
										description: 'title text of the card',
										type: 'string',
									},
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Deselect',
		type: 'object',
	}
	const message = {
		event: 'Deselect',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Deselect', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Deselect', props || {}, callback);
}
/**
 * Enter Room (triggered when user successfully enters room)
 *
 * @param {EndRoom} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function endRoom(
	props?: EndRoom,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Enter Room (triggered when user successfully enters room)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							room: {
								description: '',
								properties: {
									duration_min: {
										description: '',
										type: 'string',
									},
									id: {
										description: '',
										type: 'string',
									},
									is_moderator: {
										description: '',
										type: 'boolean',
									},
									moderator_username: {
										description: '',
										type: 'object',
									},
									name: {
										description: '',
										type: 'string',
									},
									num_removed_participants: {
										description: '',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'End Room',
		type: 'object',
	}
	const message = {
		event: 'End Room',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('End Room', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('End Room', props || {}, callback);
}
/**
 * Fires a 'Filter' track call.
 *
 * @param {Filter} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filter(
	props?: Filter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Filter',
		type: 'object',
	}
	const message = {
		event: 'Filter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Filter', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Filter', props || {}, callback);
}
/**
 * Fires a 'Flag' track call.
 *
 * @param {Flag} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flag(
	props?: Flag,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									body: {
										description: 'body text of the card',
										type: 'string',
									},
									cta: {
										description: 'cta object',
										properties: {
											confirmation: {
												description: 'what does the cta say after you post it?',
												type: 'string',
											},
											text: {
												description: 'what does the cta button say?',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									index: {
										description: '',
										type: 'integer',
									},
									title: {
										description: 'title text of the card',
										type: 'string',
									},
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Flag',
		type: 'object',
	}
	const message = {
		event: 'Flag',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Flag', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Flag', props || {}, callback);
}
/**
 * Follow member or hashtag/topic
 *
 * @param {Follow} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function follow(
	props?: Follow,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Follow member or hashtag/topic',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Follow',
		type: 'object',
	}
	const message = {
		event: 'Follow',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Follow', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Follow', props || {}, callback);
}
/**
 * Fires a 'Heart' track call.
 *
 * @param {Heart} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function heart(
	props?: Heart,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							type: {
								description: '(heart)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Heart',
		type: 'object',
	}
	const message = {
		event: 'Heart',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Heart', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Heart', props || {}, callback);
}
/**
 * Fires a 'Join Group' track call.
 *
 * @param {JoinGroup} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function joinGroup(
	props?: JoinGroup,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									community_type: {
										description: '(member_led, revenue, research) ',
										type: 'string',
									},
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
									related_topics: {
										description: '{related topics for group}',
										type: 'array',
									},
									type: {
										description: '(private, public)',
										type: 'string',
									},
								},
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							type: {
								description: '(member)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: 'userID',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Join Group',
		type: 'object',
	}
	const message = {
		event: 'Join Group',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Join Group', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Join Group', props || {}, callback);
}
/**
 * Enter Room (triggered when user successfully enters room)
 *
 * @param {JoinRoom} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function joinRoom(
	props?: JoinRoom,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Enter Room (triggered when user successfully enters room)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									community_type: {
										description: '(member_led, revenue, research) ',
										type: 'string',
									},
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
									related_topics: {
										description: '{related topics for group}',
										type: 'array',
									},
									type: {
										description: '(private, public)',
										type: 'string',
									},
								},
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Join Room',
		type: 'object',
	}
	const message = {
		event: 'Join Room',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Join Room', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Join Room', props || {}, callback);
}
/**
 * Enter Room (triggered when user successfully enters room)
 *
 * @param {LeaveRoom} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leaveRoom(
	props?: LeaveRoom,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Enter Room (triggered when user successfully enters room)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									community_type: {
										description: '(member_led, revenue, research) ',
										type: 'string',
									},
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
									related_topics: {
										description: '{related topics for group}',
										type: 'array',
									},
									type: {
										description: '(private, public)',
										type: 'string',
									},
								},
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Leave Room',
		type: 'object',
	}
	const message = {
		event: 'Leave Room',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Leave Room', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Leave Room', props || {}, callback);
}
/**
 * Fires a 'Log In' track call.
 *
 * @param {LogIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logIn(
	props?: LogIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		properties: {
			context: {},
			properties: {
				properties: {
					target: {
						description: '',
						properties: {
							name: {
								description:
									'(continue_with_email, continue_with_facebook, continue_with_apple)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Log In',
		type: 'object',
	}
	const message = {
		event: 'Log In',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Log In', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Log In', props || {}, callback);
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props?: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					keywords: {
						description: '',
						type: 'array',
					},
					name: {
						description: '',
						type: 'string',
					},
					path: {
						description: '',
						type: 'string',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					referrer: {
						description: '',
						type: 'string',
					},
					search: {
						description: '',
						type: 'string',
					},
					title: {
						description: '',
						type: 'string',
					},
					url: {
						description: '',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}

	sendSegmentEvent('Page Viewed', props || {}, callback);
}
/**
 * Fires a 'Post' track call.
 *
 * @param {Post11} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function post(
	props?: Post11,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							group: {
								description: '',
								properties: {
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Post',
		type: 'object',
	}
	const message = {
		event: 'Post',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Post', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Post', props || {}, callback);
}
/**
 * User logs in as new user
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function register(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User logs in as new user',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Register',
		type: 'object',
	}
	const message = {
		event: 'Register',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Register', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Register', props || {}, callback);
}
/**
 * Fires a 'Request to Join' track call.
 *
 * @param {RequestToJoin} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestToJoin(
	props?: RequestToJoin,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									community_type: {
										description: '(member_led, revenue, research) ',
										type: 'string',
									},
									id: {
										description: '',
										type: 'string',
									},
									is_leader: {
										description: '',
										type: 'boolean',
									},
									name: {
										description: '',
										type: 'string',
									},
									related_topics: {
										description: '{related topics for group}',
										type: 'array',
									},
									type: {
										description: '(private, public)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							view: {
								description: '',
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							name: {
								description: '',
								type: 'object',
							},
							type: {
								description: '(member_deferred_join)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: 'userID',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Request to Join',
		type: 'object',
	}
	const message = {
		event: 'Request to Join',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Request to Join',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}

	sendSegmentEvent('Request to Join', props || {}, callback);
}
/**
 * taps Save icon
 *
 * @param {Save} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function save(
	props?: Save,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'taps Save icon',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Save',
		type: 'object',
	}
	const message = {
		event: 'Save',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Save', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Save', props || {}, callback);
}
/**
 * Fires a 'Screen' track call.
 *
 * @param {Screen} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function screen_(
	props?: Screen,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which section? Usually a grouping of cards',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								properties: {
									name: {
										description: 'What is the name of the view (internally)',
										type: 'string',
									},
									topic: {
										description:
											'what is the topic of the Feed (topic feed) or what topics does the user follow? (home)',
										properties: {
											ids: {
												description: 'array of topic ids',
												type: 'array',
											},
											names: {
												description: 'array of topic names',
												type: 'array',
											},
										},
										required: [],
										type: 'object',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Screen',
		type: 'object',
	}
	const message = {
		event: 'Screen',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Screen', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Screen', props || {}, callback);
}
/**
 * Fires a 'Search' track call.
 *
 * @param {Search} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function search(
	props?: Search,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									body: {
										description: 'body text of the card',
										type: 'string',
									},
									cta: {
										description: 'cta object',
										properties: {
											confirmation: {
												description: 'what does the cta say after you post it?',
												type: 'string',
											},
											text: {
												description: 'what does the cta button say?',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									index: {
										description: '',
										type: 'integer',
									},
									title: {
										description: 'title text of the card',
										type: 'string',
									},
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							primary_topic: {
								description: '',
								type: 'object',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: '',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Search',
		type: 'object',
	}
	const message = {
		event: 'Search',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Search', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Search', props || {}, callback);
}
/**
 * Fires a 'Select' track call.
 *
 * @param {Select} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function select(
	props?: Select,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									body: {
										description: 'body text of the card',
										type: 'string',
									},
									cta: {
										description: 'cta object',
										properties: {
											confirmation: {
												description: 'what does the cta say after you post it?',
												type: 'string',
											},
											text: {
												description: 'what does the cta button say?',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									index: {
										description: '',
										type: 'integer',
									},
									title: {
										description: 'title text of the card',
										type: 'string',
									},
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Select',
		type: 'object',
	}
	const message = {
		event: 'Select',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Select', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Select', props || {}, callback);
}
/**
 * Fires a 'Send Message' track call.
 *
 * @param {SendMessage} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sendMessage(
	props?: SendMessage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									body: {
										description: 'body text of the card',
										type: 'string',
									},
									cta: {
										description: 'cta object',
										properties: {
											confirmation: {
												description: 'what does the cta say after you post it?',
												type: 'string',
											},
											text: {
												description: 'what does the cta button say?',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									index: {
										description: '',
										type: 'integer',
									},
									title: {
										description: 'title text of the card',
										type: 'string',
									},
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: '(dm, room)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							name: {
								description: '(Send Message)',
								type: 'string',
							},
							type: {
								description:
									'e.g (text, emoji, gallery) based on content of the message',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Send Message',
		type: 'object',
	}
	const message = {
		event: 'Send Message',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Send Message',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}

	sendSegmentEvent('Send Message', props || {}, callback);
}
/**
 * Fires a 'Share' track call.
 *
 * @param {Share} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function share(
	props?: Share,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							section: {
								description: '(inbox, group_feed)',
								id:
									'/properties/properties/properties/context/properties/section',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: '',
						id: '/properties/properties/properties/post',
						properties: {
							id: {
								description: '',
								id: '/properties/properties/properties/post/properties/id',
								type: 'string',
							},
							title: {
								description: '',
								id: '/properties/properties/properties/post/properties/title',
								type: 'string',
							},
							topic: {
								description: '',
								id: '/properties/properties/properties/post/properties/topic',
								properties: {
									names: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/topic/properties/names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: '',
								id: '/properties/properties/properties/post/properties/type',
								type: 'string',
							},
							user: {
								description: '',
								id: '/properties/properties/properties/post/properties/user',
								properties: {
									id: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/id',
										type: 'string',
									},
									username: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						id: '/properties/properties/properties/target',
						key: 'target',
						properties: {
							name: {
								description: '(room_invite_link)',
								id: '/properties/properties/properties/target/properties/name',
								type: 'string',
							},
							type: {
								description: '(link)',
								id: '/properties/properties/properties/target/properties/type',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: '',
						id: '/properties/properties/properties/userId',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Share',
		type: 'object',
	}
	const message = {
		event: 'Share',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Share', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Share', props || {}, callback);
}
/**
 * Fires a 'Sign Up' track call.
 *
 * @param {SignUp} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signUp(
	props?: SignUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							section: {
								description: 'can be either apple, google, facebook or email',
								type: 'string',
							},
							view: {
								description: 'can be new_account',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Sign Up',
		type: 'object',
	}
	const message = {
		event: 'Sign Up',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Sign Up', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Sign Up', props || {}, callback);
}
/**
 * Unfollow member or hashtag/topic
 *
 * @param {Unfollow} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unfollow(
	props?: Unfollow,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Unfollow member or hashtag/topic',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Unfollow',
		type: 'object',
	}
	const message = {
		event: 'Unfollow',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Unfollow', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Unfollow', props || {}, callback);
}
/**
 * Fires a 'Unheart' track call.
 *
 * @param {Unheart} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unheart(
	props?: Unheart,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								type: 'string',
							},
							name: {
								description: '',
								type: 'string',
							},
							type: {
								description: '',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Unheart',
		type: 'object',
	}
	const message = {
		event: 'Unheart',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Unheart', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Unheart', props || {}, callback);
}
/**
 * taps Save icon to unsave
 *
 * @param {Unsave} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unsave(
	props?: Unsave,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'taps Save icon to unsave',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							group: {
								description: '',
								properties: {
									id: {
										description: '',
										type: 'string',
									},
									name: {
										description: '',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Unsave',
		type: 'object',
	}
	const message = {
		event: 'Unsave',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Unsave', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Unsave', props || {}, callback);
}
/**
 * Fires a 'View' track call.
 *
 * @param {View1} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function view(
	props?: View1,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							card: {
								description: '',
								key: 'card',
								properties: {
									type: {
										description:
											'card type (preview, expanded_module, story_page, full_post)',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						properties: {
							id: {
								description: 'post id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								properties: {
									ids: {
										description: 'array of the ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										type: 'string',
									},
									type: {
										description: '',
										type: 'string',
									},
									username: {
										description: '',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'View',
		type: 'object',
	}
	const message = {
		event: 'View',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('View', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('View', props || {}, callback);
}
/**
 * Fires a 'View Sign Up' track call.
 *
 * @param {ViewSignUp} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewSignUp(
	props?: ViewSignUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							section: {
								description: 'can be either apple, google, facebook or email',
								id:
									'/properties/properties/properties/context/properties/section',
								type: 'string',
							},
							view: {
								description: 'can be new_account',
								id: '/properties/properties/properties/context/properties/view',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'View Sign Up',
		type: 'object',
	}
	const message = {
		event: 'View Sign Up',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'View Sign Up',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}

	sendSegmentEvent('View Sign Up', props || {}, callback);
}
/**
 * Fires a 'Vote' track call.
 *
 * @param {Vote} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vote(
	props?: Vote,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							card: {
								description: '',
								id: '/properties/properties/properties/context/properties/card',
								key: 'card',
								properties: {
									type: {
										description: 'card type (preview, expanded, full)',
										id:
											'/properties/properties/properties/context/properties/card/properties/type',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							group: {
								description: '',
								id:
									'/properties/properties/properties/context/properties/group',
								properties: {
									id: {
										description: '',
										id:
											'/properties/properties/properties/context/properties/group/properties/id',
										type: 'string',
									},
									name: {
										description: '',
										id:
											'/properties/properties/properties/context/properties/group/properties/name',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							module: {
								description:
									"If card is filled in, this is an element on a card, otherwise it's an element in a simpler side unit",
								id:
									'/properties/properties/properties/context/properties/module',
								type: 'string',
							},
							section: {
								description: 'Which feed/section',
								id:
									'/properties/properties/properties/context/properties/section',
								type: 'string',
							},
							view: {
								description:
									'top level category of pages on the site (home_feed, topic_feed, profile, story, thought, question etc.)',
								id: '/properties/properties/properties/context/properties/view',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					post: {
						description: 'On what post is this happening?',
						id: '/properties/properties/properties/post',
						properties: {
							id: {
								description: 'post id',
								id: '/properties/properties/properties/post/properties/id',
								type: 'string',
							},
							title: {
								description: 'title of post',
								id: '/properties/properties/properties/post/properties/title',
								type: 'string',
							},
							topic: {
								description: 'What topics does the post have?',
								id: '/properties/properties/properties/post/properties/topic',
								properties: {
									ids: {
										description: 'array of the ids',
										id:
											'/properties/properties/properties/post/properties/topic/properties/ids',
										type: 'array',
									},
									names: {
										description: 'array of the hashtag/topic names',
										id:
											'/properties/properties/properties/post/properties/topic/properties/names',
										type: 'array',
									},
								},
								required: [],
								type: 'object',
							},
							type: {
								description: 'post type (Story, Thought, Question)',
								id: '/properties/properties/properties/post/properties/type',
								type: 'string',
							},
							user: {
								description: 'Who authored the post?',
								id: '/properties/properties/properties/post/properties/user',
								key: 'user',
								properties: {
									displayname: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/displayname',
										type: 'string',
									},
									id: {
										description: 'user id (wordpress)',
										id:
											'/properties/properties/properties/post/properties/user/properties/id',
										type: 'string',
									},
									type: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/type',
										type: 'string',
									},
									username: {
										description: '',
										id:
											'/properties/properties/properties/post/properties/user/properties/username',
										key: 'username',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
						},
						required: [],
						type: 'object',
					},
					target: {
						description: 'Object being Acted On',
						id: '/properties/properties/properties/target',
						key: 'target',
						properties: {
							id: {
								description:
									'id of user or topic being followed or post being clicked on ',
								id: '/properties/properties/properties/target/properties/id',
								type: 'string',
							},
							name: {
								description: '',
								id: '/properties/properties/properties/target/properties/name',
								type: 'array',
							},
							type: {
								description: '',
								id: '/properties/properties/properties/target/properties/type',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					userId: {
						description: '',
						id: '/properties/properties/properties/userId',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Vote',
		type: 'object',
	}
	const message = {
		event: 'Vote',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Vote', props || {}, withTypewriterContext(options), callback)
	}

	sendSegmentEvent('Vote', props || {}, callback);
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Fires a 'Choose' track call.
	 *
	 * @param {Choose} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	choose,
	/**
	 * Fires a 'Comment' track call.
	 *
	 * @param {Comment} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	comment,
	/**
	 * Fires a 'Comment Reply' track call.
	 *
	 * @param {CommentReply} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	commentReply,
	/**
	 * Fires a 'Create Poll' track call.
	 *
	 * @param {CreatePoll} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createPoll,
	/**
	 * Fires a 'Deselect' track call.
	 *
	 * @param {Deselect} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deselect,
	/**
	 * Enter Room (triggered when user successfully enters room)
	 *
	 * @param {EndRoom} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	endRoom,
	/**
	 * Fires a 'Filter' track call.
	 *
	 * @param {Filter} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filter,
	/**
	 * Fires a 'Flag' track call.
	 *
	 * @param {Flag} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flag,
	/**
	 * Follow member or hashtag/topic
	 *
	 * @param {Follow} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	follow,
	/**
	 * Fires a 'Heart' track call.
	 *
	 * @param {Heart} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	heart,
	/**
	 * Fires a 'Join Group' track call.
	 *
	 * @param {JoinGroup} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	joinGroup,
	/**
	 * Enter Room (triggered when user successfully enters room)
	 *
	 * @param {JoinRoom} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	joinRoom,
	/**
	 * Enter Room (triggered when user successfully enters room)
	 *
	 * @param {LeaveRoom} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	leaveRoom,
	/**
	 * Fires a 'Log In' track call.
	 *
	 * @param {LogIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logIn,
	/**
	 * Fires a 'Page Viewed' track call.
	 *
	 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Fires a 'Post' track call.
	 *
	 * @param {Post11} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	post,
	/**
	 * User logs in as new user
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	register,
	/**
	 * Fires a 'Request to Join' track call.
	 *
	 * @param {RequestToJoin} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	requestToJoin,
	/**
	 * taps Save icon
	 *
	 * @param {Save} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	save,
	/**
	 * Fires a 'Screen' track call.
	 *
	 * @param {Screen} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	screen_,
	/**
	 * Fires a 'Search' track call.
	 *
	 * @param {Search} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	search,
	/**
	 * Fires a 'Select' track call.
	 *
	 * @param {Select} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	select,
	/**
	 * Fires a 'Send Message' track call.
	 *
	 * @param {SendMessage} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sendMessage,
	/**
	 * Fires a 'Share' track call.
	 *
	 * @param {Share} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	share,
	/**
	 * Fires a 'Sign Up' track call.
	 *
	 * @param {SignUp} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signUp,
	/**
	 * Unfollow member or hashtag/topic
	 *
	 * @param {Unfollow} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unfollow,
	/**
	 * Fires a 'Unheart' track call.
	 *
	 * @param {Unheart} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unheart,
	/**
	 * taps Save icon to unsave
	 *
	 * @param {Unsave} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unsave,
	/**
	 * Fires a 'View' track call.
	 *
	 * @param {View1} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	view,
	/**
	 * Fires a 'View Sign Up' track call.
	 *
	 * @param {ViewSignUp} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewSignUp,
	/**
	 * Fires a 'Vote' track call.
	 *
	 * @param {Vote} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vote,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/themighty/protocols/tracking-plans/rs_1GWEhCwXfXeQWMa8QhaAIvDbfcl`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}

			sendSegmentEvent('Unknown Analytics Call Fired', { method }, null);
		}
	},
})
