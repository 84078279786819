import Vue from "vue";
import userApi from "@/data/api/user";
import _ from 'lodash';

import { log, err, isSet, isObject } from "@/data/helpers";

function handleSession(userId = '', $cookies) {
  const sessionId = $cookies.get('session_id');
  const sessionTimestamp = $cookies.get('session_timestamp');
  const uID = $cookies.get('user_id');
  const date = new Date();
  const newSessionId = Math.floor(Date.now() + Math.random());
  const lastSession = new Date(sessionTimestamp);
  // if missing session data or user is not the same or if the last timestamp was greater than 30 minutes ago
  if (!sessionId || !sessionTimestamp || userId !== uID || ((date - lastSession)/1000/60) >= 30) {
    $cookies.set('session_id', newSessionId);
    $cookies.set('session_timestamp', date.toString());
    $cookies.set('user_id', userId);
    return newSessionId;
  }

  // otherwise just update timestamp
  $cookies.set('session_timestamp', date.toString());
  return sessionId;
};

// initial state
const state = () => ({
  acceptedImages: {},
  // originally this was only for Articles, but now entails all pages
  articlesRead: 0,
  sessionHasBeenLoaded: false,
  loggedIn: false,
  user: {
    settings: {},
    sessionId: null,
  },
  loading: {},
});

// getters
const getters = {
  /**
   * hasAcceptedImage
   *
   * has the user accepted that the image is worth seeing, even though it may be triggering?
   *
   * @param object currentState  the current state of this module
   */
  hasAcceptedImage: (currentState) => currentState.acceptedImages,

  /**
   * userIsTempBanned
   *
   * @param object currentState  the current state of this handler
   */
  userIsTempBanned: () => false, // currentState => currentState.user.id === 4864,

  /**
   * userIsPermaBanned
   *
   * @param object currentState  the current state of this handler
   */
  userIsPermaBanned: (currentState) => !!currentState.user.isBanned, // id === 4864,

  /**
   * sessionLoaded
   *
   * has the session already been loaded? some views may load before the check happens, and they need to know when it is done.
   *
   * @param object currentState  the current state of this handler
   */
  sessionLoaded: (currentState) => !!currentState.sessionHasBeenLoaded,

  user: (currentState) => currentState.user,
  isLoggedIn: (ctxState) => ctxState.loggedIn,
  getArticleCount: currentState => currentState.articlesRead,
};

// actions
const actions = {
  /**
   * loadUserAcceptedImages
   *
   * load the history of the images that the user has accepted, from localStorage, if available
   */
  loadUserAcceptedImages(context) {
    if (process.browser) {
      const list = localStorage.getItem("acceptedImages") || {};
      context.commit("setAcceptedImages", isObject(list) ? list : {});
    }
  },

  /**
   * To be dispatched when a login required action is hit
   * Must be manually dispatched and params object determines what to do in this case
   *
   * @param  {[type]} context [description]
   * @param  {[type]} params  [description]
   * @return {[type]}         [description]
   */
  notLoggedInAction(context) {
    context.dispatch("openModal", {
      id: "login-registration-onboarding",
      args: { action: "registration" },
    });
  },

  /**
   * userAcceptPrivacyPolicy
   * sets user Privacy Policy flag `accept: true` (logged-in endpoint)
   *
   */
  userAcceptPrivacyPolicy(context, data) {
    return userApi
      .userAcceptPrivacyPolicy(data, this.$nodeAxios)
      .then((data) => {
        if (isSet(data.accept_privacy_policy)) {
          context.commit("updatePrivacyPolicy", data.accept_privacy_policy);
          return { status: 200 };
        }
      });
  },
};

// mutations
const mutations = {
  /**
   * setSessionLoaded
   *
   * stores whether the session has been loaded or not
   *
   * @param object currentState  the object that contains the current state of the session
   * @param bool value  whether it was loaded or not
   */
  setSessionLoaded(currentState, value) {
    Vue.set(currentState, "sessionHasBeenLoaded", value);
  },

  setCurrentUser(currentState, userObject) {
    const sessionId = handleSession(userObject._id, this.$cookies);
    Vue.set(currentState, "user", { ...userObject, sessionId });
    Vue.set(currentState, "loggedIn", !_.isNil(userObject._id));
  },

  setArticleCount(currentState, n) {
    Vue.set(currentState, 'articlesRead', n);
  },

  setHasIdentity(currentState, identityData) {
    // call this if identity was set
    currentState.user.has_set_identity = true;
    // not all fields may be set. check them for existence first please
    if (isSet(identityData)) {
      if (isSet(identityData.display_name)) {
        currentState.user.display_name = identityData.display_name;
      }
      if (isSet(identityData.username)) {
        currentState.user.username = identityData.username;
      }
    }

    if (isSet(identityData.has_confirmed_age)) {
      currentState.user.has_confirmed_age = identityData.has_confirmed_age;
    }

    const obj = localStorage.getItem("TMUD");
    if (obj && isObject(obj)) {
      obj.nicename =
        identityData && identityData.username
          ? identityData.username
          : currentState.user.username;
      localStorage.setItem("TMUD", obj);
    }
  },

  /**
   * updateDisplayName
   *
   * update the user's display name
   *
   * @param object currentState  the object that contains the current state of the session
   * @param string displayName  the new display name that the user should have
   */
  updateDisplayName(currentState, displayName) {
    currentState.user.display_name = displayName;
  },

  /**
   * updateUsername
   *
   * update the user's username
   *
   * @param object currentState  the object that contains the current state of the session
   * @param string username  the new username that the user should have
   */
  updateUsername(currentState, username) {
    currentState.user.username = username;

    const obj = localStorage.getItem("TMUD");
    obj.nicename = username;
    localStorage.setItem("TMUD", obj);
  },

  setLogoutUser(currentState) {
    currentState.user = {};
    currentState.loggedIn = false;
    currentState.loading = {};
  },

  /**
   * setAcceptedImages
   *
   * set the state of all the accepted images for the user, based on the supplied array of images
   *
   * @param object list  the list of accepted images
   */
  setAcceptedImages(currentState, list) {
    if (isObject(list)) {
      Vue.set(currentState, "acceptedImages", list);
      if (process.browser) {
        localStorage.setItem("acceptedImages", currentState.acceptedImages);
      }
    }
  },

  /**
   * setAcceptedImage
   *
   * set the accepted state of a single image for this user
   *
   * @param string id  the id of the image to set the state of
   * @param bool accept  what state to set
   */
  setAcceptedImage(currentState, { id, accept }) {
    const current = Object.assign({}, currentState.acceptedImages);
    if (!accept) {
      delete current[id];
    } else {
      current[id] = 1;
    }
    Vue.set(currentState, "acceptedImages", current);
    if (process.browser) {
      localStorage.setItem("acceptedImages", currentState.acceptedImages);
    }
  },

  updatePrivacyPolicy(currentState, status) {
    Vue.set(currentState.user.privacy_policy, "accept_privacy_policy", status);
  },
  
  // using this for when user updates Avatar on Account Details page, so that navbar avatar also updates
  setAvatarThumbnail(currentState, url) {
    const user = _.assign({}, currentState.user);
    user.profile.avatar_thumbnail = url;
    Vue.set(currentState, 'user', user);
  },
  
  // using this for when user updates username on Account Details page, so that profile link update (ie. /u/username)
  setUsername(currentState, username) {
    const user = _.assign({}, currentState.user);
    user.username = username;
    Vue.set(currentState, 'user', user);
  },
  
  toggleHideAffirmation(currentState, bool) {
    if (currentState.user.settings) {
      currentState.user.settings.hide_affirmations = bool;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
