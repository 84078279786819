/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from 'vue';
import { log, err, toInt, isSet, isObject } from '@/data/helpers';
import { generateMessage, creationDate } from '@/shared/utils/notifications';

const state = () => ({
    notifications: [],
    notificationsDropdown: [],
    notificationsLength: 0,
});

const getters = {
    getMyNotificationsDropdown: currentState => currentState.notificationsDropdown,
    getMyNotifications: currentState => currentState.notifications,
    getMyNotificationsLength: currentState => currentState.notificationsLength,
};

const actions = {
  loadNotifications(context, { pp, off, location }) {
    return this.$nodeAxios.get(`/api/v1/notification/list?pp=${pp}&off=${off}&type=PUSH`).then(({ data }) => {
      if (data) {
        context.commit('setMyNotifications', { notifications: data, location });
      }
    }).catch(err);
  },
  loadNotificationsCount(context) {
    return this.$nodeAxios.get('/api/v1/notification/count').then(({ data }) => {
      if (data) {
        const { total } = data;
        context.commit('setMyNotificationsLength', { total });
      }
    }).catch(err);
  }
};

const mutations = {
    setMyNotifications(currentState, { notifications, location }) {
        let notificationsState = currentState.notifications;
        // We shouldn't filter the type MESSAGE because it belongs to the INBOX
        const filteredNotifications = notifications.filter(n => n.type !== 'MESSAGE');
        const mappedNotifications = filteredNotifications.map((n) => {
            const newNotification = {
                ...n,
                message: generateMessage(n, this.state.session.user),
                date: creationDate(n.created_at)
            }

            if (location !== 'dropdown') {
                notificationsState.push(newNotification);
            } else {
                return newNotification;
            }
        });
        if (location === 'dropdown') {
            Vue.set(currentState, 'notificationsDropdown', mappedNotifications);
        } else {
            Vue.set(currentState, 'notifications', notificationsState);
        }
    },
    setMyNotificationsLength(currentState, { total }) {
        Vue.set(currentState, 'notificationsLength', total);
    },
}

// export this module
export default {
    state,
    getters,
    actions,
    mutations,
};
