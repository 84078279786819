// helper functions
import { isNumber, isSet, clamp, log, err, DataError } from '../helpers';

function sanitizePost(post) {
  if (isSet(post._id)) {
    // eslint-disable-next-line
    post.id = post._id;
  }

  if (post.type === 'THOUGHT' || post.type === 'QUESTION') {
    // eslint-disable-next-line
    post.commentUrl = `/#post=${post._id}&comments=1`;
  }

  return post;
}

/**
 * sanitizePostsResponse
 *
 * consumes the raw response from ajax, and makes sure that all the required fields are present on each returned post
 *
 * @param object response  the raw data from the ajax
 * @return object  the translated version of the data
 */
function sanitizePostsResponse(response) {
  response.hits.forEach(sanitizePost);
  return response;
}

/**
 * getFeedFromUrl
 *
 * gets the feed items from a url
 *
 * @param string url  the url of the feed
 * @param object query  the query parameters for the url for the feed
 * @return Promise  the promise containing the results
 */
function getFeedFromUrl(url, query, $nodeAxios) {
  // perform the request and generate a promise for the response
  return $nodeAxios.get(url, { params: query })
    // get the response
    .then(response => sanitizePostsResponse(response.data))
    .catch(err);
}

/**
 * getFeedHandler
 *
 * get the function that will be used to generate the feed items list for the given feed
 *
 * @param string feedName  the name of the feed that we need the url for
 * @return function  the function that will be used to fetch the results
 */
function getFeedHandler(feedName, query, $nodeAxios) {
  switch (feedName) {
  case 'primary':
    return async () => {
      const data = await getFeedFromUrl('/api/v2/home/stream', query, $nodeAxios);
      return Promise.resolve(data);
    };
  default:
    return false;
  }
}

/**
 * loadFeedItems
 *
 * get the list of items in a feed, based on the feed name and the current offset
 *
 * @param string feedName  the name of the feed to get items for
 * @param integer offset  the offset of the list of items to get the items after. (default 0)
 * @param integer limit  the number of items to return. (default 8)
 */
function loadFeedItems(feedName, offset, limit, extra, $nodeAxios) {
  try {
    // build the core url query
    const query = {
      ...extra,
      pp: isNumber(limit) ? clamp(limit, 1, 100) : 10,
      off: isNumber(offset) ? clamp(offset, 0, 1000000000) : 0,
    };

    // get the function that will fetch the requested feed items
    const func = getFeedHandler(feedName, query, $nodeAxios);
    if (!func) {
      throw new DataError(`No api endpoint handler is defined for the feed '${feedName}'.`, { feedName, query });
    }

    return func();
  } catch (e) {
    return {};
  }
}

function getOne(contentId, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/content/${contentId}`)
    .then((rawData) => {
      log('got content:', rawData);
      sanitizePost(rawData.data);
      return rawData.data;
    })
    .catch((e) => {
      err('AJAX ERROR[getOne]:', e);
    });
}

export default {
  getOne,
  loadFeedItems,
};

