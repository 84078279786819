/* eslint no-param-reassign: 0 */
/* eslint no-console: 0 */
/* eslint prefer-rest-params: 0 */
import comments from '@/data/api/comments';

// initial state
const state = () => ({
  // whether to show the take-a-break modal or not
  showTakeABreak: false,

  // whether to show the report-a-comment modal or not
  showReportComment: false,

  // the id of the comment that is currently being reported
  currentlyBeingReportedCommentId: 0,

  // the id of the last comment that was reported. used to determine if the 'thank you' message should be displayed in the menu
  lastReportedCommentId: 0,

  // most recently updated/focused/edited field. used to help highlight fields, specifically the checkboxes field type
  lastEditedField: null,
});

// getters for states
const getters = {
  /**
   * getLastEditedField
   *
   * return the description of the last edited field (usually a field id)
   */
  getLastEditedField: currentState => currentState.lastEditedField,

  /**
   * showTakeABreak
   *
   * is the take-a-break modal supposed to be shown right now?
   *
   * @param object currentState  the current state of this handler
   */
  showTakeABreak: currentState => currentState.showTakeABreak,

  /**
   * getCurrentlyReporting
   *
   * the id of the comment that is in the process of being reported
   *
   * @param object currentState  the current state of this handler
   */
  getCurrentlyReporting: currentState => currentState.currentlyBeingReportedCommentId,

  /**
   * getLastReportedCommentId
   *
   * the id of the most recently, successfully reported comment.
   *
   * @param object currentState  the current state of this handler
   */
  getLastReported: currentState => currentState.lastReportedCommentId,

  /**
   * showReportComment
   *
   * is the report-comment modal supposed to be shown right now?
   *
   * @param object currentState  the current state of this handler
   */
  showReportComment: currentState => currentState.showReportComment,
};

// generic actions that are not mutations. usually process some logic then return a value
const actions = {
  /**
   * reportComment
   *
   * report a comment using the api
   *
   * @param object context  the current view being rendered, along with it's state and data
   * @param string commentId  id of the comment object to report
   * @param object reportData  additional data needed for the report. { status:[], feedback:'' }
   */
  reportComment(context, { commentId, reportData }) {
    // mark the comment as being the one that is currently being reported
    context.commit('setCurrentlyReporting', commentId);

    // check the data a little
    if (!reportData || !reportData.status || !Array.isArray(reportData.status)) {
      throw new Error('You must supply at least one reason for reporting this comment.');
    }

    // process the request to report the comment
    comments
      .reportComment(commentId, reportData, this.$nodeAxios, this.$ga)
      // when the request returns... interpret the response
      .then((response) => {
        // we are no longer in the process of reporting....
        context.commit('setCurrentlyReporting', 0);

        // if the report was successful, then update the last reported comment id. used to decide what to show in the menu pane for the comment
        if (response.statusCode === 200) {
          context.commit('setLastReported', commentId);
        }
      });
  },

  reportHighAlertComment(context, { commentId }) {
    // mark the comment as being the one that is currently being reported
    context.commit('setCurrentlyReporting', commentId);

    // process the request to report the comment
    comments
      .reportHighAlertComment(commentId, {}, this.$nodeAxios, this.$ga)
      // when the request returns... interpret the response
      .then((response) => {
        // we are no longer in the process of reporting....
        context.commit('setCurrentlyReporting', 0);

        // if the report was successful, then update the last reported comment id. used to decide what to show in the menu pane for the comment
        if (response.statusCode === 200) {
          context.commit('setLastReported', commentId);
        }
      });
  },
};

// mutate the data in the states
const mutations = {
  /**
   * setCurrentlyReporting
   *
   * sets the id of the comment that is currently beign reported
   *
   * @param object currentState  the object that describes the current state of this handle
   * @param string id  the id of the comment that is being reported currently
   */
  setCurrentlyReporting(currentState, id) {
    currentState.currentlyBeingReportedCommentId = id;
  },

  /**
   * setLastReported
   *
   * sets the id of the comment that was most recently reported
   *
   * @param object currentState  the object that describes the current state of this handle
   * @param string id  the id of the comment that was recently reported
   */
  setLastReported(currentState, id) {
    currentState.lastReportedCommentId = id;
  },

  /**
   * setLastEditedField
   *
   * sets the description for the last edited field
   *
   * @param object currentState  the object that describes the current state of this handle
   * @param mixed identifier  the identifier used to describe the last field that was edited. probably an id or name attribute value
   */
  setLastEditedField(currentState, identifier) {
    currentState.lastEditedField = identifier;
  },

  /**
   * toggleTakeABreak
   *
   * change the state of the take-a-break modal
   *
   * @param object currentState  the object describing the curetn state of this handler
   * @param boolean newState  the new state of the modal display
   */
  toggleTakeABreak(currentState, newState) {
    currentState.showTakeABreak = newState;
  },

  /**
   * toggleReportCommentModal
   *
   * change the state of the report a comment modal
   *
   * @param object currentState  the object describing the curetn state of this handler
   * @param object comment  the comment being reported. accepts null/undefined for closing
   */
  toggleReportCommentModal(currentState, comment) {
    currentState.showReportComment = comment;
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
