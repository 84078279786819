import { mapGetters } from 'vuex';

/**
 * this mixin adds a property to the element which tells whether the current screensize is large or small, based on our mobile/desktop breakpoint
 */
export default {
  // get properties derived from other data
  computed: {
    ...mapGetters({
      isLargeScreen: 'isLargeScreen',
      dimensions: 'getWindowDimensions',
      isPostWithImageScreen: 'isPostWithImageScreen',
    }),
    isSmallScreen() {
      return !this.isLargeScreen;
    },
  },
};
