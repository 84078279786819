/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';
import groupsApi from '@/data/api/groups';
import bookmarksApi from '@/data/api/bookmarks';
import { sanitizeFollowType, sanitizeFollowId } from '@/data/follow-helpers';
import { log, toInt, isSet, isObject } from '@/data/helpers';

function getDefaultGroup() {
  return {
  };
}

function getGroupHelper(currentState) {
  if (currentState.group === null) {
    Vue.set(currentState, 'group', getDefaultGroup());
  }

  return currentState.group;
}

const state = () => ({
  slug: "",
  group: null,
  followedGroups: [],
  allPosts: {},
  members: [],
  doneLoadingMembers: false,
  applications: [],
  doneLoadingApplications: false,
  // using this for the CommunityInfoCard to watch if a user Joins the Group from a post action (Save/Comment)
  joinedGroup: false,
  roleCounts: {
    member: 0,
    moderation: 0,
    pending_applications: 0,
    saved_by_leader: 0,
    saved_by_you: 0,
  },
  groupFeeds: {
    counts: {
      stories: 0,
      thoughts: 0,
      questions: 0,
      total: 0,
    },
    gotInitialCount: false,
    trending: {
      loading: {
        pending: true,
        failed: true,
      },
      items: [],
      offset: 0,
      last_fetched_item_count: 0,
      types: [],
    },
    latest: {
      loading: {
        pending: true,
        failed: true,
      },
      items: [],
      offset: 0,
      last_fetched_item_count: 0,
      types: [],
    },
  },
});

const getters = {
  getGroup: (currentState) => {
    if (currentState.group === null) {
      return getDefaultGroup();
    }
    return currentState.group;
  },

  getGroupSetFromGroupsApi: currentState => currentState.getGroupSetFromGroupsApi,

  getMygroups: (currentState) => {
    return currentState.followedGroups;
  },

  /**
   * getFeedItems
   *
   * get the items inside the feed
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return array  list of items in the feed
   */
  getGroupFeedItems: currentState => function privGetFeedItems(feedName) {
    return isSet(currentState.groupFeeds[feedName]) && isObject(currentState.groupFeeds[feedName])
      ? currentState.groupFeeds[feedName].items
      : [];
  },
  /**
   * getGroupFeedItemLoadingState
   *
   * get the state of loading the feed items
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to get the items of
   * @return object  the state describing the loading status
   */
  getGroupFeedItemLoadingState: currentState => function privGetFeedItemLoadingState(feedName) {
    return isSet(currentState.groupFeeds[feedName]) && isObject(currentState.groupFeeds[feedName])
      ? currentState.groupFeeds[feedName].loading
      : { pending: false, failed: true };
  },

  getGroupMembers: currentState => currentState.members,

  getDoneLoadingMembers: currentState => currentState.doneLoadingMembers,

  getGroupApplications: currentState => currentState.applications,

  getDoneLoadingApplications: currentState => currentState.doneLoadingApplications,

  getRoleCounts: currentState => currentState.roleCounts,

  getJoinedGroupTrigger: currentState => currentState.joinedGroup,

  getGroupFeedItemTotal: currentState => function privGetFeedItems(feedName) {
    return isSet(currentState.groupFeeds) && isObject(currentState.groupFeeds)
      ? currentState.groupFeeds.counts.total
      : 0;
  },

  getGroupFeedItemCounts: currentState => function privGetFeedItems(feedName) {
    return isSet(currentState.groupFeeds) && isObject(currentState.groupFeeds)
      ? currentState.groupFeeds.counts
      : {
        stories: 0,
        thoughts: 0,
        questions: 0,
        total: 0,
      };
  },
};

const actions = {
  loadGroup(context, { groupSlug }) {
    const slug = context.state.slug || groupSlug;

    if (!slug) {
      log('No group slug to load feed');
      return false;
    }

    return groupsApi.groupBySlug(slug, this.$nodeAxios)
      .then((rawData) => {

        if (rawData && rawData.data) {
          context.commit('setGroup', rawData.data);
          context.commit('setGroupSetFromGroupsApi')
        }
      })
      .catch((err) => {
        log('Error getting group:', err);
      });
  },

  loadMyGroups(context, { username }) {
    return groupsApi.loadUserGroups(username, this.$nodeAxios)
      .then((rawData) => {
        if (rawData && rawData.data) {
          context.commit('setMyGroups', rawData.data.follows);
        }
      })
      .catch((err) => {
        log('Error getting current user group info:', err);
      });
  },

  /**
   * loadGroupFeedItems
   *
   * get all the items in a given feed, and update the store with the result
   *
   * @param object context  the context of the current store
   * @param string feedName  the name of the feed to load the items of
   * @return Promise  handles the updating of the internal store data
   */
  loadGroupFeedItems(context, { feedName, offset, limit, types }) {
    const slug = context.state.slug;
    if (!slug) {
      log('No group slug to load feed');
      return false;
    }

    types = types || context.state.groupFeeds[feedName].types;

    if (!types) {
      log('Feed has not types');
    }

    context.commit('setGroupFeedLoadingState', { feedName, stateName: 'pending', value: true });

    // obtain the feed item list from the api
    return groupsApi.postsForGroup(slug, feedName, offset, limit, types, this.$nodeAxios)
      // with successful results...
      .then((rawData) => {
        context.commit('setGroupFeedLoadingState', { feedName, stateName: 'pending', value: false });
        // set the post list
        if (rawData && rawData.hits) {
          // on the first page, update the total, because it is not soured by a date range query
          if (offset == 0) {
            context.commit('appendGroupFeedItems', { feedName, items: rawData.hits, counts: rawData.counts });
            // on all other pages, only update the items
          } else {
            context.commit('appendGroupFeedItems', { feedName, items: rawData.hits, appendData: true });
          }
        } else {
          context.commit('setLastFetchedItemCount', { feedName, total: 0 });
        }
      })
      .catch((e) => {
        log('ERROR FEED ITEMS:', feedName, e);
        context.commit('setGroupFeedLoadingState', { feedName, stateName: 'pending', value: false });
        context.commit('setGroupFeedLoadingState', { feedName, stateName: 'failed', value: true });
      });
  },

  loadGroupMembers(context, { offset, limit, roles = null }) {
    const slug = context.state.slug;
    if (!slug) {
      log('No group slug');
      return false;
    }

    return groupsApi.groupMembers(slug, offset, limit, roles, this.$nodeAxios)
      .then((data) => {
        if (data) {
          context.commit('setMembers', data);
        }
      });
  },

  loadGroupApplications(context, { offset, limit, statuses }) {
    const slug = context.state.slug;
    if (!slug) {
      log('No group slug');
      return false
    }

    return groupsApi.groupApplications(slug, offset, limit, statuses, this.$nodeAxios)
      .then((data) => {
        context.commit('setApplications', data);
      })
      .catch((err) => {
        log('Error getting group applications:', err);
      });
  },

  modifyGroupApplications(context, { action, application_ids }) {
    const slug = context.state.slug;
    if (!slug) {
      log('No group slug');
      return false
    }

    return groupsApi.updateGroupApplications(slug, action, application_ids, this.$nodeAxios)
      .then(() => {
        context.commit('updateApplications', { application_ids });
        context.commit('updateRoleCounts', { application_ids, type: 'applications' });
      })
      .catch((err) => {
        log('Error updating group applications:', err);
      });
  },

  updateGroup(context, { updatedGroup }) {
    const slug = context.state.slug;
    if (!slug) {
      log ('No group slug');
      return false;
    }

    return groupsApi.updateGroup(slug, updatedGroup, this.$nodeAxios);
  },


  flagGroupUser(context, { reportedUserId, type, feedback, reportContext }) {
    return groupsApi.flagGroupUser(reportedUserId, type, feedback, reportContext, this.$nodeAxios)
      .then()
      .catch((err) => {
        log('Error flagging user group application:', err);
      });
  },

  banGroupMember(context, { reportedUserId, groupSlug }) {
    const slug = context.state.slug || groupSlug;
    if (!slug) {
      log('No group slug');
      return false
    }

    return groupsApi.banGroupMember(slug, reportedUserId, this.$nodeAxios)
      .then()
      .catch((err) => {
        log('Error banning member from group:', err);
      })
  },

  UnbanGroupMember(context, { userId, groupSlug }) {
    const slug = context.state.slug || groupSlug;
    if (!slug) {
      log('No group slug');
      return false
    }

    return groupsApi.unbanGroupMember(slug, userId, this.$nodeAxios)
      .then()
      .catch((err) => {
        log('Error unbanning member from group:', err);
      })
  },

  submitGroupApplication(context, { groupSlug, application }) {
    const slug = groupSlug || context.state.slug;
    if (!slug) {
      log('No group slug');
      return false
    }

    return groupsApi.submitGroupApplication(slug, application, this.$nodeAxios)
      .then()
      .catch((err) => {
        log('Error submitting application', err);
      });
  },

  updateApplicationUserInfo(context, { userId, key, value }) {
    context.commit('updateApplicationUserInfo', { userId, key, value });
  },

  subtractMemberFromRoleCounts(context) {
    context.commit('updateRoleCounts', { increment: -1, type: 'member' });
  },

  updateUserApplicationStatus(context) {
    context.commit('setUserApplicationStatus', { pending_approval: true })
  },

  acceptGroupGuidelines(context, { slug }) {
    const groupSlug = slug || context.state.slug;
    if (!groupSlug) {
      log('No group slug');
      return false
    }

    return groupsApi.acceptGroupGuidelines(groupSlug, context.state.group, this.$nodeAxios)
      .then((data) => {
        if (context.state.slug) {
          context.commit('updateFollowedGroupRole', data);
          context.commit('updateRoleCounts', { increment: 1, type: 'member' });
        }
      })
      .catch((err) => {
        log('Error accepting group guidelines', err);
      });
  },

  loadRoleCounts(context) {
    const slug = context.state.slug;
    if (!slug) {
      log('No group slug');
      return false
    }

    return groupsApi.roleCounts(slug, this.$nodeAxios)
      .then(data => {
        if (data) {
          context.commit('setRoleCounts', data);
        }
      });
  },

  joinGroup(context, { groupSlug }) {
    const slug = context.state.slug || groupSlug;
    if (!slug) {
      log('No group slug');
      return false;
    }

    return groupsApi.joinGroup(slug, this.$nodeAxios)
      .then((data) => {
        context.commit('updateUserGroupInfo', data);
        context.commit('setJoinedGroupTrigger', {});
      });
  },

  leaveGroup(context, { groupName }) {
    const slug = context.state.slug || groupName;

    if (!slug) {
      log('No group slug');
      return false;
    }

    return groupsApi.leaveGroup(slug, this.$nodeAxios)
      .then((data) => {
        // possible to leave group from non-group page
        if (context.state.group && context.state.group._id) {
          context.commit('updateUserGroupInfo', data);
        }
      });
  },

  updateBanner(context, { data }) {
    const id = context.state.group._id;
    return groupsApi.updateBanner(id, data, this.$nodeAxios);
  },

  loadGroupSavedContentCounts(context, { groupId, limit, savedBy }) {
    let initiatorType = 'GROUP';
    if (savedBy === 'saved_by_you') {
      initiatorType = 'USER';
    }
    return bookmarksApi.loadSavedContent({ $nodeAxios: this.$nodeAxios, groupId, limit, initiatorType })
      .then((data) => {
        context.commit('setSavedContentCounts', { type: savedBy, count: data.query.all_total });
      })
      .catch((err) => {
        log('Error getting saved content for group:', err);
      });
  },
};

// helpers

const mutations = {
  setGroupSlug(currentState, slug) {
    Vue.set(currentState, 'slug', slug);
  },

  /**
   * setGroup
   *
   *
   * @param currentState
   * @param item
   */
  setGroup(currentState, item) {
    Vue.set(currentState, 'group', item);

    // Payload for members is different then non-members, where for non-members the status field is being returned as a string
    if (typeof item.status === 'string') {
      Vue.set(currentState.group, 'status', { type: item.status });
    }

    // Similarly to above, the non-members Payload returns a role_counts while members Payload has action_counts, both with different structure
    if (item.role_counts) {
      const action_counts = {};
      _.forEach(item.role_counts, i => {
        action_counts[`${i._id.toLowerCase()}s`] = i.count;
      })
      Vue.set(currentState.group, 'action_counts', action_counts);
    }
  },

  /**
   * setGroupSetFromGroupsApi
   *
   * informs whether getGroups will have important data
   *  like for example pending_approval status for the user's membership application
   * @param currentState
   */
  setGroupSetFromGroupsApi(currentState) {
    Vue.set(currentState, 'getGroupSetFromGroupsApi', true);
  },

  setMyGroups(currentState, item) {
    Vue.set(currentState, 'followedGroups', item);
  },

  appendGroupFeedItems(currentState, { feedName, items, counts, appendData } = { feedName: '', items: [] }) {
    if (!appendData) {
      currentState.groupFeeds[feedName].items = [];
      if (feedName === 'photos') {
        Vue.set(currentState.groupFeeds, 'gotInitialCount', false);
      }
      if (!currentState.groupFeeds.gotInitialCount) {
        Vue.set(currentState.groupFeeds, 'counts', counts);
        Vue.set(currentState.groupFeeds, 'total', counts.total);
        Vue.set(currentState.groupFeeds, 'gotInitialCount', true);
      }
      if (feedName === 'photos') {
        Vue.set(currentState.groupFeeds, 'gotInitialCount', false);
      }
    }
    const list = currentState.groupFeeds[feedName].items || [];
    const itemIds = list.map(a => a._id);
    const all = currentState.allPosts;

    // cycle through the new items
    items.forEach((_, i) => {
      const itm = items[i];
      itm.author = itm.author_id;
      itm.author_id = itm.author._id;

      // add the item to the list
      if (itemIds.indexOf(itm._id) === -1) {
        list.push(itm);
        all[itm._id] = itm;
      }
    });

    // set the final list in vue
    Vue.set(currentState.groupFeeds[feedName], 'items', list);
    Vue.set(currentState.groupFeeds[feedName], 'offset', list.length);
    Vue.set(currentState.groupFeeds[feedName], 'last_fetched_item_count', items.length);

    Vue.set(currentState, 'allPosts', all);
  },

  /**
   * setFeedLoadingState
   *
   * sets the state of the feed's loading situation
   *
   * @param object currentState  the current state of this handle
   * @param string feedName  the name of the feed to modify
   * @param string stateName  the state to update
   * @param bool value  the new value for the state
   */
  setGroupFeedLoadingState(currentState, { feedName, stateName, value }) {
    Vue.set(currentState.groupFeeds[feedName].loading, stateName, value);
  },

  setMembers(currentState, data) {
    if (!data.append) {
      Vue.set(currentState, 'members', data.members);
    } else {
      Vue.set(currentState, 'members', currentState.members.concat(data.members));
    }
    Vue.set(currentState, 'doneLoadingMembers', data.done);
  },

  setApplications(currentState, data) {
    if (!data.append) {
      Vue.set(currentState, 'applications', data.applications);
    } else {
      Vue.set(currentState, 'applications', currentState.applications.concat(data.applications));
    }
    Vue.set(currentState, 'doneLoadingApplications', data.done);
  },

  updateApplications(currentState, data) {
    Vue.set(currentState, 'applications', currentState.applications.filter((application) => {
      return !data.application_ids.includes(application._id);
    }));
  },

  updateApplicationUserInfo(currentState, data) {
    Vue.set(currentState, 'applications', currentState.applications.map((application) => {
      if (application.user._id === data.userId) {
        application.user[data.key] = data.value;
      }
      return application;
    }));
  },

  setRoleCounts(currentState, data) {
    Vue.set(currentState, 'roleCounts', data);
  },

  updateRoleCounts(currentState, data) {
    if (data.type === 'applications') {
      Vue.set(currentState.roleCounts, 'pending_applications', currentState.roleCounts.pending_applications - data.application_ids.length);
    }
    if (data.type === 'member') {
      Vue.set(currentState.roleCounts, 'member', currentState.roleCounts.member + data.increment);
    }
  },

  setUserApplicationStatus(currentState, data) {
    Vue.set(currentState.group, 'pending_approval', data.pending_approval);
  },

  updateFollowedGroupRole(currentState, data) {
    Vue.set(currentState, 'followedGroups', currentState.followedGroups.map((group) => {
      if (group._id === data.group) {
        group.role = data.role;
        group.flags.accepted_guidelines = true;
        group.flags.completed_onboarding = true;
      }
      return group;
    }));
  },

  updateUserGroupInfo(currentState, data) {
    if(!data) {
      return;
    }
    if (data.data.deletedCount === 1) {
      currentState.followedGroups.splice(currentState.followedGroups.findIndex(item => item._id === currentState.group._id), 1);
      return;
    }
    currentState.followedGroups.push({ _id: data.data.group });
  },

  setSavedContentCounts(currentState, { type, count }) {
    Vue.set(currentState.roleCounts, type, count);
  },

  setJoinedGroupTrigger(currentState, {}) {
    Vue.set(currentState, 'joinedGroup', true);
  },

  resetGroupState(currentState) {
    Object.assign(currentState, state());
  },
};

// export this module
export default {
  state,
  getters,
  actions,
  mutations,
};
