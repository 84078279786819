/* eslint-disable */
import { genAnalyticsEventDefaultData } from '@/data/helpers';

// valid values for the follow action
const validBtnTypes = ['follow', 'unfollow'];
const state = () => ({});
// topic feed event base
const topicFeedEvent = genAnalyticsEventDefaultData({
  context: {
    view: 'topic_feed',
    section: { name: 'about' },
  },
  object: {
    purpose: 'engagement',
  },
});

const actions = {
  voiceEvent(context, payload) {
    if(!payload.hasOwnProperty('label')) {
      payload.label = this.$router.currentRoute.fullPath;
    }

    this.$sendAnalyticsEvent(payload, ['ga']);
  },

  /**
   * toggleFollowTopicEvent
   *
   * when the user toggles their follow status on topics, trigger an event
   *
   * @param string type  the type of action. valid ['follow', 'unfollow']
   * @param string topicId  the id of the topic that the follow was toggled for
   */
  toggleFollowTopicEvent(context, { type, topicId }) {
    // the normalized name of the button type
    const btnType = type.toLowerCase();

    // if a valid button type is not supplied
    if (validBtnTypes.indexOf(btnType) < 0) {
      return;
    }

    // build the event
    const data = topicFeedEvent({
      cordial_event: type,
      category: `topic_feed.about`,
      action: type,
      label: topicId,
      context: {
        path: `topic_feed.about`,
      },
      object: {
        purpose: 'retention',
      },
    });

    // send the event
    this.$sendAnalyticsEvent(data);
  },
};

// export this module
export default {
  state,
  getters: {},
  actions,
  mutations: {},
};
