/* eslint no-underscore-dangle:0 */
/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';

const state = () => ({
    parentItem: '',
    navItem: '',
    inAboutPage: false,
    showMsgOptionsPanel: false,
    showGlobalOptionsPanel: false,
    showSavedMessagesPanel: false,
    selectedMsg: null,
});

const getters = {
    getParentItem: currentState => currentState.parentItem,
    getNavItem: currentState => currentState.navItem,
    getInAboutPage: currentState => currentState.inAboutPage,
    getShowMsgOptionsPanel: currentState => currentState.showMsgOptionsPanel,
    getShowGlobalOptionsPanel: currentState => currentState.showGlobalOptionsPanel,
    getShowSavedMessagesPanel: currentState => currentState.showSavedMessagesPanel,
    getSelectedMsg: currentState => currentState.selectedMsg,
};

const actions = {
    selectParent(context, tab) {
        context.commit('setParent', { tab })
    },
    selectNav(context, tab) {
        context.commit('setNavItem', { tab })
    },
    toggleAboutPage(context) {
        context.commit('setInAboutPage', { toggle: !context.state.inAboutPage })
    },
    toggleSavedMessagesPanel(context) {
      context.commit('setShowSavedMessagesPanel', { toggle: !context.state.showSavedMessagesPanel })
    },
    showMsgOptionsPanel(context, { open, msg = null }) {
      context.commit('setShowMsgOptionsPanel', { toggle: open ?? !context.state.showMsgOptionsPanel, msg })
    },
    showGlobalOptionsPanel(context) {
      context.commit('setShowGlobalOptionsPanel', { toggle: !context.state.showGlobalOptionsPanel })
    },
    selectMsg(context, { msg }) {
      context.commit('setSelectedMsg', { msg: msg })
    }
};

const mutations = {
    setParent(currentState, { tab }) {
      Vue.set(currentState, 'parentItem', tab);
    },
    setNavItem(currentState, { tab }) {
      Vue.set(currentState, 'navItem', tab);
    },
    setInAboutPage(currentState, { toggle }) {
      Vue.set(currentState, 'inAboutPage', toggle);
    },
    setShowSavedMessagesPanel(currentState, { toggle }) {
      Vue.set(currentState, 'showSavedMessagesPanel', toggle);
    },
    setShowMsgOptionsPanel(currentState, { toggle, msg }) {
      Vue.set(currentState, 'showMsgOptionsPanel', toggle);
      Vue.set(currentState, 'selectedMsg', msg);
    },
    setShowGlobalOptionsPanel(currentState, { toggle }) {
      Vue.set(currentState, 'showGlobalOptionsPanel', toggle);
    },
    setSelectedMsg(currentState, { msg }) {
      Vue.set(currentState, 'selectedMsg', msg);
    },
}

// export this module
export default {
    state,
    getters,
    actions,
    mutations,
  };
