/* eslint no-param-reassign: 0 */
/* eslint prefer-rest-params: 0 */
import Vue from 'vue';
import _ from 'lodash';
import { replaceLinks } from '@/data/submission-helpers';
import comments from '@/data/api/comments';
import postApi from '@/data/api/post';
import { log, toInt, isSet, clamp, dynamicStoreVariableAssignment } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';

// logger
// import { log } from '../helpers';

/**
 * makeNewCommentForBlock
 *
 * create a new 'commentFor' block in our data structure
 *
 * @param Object state  the current state handler object
 * @param string contentId  the id of the new key to make
 */
function makeNewCommentForBlock(currentState, contentId) {
  if (!isSet(currentState.commentsFor[contentId]) || !isSet(currentState.commentsFor[contentId].comments)) {
    const newStuff = {};
    newStuff[contentId] = {
      total: 0,
      comments: [],
    };
    Vue.set(currentState, 'commentsFor', Object.assign({}, currentState.commentsFor, newStuff));
  }
}

/**
 * updateCommentIndex
 *
 * update the comment index and the count of the comments
 * @recursive
 *
 * @param object currentState  the current state of the handler
 * @param array commentList  the list of comments to index
 * @param string contentId  the content id for the list of comments to update the index for
 */
function updateCommentIndex(currentState, commentList, contentId) {
  // default to the master list of comments if not specifically supplied
  if (!commentList) {
    if (contentId && isSet(currentState.commentsFor[contentId])) {
      commentList = currentState.commentsFor[contentId] || [];
    } else {
      commentList = currentState.comments || [];
    }
    currentState.commentsById = {};
  }

  let i;
  // cycle through the list, and update the index, recursively
  for (i = 0; i < commentList.length; i += 1) {
    currentState.commentsById[`${commentList[i].id}`] = commentList[i];
    if (commentList[i].replies && commentList[i].replies.length) {
      updateCommentIndex(currentState, commentList[i].replies);
    }
  }
}

/**
 * getValueForContentId
 *
 * helper to get a value off a comment list record
 *
 * @param object currentState  the current state of the store
 * @param string contentId  the string id of the content
 * @param string field  the name of the field to extract from the record
 * @param mixed defaultValue  the fallback value if the record is not found or the entry is not found
 */
function getValueForContentId(currentState, contentId, field, defaultValue) {
  if (!isSet(currentState.commentsFor[contentId])) {
    // log('getValueForContentId[missing-record]:', contentId, currentState.commentsFor);
    return defaultValue;
  }

  if (!isSet(currentState.commentsFor[contentId][field])) {
    // log('getValueForContentId[record-missing-field]:', contentId, field, currentState.commentsFor[contentId]);
    return defaultValue;
  }

  return currentState.commentsFor[contentId][field];
}

/**
 * dynamicReassignment
 *
 * vuejs CANNOT detect changes in dynamically added object or object keys. because of this, we have to hack that shit in there
 * @NOTE - there is no real way to simplify this and still have dynamic property updating register in the vue component
 * @reference - https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
 *
 * @param object currentState  the current state to update
 * @param string contentId  the id of the content to update the data on
 * @param mixed newData  the new data to pass to the translation function
 * @param function translation  this function performs the needed operations on the existing data, and shapes a new object to be stored in the contentId key
 */
function dynamicReassignment(currentState, contentId, newData, translation) {
  dynamicStoreVariableAssignment(currentState, 'commentsFor', contentId, newData, translation);
}

function triggerReplyChanges(currentState, contentId, commentId) {
  dynamicReassignment(currentState, contentId, [{ id: commentId }], (data, intoObj) => intoObj);
}


// initial state
const state = () => ({
  post_id: null,
  comments: [],
  totalCommentCnt: 0,
  commentsById: {},
  limit: 8,
  specificCommentId: '',

  // postId indexed comment lists
  commentsFor: {},

  projectCommentsFeed: [],
});

// getters
const getters = {
  /**
   * userCommentHearts
   *
   * @param object currentState  the current state of this handler
   */
  userCommentHearts: currentState => currentState.userHearts,

  /**
   * userHeartedComment
   *
   * determine if the user has hearted the given comment
   *
   * @param object currentState  the current state of this handler
   * @param object contextGetters  the getters for the current context
   */
  userHeartedComment: currentState =>
    commentId => currentState.commentsById[`${commentId}`].hearted,

  /**
   * getCommentCnt
   *
   * get the total comments for the current content_id
   *
   * @param object currentState  the current state of this handler
   */
  getCommentCnt: currentState => currentState.totalCommentCnt,

  /**
   * allComments
   *
   * get a list of all the comments that have been loaded
   *
   * @param object currentState  the current state of this handler
   */
  allComments: currentState => currentState.comments,

  /**
   * getSpecificCommentId
   *
   * when loading a specific comment (like for comment permalink), we need ot be able to test the id of the comment
   *
   * @param object currentState  the current state of this handler
   */
  getSpecificCommentId: currentState => currentState.specificCommentId,
  getSpecificCommentIdFor: currentState => contentId => getValueForContentId(currentState, contentId, 'specificCommentId', 0),

  /**
   * getCommentCntFor
   *
   * get the total comments for A SPECIFIC content_id
   *
   * @param object currentState  the current state of this handler
   * @param string contentId  the id of the content to fetch the comment list for
   */
  getCommentCntFor: currentState => contentId => getValueForContentId(currentState, contentId, 'total', 0),

  /**
   * allCommentsFor
   *
   * get a list of all the comments that have been loaded for a SPECIFIC content_id
   *
   * @param object currentState  the current state of this handler
   * @param string contentId  the id of the content to fetch the comment list for
   */
  allCommentsFor: currentState => contentId => getValueForContentId(currentState, contentId, 'comments', []),

  projectCommentsFeed: currentState => currentState.projectCommentsFeed,
};

// actions
const actions = {
  /**
   * loadCommentsFor
   *
   * load all the comments for A SPECIFIC content_id
   *
   * @param object context  describes the current context of the comment section being modified
   * @param string contentId  the id of the content to load the comments for
   * @param function after  the function to run after the comments are loaded
   * @return Promise  handles the updating of the internal store data
   */
  loadCommentsFor(context, { after, contentId }) {
    // log('COMMENTS_MODULE[loadCommentsFor]: loading comments');
    const last = isSet(context.state.commentsFor[contentId]) && isSet(context.state.commentsFor[contentId].comments) && context.state.commentsFor[contentId].comments.length
      ? context.state.commentsFor[contentId].comments[context.state.commentsFor[contentId].comments.length - 1].id
      : null;
    if (isSet(context.state.commentsFor[contentId])) {
      log('LOAD_COMMENTS_FOR:', contentId, last, context.state.commentsFor[contentId].comments.length, context.state.commentsFor[contentId].comments);
    }
    return comments
      .commentsForPost(contentId, context.state.limit, last, '', this.$nodeAxios, this.$ga)
      .then((data) => {
        context.commit('setTotalFor', { contentId, cnt: data.maxOffset });
        // this edits offset indirectly
        context.commit('appendCommentsFor', { contentId, commentsList: data.items });
        // context can change before setOffset dispatched
        if (typeof after === 'function') {
          after();
        }
      });
  },

  /**
   * loadPreviousCommentsFor
   *
   * load all the comments for A SPECIFIC contentId, that exist before the top most comment on display
   *
   * @param object context  describes the current context of the comment section being modified
   * @param string contentId  the id of the content to load the comments for
   * @param function after  the function to run after the comments are loaded
   * @return Promise  handles the updating of the internal store data
   */
  loadPreviousCommentsFor(context, { after, contentId }) {
    const first = isSet(context.state.commentsFor[contentId]) && isSet(context.state.commentsFor[contentId].comments) && context.state.commentsFor[contentId].comments.length
      ? context.state.commentsFor[contentId].comments[0].id
      : null;
    return comments
      .commentsForPost(contentId, context.state.limit, '', first, this.$nodeAxios, this.$ga)
      .then((data) => {
        context.commit('setTotalFor', { contentId, cnt: data.maxOffset });
        // this edits offset indirectly
        context.commit('prependCommentsFor', { contentId, commentsList: data.items });
        // context can change before setOffset dispatched
        if (typeof after === 'function') {
          after();
        }
      });
  },

  /**
   * loadSpecificCommentFor
   *
   * load one specific comment, on page load, and setup prev and next indicators, for the current TnQ
   *
   * @param object context  describs the current contect of the comment section being modified
   * @param string commentId  the id of the comment to load
   */
  loadSpecificCommentFor(context, { after, commentId, contentId }) {
    context.specificCommentId = commentId;
    return comments
      .commentById(commentId, this.$nodeAxios)
      .then((data) => {
        context.commit('setTotalFor', { contentId, cnt: data.total });
        context.commit('setSpecificCommentIdFor', { contentId, commentId });
        context.commit('appendCommentsFor', { contentId, commentsList: data.items });
        if (typeof after === 'function') {
          after();
        }
      });
  },

  /**
   * loadComments
   *
   * load all the comments for the current post
   *
   * @param object context  describes the current context of the comment section being modified
   * @param function after  the function to run after the comments are loaded
   * @return Promise  handles the updating of the internal store data
   */
  loadComments(context, after) {
    const last = context.state.comments.length
      ? context.state.comments[context.state.comments.length - 1].id
      : null;
    return comments
      .commentsForPost(context.state.post_id, context.state.limit, last, '', this.$nodeAxios, this.$ga)
      .then((data) => {
        context.commit('setTotal', data.maxOffset);
        // this edits offset indirectly
        context.commit('appendComments', data.items);
        // context can change before setOffset dispatched
        if (typeof after === 'function') {
          after();
        }
      });
  },

  /**
   * loadPreviousComments
   *
   * load all the comments for the current post, that exist before the top most comment on display
   *
   * @param object context  describes the current context of the comment section being modified
   * @param function after  the function to run after the comments are loaded
   * @return Promise  handles the updating of the internal store data
   */
  loadPreviousComments(context, after) {
    const first = context.state.comments.length
      ? context.state.comments[0].id
      : null;
    return comments
      .commentsForPost(context.state.post_id, context.state.limit, '', first, this.$nodeAxios, this.$ga)
      .then((data) => {
        context.commit('setTotal', data.maxOffset);
        // this edits offset indirectly
        context.commit('prependComments', data.items);
        // context can change before setOffset dispatched
        if (typeof after === 'function') {
          after();
        }
      });
  },

  /**
   * loadSpecificComment
   *
   * load one specific comment, on page load, and setup prev and next indicators
   *
   * @param object context  describs the current contect of the comment section being modified
   * @param string commentId  the id of the comment to load
   */
  loadSpecificComment(context, { commentId }) {
    context.specificCommentId = commentId;
    return comments
      .commentById(commentId, this.$nodeAxios)
      .then((data) => {
        context.commit('setTotal', data.total);
        context.commit('setSpecificCommentId', commentId);
        context.commit('appendComments', data.items);
      });
  },

  /**
   * loadMoreReplies
   *
   * load more replies for the specified comment
   *
   * @param object context  describes the current state of the current comment section being modified
   * @param string commentId  the id of the comment that needs more replies loaded
   */
  loadMoreReplies(context, commentId) {
    // find the parent comment
    const comment = typeof context.state.commentsById[`${commentId}`] !== 'undefined' && context.state.commentsById[`${commentId}`] !== null
      ? context.state.commentsById[`${commentId}`]
      : null;

    let res;
    // if the comment is not found, then bail
    if (!comment) {
      res = new Promise(() => []);
    // otherwise, add the replies
    } else {
      const last = comment.replies.length
        ? comment.replies[comment.replies.length - 1].id
        : '';
      res = comments
        .repliesForComment(commentId, context.state.limit, last, '', this.$nodeAxios)
        .then((data) => {
          context.commit('appendReplies', { commentId: comment.id, replies: data });
        });
    }

    return res;
  },

  /**
   * loadPreviousReplies
   *
   * load more replies for the specified comment which exist before the first displayed reply
   *
   * @param object context  describes the current state of the current comment section being modified
   * @param string commentId  the id of the comment that needs more replies loaded
   */
  loadPreviousReplies(context, commentId) {
    // find the parent comment
    const comment = typeof context.state.commentsById[`${commentId}`] !== 'undefined' && context.state.commentsById[`${commentId}`] !== null
      ? context.state.commentsById[`${commentId}`]
      : null;

    let res;
    // if the comment is not found, then bail
    if (!comment) {
      res = new Promise(() => []);
    // otherwise, add the replies
    } else {
      const first = comment.replies.length
        ? comment.replies[0].id
        : '';
      res = comments
        .repliesForComment(commentId, context.state.limit, '', first, this.$nodeAxios)
        .then((data) => {
          context.commit('prependReplies', { commentId: comment.id, replies: data });
        });
    }

    return res;
  },

  /**
   * getComment
   *
   * get the comment object of the comment that goes by the supplied id
   *
   * @param object context  the current view being rendered, along with it's state and data
   * @param string commentId  id of the comment object to fetch
   */
  getComment(context, commentId) {
    return context.state.commentsById[`${commentId}`] || null;
  },

  /**
   * deleteComment
   *
   * delete a comment using the api
   *
   * @param object context  the current view being rendered, along with it's state and data
   * @param string commentId  id of the comment object to report
   */
  deleteComment(context, commentId) {
    const comment = typeof context.state.commentsById[`${commentId}`] !== 'undefined' && context.state.commentsById[`${commentId}`] !== null
      ? context.state.commentsById[`${commentId}`]
      : null;
    // pass comment object itself to update counts on content
    context.commit('removeCommentFromList', { commentId, comment, replyCount: comment.reply_count });
    return comments.deleteComment(commentId, this.$nodeAxios, this.$ga);
  },

  /**
   * toggleUserHeartedComment
   *
   * either turn the heart on or off for the supplied comment
   *
   * @param object context  the current view being rendered, along with it's state and data
   * @param string commentId  id of the comment object to fetch
   */
  toggleUserHeartedComment(context, params) {
    const { commentId } = params;
    const userId = _.get(context.rootState, 'session.user._id', '');
    const sessionId = _.get(context.rootState, 'session.user.sessionId', '');
    let promise;
    // if the user hearted the comment already, then unheart..
    if (context.getters.userHeartedComment(commentId)) {
      // update the context with the removed heart record
      context.commit('removeCommentHeart', params);

      // use the api to remove the heart, in the form of a promise
      promise = comments.removeCommentHeart({ ...params, $nodeAxios: this.$nodeAxios, $ga: this.$ga })
        .then(() => {
          segmentAnalytics.unheart({
            ...params.segmentData,
            userId,
            sessionId,
          });
        })
        .catch(() => context.commit('addCommentHeart', params));
    // otherwise heart it
    } else {
      // update the context with the new heart record
      context.commit('addCommentHeart', params);

      // use the api to add the heart, in the form of a promise
      promise = comments.addCommentHeart({...params, $nodeAxios: this.$nodeAxios, $ga: this.$ga })
        .then(() => {
          segmentAnalytics.heart({
            ...params.segmentData,
            userId,
            sessionId,
          });
        })
        .catch(() => context.commit('removeCommentHeart', params));
    }
    // return the promise so that they can use it to do something when it resolves
    return promise;
  },

  /**
   * submitComment
   *
   * submit a new comment to the api
   *
   * @param object context  the current context of the this instance of the element
   * @param object data  the data that we are sending in the package to the server
   * @param function after  the function to run after the request is done
   */
  submitComment(context, { data, after }) {
    // normalize the after to a function
    after = typeof after === 'function' ? after : () => {};

    // fix body links please
    const sane = data;
    if (isSet(sane.body)) {
      sane.body = replaceLinks(sane.body);
    }

    // process the ajax request
    try {
      comments.addComment(data, this)
        .then(async (resp) => {
          if (_.get(resp, 'id')) {
            if (_.get(resp, 'parent_id')) {
              context.commit('prependReply', { commentId: resp.parent_id, reply: _.merge(resp, { isFirst: true }) });
            } else if (!_.isNil(context.state.post_id)) {
              context.commit('prependComment', _.merge(resp, { isFirst: true }));
            } else {
              context.commit('prependCommentFor', { contentId: _.get(resp, 'content_id'), comment: _.merge(resp, { isFirst: true }) });
            }
          }

          // for the Project Comments feed, to remove any post that receives a comment
          if (_.includes(_.get(this.$router, 'history.current.path'), '/project-comments')) {
            let posts = _.filter(context.state.projectCommentsFeed, p => p._id !== data.content_id);
            context.commit('setProjectCommentsFeed', posts);
          }

          after(resp);
          // this will update the users progress for the Commenting badge
          await context.dispatch('updateCommentsBadge');
        })
        .catch((reason) => {
          after({ success: 0, error: reason });
        });
    } catch (e) {
      after({ success: 0, error: e.message });
    }
  },

  loadProjectCommentsFeed(context) {
    return postApi.uncommentedPosts(this.$nodeAxios)
        .then((data) => { 
            context.commit('setProjectCommentsFeed', data);
        });
  }
};

// mutations
const mutations = {
  /**
   * setLimit
   *
   * setter for limit of the comments endpoint cursor
   *
   * @param object current_state  the object that describes the current states of this handler
   * @param int limit  the new limit for the endpoint
   */
  setLimit(currentState, limit) {
    currentState.limit = limit;
  },

  /**
   * setSpecificCommentId
   *
   * when loading a specific comment (like for a permalink) we need to track which comment was loaded
   *
   * @param object current_state  the object that describes the current states of this handler
   * @param string id  the id of the loaded comment
   */
  setSpecificCommentId(currentState, id) {
    currentState.specificCommentId = id;
  },

  /**
   * setSpecificCommentIdFor
   *
   * when loading a specific comment (like for a permalink) we need to track which comment was loaded, for a TnQ
   *
   * @param object current_state  the object that describes the current states of this handler
   * @param string id  the id of the loaded comment
   */
  setSpecificCommentIdFor(currentState, { contentId, commentId }) {
    makeNewCommentForBlock(currentState, contentId);
    Vue.set(currentState.commentsFor[contentId], 'specificCommentId', commentId);
  },

  /**
   * setPostId
   *
   * setter for updating the internal post_id of the current comment section
   *
   * @param object current_state  the object that describes the current states of this handler
   * @param array post_id  the id of the current post
   */
  setPostId(currentState, postId) {
    currentState.post_id = postId;
  },

  /**
   * prependCommentFor
   *
   * add a comment the beginning of the comment list for a SPECIFIC contentId
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string contentId  the id of the content to load the comments for
   * @param array comment  the comment to add to the list
   */
  prependCommentFor(currentState, { contentId, comment }) {
    currentState.commentsFor[contentId].comments.unshift(comment);
    updateCommentIndex(currentState, currentState.commentsFor[contentId].comments, contentId);
  },

  /**
   * setTotalFor
   *
   * set the total number of comments that are on A SPECIFIC content_id
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string contentId  the id of the content to load the comments for
   * @param integer total  the number of comments to record as the total
   */
  setTotalFor(currentState, { contentId, cnt }) {
    makeNewCommentForBlock(currentState, contentId);
    Vue.set(currentState.commentsFor[contentId], 'total', cnt);
  },

  /**
   * appendCommentsFor
   *
   * add more comments to A SPECIFIC existing list of comments
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string contentId  the id of the content to load the comments for
   * @param array commentsList  the list of comments to use as the new data
   */
  appendCommentsFor(currentState, { contentId, commentsList }) {
    makeNewCommentForBlock(currentState, contentId);

    // @NOTE - we cannot simplify this
    // @reference - https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    dynamicReassignment(currentState, contentId, commentsList, (data, intoObj) => {
      intoObj.comments = intoObj.comments.concat(data);
      return intoObj;
    });

    // update the index lookup map
    updateCommentIndex(currentState, currentState.commentsFor[contentId].comments, contentId);
  },

  /**
   * prependCommentsFor
   *
   * add more comments to A SPECIFIC existing list of comments, onto the beginning of the list, in reverse order in which they came in
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string contentId  the id of the content to load the comments for
   * @param array commentsList  the list of comments to use as the new data
   */
  prependCommentsFor(currentState, { contentId, commentsList }) {
    makeNewCommentForBlock(currentState, contentId);

    // @NOTE - we cannot simplify this
    // @reference - https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    dynamicReassignment(currentState, contentId, commentsList, (data, intoObj) => {
      // do the inclusion in reverse order, like prependComment() function
      data.forEach((itm) => {
        intoObj.comments.unshift(itm);
      });
      return intoObj;
    });

    // update the index lookup map
    updateCommentIndex(currentState, currentState.commentsFor[contentId].comments, contentId);
  },

  /**
   * removeCommentFromListFor
   *
   * removes a given comment from A SPECIFIC list, which triggers a visual update too
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string contentId  the id of the content to load the comments for
   * @param string commentId  the id of the comment to remove
   */
  removeCommentFromListFor(currentState, { contentId, commentId }) {
    makeNewCommentForBlock(currentState, contentId);

    // @NOTE - we cannot simplify this
    // @reference - https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    dynamicReassignment(currentState, contentId, [commentId], (data, intoObj) => {
      data.forEach((cId) => {
        intoObj.comments = intoObj.comments.filter(item => item.id !== cId);
      });
      intoObj.total -= data.length;
      return intoObj;
    });

    // update the index lookup map
    updateCommentIndex(currentState, currentState.commentsFor[contentId].comments, contentId);
  },

  /**
   * prependComment
   *
   * add a comment the beginning of the comment list
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param array comment  the comment to add to the list
   */
  prependComment(currentState, comment) {
    if (currentState.comments.length === 0) {
      comment.isLast = true;
    }
    currentState.comments.unshift(comment);
    currentState.totalCommentCnt += 1;
    updateCommentIndex(currentState, currentState.comments);
  },

  /**
   * setTotal
   *
   * set the total number of comments that are on this post
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param integer cnt  the number of comments to record as the total
   */
  setTotal(currentState, cnt) {
    currentState.totalCommentCnt = cnt;
  },

  /**
   * appendComments
   *
   * add more comments to the existing list of comments
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param array commentsList  the list of comments to use as the new data
   */
  appendComments(currentState, commentsList) {
    let i;
    for (i = 0; i < commentsList.length; i += 1) {
      currentState.comments.push(commentsList[i]);
    }

    // update the index lookup map
    updateCommentIndex(currentState, currentState.comments);
  },

  /**
   * prependComments
   *
   * add more comments to the existing list of comments, onto the beginning of the list, in reverse order in which they came in
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param array commentsList  the list of comments to use as the new data
   */
  prependComments(currentState, commentsList) {
    let i;
    for (i = 0; i < commentsList.length; i += 1) {
      currentState.comments.unshift(commentsList[i]);
    }

    // update the index lookup map
    updateCommentIndex(currentState, currentState.comments);
  },

  /**
   * removeCommentFromList
   *
   * removes a given comment from the list, which triggers a visual update too
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string commentId  the id of the comment to remove
   */
  removeCommentFromList(currentState, params) {
    const { commentId, replyCount } = params;
    // remove it from the all lists
    if (currentState.commentsById[`${commentId}`].parent_id) {
      currentState.commentsById[currentState.commentsById[`${commentId}`].parent_id].replies =
        currentState.commentsById[currentState.commentsById[`${commentId}`].parent_id].replies.filter(item => item.id !== commentId);
      currentState.commentsById[currentState.commentsById[`${commentId}`].parent_id].reply_count -= 1;
    }
    currentState.comments = currentState.comments.filter(item => item.id !== commentId);
    // used to remove from commentsFor later
    const deletedComment = currentState.commentsById[`${commentId}`];

    // remove from commentsById lookup
    delete currentState.commentsById[`${commentId}`];

    log('------------------------------------', currentState.totalCommentCnt, '-', (1 + toInt(replyCount)), '=');
    // update the visual for the total comment count
    currentState.totalCommentCnt -= (1 + toInt(replyCount));
    log('++++++++++++++++++++++++++++++++++++', currentState.totalCommentCnt);

    // update commentsFor list
    const contentId = deletedComment.content_id;
    if (currentState.commentsFor[contentId]) {
      const tmpCommentsFor = currentState.commentsFor[contentId].comments;
      currentState.commentsFor[contentId].comments = tmpCommentsFor.filter(cmt => cmt.id !== commentId);
      // update the index lookup map
      updateCommentIndex(currentState, currentState.commentsFor[contentId].comments, contentId);
    }
  },

  /**
   * prependReply
   *
   * add a reply to the comment reply list
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string commentId  the id of the comment to add the reply to
   * @param object reply  the reply to add to the list
   */
  prependReply(currentState, { commentId, reply }) {
    // find the parent comment
    const comment = typeof currentState.commentsById[`${commentId}`] !== 'undefined' && currentState.commentsById[`${commentId}`] !== null
      ? currentState.commentsById[`${commentId}`]
      : null;

    log('inside prepend', commentId, comment, reply, reply.id);
    // if there is a comment found, add the replies to it's list
    if (comment && reply && reply.id) {
      if (comment.replies.length === 0) {
        reply.isLast = true;
      }
      comment.replies.unshift(reply);
      comment.reply_count += 1;
      log('triggering update');
      triggerReplyChanges(currentState, comment.content_id, commentId);
    }
    currentState.totalCommentCnt += 1;

    // update index
    updateCommentIndex(currentState, [reply]);
  },

  /**
   * appendReplies
   *
   * add more replies to an existing list of replies to a comment
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string commentId  the id of the comment to add the replies to
   * @param array replies  the list of replies to add to the list
   */
  appendReplies(currentState, { commentId, replies }) {
    // find the parent comment
    const comment = typeof currentState.commentsById[`${commentId}`] !== 'undefined' && currentState.commentsById[`${commentId}`] !== null
      ? currentState.commentsById[`${commentId}`]
      : null;

    // if there is a comment found, add the replies to it's list
    if (comment && replies) {
      let i;
      for (i = 0; i < replies.length; i += 1) {
        comment.replies.push(replies[i]);
      }

      // update index
      triggerReplyChanges(currentState, comment.content_id, commentId);
      updateCommentIndex(currentState, replies);
    }
  },

  /**
   * prependReplies
   *
   * add more replies to an existing list of replies to a comment, to the beginning of the list, in reverse order from the response (which is the correct order chronologically)
   *
   * @param object currentState  the object that describes the current states of this handler
   * @param string commentId  the id of the comment to add the replies to
   * @param array replies  the list of replies to add to the list
   */
  prependReplies(currentState, { commentId, replies }) {
    // find the parent comment
    const comment = typeof currentState.commentsById[`${commentId}`] !== 'undefined' && currentState.commentsById[`${commentId}`] !== null
      ? currentState.commentsById[`${commentId}`]
      : null;

    // if there is a comment found, add the replies to it's list
    if (comment && replies) {
      let i;
      for (i = 0; i < replies.length; i += 1) {
        comment.replies.unshift(replies[i]);
      }

      // update index
      triggerReplyChanges(currentState, comment.content_id, commentId);
      updateCommentIndex(currentState, replies);
    }
  },

  /**
   * addCommentHeart
   *
   * add a heart record for the specified comment
   *
   * @param object currentState  the object describing the curetn state of this handler
   * @param string commentId  the id of the comment that currently has their menu open
   */
  addCommentHeart(currentState, params) {
    const { commentId } = params;
    const comment = currentState.commentsById[`${commentId}`];
    if (comment) {
      comment.hearted = true;
      comment.action_counts.hearts = clamp(parseInt(comment.action_counts.hearts, 10) + 1, 0, 1000000000000);
    }
  },

  /**
   * removeCommentHeart
   *
   * remove a heart record for the specified comment
   *
   * @param object currentState  the object describing the curetn state of this handler
   * @param string commentId  the id of the comment that currently has their menu open
   */
  removeCommentHeart(currentState, params) {
    const { commentId } = params;
    const comment = currentState.commentsById[`${commentId}`];
    if (comment) {
      comment.hearted = false;
      comment.action_counts.hearts = clamp(parseInt(comment.action_counts.hearts, 10) - 1, 0, 1000000000000);
    }
  },

  resetCommentsState(currentState) {
    Object.assign(currentState, state());
  },

  setProjectCommentsFeed(currentState, posts) {
    Vue.set(currentState, 'projectCommentsFeed', posts);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
