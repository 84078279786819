// helper functions
import { log, err } from '../helpers';

function uploadImage({ data }, $nodeAxios) {
  const settings = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return $nodeAxios
    .post('/api/v1/content/image/new/', data, settings)
    .then(rawData => rawData.data)
    .catch(e => err('AJAX ERROR[uploadImage]:', e));
}

function deleteImage({ id }, $nodeAxios) {
  return $nodeAxios
    .delete(`/api/v1/content/image/${id}/`)
    .then(rawData => rawData.data)
    .catch(e => err('AJAX ERROR[deleteImage]:', e));
}

export default {
  uploadImage,
  deleteImage,
};
