
import _ from 'lodash';
import $ from 'jquery';
import { log, staticPath } from '@/data/helpers';
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import WebNav from './WebNav';
import PostButton from '../NewPostButton/index';
import MenuDropdownMobile from '../MenuDropdownMobile/index';

export default {
  name: 'LoggedInNav',
  components: { WebNav, PostButton, MenuDropdownMobile },
  data() {
    return {
      isProfileDropdownOpen: false,
      isMenuDropdownMobileOpen: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      inAboutPage: 'getInAboutPage',
      streamInitialized: 'streamClient/streamInitialized',
      getMyFollows: 'getMyFollows',
    }),
    loggedIn() {
      return !_.isEmpty(this.user._id);
    },
    directMessagingEnabled() {
      return _.get(this.user, 'settings.direct_messaging_enabled', false);
    },
  },
  watch: {
    isMenuDropdownMobileOpen(val) {
      if (val) {
        document.getElementById('scroll-shim').style.marginTop = '0px';
        document.getElementsByClassName('nuxt-view')[0].style.display = 'none';
        document.getElementById('main-footer').style.display = 'none';
      } else {
        document.getElementById('main-footer').removeAttribute('style');
        document.getElementById('scroll-shim').style.marginTop = '85px';
        document.getElementsByClassName('nuxt-view')[0].style.display = 'block';
      }
    },
    loggedIn: {
      immediate: true,
      async handler(val) {
        if (process.browser) {
          if (val && _.get(this.$route, 'path', '/') !== '/logout/') {
            if (!this.streamInitialized && this.directMessagingEnabled) {
              await this.$store.dispatch('streamClient/verifyAndConnect', { user: this.user });
            }
          }
        }
      },
    },
  },
  methods: {
    staticPath,
    // Desktop Menu
    toggleProfileDropdown() {
      this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
    },
    // Mobile Menu
    toggleMenuDropdownMobile(val) {
      if (val) {
        this.isMenuDropdownMobileOpen = !this.isMenuDropdownMobileOpen;
      }
    },
    closeConversationDropdown() {
      if (this.isProfileDropdownOpen) {
        this.isProfileDropdownOpen = false;
      }
    },
    clearWindowSessionSkip($event, navigate) {
      window.localStorage.removeItem('skippedAcceptPrivacyPolicy');
      navigate($event);
    },
  },
};
