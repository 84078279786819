/* eslint no-underscore-dangle: "off" */
import FormData from 'form-data';
import _ from "lodash";
import { log, err } from "../helpers";

function userAcceptPrivacyPolicy(data, $nodeAxios) {
  const promise = $nodeAxios
    .post("/api/v1/user/privacy-policy", data)
    .then((res) => res.data)
    .catch(err);
  return promise;
}

/**
 * Gets partner stories list
 * @param  string   slugs  array of topic slugs to get stories for
 * @return {[type]}        [description]
 */
function partnerStories(slugs, postsPerPage, offset, $nodeAxios) {
  const endpoint = "/api/v1/content/for_topic_slugs";
  return $nodeAxios
    .get(endpoint, {
      params: {
        slugs,
        pp: postsPerPage,
        off: offset,
      },
    })
    .then((response) => response.data)
    .catch((error) => log("partnerStories", error));
}

/**
 * Get content for a partner. Includes Stories, Thoughts and Questions
 *
 * @param {int} partnerId
 * @param {int} postsPerPage
 * @param {int} offset
 */
function partnerContent(
  partnerId,
  postsPerPage,
  lastId = "",
  filterTypes = [],
  $nodeAxios
) {
  const endpoint = `/api/v1/content/for_partner_id/${partnerId}`;
  return $nodeAxios
    .get(endpoint, {
      params: {
        pp: postsPerPage,
        before: lastId,
        types: _.filter(filterTypes),
      },
    })
    .then((response) => {
      const data = response.data;
      if (!lastId && !data.counts && data.total) {
        data.counts = {
          total: data.total
        }
      }
      return data;
    })
    .catch((error) => log("partnerContent", error));
}

/**
 * Gets partner stories list
 * @param  string   slugs  array of topic slugs to get stories for
 * @return {[type]}        [description]
 */
function specificStories(postIds, $nodeAxios) {
  const endpoint = "/api/v1/content/get_many";
  return $nodeAxios
    .get(endpoint, {
      params: {
        post_ids: postIds,
      },
    })
    .then((response) => response.data)
    .catch((error) => log("specificStories", error));
}

/**
 * Report a user by their ID.
 *
 * @param {String} userId : mongoId
 */
function reportUser(userId, $nodeAxios) {
  const endpoint = `/api/v1/user/${userId}/report/`;
  return $nodeAxios
    .put(endpoint, {
      type: "profile",
    })
    .then((response) => response.data)
    .catch((error) => log("reportUser", userId, error));
}

/**
 * Block a user by their ID.
 *
 * @param {String} userId : mongoId
 */
function blockUser(userId, $nodeAxios) {
  return $nodeAxios
    .put("/api/v1/user/block", { blocked_user_id: userId })
    .then((response) => response.data)
    .catch((error) => log("blockUser", userId, error));
}

/**
 * Unblock a user by their ID.
 *
 * @param {String} userId : mongoId
 */
function unblockUser(userId, $nodeAxios) {
  return $nodeAxios
    .put("/api/v1/user/unblock", { blocked_user_id: userId })
    .then((response) => response.data)
    .catch((error) => log("blockUser", userId, error));
}

/**
 * for a provided list of followed users, retrieve their user profile information
 * example use case: checking list of followed users to see which have DMs enabled
 *
 * @param {Array} usernames : array of usernames
 */
function followedUsersInfo(usernames, $nodeAxios) {
  return $nodeAxios
    .post("/api/v1/user/for_usernames", { usernames })
    .then((response) => response.data.users)
    .catch((error) => log("followedUsersInfo", error));
}

function loadActivity(userId, types, pp, offset = 0, sort, $nodeAxios) {
  let typesFilter = "";
  _.forEach(types, (t) => {
    typesFilter += `&types[]=${t}`;
  });

  return $nodeAxios
    .get(`/api/v2/user/activity/${userId}/?pp=${pp}&off=${offset}${typesFilter}&sort=${sort}`)
      .then((response) => response.data)
      .catch((error) => log("loadActivity", error));
}

function loadMentionActivity({ pp = 100, off = 0, }, $nodeAxios) {
  return $nodeAxios.get(`/api/v1/user/mentions/?pp=${pp}&off=${off}`)
    .then((response) => response.data)
    .catch((error) => log("loadMentionActivity", error));
}

async function updateBanner(r, $wpAxios) {
  const formData = new FormData();
  const userData = await getUserSettings($wpAxios);
  const nonce = _.get(userData, 'U._n');
  if (nonce) {
    formData.append("_n", nonce);
  }
  formData.append("action", "tmud");
  formData.append("sa", "upload-splash-image");
  formData.append("splash_image", r);
  return $wpAxios
    .post(
      "/wp-admin/admin-ajax.php?action=tmud&sa=upload-splash-image",
      formData,
      process.server && {
        headers: formData.getHeaders(),
      }
    )
    .then((response) => response)
    .catch((error) => log("updateBanner", error));
}

function registerUser({ email, day, month, year, referrer }, $wpAxios) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("referrer", referrer);
  formData.append("sa", "email-register-login");
  formData.append("birthdate-day", day);
  formData.append("birthdate-month", month);
  formData.append("birthdate-year", year);
  formData.append("action", "tm-login-form");
  formData.append("F", "login");
  formData.append("auto_heart", "false");
  return $wpAxios
    .post("/wp-admin/admin-ajax.php?sa=email-register-login", formData, process.server && {
      headers: formData.getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => error);
}

function getUserSettings($wpAxios) {
  const formData = new FormData();
  formData.append("sa", "user-settings");
  formData.append("action", "tmud");

  return $wpAxios
    .post("/wp-admin/admin-ajax.php", formData, process.server && {
      headers: formData.getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => error);
}

function updateUser({ email, displayname, username, dob, nonce }, $wpAxios) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("sa", "save-account-details");
  formData.append("display-name", displayname);
  formData.append("user-login", username);
  formData.append("dob", dob);
  formData.append("action", "tmud");
  formData.append("_n", nonce);
  return $wpAxios
    .post("/wp-admin/admin-ajax.php?sa=save-account-details", formData, process.server && {
      headers: formData.getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => error);
}

/**
 * confirmEmail
 * checks to see if the email belongs to a user that exists already in our database
 *
 * @param {Array} usernames : array of usernames
 */
function confirmEmail(email, $nodeAxios) {
  return $nodeAxios
    .get(`/api/v1/user/confirm_email/${email}`, {})
    .then((res) => res)
    .catch((error) => error.response.data);
}

/**
 * saveUserPreferences
 * save the respective fields you need to for the user preferences
 *
 * @param {*} formData : form with the fields to update
 * @param {*} $wpAxios  : wordpress axios variable
 */
function saveUserPreferences(formData, $wpAxios) {
  formData.append('action', 'tmud');
  formData.append('sa', 'save-preferences');
  return $wpAxios.post('/wp-admin/admin-ajax.php?sa=save-preferences', formData, process.server && {
    headers: formData.getHeaders(),
  })
      .then(response => response.data)
      .catch(error => error);
}


function searchConditions(term, nonce, $wpAxios, cancelToken = null) {
  const formData = new FormData();
  formData.append('sa', 'find-conditions');
  formData.append('action', 'tmud');
  formData.append('s[term]', term);
  formData.append('_n', nonce);
  return $wpAxios.post('/wp-admin/admin-ajax.php', formData, process.server && {
    headers: formData.getHeaders(),
  })
      .then(response => {
        return response.data.U || [];
      })
      .catch((err) => {
        if ($wpAxios.isCancel(err)) {
            throw new Error('Cancelled');
        }
    })
}

function WpAjaxCall({ formData, sa, nonce, wpAxios }) {
  formData.append('sa', sa);
  formData.append('action', 'tmud');
  formData.append('_n', nonce);
  return wpAxios.post(`/wp-admin/admin-ajax.php?sa=${sa}`, formData, process.server && {
    headers: formData.getHeaders(),
  })
    .then(response => response.data)
    .catch(error => log('error: WpAjaxCall', error));
}

function getSuggestedUsernames(email, wpAxios) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append('sa', 'generate-usernames');
  formData.append('action', 'tm-login-form');
  return wpAxios.post("/wp-admin/admin-ajax.php?sa=generate-usernames", formData, process.server && {
      headers: formData.getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => error);
}

function pinFeaturedContent(userId, contentId, $nodeAxios) {
  return $nodeAxios
    .post(`/api/v1/user/${userId}/featured_content`, { content_id: contentId })
      .then((response) => response.data)
      .catch((error) => log("pinFeaturedContent", error));
}

function removeFeaturedContent(userId, contentId, $nodeAxios) {
  return $nodeAxios
    .delete(`/api/v1/user/${userId}/featured_content`, { data : { content_id: contentId } })
      .then((response) => response.data)
      .catch((error) => log("removeFeaturedContent", error));
}

function profileFeaturedContent(userId, $nodeAxios) {
  return $nodeAxios
    .get(`/api/v1/user/${userId}/featured_content`, {})
      .then((response) => response.data)
      .catch((error) => log("pinFeaturedContent", error));
}

/*
/ this is for keeping track of the users streak for badges
*/
function connectUser(userId, $nodeAxios) {
  return $nodeAxios
    .get(`/api/v1/user/${userId}/connected`, {})
      .then((response) => response.data)
      .catch((error) => log("pinFeaturedContent", error));
}


export default {
  updateBanner,
  partnerStories,
  partnerContent,
  specificStories,
  reportUser,
  blockUser,
  unblockUser,
  followedUsersInfo,
  loadActivity,
  loadMentionActivity,
  userAcceptPrivacyPolicy,
  registerUser,
  updateUser,
  getUserSettings,
  confirmEmail,
  saveUserPreferences,
  searchConditions,
  WpAjaxCall,
  getSuggestedUsernames,
  pinFeaturedContent,
  removeFeaturedContent,
  profileFeaturedContent,
  connectUser,
};
