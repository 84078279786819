import { genAnalyticsEventDefaultData } from '@/data/helpers';

// list of CTA button types that are valid
const validBtnTypes = ['thought', 'question'];
const state = () => ({});

// topic feed event base
const topicFeedEvent = genAnalyticsEventDefaultData({
  context: {
    view: 'topic_feed',
    section: { name: 'about' },
  },
  object: {
    purpose: 'engagement',
  },
});

const actions = {
  /**
   * clickedPostCTA
   *
   * when the user clicks the one-click post-creation CTAs on the topic pages
   *
   * @param string type  the type of the button that was clicked. valid values ['thought', 'question']
   * @param string topicId  the id of the topic that the button was hit on
   * @param string topicName  the name of the topic for the page in view
   */
  clickedPostCTA(context, { type, topicId, topicName }) {
    // the normalized name of the button type
    const btnType = type.toLowerCase();

    // if a valid button type is not supplied
    if (validBtnTypes.indexOf(btnType) < 0) {
      return;
    }

    // build the event
    const data = topicFeedEvent({
      cordial_event: 'choose',
      category: `topic_feed.about.${btnType}_cta`,
      action: 'choose',
      label: topicId,
      context: {
        path: `topic_feed.about.${btnType}_cta`,
        module: { name: `${btnType}_cta` },
      },
      object: {
        name: `${btnType}_cta`,
        type: 'button',
        attributes: {
          label: topicName,
          topic_id: topicId,
        },
      },
    });

    // send the event
    this.$sendAnalyticsEvent(data);
  },

  /**
   * viewedCreateModalFromCta
   *
   * when the user views the creation modal by clicking the new cta buttons
   *
   * @param string type  the type of the button that was clicked. valid values ['thought', 'question']
   * @param string topicId  the id of the topic that the button was hit on
   */
  viewedCreateModalFromCta(context, { type, topicId }) {
    // the normalized name of the button type
    const btnType = type.toLowerCase();

    // if a valid button type is not supplied
    if (validBtnTypes.indexOf(btnType) < 0) {
      return;
    }

    // build the event
    const data = topicFeedEvent({
      cordial_event: 'view',
      category: `topic_feed.submit_post.compose_${btnType}`,
      action: 'view',
      label: topicId,
      context: {
        path: `topic_feed.submit_post.compose_${btnType}`,
        module: { name: `compose_${btnType}` },
      },
    });

    // send the event
    this.$sendAnalyticsEvent(data);
  },

  /**
   * submitCreateModalFromCta
   *
   * when the user submits the creation modal after viewing the by clicking the new cta buttons
   *
   * @param string type  the type of the button that was clicked. valid values ['thought', 'question']
   * @param string topicId  the id of the topic that the button was hit on
   */
  submitCreateModalFromCta(context, { type, topicId }) {
    // the normalized name of the button type
    const btnType = type.toLowerCase();

    // if a valid button type is not supplied
    if (validBtnTypes.indexOf(btnType) < 0) {
      return;
    }

    // build the event
    const data = topicFeedEvent({
      cordial_event: 'choose',
      category: `topic_feed.submit_post.compose_${btnType}.submit_button`,
      action: 'choose',
      label: topicId,
      context: {
        path: `topic_feed.submit_post.compose_${btnType}.submit_button`,
        module: { name: `compose_${btnType}` },
      },
      object: {
        name: 'submit_button',
        type: 'button',
      },
    });

    // send the event
    this.$sendAnalyticsEvent(data);
  },
};

// export this module
export default {
  state,
  getters: {},
  actions,
  mutations: {},
};
