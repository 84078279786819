
import _ from 'lodash';
import $ from 'jquery';
import { log, staticPath } from '@/data/helpers';
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import MenuDropdownMobile from './MenuDropdownMobile';
import segmentAnalytics from '@/libs/events';

export default {
  name: 'NonLoggedInNav',
  components: { MenuDropdownMobile },
  data() {
    return {
      isProfileDropdownOpen: false,
      isMenuDropdownMobileOpen: false,
      openContentGate: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      inAboutPage: 'getInAboutPage',
      getMyFollows: 'getMyFollows',
    }),
    loggedIn() {
      return this.user && this.user.id;
    },
    directMessagingEnabled() {
      return _.get(this.user, 'settings.direct_messaging_enabled', false);
    },
    isContentGateOpen() {
      return this.$store.getters.isModalOpen('content-gate');
    },
  },
  watch: {
    isMenuDropdownMobileOpen(val) {
      if (val) {
        document.getElementById('scroll-shim').style.marginTop = '0px';
        document.getElementsByClassName('nuxt-view')[0].style.display = 'none';
        document.getElementById('main-footer').style.display = 'none';
      } else {
        document.getElementById('main-footer').removeAttribute('style');
        document.getElementById('scroll-shim').style.marginTop = '85px';
        document.getElementsByClassName('nuxt-view')[0].style.display = 'block';
      }
    },
  },
  methods: {
    staticPath,
    // Desktop Menu
    toggleProfileDropdown() {
      this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
    },
    openMenu() {
      if (this.isContentGateOpen) {
        this.$store.dispatch('closeModal');
        this.openContentGate = true;
      }

      this.isMenuDropdownMobileOpen = true;
    },
    // for close icon, if content gate was originally opened we want to open it back up
    toggleMenuDropdownMobile(val, isCloseIcon = false) {
      if (val) {
        this.isMenuDropdownMobileOpen = !this.isMenuDropdownMobileOpen;
      }

      if (isCloseIcon && this.openContentGate) {
        this.$store.dispatch("openModal", {
          id: "content-gate",
          args: {},
        });
        this.openContentGate = false;
      }
    },
    closeConversationDropdown() {
      if (this.isProfileDropdownOpen) {
        this.isProfileDropdownOpen = false;
      }
    },
    openRegistration(action) {
      this.$store.dispatch('openModal', {
        id: 'login-registration-onboarding',
        args: { action },
      });

      segmentAnalytics.choose({
        target: {
          name: 'Join Us',
          type: 'button',
        },
        context: {
          section: 'nav_bar',
        },
        userId: this.user._id,
        sessionId: this.user.sessionId,
      });
    },
    clearWindowSessionSkip($event, navigate) {
      window.localStorage.removeItem('skippedAcceptPrivacyPolicy');
      navigate($event);
    },
  },
};
