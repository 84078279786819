import Vue from 'vue';
import { VueEditor, Quill } from 'vue2-editor';
import 'quill-mention';

import QuillPlainClipBoard from '@/components/QuillTextArea/QuillPlainClipBoard';
import QuillTopicBloat from '@/components/QuillTextArea/QuillTopicBloat';
import QuillTopicNewBloat from '@/components/QuillTextArea/QuillTopicNewBloat';
import QuillUserBloat from '@/components/QuillTextArea/QuillUserBloat';

Quill.register('modules/clipboard', QuillPlainClipBoard, true);
Quill.register('formats/topic', QuillTopicBloat, true);
Quill.register('formats/topic-new', QuillTopicNewBloat, true);
Quill.register('formats/user', QuillUserBloat, true);

Vue.component('VueEditor', VueEditor);

export default function (_, inject) {
  inject('quill', Quill);
}
