const middleware = {}

middleware['authorize'] = require('../middleware/authorize.js')
middleware['authorize'] = middleware['authorize'].default || middleware['authorize']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isLoggedIn'] = require('../middleware/isLoggedIn.js')
middleware['isLoggedIn'] = middleware['isLoggedIn'].default || middleware['isLoggedIn']

export default middleware
