import _ from 'lodash';
import { log } from '@/data/helpers';
/**
 * loadCreatedCommunities
 *
 * retrieves the communities created by the session user
 */
function loadCreatedCommunities($nodeAxios) {
  return $nodeAxios.get('/api/v1/user/me/created_communities/')
    .then(res => res.data.communities)
    .catch((e) => {
      log('Error getting created communities:', e);
    });
}

/**
 * loadFeaturedCommunities
 *
 * retrieves the communities featured by The Mighty
 */
function loadFeaturedCommunities($nodeAxios) {
  return $nodeAxios.get('/api/v1/featured/communities')
    .then(res => {
      if (res.data) {
        return res.data.groups;
      }
    })
    .catch((e) => {
      log('Error getting featured communities:', e);
    });
}

/**
 * createCommunity
 *
 * route for creating a new community
 */
function createCommunity(community, $nodeAxios) {
  return $nodeAxios.post('/api/v1/group', community)
    .then(res => res)
    .catch((e) => {
      log('Error creating new community:', e);
      return e.response.data;
    });
}

/**
 * updateCommunity
 *
 * route for updating a new community
 */
function updateCommunity(groupdId, community, $nodeAxios) {
  return $nodeAxios.put(`/api/v1/group/${groupdId}`, community)
    .then(res => res)
    .catch((e) => {
      log('Error creating new community:', e);
      return e.response.data;
    });
}

/**
 * loadCommunityLimit
 *
 * route that returns whether user can create more communities
 */
function canCreateCommunity($nodeAxios) {
  return $nodeAxios.get('/api/v1/group/limits', {})
    .then(res => res.data.canCreateCommunity)
    .catch((e) => {
      log('Error getting users create community limit', e);
      return e.response.data;
    });
}

/**
 * validateCommunityFields
 *
 * validate array of strings to see whether each one passes moderation
 *
 * @param array strings array of strings to validate
 */
function validateCommunityFields(content, $nodeAxios) {
  return $nodeAxios.post('/api/v1/moderation/validate', { content })
    .then(res => res.data.results)
    .catch((e) => {
      log('Error getting users create community limit', e);
      return e.response.data;
    });
}

/**
 * getBlacklistedKeywords
 *
 * retrieves the global list of blacklisted words (not specific to community)
 *
 * @param array strings array of strings to validate
 */
function getBlacklistedKeywords($nodeAxios) {
  return $nodeAxios.get('/api/v1/moderation/keywords?list=chat_black_list', {})
    .then(res => res.data)
    .catch((e) => {
      log('Error getting blacklistedkeywords', e);
    });
}

/**
 * uploadCommunityImage
 *
 * handles the upload for either community banner or avatar image
 *
 * @param string groupId  id of the group
 * @param string imageType  banner or avatar
 * @param file data  file to be uploaded
 */
function uploadCommunityImage(groupId, imageType, data, $nodeAxios) {
  const settings = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return $nodeAxios.put(`/api/v1/group/update/${groupId}/images/upload/${imageType}`, data, settings)
    .then(res => _.assign({ imageType, url: res.data.images[imageType].url }))
    .catch((e) => {
      log('Error uploading image', e);
    });
}

function getDefaultCommunitySettings($nodeAxios) {
  return $nodeAxios.get('api/v1/group/settings/defaults', {})
    .then(res => res.data)
    .catch((e) => {
      log('Error getting blacklistedkeywords', e);
    });
}

// for the new user profile, this will be the communities in
// which the profile owner is a Leader for
function loadLeaderCommunities(userId, $nodeAxios) {
  return $nodeAxios.get(`api/v1/user/${userId}/communities`, {})
    .then(res => res.data)
    .catch((e) => {
      log('Error getting loadLeaderCommunities', e);
    });
}

export default {
  loadCreatedCommunities,
  loadFeaturedCommunities,
  createCommunity,
  updateCommunity,
  canCreateCommunity,
  validateCommunityFields,
  getBlacklistedKeywords,
  uploadCommunityImage,
  getDefaultCommunitySettings,
  loadLeaderCommunities,
};
